import React, { Component, Fragment } from 'react';
import RequestQuote from '../components/RequestQuote';
import PageTop from '../components/PageTop';
import VtacQuote from '../components/VtacQuote';
import Footer from '../components/Footer';
import NewTopNavigation from '../components/NewTopNavigation';
class RequestQuotePage extends Component {
    render() {
        return (
            <Fragment>
            <NewTopNavigation title="Request Quote-V-Tac Accounting & Tax | The Professionals' Professional" />
            <PageTop topPageAccSoftTitle="Request Quote"/>
            <RequestQuote />
            <VtacQuote />
             <Footer />
           </Fragment>
        );
    }
}

export default RequestQuotePage;