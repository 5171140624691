import React, { Fragment } from 'react';
import BlogTop from '../../components/BlogTop';
import VtacQuote from '../../components/VtacQuote';
import Footer from '../../components/Footer';
import NewTopNavigation from '../../components/NewTopNavigation';
import SearchByWord from '../../components/SearchByWord';
import UnderstandingAccurateBookkeeping from '../../components/Blogs/UnderstandingAccurateBookkeeping';

const UnderstandingAccurateBookkeepingPage = () => {
    return (
        <Fragment>
        <NewTopNavigation title="Understanding the Importance of Accurate Bookkeeping for Your Business: A Guide to Financial Success
            -V-Tac Accounting & Tax | The Professionals' Professional" />
        <BlogTop />
        <SearchByWord />
        <UnderstandingAccurateBookkeeping />
        <VtacQuote />
        <Footer />
    </Fragment>
    );
};

export default UnderstandingAccurateBookkeepingPage;
