import React, { Component, Fragment } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Slide1 from '../asset/images/Slide1.jpg';
import Slide2 from '../asset/images/Slide2.jpg';
import Slide3 from '../asset/images/Slide3.jpg';
import Slide4 from '../asset/images/Slide4.jpg';
import Slide5 from '../asset/images/Slide5.jpg';

class AboutUs extends Component {
    render() {
        var settings = {
            arrows: false,
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 3000,
            fade: true,
            fadeSpeed: 1000,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        initialSlide: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        return (
            <Fragment>
                <Container fluid={true} className="accContainer">

                    <Container className='accSection'>
                        <div>
                            <h2 className='accTitle'>
                                About Us</h2>
                        </div>
                        <div>
                            <p className='accSubtitle'>V-Tac was established in <span className='spancolor'>1997</span> and introduced its accounting and taxation services to Canada. Over the years, V-Tac has continued to expand its operations and accrue a diverse clientele because of its reputable knowledge and reliable financial services. V-Tac has been providing<span className='spancolor'> over 25 years</span> of solid expertise in a variety of financial areas such as: bookkeeping, accounting, business consulting, corporate taxes, personal income tax and payroll services across Canada.</p>

                        </div>
                        <div className='mt-5'></div>
                        <Row>
                            <Col lg={6} md={6} sm={12}>

                                <div className='accSlide mt-4' >
                                    <Slider {...settings}>
                                        <div>
                                            <Row>
                                                <Col>
                                                    <a> <img className="clientImage img-fluid" src={Slide1} alt="meeting"/></a>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div>
                                            <Row>
                                                <Col>
                                                    <a> <img className="clientImage img-fluid" src={Slide2} alt="conference"/></a>
                                                </Col>
                                            </Row>
                                        </div>

                                        <div>
                                            <Row>
                                                <Col>
                                                    <a> <img className="clientImage img-fluid" src={Slide4} alt="V-tac-tax"/></a>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div>
                                            <Row>
                                                <Col>
                                                    <a> <img className="clientImage img-fluid" src={Slide5} alt="all employees"/></a>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div>
                                            <Row>
                                                <Col>
                                                    <a> <img className="clientImage img-fluid" src={Slide3} alt="team meeting"/></a>
                                                </Col>
                                            </Row>
                                        </div>

                                    </Slider>
                                </div>
                            </Col>
                            <Col lg={6} md={6} sm={12} >
                                <p className='accSubtitle mt-3'>V-Tac makes <span className='spancolor'>every effort</span> to provide professional services to its diverse clients that range from small businesses to large corporations. Our staff affords invaluable knowledge that is intended to benefit our clients to help them succeed and financially grow. We take pride in working closely with our customers to better understand their goals and expectations in order to create better solutions for positive outcomes.</p>
                                <p className='accSubtitle'>We know <span className='spancolor'>our clients</span> play an integral role in our company’s success. We thus transform the knowledge we possess into a service that benefits our clients at affordable rates. In today’s challenging market, we understand the need for reliable advice, quick responses, and immediate results. This is why we strive to deliver the highest standard of services, to surpass customer expectations.</p>
                            </Col>
                        </Row>
                        <div className='mt-5'></div>
                        <Container>
                            <Row>
                                <Col lg={12} md={12} sm={12}>
                                    <p className='description mt-5'>
                                        We recognize that our <span className='spancolor'>quality</span> services are a result of our exceptionally trained staff. Our team work hard to contribute their knowledge in helping businesses function effectively while forming strong relationships to build rapport and increase economic growth. V-Tac employees take pride in the way they deliver personal attention and accommodate customers’ needs through the best practical solutions our company has to offer. Our expertise, flexibility, and creativity are incomparable and set us apart from mainstream competitors, thereby making us the better and more productive alternative.
                                    </p>
                                </Col>
                            </Row>
                        </Container>
                    </Container>
                </Container>
            </Fragment>
        );
    }
}

export default AboutUs;