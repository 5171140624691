import React, { Component, Fragment } from 'react';
import BlogTop from '../components/BlogTop';
import VtacQuote from '../components/VtacQuote';
import Footer from '../components/Footer';
import NewTopNavigation from '../components/NewTopNavigation';
import TaxTips from '../components/TaxTips';
import BlogSlide from '../components/BlogSlide';

class TaxTipsPage extends Component {
    render() {
        return (
            <Fragment>
            <NewTopNavigation title="Tips For Tax | The Professionals' Professional" />
            <BlogTop />
            {/* topPageTitle="Tips For Tax" topPagesubTitle="V-Tac has been providing over 20 years of solid expertise in a variety of financial areas such as: bookkeeping, accounting, business consulting, corporate taxes, personal income tax and payroll services across Canada."  */}
            <TaxTips />
            <BlogSlide />
            <VtacQuote />
            <Footer />
        </Fragment>
        );
    }
}

export default TaxTipsPage;