import React, { Component, Fragment } from 'react';
import OtherServices from '../components/OtherServices';
import PageTop from '../components/PageTop';
import VtacQuote from '../components/VtacQuote';
import Footer from '../components/Footer';
import NewTopNavigation from '../components/NewTopNavigation';
class OtherServicesPage extends Component {
    render() {
        return (
            <Fragment>
                <NewTopNavigation title="Other Services-V-Tac Accounting & Tax | The Professionals' Professional" />
                <PageTop topPageTitle="Other Services" topPagesubTitle="We provide technical support in preparing Cash Flow Statements, Financial Statements, Tax Planning, Bank Reconciliation, HST and maintain an assortment of accounting data for clients in our digital system." />
                <OtherServices />
                <VtacQuote />
                <Footer />
            </Fragment>
        );
    }
}

export default OtherServicesPage;