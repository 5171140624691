import React, { Component, Fragment } from 'react';
import { Col, Container, Row, Button } from 'react-bootstrap';

import { Link } from 'react-router-dom';

class VtacQuote extends Component {
    render() {
        return (
            <Fragment>
                <Container fluid={true} className='footerConsultation'>
                    <div className='container'>
                        <Row>
                            <Col lg={8} md={6} sm={12}>
                                <div data-aos="fade-down"
                                    data-aos-duration="1000"
                                    data-aos-once="true"><h2 className='quotitle mt-5'>Would you like us to send you a quote?</h2>
                                    <p className='quoSubtitle'>Fill out the form and we’ll be right with you in a short time!</p></div>
                            </Col>
                            <Col lg={4} md={6} sm={12}>
                                <div className='quoteBtndiv' data-aos="fade-down"
                                    data-aos-duration="1000"
                                    data-aos-once="true">
                                    <Button className="quoteBtn mt-5 mb-3" to="/quote">
                                        <Link to="/request-quote" onClick={() => {
                                            window.scrollTo({ top: 0, behavior: 'smooth' });
                                        }} ><span>Request Quote</span></Link>
                                    </Button></div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </Fragment>
        );
    }
}

export default VtacQuote;