import React, { Component, Fragment } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import TAXTiis from '../asset/images/TAX Tiis.png';
import TAXRATE from '../asset/images/TAX RATE.png';
import house from '../asset/images/house.jpg';
import TAXDates from '../asset/images/TAX Dates.png';
import tipsfsttimefiling2 from '../asset/images/5tips-fst-time-filing2.webp';
import taximplication1 from '../asset/images/tax-implication1.webp';
import Studenttax from '../asset/images/Student tax.png';
import SelfEmployedTaxpayers from '../asset/images/Self-Employed -Taxpayers.png';
import taxinmultiplestate1 from '../asset/images/tax-in-multiple-state1.png';
import taxdeduction2 from '../asset/images/tax-deduction-2.png';


class BlogPart7 extends Component {
    render() {
        return (
            <Fragment>
                <Container>
                    <Row>
                        <Col lg={8} md={6} sm={12}>
                            <div className='blogDetailDiv'>
                                <h2 className='blogTopTitle fs-1'>5 tips for a great first-time filing experience</h2>
                                <p className='blogDesc'>Filing your first tax return in Canada doesn't have to be a daunting experience. Whether you're a first-time filer or prepping for the upcoming tax season, use these five helpful tips to simplify the process and make sure you get the most out of your return.</p>
                                <h2 className='blogDescTitle'>Stay up-to-date on key deadlines:</h2>
                                <p className='blogDesc'>Using a calendar (or calendar app) can be a great way to stay on top of important tax-related dates. Set reminders on your phone for when the CRA opens for electronic filing, tax filing deadlines, and other important dates. That way, you'll be prepared when it's time to file.</p>
                                <p className='blogDesc'>It is important to file a tax return even if you did not have any income over the year. The government requires this information to verify that you are eligible for federal and provincial benefits like the Canada Child Benefit (CCB), GST/HST Credit, and the BC Recovery Benefit. If you do not file a return, you will not receive these benefits.</p>
                                <h2 className='blogDescTitle'>Remain organized: </h2>
                                <img variant="top" className='blog1PageImg2 mt-4 mb-5' src={tipsfsttimefiling2}></img>
                                <p className='blogDesc'>To complete your tax return, you will need your tax slips. These slips can come from your school, employer, or government, and provide information such as the income you have earned or any amounts you owe. If you are a resident of Québec, you may receive both federal and Québec slips from Revenu Québec.</p>
                                <p className='blogDesc'>Make it a habit to file away your receipts each time you empty your wallet or when you receive them electronically. Consider taking photos of hard-copy receipts and adding them to a designated digital folder. It is essential to keep all your documents for at least 6 years as the CRA and Revenu Québec may request to see them during a detailed review of your return.</p>
                                <h2 className='blogDescTitle'>Determine your current relationship status:</h2>
                                <p className='blogDesc'>Your marital status is important when it comes to filing taxes and understanding your benefits. It determines whether you need to file as an individual or with a spouse or common-law partner. Your marital status could be single, married, divorced, separated, widowed, or in a common-law relationship.</p>
                                <p className='blogDesc'>If you are legally married or in a common-law relationship, you may decide to file your tax return on your own or jointly with your spouse. To maximize your refund or reduce the tax you owe, consider pooling, splitting, or transferring certain credits and deductions with your spouse. For further information, consult the H&R Block Online Help Centre for articles on this topic.</p>
                                <h2 className='blogDescTitle'>Take full advantage of the available supports:</h2>
                                <p className='blogDesc'>For over 25 years, V-TAC has been helping Canadians with all of their tax needs. With our extensive network of trusted Tax Experts, our service will suit your individual requirements, so you can get the most out of your tax return. We guarantee you will get the maximum refund with our Maximum Refund Guarantee.</p>
                                <h2 className='blogDescTitle'>Create an online account next year to access your tax information:</h2>
                                <p className='blogDesc'>Once you've filed your first return in Canada, you'll be able to create a CRA My Account (and Revenu Québec My Account for Individuals, if you're a resident of Québec). Through your account, you'll be able to check the status of your refund, access tax slips from previous years, set up direct deposits for your benefits, and receive secure messages from the CRA or Revenu Québec. It's important to make sure the CRA and Revenu Québec have up-to-date information regarding any changes to your name, marital status, or address before you file your return. </p>
                                <p className='blogDesc'>You won't be able to take advantage of all these services as a first-time filer, but you can look forward to utilizing them when you prepare your return next year!</p>

                            </div>
                        </Col>

                        <Col lg={4} md={6} sm={12}>
                            <div className='relatedArticle'>
                                <h2 className='fs-3 '>Related Articles</h2>
                                <hr style={{ width: "100%", float: "left" }}></hr>
                            </div>
                            <Card className='blogCardRight' >
                                <Link to="/ways-to-stay-ahead-of-tax-season" style={{ textDecoration: "none" }}>
                                    <div>
                                        <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                        <Card.Img variant="top" className='industryImg' src={TAXTiis} />
                                    </div>

                                    <Card.Body>
                                        <Card.Title className='blogCardRightTitle'>8 straight ways to stay ahead of tax season 2023</Card.Title>
                                    </Card.Body>
                                </Link>
                            </Card>
                            <Card className='blogCardRight float-right'>
                                <Link to="/federal-tax-brackets-rates-credits" style={{ textDecoration: "none" }}>
                                    <div>
                                        <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                        <Card.Img variant="top" className='industryImg' src={TAXRATE} />
                                    </div>

                                    <Card.Body>
                                        <Card.Title className='blogCardRightTitle'>Federal Tax Brackets, Rates & Credits 2023</Card.Title>
                                    </Card.Body>
                                </Link>
                            </Card>
                            <Card className='blogCardRight float-right'>
                                <Link to="/housing-benefit-and-the-way-to-apply" style={{ textDecoration: "none" }}>
                                    <div>
                                        <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                        <Card.Img variant="top" className='industryImg' src={house} />
                                    </div>

                                    <Card.Body>
                                        <Card.Title className='blogCardRightTitle'>Are you a renter in Canada? Learn about the Housing Benefit and the way to apply for it. </Card.Title>
                                    </Card.Body>
                                </Link>
                            </Card>

                            <Card className='blogCardRight float-right'>
                                <Link to="/important-tax-deadlines" style={{ textDecoration: "none" }}>
                                    <div>
                                        <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                        <Card.Img variant="top" className='industryImg' src={TAXDates} />
                                    </div>

                                    <Card.Body>
                                        <Card.Title className='blogCardRightTitle'>Important Tax Deadlines 2023</Card.Title>
                                    </Card.Body>
                                </Link>
                            </Card>
                            <Card className='blogCardRight'>
                                <Link to="/tips-for-tax-students" style={{ textDecoration: "none" }}>
                                    <div>
                                        <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                        <Card.Img variant="top" className='industryImg' src={Studenttax} />
                                    </div>

                                    <Card.Body>
                                        <Card.Title className='blogCardRightTitle'>Maximizing Your 2022 Return: Tips for Students</Card.Title>
                                    </Card.Body>
                                </Link>
                            </Card>
                            <Card className='blogCardRight float-right'>
                                <Link to="/tax-implications-for-having-multiple-employers" style={{ textDecoration: "none" }}>
                                    <div>
                                        <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                        <Card.Img variant="top" className='industryImg' src={taximplication1} />
                                    </div>

                                    <Card.Body>
                                        <Card.Title className='blogCardRightTitle'>Tax implications for having multiple employers</Card.Title>
                                    </Card.Body>
                                </Link>
                            </Card>
                            <Card className='blogCardRight float-right'>
                                <Link to="/tips-for-self-employed-taxpayers" style={{ textDecoration: "none" }}>
                                    <div>
                                        <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                        <Card.Img variant="top" className='industryImg' src={SelfEmployedTaxpayers} />
                                    </div>

                                    <Card.Body>
                                        <Card.Title className='blogCardRightTitle'>Tips for Self-Employed Taxpayers</Card.Title>
                                    </Card.Body>
                                </Link>
                            </Card>
                            <Card className='blogCardRight float-right'>
                                <Link to="/how-to-filing-taxes-in-multiple-states-when-moved" style={{ textDecoration: "none" }}>
                                    <div>
                                        <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                        <Card.Img variant="top" className='industryImg' src={taxinmultiplestate1} />
                                    </div>

                                    <Card.Body>
                                        <Card.Title className='blogCardRightTitle'>How to Filing Taxes in Multiple States When Moved</Card.Title>
                                    </Card.Body>
                                </Link>
                            </Card>
                            <Card className='blogCardRight float-right'>
                                <Link to="/tax-deductions-for-medical-expenses-under-the-CRA" style={{ textDecoration: "none" }}>
                                    <div>
                                        <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                        <Card.Img variant="top" className='industryImg' src={taxdeduction2} alt="tax-deduction-for medical- expenses" />
                                    </div>

                                    <Card.Body>
                                        <Card.Title className='blogCardRightTitle'>Tax deductions for medical expenses under the CRA</Card.Title>
                                    </Card.Body>
                                </Link>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        );
    }
}

export default BlogPart7;