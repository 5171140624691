import React, { Component, Fragment } from 'react';
import BlogTop from '../components/BlogTop';
import VtacQuote from '../components/VtacQuote';
import Footer from '../components/Footer';
import NewTopNavigation from '../components/NewTopNavigation';
import SearchByWord from '../components/SearchByWord';
import BlogPart10 from '../components/BlogPart10';


class BlogPart10Page extends Component {
    render() {
        return (
            <Fragment>
                <NewTopNavigation title="Tax deductions for medical expenses under the CRA-V-Tac Accounting & Tax | The Professionals' Professional" />
                <BlogTop />
                <SearchByWord/>
                <BlogPart10 />
                <VtacQuote />
                <Footer />
            </Fragment>
        );
    }
}

export default BlogPart10Page;