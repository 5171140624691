import React, { Component, Fragment } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
// import TaxTips2 from '../asset/images/tax-small-2.jpg';
import TaxTips2 from '../asset/images/tax-small-3.jpg';
import TaxDate from '../asset/images/Tax Datess.png';
import TAXRATE from '../asset/images/TAX RATE.png';
import house from '../asset/images/house.jpg';
import Studenttax from '../asset/images/Student tax.png';
import tipsfsttimefiling2 from '../asset/images/5tips-fst-time-filing2.webp';
import taximplication1 from '../asset/images/tax-implication1.webp';
import SelfEmployedTaxpayers from '../asset/images/Self-Employed -Taxpayers.png';
import taxinmultiplestate1 from '../asset/images/tax-in-multiple-state1.png';
import taxdeduction2 from '../asset/images/tax-deduction-2.png';

class BlogPart4 extends Component {
    render() {
        return (
            <Fragment>
                <Container>
                    <Row>
                        <Col lg={8} md={6} sm={12}>
                            <div className='blogDetailDiv'>
                                <h2 className='blogTopTitle fs-1'>Are you a renter in Canada? Learn about the Housing Benefit and the way to apply for it.</h2>
                                <p className='blogDesc'>Are you a Canadian renter with a low income? If so, you can now apply for the Housing Benefit! This one-time tax-free payment is offered to those with an adjusted net income of less than $20,000 for individuals or $35,000 for families and can be worth up to $500. Don't miss out on this opportunity to get some help with your rent. The last day of submitting the application is Friday, March 31, 2023.</p>
                                <img variant="top" className='blog1PageImg2 mt-3 mb-3' src={house} alt="tips-for-housing-enefit"></img>
                                <h2 className='blogDescTitle'>To qualify for the 2022 Rental Assistance Benefit, you must meet the following criteria</h2>
                                <ul className='fs-5'>
                                    <li>Be at least 15 years old on December 1, 2022.</li>
                                    <li>Have lived in Canada in 2022 and your principal residence (house, apartment, college residence, etc.) must have been in Canada on December 1, 2022.</li>
                                    <li>Both you and your spouse/partner (if applicable) must have filed a 2021 income tax return.</li>
                                    <li>Have paid rent in 2022 that is equal to at least 30% of your 2021 adjusted family net income.</li>
                                    <li>This amount does not include things like meals, utilities, taxes, rent-to-own payments, or rent paid to a parent or relative for contributing to household expenses.</li>
                                </ul>
                                <h2 className='blogDescTitle'>If you are eligible for the $35,000 adjusted net income threshold, you must meet certain criteria</h2>
                                <p className='blogDesc'>You must have had a spouse or common-law partner on December 1, 2022, and you must have lived with a child under the age of 18 on the same date. Additionally, you must have claimed an eligible dependent amount on your 2021 income tax return. </p>
                                <h2 className='blogDescTitle'>For those in complicated living situations, here are a few tips</h2>
                                <p className='blogDesc'>Applicants filing for this benefit will need to provide the following information: </p>
                                <ul className='fs-5'>
                                    <li>The address(es) of all their principal residences in Canada for 2022.</li>
                                    <li>The total rent paid for these residences in the 2022 calendar year</li>
                                    <li>The name and contact information of the person to whom the rent was paid.</li>
                                </ul>
                                <p className='blogDesc'>You should keep rent receipts or other documentation that can verify the claim (such as a letter from the landlord) for six years in case the Canada Revenue Agency (CRA) requests to verify the claim.</p>
                                <h2 className='blogDescTitle'>You can apply in three different ways</h2>
                                <ul className='fs-5'>
                                    <li>Through My Account or My Service Canada Account (waiting time is estimated to be 5 to 10 business days)</li>
                                    <li>By completing the online application form on the CRA website (waiting time is estimated to be 6 to 11 business days). </li>
                                    <li>By calling 1-800-282-8079 (waiting time is estimated to be 7 to 12 business days).</li>
                                </ul>
                            </div>
                        </Col>
                        <Col lg={4} md={6} sm={12}>
                            <div className='relatedArticle'>
                                <h2 className='fs-3 '>Related Articles</h2>
                                <hr style={{ width: "100%", float: "left" }}></hr>
                            </div>
                            <Card className='blogCardRight float-right'>
                                <Link to="/ways-to-stay-ahead-of-tax-season" style={{ textDecoration: "none" }}>
                                    <div>
                                        <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                        <Card.Img variant="top" className='industryImg' src={TaxTips2} />
                                    </div>

                                    <Card.Body>
                                        <Card.Title className='blogCardRightTitle'>8 straight ways to stay ahead of tax season 2023</Card.Title>
                                    </Card.Body>
                                </Link>
                            </Card>
                            <Card className='blogCardRight float-right'>
                                <Link to="/important-tax-deadlines" style={{ textDecoration: "none" }}>
                                    <div>
                                        <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                        <Card.Img variant="top" className='industryImg' src={TaxDate} />
                                    </div>

                                    <Card.Body>
                                        <Card.Title className='blogCardRightTitle'>Important Tax Deadlines 2023</Card.Title>
                                    </Card.Body>
                                </Link>
                            </Card>
                            <Card className='blogCardRight float-right'>
                                <Link to="/federal-tax-brackets-rates-credits" style={{ textDecoration: "none" }}>
                                    <div>
                                        <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                        <Card.Img variant="top" className='industryImg' src={TAXRATE} />
                                    </div>

                                    <Card.Body>
                                        <Card.Title className='blogCardRightTitle'>Federal Tax Brackets, Rates & Credits 2023</Card.Title>
                                    </Card.Body>
                                </Link>
                            </Card>
                            <Card className='blogCardRight'>
                                <Link to="/tips-for-tax-students" style={{ textDecoration: "none" }}>
                                    <div>
                                        <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                        <Card.Img variant="top" className='industryImg' src={Studenttax} />
                                    </div>

                                    <Card.Body>
                                        <Card.Title className='blogCardRightTitle'>Maximizing Your 2022 Return: Tips for Students</Card.Title>
                                    </Card.Body>
                                </Link>
                            </Card>

                            <Card className='blogCardRight float-right'>
                                <Link to="/tax-implications-for-having-multiple-employers" style={{ textDecoration: "none" }}>
                                    <div>
                                        <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                        <Card.Img variant="top" className='industryImg' src={taximplication1} />
                                    </div>

                                    <Card.Body>
                                        <Card.Title className='blogCardRightTitle'>Tax implications for having multiple employers</Card.Title>
                                    </Card.Body>
                                </Link>
                            </Card>

                            <Card className='blogCardRight float-right'>
                                <Link to="/tips-for-a-great-first-time-filing-experience" style={{ textDecoration: "none" }}>
                                    <div>
                                        <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                        <Card.Img variant="top" className='industryImg' src={tipsfsttimefiling2} />
                                    </div>

                                    <Card.Body>
                                        <Card.Title className='blogCardRightTitle'>5 tips for a great first-time filing experience</Card.Title>
                                    </Card.Body>
                                </Link>
                            </Card>
                            <Card className='blogCardRight float-right'>
                                <Link to="/tips-for-self-employed-taxpayers" style={{ textDecoration: "none" }}>
                                    <div>
                                        <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                        <Card.Img variant="top" className='industryImg' src={SelfEmployedTaxpayers} />
                                    </div>

                                    <Card.Body>
                                        <Card.Title className='blogCardRightTitle'>Tips for Self-Employed Taxpayers</Card.Title>
                                    </Card.Body>
                                </Link>
                            </Card>
                            <Card className='blogCardRight float-right'>
                                <Link to="/how-to-filing-taxes-in-multiple-states-when-moved" style={{ textDecoration: "none" }}>
                                    <div>
                                        <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                        <Card.Img variant="top" className='industryImg' src={taxinmultiplestate1} />
                                    </div>

                                    <Card.Body>
                                        <Card.Title className='blogCardRightTitle'>How to Filing Taxes in Multiple States When Moved</Card.Title>
                                    </Card.Body>
                                </Link>
                            </Card>
                            <Card className='blogCardRight float-right'>
                                <Link to="/tax-deductions-for-medical-expenses-under-the-CRA" style={{ textDecoration: "none" }}>
                                    <div>
                                        <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                        <Card.Img variant="top" className='industryImg' src={taxdeduction2} alt="tax-deduction-for medical-expenses" />
                                    </div>

                                    <Card.Body>
                                        <Card.Title className='blogCardRightTitle'>Tax deductions for medical expenses under the CRA</Card.Title>
                                    </Card.Body>
                                </Link>
                            </Card>

                        </Col>
                    </Row>
                </Container>
            </Fragment>
        );
    }
}

export default BlogPart4;