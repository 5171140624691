import React, { Component, Fragment } from 'react';
import PageTop2 from '../components/PageTop2';
import VtacQuote from '../components/VtacQuote';
import Footer from '../components/Footer';
import NewTopNavigation from '../components/NewTopNavigation';
import OnlineTaxFiling from '../components/OnlineTaxFiling';

class OnlineTaxFilingPage extends Component {
    render() {
        return (
            <Fragment>
            <NewTopNavigation title="Remote Tax Filing-V-Tac Accounting & Tax | The Professionals' Professional" />
            <PageTop2  topPageTitle="Remote Tax Filing" topPagesubTitle="The 2022 tax deadline is Monday, May 1st, 2023." />
            <OnlineTaxFiling />
            <VtacQuote />
            <Footer />
        </Fragment>
        );
    }
}

export default OnlineTaxFilingPage;