import React, { Component, Fragment } from 'react';
import { Route, Routes } from "react-router-dom";
import HomePage from '../pages/HomePage';
import AccountingPage from '../pages/AccountingPage';
import BookkipingPage from '../pages/BookkipingPage';
import TaxationPage from '../pages/TaxationPage';
import PayrollProcessingPage from '../pages/PayrollProcessingPage';
import VtacTaxClinicPage from '../pages/VtacTaxClinicPage';
import BusinessIncorporationPage from '../pages/BusinessIncorporationPage';
import BusinessPlanLoanPage from '../pages/BusinessPlanLoanPage';
import AccountingSoftwarePage from '../pages/AccountingSoftwarePage';
import CaseStudyPage from '../pages/CaseStudyPage';
import OtherServicesPage from '../pages/OtherServicesPage';
import AboutUsPage from '../pages/AboutUsPage';
import Covid19Page from '../pages/Covid19Page';
import UsefulLinkPage from '../pages/UsefulLinkPage';
import OurClientsPage from '../pages/OurClientsPage';
import OurTestimonialPage from '../pages/OurTestimonialPage';
import NewsUpdatesPage from '../pages/NewsUpdatesPage';
import ContactUsPage from '../pages/ContactUsPage';
import RequestQuotePage from '../pages/RequestQuotePage';
import CareersPage from '../pages/CareersPage';
import FAQPage from '../pages/FAQPage';
import AllServicePage from '../pages/AllServicePage';
import SendQuotationSmsPage from '../pages/SendQuotationSmsPage';
import TaxTipsPage from '../pages/TaxTipsPage';
import BlogPart1Page from '../pages/BlogPart1Page';
import GoogleFrmPage from '../pages/GoogleFrmPage';
import NotFoundPage from '../pages/NotFoundPage';
import OnlineTaxFilingPage from '../pages/OnlineTaxFilingPage';
import BlogPart2Page from '../pages/BlogPart2Page';
import BlogPart3Page from '../pages/BlogPart3Page';
import BlogPart4Page from '../pages/BlogPart4Page';
import BlogPart5Page from '../pages/BlogPart5Page';
import BlogPart6Page from '../pages/BlogPart6Page';
import BlogPart7Page from '../pages/BlogPart7Page';
import GoogleReviewPage from '../pages/GoogleReviewPage';
import BlogPart8Page from '../pages/BlogPart8Page';
import BlogPart9Page from '../pages/BlogPart9Page';
import BlogPart10Page from '../pages/BlogPart10Page';
import BlogPart11Page from '../pages/BlogPart11Page';
import BlogPart12Page from '../pages/BlogPart12Page';
import BlogPart13Page from '../pages/BlogPart13Page';
import OutsourcingAccountingPage from '../pages/BlogPage/OutsourcingAccountingPage';
import UnderstandingAccurateBookkeepingPage from '../pages/BlogPage/UnderstandingAccurateBookkeepingPage';

// import ContestPage from '../pages/ContestPage';

const ContestPage = React.lazy(() => import('../pages/ContestPage'))

class AppRoute extends Component {
    render() {
        return (
            <Fragment>
                <Routes>
                    <Route exact path="/" element={<HomePage />} />
                    <Route exact path="/services" element={<AllServicePage />} />
                    <Route exact path="/accounting" element={<AccountingPage />} />
                    <Route exact path="/bookkeeping" element={<BookkipingPage />} />
                    <Route exact path="/taxation" element={<TaxationPage />} />
                    <Route exact path="/payroll-processing" element={<PayrollProcessingPage />} />
                    <Route exact path="/tax-clinic" element={<VtacTaxClinicPage />} />
                    <Route exact path="/business-incorporation" element={<BusinessIncorporationPage />} />
                    <Route exact path="/business-plan" element={<BusinessPlanLoanPage />} />
                    <Route exact path="/accounting-software" element={<AccountingSoftwarePage />} />
                    <Route exact path="/case-study" element={<CaseStudyPage />} />
                    <Route exact path="/other-services" element={<OtherServicesPage />} />
                    <Route exact path="/about-us" element={<AboutUsPage />} />
                    <Route exact path="/our-clients" element={<OurClientsPage />} />
                    <Route exact path="/our-testimonials" element={<OurTestimonialPage />} />
                    <Route exact path="/news-and-updates" element={<NewsUpdatesPage />} />
                    <Route exact path="/covid-19-update" element={<Covid19Page />} />
                    <Route exact path="/useful-links" element={<UsefulLinkPage />} />
                    <Route exact path="/contest" element={<React.Suspense fallback="Loading...."><ContestPage /></React.Suspense>} />
                    <Route exact path="/contact" element={<ContactUsPage />} />
                    <Route exact path="/request-quote" element={<RequestQuotePage />} />
                    <Route exact path="/careers" element={<CareersPage />} />
                    <Route exact path="/faqs" element={<FAQPage />} />

                    <Route exact path="/request-quotation-success" element={<SendQuotationSmsPage />} />

                    {/* online tax filing */}
                    <Route exact path="/remote-tax-filing" element={<OnlineTaxFilingPage />} />
                    <Route exact path="/contest-form-submit" element={<GoogleFrmPage />} />
                    <Route exact path="/clients-review" element={<GoogleReviewPage />} />

                    {/* Blog */}
                    <Route exact path="/tips-for-tax" element={<TaxTipsPage />} />
                    <Route exact path="/ways-to-stay-ahead-of-tax-season" element={<BlogPart1Page />} />
                    <Route exact path="/important-tax-deadlines" element={<BlogPart2Page />} />
                    <Route exact path="/federal-tax-brackets-rates-credits" element={<BlogPart3Page />} />
                    <Route exact path="/housing-benefit-and-the-way-to-apply" element={<BlogPart4Page />} />
                    <Route exact path="/tips-for-tax-students" element={<BlogPart5Page />} />
                    <Route exact path="/tax-implications-for-having-multiple-employers" element={<BlogPart6Page />} />
                    <Route exact path="/tips-for-a-great-first-time-filing-experience" element={<BlogPart7Page />} />
                    <Route exact path="/tips-for-self-employed-taxpayers" element={<BlogPart8Page />} />
                    <Route exact path="/how-to-filing-taxes-in-multiple-states-when-moved" element={<BlogPart9Page />} />
                    <Route exact path="/tax-deductions-for-medical-expenses-under-the-CRA" element={<BlogPart10Page />} />
                    <Route exact path="/essential-points-to-understand-regarding-capital-gains" element={<BlogPart11Page />} />
                    <Route exact path="/fix-your-income-tax-return-in-Canada" element={<BlogPart12Page />} />
                    <Route exact path="/accounting-tips-for-small-business-owners-a-guide-to-financial-success" element={<BlogPart13Page />} />

                    <Route exact path="/outsourcing-accounting-services-pros-and-cons-for-businesses" element={<OutsourcingAccountingPage />} />
                    <Route exact path="/understanding-the-importance-of-accurate-bookkeeping-for-Your-business" element={<UnderstandingAccurateBookkeepingPage />} />

                    <Route path="*" element={<NotFoundPage />}></Route>

                    {/* <Route exact path="/file/Vtac-Incorporation.pdf" element={BusinessIncorporationPage} /> */}
                </Routes>
            </Fragment>
        );
    }
}

export default AppRoute;