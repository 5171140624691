import React, { Component, Fragment } from 'react';
import { Button, Col, Container, Row, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload, faFile, faFileArrowUp } from '@fortawesome/free-solid-svg-icons';
// import TaxTips2 from '../asset/images/house.jpg';
import TAXDates from '../asset/images/tax-1.jpg';
import filing from '../asset/images/filing.png';

import noneedoffice from '../asset/images/no-need-office.png';
import AccessFreeExpertSupport from '../asset/images/Access-Free-Expert-Support.png';
import get24Hours from '../asset/images/24-Hours.png';
import security from '../asset/images/security.png';
import GuaranteedAccuracy from '../asset/images/Guaranteed Accuracy.png';
import CostEffective from '../asset/images/Cost-Effective.png';
import review from '../asset/images/review.png';
import Access from '../asset/images/Access.png';
import Upload from '../asset/images/Upload.png';
import Authorization from '../asset/images/Authorization.png';


class OnlineTaxFiling extends Component {
    render() {
        return (
            <Fragment>
                <Container>
                    <Row>
                        <Col lg={12} md={6} sm={12}>
                            <div style={{ textAlign: "center" }}>

                                <h1 className='mt-5 fs-1 fw-bold' style={{ color: "#005BAA" }}
                                    data-aos="fade-down"
                                    data-aos-easing="linear"
                                    data-aos-duration="1000"
                                    data-aos-once="true">
                                    File Your Individual Taxes Remotely
                                </h1>
                                <p className='fs-4'>
                                    Upload your documents securely from your home and get your tax return done within 24 hours
                                </p>
                                <a href='https://docs.google.com/forms/d/e/1FAIpQLSdyebo_rQ394z07D_-AXSMG0tl0-Q8DZx4vvFdVc7aPfYvhtg/viewform?usp=sf_link' target="_
                                "><Button className="filingButton" ><span>Get Started</span></Button></a>
                            </div>
                        </Col>
                    </Row>
                    <Container>
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <h2 className='mt-5 fs-1 fw-bold text-center' style={{ color: "#005BAA" }}>
                                    Fast & Simple Tax Returns with V-Tac Remote Filing
                                </h2>
                            </Col>
                        </Row>
                        <Row style={{ textAlign: "center", marginTop: "5rem" }} >

                            <Col lg={4} md={6} sm={12}
                                data-aos="zoom-in"
                                data-aos-duration="1500"
                                data-aos-once="true">
                                <Card className='onlineFileCard'>
                                    <div>
                                        <Card.Img variant="top" className='taxFilingImg' src={noneedoffice} alt="no-need-come-to-office" />
                                        <div className=''>
                                        </div>
                                    </div>
                                    <Card.Body>
                                        <Card.Title className='taxTipscardTtle'>No Need to Come to the Office</Card.Title>
                                        <Card.Text className='commonDesc'>
                                            Remote tax filing allows flexibility and convenience, as you can get your taxes done from any location, at any hour, with just an internet connection.
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg={4} md={6} sm={12}
                                data-aos="zoom-in"
                                data-aos-duration="1500"
                                data-aos-once="true">
                                <Card className='onlineFileCard'>

                                    <div>

                                        <Card.Img variant="top" className='taxFilingImg' src={GuaranteedAccuracy} alt="guaranteed-accuracy" />
                                        <div className=''>

                                        </div>
                                    </div>

                                    <Card.Body>
                                        <Card.Title className='taxTipscardTtle'>Guaranteed Accuracy</Card.Title>
                                        <Card.Text className='commonDesc'>
                                            We will conduct a manual review to eliminate errors and omissions, thus reducing the chance of mistakes.

                                        </Card.Text>
                                    </Card.Body>
                                </Card>

                            </Col>
                            <Col lg={4} md={6} sm={12}
                                data-aos="zoom-in"
                                data-aos-duration="1500"
                                data-aos-once="true">
                                <Card className='onlineFileCard'>

                                    <div>

                                        <Card.Img variant="top" className='taxFilingImg' src={get24Hours} alt="get-tax-in-24-hours" />

                                    </div>

                                    <Card.Body>
                                        <Card.Title className='taxTipscardTtle'>Get Your Tax Return in 24 Hours</Card.Title>
                                        <Card.Text className='commonDesc'>
                                            Once we get everything we need, our professional tax experts will work diligently to get back to you as soon as possible.

                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>

                        </Row>
                        <Row> <Col lg={4} md={6} sm={12}
                            data-aos="zoom-in"
                            data-aos-duration="1500"
                            data-aos-once="true">
                            <Card className='onlineFileCard'>

                                <div>

                                    <Card.Img variant="top" className='taxFilingImg' src={AccessFreeExpertSupport} alt="access-free-expert-support" />
                                    <div className=''>

                                    </div>
                                </div>

                                <Card.Body>
                                    <Card.Title className='taxTipscardTtle'>Access Free Expert Support</Card.Title>
                                    <Card.Text className='commonDesc'>
                                        Get expert tax support when you need it - just a click away. We won’t leave you in the dark.


                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                            <Col lg={4} md={6} sm={12}
                                data-aos="zoom-in"
                                data-aos-duration="1500"
                                data-aos-once="true">
                                <Card className='onlineFileCard'>
                                    <div>
                                        <Card.Img variant="top" className='taxFilingImg' src={security} alt="security" />
                                        <div className=''>
                                        </div>
                                    </div>

                                    <Card.Body>
                                        <Card.Title className='taxTipscardTtle'>Enhanced Security</Card.Title>
                                        <Card.Text className='commonDesc'>
                                            Our remote tax filing system makes sure your sensitive personal and financial information is safe and protected.
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg={4} md={6} sm={12}
                                data-aos="zoom-in"
                                data-aos-duration="1500"
                                data-aos-once="true">
                                <Card className='onlineFileCard'>

                                    <div>

                                        <Card.Img variant="top" className='taxFilingImg' src={CostEffective} alt="Cost-Effective" />

                                    </div>

                                    <Card.Body>
                                        <Card.Title className='taxTipscardTtle'>Cost-Effective & No Hidden Fees</Card.Title>
                                        <Card.Text className='commonDesc'>
                                            Our basic filing services start at $70. Once you upload your information, we will review and confirm the price with you. Pay only when you file.
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>

                        </Row>
                    </Container>
                    <Container>
                        <Row>
                            <Col lg={8} md={12} sm={12}>
                                <div className='taxFilingDiv'>
                                    <h2 className='mt-5 fs-1 fw-bold' style={{ color: "#005BAA" }}>File Your Personal Taxes in Less Than 20 Minutes</h2>
                                    <p className='blogDesc'>Tax preparation can be a source of overwhelming stress and anxiety for many people, but it doesn't have to be. Imagine being able to avoid the never-ending piles of paperwork, the perplexing tax laws, and the fear of making a mistake. With our online tax submission service, you can put all of your concerns to rest, knowing that a team of professionals is handling everything for you.</p>
                                    <p className='blogDesc'>We understand the importance of completing your taxes correctly and on time, which is why we are committed to providing a stress-free experience. Don't let your fear of taxes keep you from submitting your documents to us and letting us handle the rest. Your peace of mind is only a few clicks away.
                                    </p>
                                    <h3 className='fs-3' style={{ color: "red" }}>The 2022 tax deadline is Monday, May 1st, 2023.</h3>
                                    <a href='https://docs.google.com/forms/d/e/1FAIpQLSdyebo_rQ394z07D_-AXSMG0tl0-Q8DZx4vvFdVc7aPfYvhtg/viewform?usp=sf_link' target="_blank"><Button className="navButtonLink2 mt-3" ><span>Get Started</span></Button></a>
                                </div>
                            </Col>
                            <Col lg={4} md={12} sm={12}
                                data-aos="zoom-in"
                                data-aos-duration="1500"
                                data-aos-once="true">
                                <img className='getinTouchImage' style={{ height: "400px", width: "100%", marginTop: "6rem" }} alt="" loading="lazy" src={TAXDates}></img>
                            </Col>
                        </Row>
                    </Container>
                    <Container>
                        <h2 className='mt-5 fs-1 fw-bold text-center' style={{ color: "#005BAA" }}>How it Works</h2>
                        <Row className='mt-5'>
                            <Col lg={3} md={6} sm={12}
                                data-aos="zoom-in"
                                data-aos-duration="1500"
                                data-aos-once="true">

                                <img className='taxFilingImg img-fluid' src={Access} alt="access-web-portal"></img>
                            </Col>
                            <Col lg={3} md={6} sm={12}
                                data-aos="zoom-in"
                                data-aos-duration="1500"
                                data-aos-once="true">
                                <h2 className='commonTitle mt-2'>Access our Web Portal</h2>
                                <p className='commonDesc'>No need to download anything. Create a free account in our desktop application to get started.</p>
                            </Col>
                            <Col lg={3} md={6} sm={12}
                                data-aos="zoom-in"
                                data-aos-duration="1500"
                                data-aos-once="true">

                                <img variant="top" className='taxFilingImg img-fluid' src={Upload} alt="upload-file"></img>
                            </Col>
                            <Col lg={3} md={6} sm={12}
                                data-aos="zoom-in"
                                data-aos-duration="1500"
                                data-aos-once="true">
                                <h2 className='commonTitle'>Upload Your Files</h2>
                                <p className='commonDesc'>Follow the steps prompted by our tax software to submit the necessary documents.</p>
                            </Col>
                        </Row>
                        <Row className='mt-5'>
                            <Col lg={3} md={6} sm={12}
                                data-aos="zoom-in"
                                data-aos-duration="1500"
                                data-aos-once="true">
                                <img variant="top" className='taxFilingImg img-fluid' src={Authorization}></img>
                            </Col>
                            <Col lg={3} md={6} sm={12}
                                data-aos="zoom-in"
                                data-aos-duration="1500"
                                data-aos-once="true">
                                <h2 className='commonTitle'>Sign an Authorization Form</h2>
                                <p className='commonDesc'>A T183 must be completed and sent back, this allows us authorization to file on your  behalf.</p>
                            </Col>
                            <Col lg={3} md={6} sm={12}
                                data-aos="zoom-in"
                                data-aos-duration="1500"
                                data-aos-once="true">
                                <img variant="top" className='taxFilingImg img-fluid' src={review} alt="review-submission"></img>
                            </Col>
                            <Col lg={3} md={6} sm={12}
                                data-aos="zoom-in"
                                data-aos-duration="1500"
                                data-aos-once="true">
                                <h2 className='commonTitle'>We Review Your Submission</h2>
                                <p className='commonDesc'>We look for any credits or deductions you may have overlooked to ensure you get the most out of your tax return.</p>
                            </Col>
                        </Row>
                    </Container>
                    <Container>
                        <Row className='mt-5'>

                            <Col lg={6} md={6} sm={12}>
                                <img className='onlineFileImage' style={{ height: "200px", width: "100%", marginTop: "6rem" }} alt="" loading="lazy" src={get24Hours}></img>
                            </Col>
                            <Col lg={6} md={6} sm={12}>
                                <div className='taxFilingDiv mt-5'>
                                    <h2 className=' fs-2 fw-bold mt-2' style={{ color: "#005BAA" }}>Expect a Tax Return in 24 Hours</h2>
                                    <p className='blogDesc'>We have the expertise and team needed to complete your tax return within the specified time frame, ensuring that you meet  your tax obligations without delay.</p>
                                </div>
                            </Col>
                            <Col lg={12} md={12} sm={12}>
                                <img className='getinTouchImage mt-5' src={filing}></img>
                            </Col>
                        </Row>
                    </Container>
                    <Container className='text-center mt-5'>
                        <h2 className='fs-1 fw-bold text-center' style={{ color: "#005BAA" }}>Get Your 2022 Taxes Done Quickly</h2>
                        <Row className='mt-5'>
                            <Col lg={6} md={6} sm={12}>

                                <FontAwesomeIcon className="fileIcon" icon={faFile} />
                                <h2>Drop Off Your Documents in Person</h2>
                                <Link to="/contact"><Button className="navButtonLink2 mt-3 mb-5" ><span>Contact Us</span></Button></Link>

                            </Col>
                            <Col lg={6} md={6} sm={12}>
                                <FontAwesomeIcon className="fileIcon" icon={faFileArrowUp} />
                                <h2>File Remotely</h2>
                                <a href='https://docs.google.com/forms/d/e/1FAIpQLSdyebo_rQ394z07D_-AXSMG0tl0-Q8DZx4vvFdVc7aPfYvhtg/viewform?usp=sf_link' target="_blank"><Button className="navButtonLink2 mt-3 mb-5" ><span>Get Started</span></Button></a>
                            </Col>
                        </Row>
                    </Container>
                </Container>
            </Fragment>
        );
    }
}

export default OnlineTaxFiling;