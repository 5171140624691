import React, { Component, Fragment } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Slide5 from '../asset/images/Slide5.jpg';
import Slide4 from '../asset/images/Slide4.jpg';
import Slide6 from '../asset/images/tax-2.jpg';

class GoogleFrm extends Component {
    render() {
        return (
            <Fragment>
                <Container>
                    <Row>
                        <Col lg={6} md={6} sm={12}>

                            <div className='mt-5'>
                            <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfJm6II6JrlymS5h6VjWk2ir15FwY-Hfuj4PkKIjHnDUYKuOA/viewform?embedded=true" width="640" height="1100" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
                            </div>

                        </Col>
                        <Col lg={6} md={6} sm={12}>
                            <div className='reqImgDiv mt-5'>
                                <img className="REQImg" src={Slide6} alt="v-tac-tax" title='v-tac-tax' />
                                <img className="REQImg" src={Slide5} alt="v-tac-tax-clinic" title='v-tac-tax-clinic' />
                                {/* <img className="REQImg" src={Slide4} alt="v-tac-tax" title='v-tac-tax' /> */}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        );
    }
}

export default GoogleFrm;