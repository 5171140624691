import React, { Component, Fragment } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import BasicMap from './BasicMap/BasicMap';


class ContactUs extends Component {
    render() {
        return (
            <Fragment>
                <Container fluid={true} className="accContainer">

                    <Container className='contactSec'>
                        <div>
                            <h2 className='accTitle'>
                               </h2>
                        </div>
                        <div>
                            <BasicMap />
                            
                        </div>
                        <div className='accHr'></div>
                        <Row>
                            <Col lg={6} md={6} sm={12}>
                                <div className='contactDiv'style={{marginLeft:"100px"}}><h4><strong>Ontario</strong></h4>
                                    <p>100 King St. W., Suite 5700<br />
                                        Toronto, ON M5X 1C7<br />
                                        T: 416.425.9897<br />
                                        F: 416.769.7218</p>
                                    <p>401 Bay Street Suite #1600<br />
                                        Toronto, ON M5H 2Y4<br />
                                        T: 416.646.6750<br />
                                        F: 416.769.7218</p>
                                    <p>881 Jane Street #207<br />
                                        Toronto, ON M6N 4C4<br />
                                        T: 416.769.9957<br />
                                        F: 416.769.7218</p>
                                </div>
                            </Col>
                            <Col lg={6} md={6} sm={12}>
                                <div className='contactDiv'><h4><strong>Quebec</strong></h4>
                                    <p>7099B av De L’Epee<br />
                                        Montreal, QC H3N 2C9<br />
                                        T: 514.272.9777<br />
                                        F: 514.272.9777</p>
                                    <p>152 Rue Gendron<br />
                                        Chateauguay, QC J6J 3G6<br />
                                        T: 450.692.7779<br />
                                        F: 450.692.7154</p>
                                    <p><strong>Toll Free: 1.866.882.2829</strong></p>
                                    <p>E-mail: info@vtac.ca<br />
                                        Website: www.vtac.ca</p>
                                    <p><strong>Office Hours:<br />
                                    </strong>Monday &#8211; Friday 9:00 am to 8:00 pm<br />
                                        Saturday &#8211; 9:00 am to 2:00 pm<br />
                                        Sunday &#8211; Closed</p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Container>
            </Fragment>
        );
    }
}

export default ContactUs;