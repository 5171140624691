import React, { Component, Fragment } from 'react';
import BlogTop from '../components/BlogTop';
import VtacQuote from '../components/VtacQuote';
import Footer from '../components/Footer';
import NewTopNavigation from '../components/NewTopNavigation';
import BlogPart5 from '../components/BlogPart5';
import SearchByWord from '../components/SearchByWord';

class BlogPart5Page extends Component {
    render() {
        return (
            <Fragment>
                <NewTopNavigation title="Tax Tips for Students-V-Tac Accounting & Tax | The Professionals' Professional" />
                <BlogTop />
                <SearchByWord />
                <BlogPart5 />
                <VtacQuote />
                <Footer />
            </Fragment>
        );
    }
}

export default BlogPart5Page;