import React, { Component, Fragment } from 'react';
import BusinessIncorporation from '../components/BusinessIncorporation';
import PageTop from '../components/PageTop';
import VtacQuote from '../components/VtacQuote';
import Footer from '../components/Footer';
import NewTopNavigation from '../components/NewTopNavigation';
class BusinessIncorporationPage extends Component {
    render() {
        return (
            <Fragment>
            <NewTopNavigation title="Business Incorporation-V-Tac Accounting & Tax | The Professionals' Professional" />
            <PageTop topPageTitle="Business Incorporation" topPagesubTitle="We do both Federal and Provincial Incorporation. We provide official searches for full Ontario incorporation, Canada trademark registration and other related corporate services with minimum cost. We respond to your needs quicker and faster so you can save more. Please contact us for your company incorporation."/>
            <BusinessIncorporation />
            <VtacQuote />
            <Footer />
        </Fragment>
        );
    }
}

export default BusinessIncorporationPage;