import React, { Component, Fragment } from 'react'
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import axios from 'axios';
import SinceServe from '../asset/images/Employment-Insurance-scaled.jpg';

export default class Summary extends Component {
    constructor() {
        super();
        this.state = {
            firstName: "",
            lastName: "",
            phone: "",
            emailAddress: "",
            serviceType: "Accounting",
            hearFrom: "Google Search",
        };
    }
    clearForm = () => {
        this.setState({
            firstName: "",
            lastName: "",
            phone: "",
            emailAddress: "",
            serviceType: "Accounting",
            hearFrom: "Google Search",
        })
    }

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    }

    submitHandaler = (event) => {
        event.preventDefault();
        axios.post("https://vtacemail.vtac.ca/vtacQuoteReq", this.state).then(Response => {
            this.clearForm();
            window.location.href = "https://www.vtac.ca/#/request-quotation-success";
        }).catch(error => {
            console.log(error);
        })

    }

    render() {
        const { firstName, lastName, phone, emailAddress, serviceType, hearFrom } = this.state;
        return (

            <Fragment>
                <Container fluid={true} className="  aboutContainer p-0" style={{ backgroundColor: "#F8F8F8" }}>
                    <div className='container mt-5'>
                        <Row>
                            <Col lg={6} md={12} sm={12} >

                                <Container className='FBackSec' data-aos="fade-right"
                                    data-aos-anchor="#example-anchor"
                                    data-aos-offset="500"
                                    data-aos-duration="2000"
                                    data-aos-once="true">

                                    <Container className='SectionBetween'>
                                        <div>
                                            <h2 className='accTitle text-center'>
                                                Request Quote</h2>
                                        </div>
                                        <div className='accHr mt-3'></div>
                                        <Row>
                                            <Col>
                                                <div className=" getinTouchCard">
                                                    <div id="RQForm">
                                                        <Form onSubmit={this.submitHandaler}>
                                                            <Form.Group >
                                                                <Form.Label className='formLabel'>First Name*</Form.Label>
                                                                <Form.Control type="text" name='firstName' value={firstName} onChange={this.handleChange} required />
                                                            </Form.Group>

                                                            <Form.Group >
                                                                <Form.Label className='formLabel'>Last Name*</Form.Label>
                                                                <Form.Control type="text" name='lastName' value={lastName} onChange={this.handleChange} required />
                                                            </Form.Group>

                                                            <Form.Group >
                                                                <Form.Label className='formLabel'>Phone*</Form.Label>
                                                                <Form.Control type="number" name='phone' value={phone} onChange={this.handleChange} required />
                                                            </Form.Group>

                                                            <Form.Group >
                                                                <Form.Label className='formLabel'>Email*</Form.Label>
                                                                <Form.Control type="email" name='emailAddress' value={emailAddress} onChange={this.handleChange} required />
                                                            </Form.Group>

                                                            <Form.Group >
                                                                <Form.Label className='formLabel'>Type of Service*</Form.Label>
                                                                <select className='quoteDropdown' name='serviceType' value={serviceType} onChange={this.handleChange} required>
                                                                    <option value={"Accounting"}>Accounting</option>
                                                                    <option value={"Bookkeeping"}>Bookkeeping</option>
                                                                    <option value={"Payroll Processing"}>Payroll Processing</option>
                                                                    <option value={"Income Tax"}>Income Tax</option>
                                                                    <option value={"Business Incorporation"}>Business Incorporation</option>
                                                                </select>
                                                            </Form.Group>

                                                            <Form.Group >
                                                                <Form.Label className='formLabel'>How'd you hear about us ?*</Form.Label>
                                                                <select className='quoteDropdown' name='hearFrom' value={hearFrom} onChange={this.handleChange} >
                                                                    {/* <option value={""}>How'd you hear about us ?*</option> */}
                                                                    <option value={"Google Search"}>Google Search</option>
                                                                    <option value={"Social Media"}>Social Media</option>
                                                                    <option value={"Magazine Ad"}>Magazine Ad</option>
                                                                    <option value={"Word Of Mouth"}>Word Of Mouth</option>
                                                                    <option value={"Promotional Calender"}>Promotional Calender</option>
                                                                    <option value={"Others"}>Others</option>
                                                                </select>
                                                            </Form.Group>
                                                            <Button name='submitBtn' className="RequestQuoteBtn mt-3" variant="primary" type="submit">
                                                                <span>GET QUOTE</span>
                                                            </Button>
                                                        </Form>
                                                    </div>

                                                </div>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Container>
                            </Col>
                            <Col lg={6} md={6} sm={12} >

                                <div data-aos="fade-left"
                                    data-aos-anchor="#example-anchor"
                                    data-aos-offset="500"
                                    data-aos-duration="2000"
                                    data-aos-once="true" className='mb-5'><img className="getinTouchImage" style={{ height: "778px", width: "100%" }} src={SinceServe} alt="Team working" loading="lazy" /></div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </Fragment>
        )
    }
}
