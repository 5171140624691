import React, { Component, Fragment } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Slide1 from '../asset/images/Slide1.jpg';
import Slide2 from '../asset/images/Slide2.jpg';
import Slide3 from '../asset/images/Slide3.jpg';
import Slide4 from '../asset/images/Slide4.jpg';
import Slide5 from '../asset/images/Slide5.jpg';
import { Link } from "react-router-dom";

class Careers extends Component {
    handleClick(id) {
        var coll = document.getElementById(id);
        coll.classList.toggle("factive");
        var content = coll.nextElementSibling;
        if (content.style.maxHeight) {
            content.style.maxHeight = null;
        } else {
            content.style.maxHeight = content.scrollHeight + "px";
        }
    }
    render() {
        var settings = {
            arrows: false,
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 3000,
            fade: true,
            fadeSpeed: 1000,

            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        initialSlide: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        return (
            <Fragment>
                <Container fluid={true} className="accContainer">

                    <Container className='accSection'>
                        <div>
                            <h2 className='accTitle'>
                                Careers</h2>
                        </div>
                        <Row>
                            <Col lg={6} md={6} sm={12}>

                                <div className='accSlide mt-3'>
                                    <Slider {...settings}>
                                        <div>
                                            <Row>
                                                <Col>
                                                    <a> <img className="clientImage img-fluid" src={Slide1} alt="meeting" /></a>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div>
                                            <Row>
                                                <Col>
                                                    <a> <img className="clientImage img-fluid" src={Slide2} alt="conference"/></a>
                                                </Col>
                                            </Row>
                                        </div>

                                        <div>
                                            <Row>
                                                <Col>
                                                    <a> <img className="clientImage img-fluid" src={Slide4} alt="V-tac-tax"/></a>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div>
                                            <Row>
                                                <Col>
                                                    <a> <img className="clientImage img-fluid" src={Slide5} alt="all employees"/></a>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div>
                                            <Row>
                                                <Col>
                                                    <a> <img className="clientImage img-fluid" src={Slide3} alt="team meeting"/></a>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Slider>
                                </div>
                            </Col>
                            <Col lg={6} md={6} sm={12} >
                                <p className='accSubtitle mt-4'><span className='spancolor'>V-Tac</span> is a growing firm that provides opportunities for professional development and valuable experiences. At V-Tac, we are committed to seeking and cultivating outstanding professionals to add to the quality of our business services. By becoming a part of V-Tac, we are confident that your experience with us will help you gain useful knowledge and skills that will positively impact your career.</p>
                                <p className='accSubtitle mt-4'>We will be posting new full-time positions in the near future. Motivated individuals willing to challenge their sales expertise are encouraged to apply. You are welcome to browse our site to learn more about available job opportunities as they arise.</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <div className='sectionBetween'>
                                    <h2 className='accTitle'>
                                        Current Job Openings</h2>
                                </div>
                                <div className='cardforCareer mt-4'>
                                    <button class="FAQcollapsible " id='b1' onClick={() => this.handleClick('b1')}>Sales Associate</button>
                                    <div class="FAQcontent">
                                        <p className='FaqDescription'>Your primary duties will include presenting, selling, listing, and advertising packages that will improve the impact and visibility of your group’s prospects.</p>
                                    <p className='FaqDescription'><strong style={{color:"#273044"}}>Responsibilities:</strong></p>
                                    <ul className='careerFaqUI'>
                                        <li>Deliver positive results to actively promote the company.</li>
                                        <li>Travel to periodic trade shows and sales meetings.</li>
                                        <li>Coordinate market strategies, sales, and public relations to gain greater exposure through brochures, flyers, websites, and trade shows.</li>
                                        <li>Communicate politely and clearly with clients over the phone or in person.</li>
                                        <li>Deliver effective presentations (including proposals) to customers.</li>
                                        <li>Maintain account files, report documentation and proposals/ bids using our website (www.vtac.com).</li>
                                        <li>We will soon be posting new full-time positions in the future. Motivated individuals willing to challenge their sales expertise are encouraged to apply. You are welcome to browse our site to learn more about available job opportunities as they arise.</li>
                                    </ul>
                                    <p className='FaqDescription'><strong style={{color:"#273044"}}>Skills and Qualification:</strong></p>
                                    
                                    <ul  className='careerFaqUI'>
                                        <li>Minimum of 3-5 years’ experience in business sales.</li>
                                        <li>University/College diploma in Accounting, Marketing or Business.</li>
                                        <li>Strong knowledge of general accounting procedures.</li>
                                        <li>Previous management experience and proven sales experience an asset.</li>
                                        <li>Computer literate</li>
                                        <li>Self-motivated and energetic about sales with a strong desire to succeed.</li>
                                        <li>Articulate and confident with a strong command of the English language.</li>
                                        <li>Analytical with apt decision-making and problem-solving skills.</li>
                                        <li>Able to work independently and as part of a team.</li>
                                        <li>Excellent interpersonal skills</li>
                                        <li>Great and positive attitude</li>
                                    </ul>
                                    <p className='FaqDescription'>This is a salary-based position with commission.</p>
                                    </div>
                                    <button class="FAQcollapsible mt-2" id='b2' onClick={() => this.handleClick('b2')}>Accounting Assistant</button>
                                    <div class="FAQcontent">
                                        <p className='FaqDescription'><strong style={{color:"#273044"}}>Responsibilities:</strong></p>
                                       <ul  className='careerFaqUI'>
                                        <li>Increase customers’ awareness of our accounting services.</li>
                                        <li>Strong knowledge of general accounting procedures.</li>
                                        <li>Prepare government remittances (including GST and PST).</li>
                                        <li>Provide bookkeeping services for clients.</li>
                                        <li>Create and implement insightful accounting strategies.</li>
                                        <li>Develop relationships and rapport with team members</li>
                                        <li>Travel to periodic trade shows and sales meetings.</li>
                                        <li>Prepare account records for clients</li>
                                       </ul>
                                       <p className='FaqDescription'><strong style={{color:"#273044"}}>Data entry</strong></p>
                                       <p className='FaqDescription'><strong style={{color:"#273044"}}>Skills and Qualifications:</strong></p>
                                       <ul  className='careerFaqUI'>
                                        <li>Minimum 4-6 years’ experience in accounting, business and tax sales services</li>
                                        <li>University/College diploma in Accounting or Business</li>
                                        <li>Computer literate</li>
                                        <li>Knowledgeable of payroll accounting</li>
                                        <li>Practical decision-making and problem solving skills</li>
                                        <li>Able to work independently and as part of a team</li>
                                        <li>Excellent verbal and written communication in English</li>
                                        <li>Positive attitude and willingness to learn</li>
                                       </ul>
                                       <p className='FaqDescription'><strong style={{color:"#273044"}}>Software Knowledge Required:</strong></p>
                                       <ul  className='careerFaqUI'>
                                       <li>Accpac, Simple Accounting, Quick Books, Business Vision, MS Excel, and MS Access.</li>
                                       </ul>
                                       <p className='FaqDescription'>This is a salary-based position.</p>
                                    </div>
                                </div>
                                <p className='accSubtitle mt-4'>V-Tac is an equal opportunity employer and welcomes applications from all interested individuals. We thank you for your interest, however, only qualified candidates will be contacted. Please visit our website regularly to stay informed on new career opportunities. </p>
                                <p className='accSubtitle mt-4'><strong>Benefits</strong></p>
                                <ul>
                                    <li className='accSubtitle'>Become a productive member of V-Tac Accounting & Tax workplace where you will gain valuable and practical experience</li>
                                    <li className='accSubtitle'>Take advantage of our comprehensive training programs to develop your skills and knowledge</li>
                                    <li className='accSubtitle'>Enjoy additional competitive benefits</li>
                                </ul>
                                <p className='accSubtitle mt-4'>Interested applicants must forward their resume to info@vtac.ca with the subject line reading, “Attention HR”. Thank you.</p>
                            </Col>
                        </Row>
                    </Container>
                </Container>
            </Fragment>
        );
    }
}

export default Careers;