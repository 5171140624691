import React, { Component } from 'react';
import { Fragment } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import TaxTips1 from '../asset/images/Tax tips In-2023.jpg';
import TAXRATE from '../asset/images/TAX RATE.png';
import house from '../asset/images/house.jpg';
import TAXDates from '../asset/images/TAX Dates.png';
import Studenttax from '../asset/images/Student tax.png';
import tipsfsttimefiling2 from '../asset/images/5tips-fst-time-filing2.webp';
import taximplication1 from '../asset/images/tax-implication1.webp';
import SelfEmployedTaxpayers from '../asset/images/Self-Employed -Taxpayers.png';
import taxinmultiplestate1 from '../asset/images/tax-in-multiple-state1.png';
import taxdeduction2 from '../asset/images/tax-deduction-2.png';

class BlogPart1 extends Component {
    render() {
        return (
            <Fragment>
                <Container>
                    <Row>
                        <Col lg={8} md={6} sm={12}>
                            <div className='blogDetailDiv'>
                                <h2 className='blogTopTitle fs-1'>8 straight ways to stay ahead of tax season 2023.</h2>
                                <p className='blogDesc'>Are you getting the most out of your taxes? Individuals must take advantage of potential deductions and credits that could lead to a bigger tax refund. Doing your taxes correctly ensures you receive the maximum refund for which you are eligible. Don't let years of receipts pile up in a drawer; take the time to maximize your return!</p>
                                <p className='blogDesc'>Filing an income tax return doesn't have to be a dreaded job! It can be a fantastic chance to save money or get reimbursed for funds you may have yet to be aware you were eligible to receive.</p>
                                <p className='blogDesc'>In Canada, The deadline to file your income tax and benefit return for 2022 is April 30, 2023. That's going to be a Sunday, so the return will be considered on time if the Canada Revenue Agency (CRA) receives it or it is postmarked on or before May 1, 2023. In order to be considered in time, The CRA must also acquire payment or be processed at a Canadian financial institution on or before May 1, 2023.</p>
                                <p className='blogDesc'>If you act quickly, you can study the benefits available to you before the filing deadline. However, if you haven't done so before this date, you can still file your return and benefit from the deductions. Just keep in mind that if you owe money, you may be subject to extra fine charges.</p>
                                <p className='blogDesc'>Here are eight tips to make the filing process easier in this upcoming tax season by staying on top of things year-round.</p>
                                <img variant="top" className='blog1PageImg2 mt-3 mb-3' src={TaxTips1} alt="ways-to-stay-ahead-of-tax-season"></img>
                                <h2 className='blogDescTitle'>RRSP contributions</h2>
                                <p className='blogDesc'>If you contributed to an RRSP before the deadline, you can deduct these contributions from your taxes. You can check the tax slips provided by your financial institution to know the amount. It's recommended to keep these receipts in case of an audit, but they don't need to be submitted when filing.</p>
                                <h2 className='blogDescTitle'>Childcare expenses</h2>
                                <p className='blogDesc'> If your child is under the age of 16, you may be able to deduct childcare expenses such as fees for academic institutions (where applicable), daycare centers, summer camps, boarding schools, overnight sports schools, and amounts paid to caregivers, such as babysitters. The partner with the lower earnings typically claims the deduction.</p>
                                <h2 className='blogDescTitle'>Student loan interest</h2>
                                <p className='blogDesc'>You can deduct the interest paid on a student loan if it was received under the laws of your province or territory. It is applicable to you or your child. This deduction does not apply to a personal loan. If you owe taxes, you should apply this deduction. Otherwise, it is better to carry the interest forward and use it to reduce any taxes owed for the next five years.</p>
                                <h2 className='blogDescTitle'>Home office expenses</h2>
                                <p className='blogDesc'>If you worked from home in 2020-2022 due to the pandemic for at least 50% of the time and for four or more consecutive weeks, you are eligible to claim certain work-related expenditures from the Canada Revenue Agency (CRA). You can do this either through the temporary flat rate method or the detailed method. For more please click here.</p>
                                <h2 className='blogDescTitle'>Moving expenses</h2>
                                <p className='blogDesc'>If you relocate to a location at least 40 kilometers closer to your workplace, a new business, or post-secondary school, you can claim expenses related to the move, such as storage fees, transportation costs, temporary housing costs, and the cost of terminating a lease agreement.</p>
                                <h2 className='blogDescTitle'>Capital losses</h2>
                                <p className='blogDesc'>If one of your investments turns out to be unprofitable and you sell it at a loss, you may be able to use the amount as a deduction against any capital gains you have in the current year. If the loss is greater than your gains, you can claim the remainder as a net capital loss, which can be applied to the capital gains from the previous three tax years or carried forward to use in future tax years. Note that you cannot deduct capital losses from tax-advantaged accounts, such as RRSPs or TFSAs.</p>
                                <h2 className='blogDescTitle'>Charitable donations</h2>
                                <p className='blogDesc'>You may be eligible to receive a tax credit of up to 29% at the federal level, plus an additional 5.05% to 24% depending on the province you live in, for donations of up to $200. Any donations above that amount are credited at 29% federally and 11.16% to 24% depending on the province.</p>
                                <p className='blogDesc'></p>
                                <h2 className='blogDescTitle'>Union/professional dues</h2>
                                <p className='blogDesc'>Small business owners can claim deductions for various expenses associated with their operations, such as advertising, banking fees, office supplies, and travel. Home-based entrepreneurs are also eligible to deduct a portion of their utilities, insurance, and maintenance expenses, depending on the portion of their residence used for business purposes.</p>
                            </div>
                        </Col>
                        <Col lg={4} md={6} sm={12}>
                            <div className='relatedArticle'>
                                <h2 className='fs-3 '>Related Articles</h2>
                                <hr style={{ width: "100%", float: "left" }}></hr>
                            </div>
                            <Card className='blogCardRight'>
                                <Link to="/important-tax-deadlines" style={{ textDecoration: "none" }}>
                                    <div>
                                        <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                        <Card.Img variant="top" className='industryImg' src={TAXDates} />


                                        <Card.Body>
                                            <Card.Title className='blogCardRightTitle'> Important Tax Deadlines by Month 2023</Card.Title>
                                        </Card.Body>
                                    </div>
                                </Link>
                            </Card>
                            <Card className='blogCardRight'>
                                <Link to="/federal-tax-brackets-rates-credits" style={{ textDecoration: "none" }}>
                                    <div>
                                        <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                        <Card.Img variant="top" className='industryImg' src={TAXRATE} />
                                    </div>

                                    <Card.Body>
                                        <Card.Title className='blogCardRightTitle'>Federal Tax Brackets, Rates & Credits 2023</Card.Title>
                                    </Card.Body>
                                </Link>
                            </Card>
                            <Card className='blogCardRight float-right'>
                                <Link to="/housing-benefit-and-the-way-to-apply" style={{ textDecoration: "none" }}>
                                    <div>
                                        <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                        <Card.Img variant="top" className='industryImg' src={house} />
                                    </div>

                                    <Card.Body>
                                        <Card.Title className='blogCardRightTitle'>Are you a renter in Canada? Learn about the Housing Benefit and the way to apply for it. </Card.Title>
                                    </Card.Body>
                                </Link>
                            </Card>
                            <Card className='blogCardRight'>
                                <Link to="/tips-for-tax-students" style={{ textDecoration: "none" }}>
                                    <div>
                                        <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                        <Card.Img variant="top" className='industryImg' src={Studenttax} />
                                    </div>

                                    <Card.Body>
                                        <Card.Title className='blogCardRightTitle'>Maximizing Your 2022 Return: Tips for Students</Card.Title>
                                    </Card.Body>
                                </Link>
                            </Card>

                            <Card className='blogCardRight float-right'>
                                <Link to="/tax-implications-for-having-multiple-employers" style={{ textDecoration: "none" }}>
                                    <div>
                                        <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                        <Card.Img variant="top" className='industryImg' src={taximplication1} />
                                    </div>

                                    <Card.Body>
                                        <Card.Title className='blogCardRightTitle'>Tax implications for having multiple employers</Card.Title>
                                    </Card.Body>
                                </Link>
                            </Card>

                            <Card className='blogCardRight float-right'>
                                <Link to="/tips-for-a-great-first-time-filing-experience" style={{ textDecoration: "none" }}>
                                    <div>
                                        <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                        <Card.Img variant="top" className='industryImg' src={tipsfsttimefiling2} />
                                    </div>

                                    <Card.Body>
                                        <Card.Title className='blogCardRightTitle'>5 tips for a great first-time filing experience</Card.Title>
                                    </Card.Body>
                                </Link>
                            </Card>
                            <Card className='blogCardRight float-right'>
                                <Link to="/tips-for-self-employed-taxpayers" style={{ textDecoration: "none" }}>
                                    <div>
                                        <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                        <Card.Img variant="top" className='industryImg' src={SelfEmployedTaxpayers} />
                                    </div>

                                    <Card.Body>
                                        <Card.Title className='blogCardRightTitle'>Tips for Self-Employed Taxpayers</Card.Title>
                                    </Card.Body>
                                </Link>
                            </Card>
                            <Card className='blogCardRight float-right'>
                                <Link to="/how-to-filing-taxes-in-multiple-states-when-moved" style={{ textDecoration: "none" }}>
                                    <div>
                                        <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                        <Card.Img variant="top" className='industryImg' src={taxinmultiplestate1} />
                                    </div>

                                    <Card.Body>
                                        <Card.Title className='blogCardRightTitle'>How to Filing Taxes in Multiple States When Moved</Card.Title>
                                    </Card.Body>
                                </Link>
                            </Card>
                            <Card className='blogCardRight float-right'>
                                <Link to="/tax-deductions-for-medical-expenses-under-the-CRA" style={{ textDecoration: "none" }}>
                                    <div>
                                        <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                        <Card.Img variant="top" className='industryImg' src={taxdeduction2} alt="tax-deduction-for medical-expenses" />
                                    </div>

                                    <Card.Body>
                                        <Card.Title className='blogCardRightTitle'>Tax deductions for medical expenses under the CRA</Card.Title>
                                    </Card.Body>
                                </Link>
                            </Card>
                        </Col>
                    </Row>

                </Container>
            </Fragment>
        );
    }
}

export default BlogPart1;