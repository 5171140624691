import React, { Component, Fragment } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from "react-router-dom";

class FAQ extends Component {
    handleClick(id) {
        var coll = document.getElementById(id);
        coll.classList.toggle("factive");
        var content = coll.nextElementSibling;
        if (content.style.maxHeight) {
            content.style.maxHeight = null;
        } else {
            content.style.maxHeight = content.scrollHeight + "px";
        }
    }
    render() {
        return (
            <Fragment>
                <Container fluid={true} className='accContainer'>

                    <Container className='accSection'>
                        <div>
                            <h2 className='serviceMainTitle text-center'>FREQUENTLY ASKED QUESTIONS ABOUT <span className='spancolor'><strong>OUR SERVICE</strong></span></h2>
                        </div>
                        <Row>
                            <Col>
                                <div className='card mt-4'>
                                    <button class="FAQcollapsible " id='b1' onClick={() => this.handleClick('b1')}>What kind of income tax services does your company provide?</button>
                                    <div class="FAQcontent">
                                        <p className='FaqDescription'>We offer Personal Income Tax, Sole Property Business Tax (Commission Income, Sales, Trucking Company, etc), Corporate Tax Return, and Trusty Tax Return.</p>
                                    </div>
                                    <button class="FAQcollapsible mt-2" id='b2' onClick={() => this.handleClick('b2')}>Does your company have bookkeeping services?</button>
                                    <div class="FAQcontent">
                                        <p className='FaqDescription'>Yes, we offer complete in-house and offsite bookkeeping services to clients.</p>
                                        <p className='FaqDescription'><Link to="/contact" className='faqLink'>Contact V-TAC </Link> today for more information about our bookkeeping services.</p>
                                    </div>
                                    <button class="FAQcollapsible mt-2" id='b3' onClick={() => this.handleClick('b3')}>Which bookkeeping service is best for me, in-house or offsite?</button>
                                    <div class="FAQcontent">
                                        <p className='FaqDescription'>That depends entirely on what your business limitations are. If you lack the space and software, your best bet is to go with offsite bookkeeping. If on the other hand, you can afford the space and software, then in-house bookkeeping would be suitable. Ultimately, it all depends on what is best for you.</p>
                                    </div>
                                    <button class="FAQcollapsible mt-2" id='b4' onClick={() => this.handleClick('b4')}>Does your company have E-File Tax Returns?</button>
                                    <div class="FAQcontent">
                                        <p className='FaqDescription'>Yes, we do. We provide services that offer E-File for Personal Tax, Self-Employment and Corporate Tax.</p>
                                        <p className='FaqDescription'>If you have more questions regarding our services, <Link to="/contact" className='faqLink'>contact us </Link> today!</p>
                                    </div>
                                    <button class="FAQcollapsible mt-2" id='b5' onClick={() => this.handleClick('b5')}>Does your company do tax planning for GST, PST, Health Tax and WSIB?</button>
                                    <div class="FAQcontent">
                                        <p className='FaqDescription'>Yes, we do tax planning for all of that including reconciliation.</p>
                                    </div>
                                    <button class="FAQcollapsible mt-2" id='b6' onClick={() => this.handleClick('b6')}>Does your company incorporate businesses?</button>
                                    <div class="FAQcontent">
                                        <p className='FaqDescription'>Yes, we incorporate businesses at the Federal and Provincial level.</p>
                                    </div>
                                    <button class="FAQcollapsible mt-2" id='b7' onClick={() => this.handleClick('b7')}>Does your firm file Corporate Tax Returns for provinces outside of Ontario?</button>
                                    <div class="FAQcontent">
                                        <p className='FaqDescription'>Yes, in fact we can do Corporate Tax Returns for any business anywhere in Canada.</p>
                                        <p className='FaqDescription'>If you have more questions regarding our services, <Link to="/contact" className='faqLink'>contact us </Link> today!</p>
                                    </div>
                                    <button class="FAQcollapsible mt-2" id='b8' onClick={() => this.handleClick('b8')}>Does your company do Non-Profitable and Charitable Tax Returns?</button>
                                    <div class="FAQcontent">
                                        <p className='FaqDescription'>Yes, we provide tax services for charitable and non-profit organizations.</p>
                                    </div>
                                    <button class="FAQcollapsible mt-2" id='b9' onClick={() => this.handleClick('b9')}>Can you stay up-to-date with tax law changes?</button>
                                    <div class="FAQcontent">
                                        <p className='FaqDescription'>We most certainly can. Our team of professionals are prepared and knowledgeable of newly changed tax laws every year. See <span className='faqLink'>“News and Updates”</span></p>

                                    </div>
                                    <button class="FAQcollapsible mt-2" id='b10' onClick={() => this.handleClick('b10')}>What if I haven’t filed my previous year’s tax returns?</button>
                                    <div class="FAQcontent">
                                        <p className='FaqDescription'>We can still help and we’d be willing to speak on your behalf to tax authorities to help you settle your tax issues.</p>
                                        <p className='FaqDescription'>For more questions regarding our services, <Link to="/contact" className='faqLink'>contact us </Link> today!</p>
                                    </div>
                                    <button class="FAQcollapsible mt-2" id='b11' onClick={() => this.handleClick('b11')}>What if despite your quality services, I am still audited?</button>
                                    <div class="FAQcontent">
                                        <p className='FaqDescription'>Do not stress. Simply <span className='faqLink'>contact us</span> and we will guide and assist you throughout the process.</p>
                                    </div>
                                    <button class="FAQcollapsible mt-2" id='b12' onClick={() => this.handleClick('b12')}>What If I am self-employed. Can you help me decipher my business expenses?</button>
                                    <div class="FAQcontent">
                                        <p className='FaqDescription'>We definitely can. You can either contact us or drop by our offices and we will give you a thorough assessment of what your business expenses are.</p>
                                    </div>
                                    <button class="FAQcollapsible mt-2" id='b13' onClick={() => this.handleClick('b13')}>I would like to start my own business soon, how can I get a business plan started?</button>
                                    <div class="FAQcontent">
                                        <p className='FaqDescription'>To get started on a business plan, call us to set up an appointment with one of our business advisors who will help you take the right steps.</p>
                                    </div>
                                    <button class="FAQcollapsible mt-2" id='b14' onClick={() => this.handleClick('b14')}>What if I still have additional questions?</button>
                                    <div class="FAQcontent">
                                        <p className='FaqDescription'>If you have additional questions, call our office at 1-866-882-2829 or e-mail: info@vtac.ca</p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Container>
            </Fragment>
        );
    }
}

export default FAQ;