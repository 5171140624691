import React, { Component, Fragment } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import TAXRATESCANADA from '../asset/images/TAX RATES CANADA.png';
import fedarelTaxRate from '../asset/images/TAX rateSSS.png';
import TaxTips2 from '../asset/images/tax-small-3.jpg';
import TAXDates from '../asset/images/TAX Dates.png';
import house from '../asset/images/house.jpg';
import Studenttax from '../asset/images/Student tax.png';
import tipsfsttimefiling2 from '../asset/images/5tips-fst-time-filing2.webp';
import taximplication1 from '../asset/images/tax-implication1.webp';
import SelfEmployedTaxpayers from '../asset/images/Self-Employed -Taxpayers.png';
import taxinmultiplestate1 from '../asset/images/tax-in-multiple-state1.png';
import taxdeduction2 from '../asset/images/tax-deduction-2.png';

class BlogPart3 extends Component {
    render() {
        return (
            <Fragment>
                <Container>
                    <Row>
                        <Col lg={8} md={6} sm={12}>
                            <div className='blogDetailDiv'>
                                <h2 className='blogTopTitle fs-1'>Federal Tax Brackets, Rates & Credits 2023</h2>
                                <p className='blogDesc'>Understand how Canadian personal income taxes are calculated fairly with this guide. Learn the 2022 tax brackets and rates, how they work, and how much you may pay depending on your income. Plus, optimize your return with tax credits to be in the lowest possible tax bracket.</p>
                                <h2 className='blogDescTitle'>Federal Income Tax rates for the 2022 Tax year</h2>
                                <img variant="top" className='blog1PageImg2 mt-3 mb-3' src={TAXRATESCANADA} alt="federal-tax-brackets"></img>
                                <h2 className='blogDescTitle'>What are tax brackets?</h2>
                                <p className='blogDesc'>Tax brackets are the ranges of income set by the CRA to determine how much personal income tax you have to pay each year. These brackets are based on your taxable income, which is the amount from Line 15000 of your income tax return less any deductions you may be eligible for. The amount of taxes you pay depends on your income, with those who earn lower incomes being taxed at a lower percentage than those who have higher incomes. This is known as a progressive or graduated tax system.</p>
                                <h2 className='blogDescTitle'>What is the amount of federal taxes I owe based on my income?</h2>
                                <p className='blogDesc'>If your taxable income is less than $50,197 after deductions and amounts are taken into consideration, you are required to pay 15% of your taxable income in federal income tax. For example, if your taxable income is $30,000, you must pay $4,500 in federal income tax.</p>
                                <h2 className='blogDescTitle'>Marginal Tax Rates</h2>
                                <p className='blogDesc'>A common misunderstanding is that when your taxable income enters a higher tax bracket, all of your income will be taxed at that higher rate. This is not the case. Your earnings are divided into different portions that are taxed at different rates based on the bracket they fall into. Even if you move into a higher tax bracket, not all of your income is taxed at that rate; only the portion that falls into the range of the higher bracket is taxed at the higher rate. This is referred to as the "marginal tax rate," which is the amount of tax that is paid for each extra dollar earned.</p>
                                <h2 className='blogDescTitle'>Combined Federal & Provincial Tax Rates</h2>
                                <p className='blogDesc'>If you are a Canadian taxpayer, you will be subject to both federal and provincial/territorial income tax. Your provincial or territorial tax rate is determined by the province or territory you are living in on December 31 of the tax year. For example, if you move from Manitoba to Ontario in July, but you are still living in Ontario on December 31, you will be subject to the Ontario provincial tax rates.</p>
                                <h2 className='blogDescTitle'>Why does the tax bracket matter?</h2>
                                <img variant="top" className='blog1PageImg3 mt-3 mb-3' src={fedarelTaxRate} alt="federal-tax"></img>
                                <p className='blogDesc'>Being aware of which tax bracket your income falls into can help you comprehend why your taxes may have changed from the previous year. For example, if your income rises due to a side job or other extra income and places you into a higher tax bracket, this could be the reasoning behind owing taxes or having a different refund amount. Knowing your tax bracket can also be beneficial when deciding when and how to use deductions and credits.</p>
                                <h2 className='blogDescTitle'>How can I reduce my tax bracket?</h2>
                                <p className='blogDesc'>Two strategies to reduce taxes payable or increase the tax refund are credits and deductions. </p>
                                <p className='blogDesc'><strong>Credits:</strong> reduce the tax you owe on your taxable income and can be either refundable or non-refundable. An example of a refundable credit is the Canada Training credit, while the charitable tax credit is an example of a non-refundable credit.</p>
                                <p className='blogDesc'><strong>Deductions:</strong> You can reduce your taxable income and lower the amount of your income that is subject to taxes by subtracting certain amounts and expenses from your income. An example of this would be expenses related to self-employment.</p>
                            </div>
                        </Col>
                        <Col lg={4} md={6} sm={12}>
                            <div className='relatedArticle'>
                                <h2 className='fs-3 '>Related Articles</h2>
                                <hr style={{ width: "100%", float: "left" }}></hr>
                            </div>
                            <Card className='blogCardRight float-right'>
                                <Link to="/ways-to-stay-ahead-of-tax-season" style={{ textDecoration: "none" }}>
                                    <div>
                                        <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                        <Card.Img variant="top" className='industryImg' src={TaxTips2} />
                                    </div>

                                    <Card.Body>
                                        <Card.Title className='blogCardRightTitle'>8 straight ways to stay ahead of tax season 2023</Card.Title>
                                    </Card.Body>
                                </Link>
                            </Card>
                            <Card className='blogCardRight float-right'>
                                <Link to="/important-tax-deadlines" style={{ textDecoration: "none" }}>
                                    <div>
                                        <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                        <Card.Img variant="top" className='industryImg' src={TAXDates} />
                                    </div>

                                    <Card.Body>
                                        <Card.Title className='blogCardRightTitle'>Important Tax Deadlines 2023</Card.Title>
                                    </Card.Body>
                                </Link>
                            </Card>
                            <Card className='blogCardRight float-right'>
                                <Link to="/housing-benefit-and-the-way-to-apply" style={{ textDecoration: "none" }}>
                                    <div>
                                        <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                        <Card.Img variant="top" className='industryImg' src={house} />
                                    </div>

                                    <Card.Body>
                                        <Card.Title className='blogCardRightTitle'>Are you a renter in Canada? Learn about the Housing Benefit and the way to apply for it. </Card.Title>
                                    </Card.Body>
                                </Link>
                            </Card>
                            <Link to="/tips-for-tax-students" style={{ textDecoration: "none" }}>
                                <Card className='blogCardRight float-right'>
                                    <div>
                                        <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                        <Card.Img variant="top" className='industryImg' src={Studenttax} />
                                    </div>

                                    <Card.Body>
                                        <Card.Title className='blogCardRightTitle'>Maximizing Your 2022 Return: Tips for Students</Card.Title>
                                    </Card.Body>
                                </Card>
                                <Card className='blogCardRight float-right'>
                                    <Link to="/tax-implications-for-having-multiple-employers" style={{ textDecoration: "none" }}>
                                        <div>
                                            <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                            <Card.Img variant="top" className='industryImg' src={taximplication1} />
                                        </div>

                                        <Card.Body>
                                            <Card.Title className='blogCardRightTitle'>Tax implications for having multiple employers</Card.Title>
                                        </Card.Body>
                                    </Link>
                                </Card>

                                <Card className='blogCardRight float-right'>
                                    <Link to="/tips-for-a-great-first-time-filing-experience" style={{ textDecoration: "none" }}>
                                        <div>
                                            <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                            <Card.Img variant="top" className='industryImg' src={tipsfsttimefiling2} />
                                        </div>

                                        <Card.Body>
                                            <Card.Title className='blogCardRightTitle'>5 tips for a great first-time filing experience</Card.Title>
                                        </Card.Body>
                                    </Link>
                                </Card>
                            </Link>

                            <Card className='blogCardRight float-right'>
                                <Link to="/tips-for-self-employed-taxpayers" style={{ textDecoration: "none" }}>
                                    <div>
                                        <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                        <Card.Img variant="top" className='industryImg' src={SelfEmployedTaxpayers} />
                                    </div>

                                    <Card.Body>
                                        <Card.Title className='blogCardRightTitle'>Tips for Self-Employed Taxpayers</Card.Title>
                                    </Card.Body>
                                </Link>
                            </Card>
                            <Card className='blogCardRight float-right'>
                                <Link to="/how-to-filing-taxes-in-multiple-states-when-moved" style={{ textDecoration: "none" }}>
                                    <div>
                                        <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                        <Card.Img variant="top" className='industryImg' src={taxinmultiplestate1} />
                                    </div>

                                    <Card.Body>
                                        <Card.Title className='blogCardRightTitle'>How to Filing Taxes in Multiple States When Moved</Card.Title>
                                    </Card.Body>
                                </Link>
                            </Card>
                            <Card className='blogCardRight float-right'>
                                <Link to="/tax-deductions-for-medical-expenses-under-the-CRA" style={{ textDecoration: "none" }}>
                                    <div>
                                        <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                        <Card.Img variant="top" className='industryImg' src={taxdeduction2} alt="tax-deduction-for medical-expenses" />
                                    </div>

                                    <Card.Body>
                                        <Card.Title className='blogCardRightTitle'>Tax deductions for medical expenses under the CRA</Card.Title>
                                    </Card.Body>
                                </Link>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        );
    }
}

export default BlogPart3;