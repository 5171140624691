import React, { Component, Fragment } from 'react';
import { Col, Container, Row, Button } from 'react-bootstrap';

class NewsUpdates extends Component {
    render() {
        return (
            <Fragment>
                <Container className='incorporationSec'>
                    <div>
                        <h2 className='accTitle'>
                            News and Updates
                        </h2>
                    </div>
                    <Button className='Infobtn' variant="info">2015</Button>{' '}
                    <Row>
                        <Col className='NewsHead float-left' lg={6} md={6} sm={6}>
                            <p><strong>Basic Deduction’s</strong></p>
                        </Col>
                        <Col className='NewsHead text-center' lg={2} md={2} sm={2}>
                            <p><strong>Federal</strong></p>
                        </Col>
                        <Col className='NewsHead text-center' lg={2} md={2} sm={2}>
                            <p><strong>Ontario</strong></p>
                        </Col>
                        <Col className='NewsHead text-center' lg={2} md={2} sm={2}>
                            <p><strong>Quebec</strong></p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid1 float-left' lg={6} md={6} sm={6}>
                            <p>Basic personal amount</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>$11,327</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>$8,963</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>$11,425</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid2 float-left' lg={6} md={6} sm={6}>
                            <p>Spouse /equivalent amount</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>$11,327</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>$8,375</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>$11,425</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid1 float-left' lg={6} md={6} sm={6}>
                            <p>Age credit</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>$7,033</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>$4,815</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>$2,460</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid2 float-left' lg={6} md={6} sm={6}>
                            <p>Disability amount</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>$7,899</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>$7,968</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>$2,595</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid1 float-left' lg={6} md={6} sm={6}>
                            <p>Disability amount supplement for children n with severe disabilities</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>$4,607</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>$4,647</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid2 float-left' lg={6} md={6} sm={6}>
                            <p>Education credit (Full time)</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>$400/Per month</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>$531/Per month</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>8% of amount you paid</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid1 float-left' lg={6} md={6} sm={6}>
                            <p>Education credit (Part time)</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>$120/Per month</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>$159/Per month</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>8% of amount you paid</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid2 float-left' lg={6} md={6} sm={6}>
                            <p>Tuition & Education amount transferred</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>$5,000</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>$6,820</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>20% of lines54</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='NewsHead1 float-left' lg={6} md={6} sm={6}>
                            <p><strong>Ontario Tax Rates:</strong></p>
                        </Col>
                        <Col className='NewsHead1 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='NewsHead1 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='NewsHead1 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                    </Row>

                    <Row>
                        <Col className='grid1 float-left' lg={6} md={6} sm={6}>
                            <p>01st Tax Bracket</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>Up to $40922</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>5.05%</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid2 float-left' lg={6} md={6} sm={6}>
                            <p>02nd Tax Bracket</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>$ 40,923–81,847</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>9.15%</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid1 float-left' lg={6} md={6} sm={6}>
                            <p>03rd Tax Bracket</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>$ 81,848–150,000</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>11.16%</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid2 float-left' lg={6} md={6} sm={6}>
                            <p>04th Tax Bracket</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>$ 150,001–220,000</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>12.16%</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid1 float-left' lg={6} md={6} sm={6}>
                            <p></p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>$ 220,001 and over</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>	13.16%</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='NewsHead2 float-left' lg={6} md={6} sm={6}>
                            <p><strong>Federal Tax Rates:</strong></p>
                        </Col>
                        <Col className='NewsHead2 ' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='NewsHead2 ' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='NewsHead2 ' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid1 float-left' lg={6} md={6} sm={6}>
                            <p>01st Tax Bracket</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>Up to $44,701</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>15%</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid2 float-left' lg={6} md={6} sm={6}>
                            <p>02nd Tax Bracket</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>$ 44,702–89,401</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>22%</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid1 float-left' lg={6} md={6} sm={6}>
                            <p>03rd Tax Bracket</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>$ 89,402–138,586</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>26%</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid2 float-left' lg={6} md={6} sm={6}>
                            <p>04th Tax Bracket</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>$ 138,587 and over</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>29%</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='NewsHead1 float-left' lg={6} md={6} sm={6}>
                            <p><strong>Quebec Tax Rates:</strong></p>
                        </Col>
                        <Col className='NewsHead1 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='NewsHead1 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='NewsHead1 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid1 float-left' lg={6} md={6} sm={6}>
                            <p>01st Tax Bracket</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>First $41,935</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>16%</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid2 float-left' lg={6} md={6} sm={6}>
                            <p>02nd Tax Bracket</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>$ 41,936 up to $83,865</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>20%</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid1 float-left' lg={6} md={6} sm={6}>
                            <p>03rd Tax Bracket</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>$ 83,866 up to $102,040</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>24%</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid2 float-left' lg={6} md={6} sm={6}>
                            <p>04th Tax Bracket</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>$102,041 and over</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>	25.75%</p>
                        </Col>
                    </Row>

                    <Button className='Infobtn mt-2' variant="info">2014</Button>{' '}

                    <Row>
                        <Col className='NewsHead float-left' lg={6} md={6} sm={6}>
                            <p><strong>Basic Deduction’s</strong></p>
                        </Col>
                        <Col className='NewsHead text-center' lg={2} md={2} sm={2}>
                            <p><strong>Federal</strong></p>
                        </Col>
                        <Col className='NewsHead text-center' lg={2} md={2} sm={2}>
                            <p><strong>Ontario</strong></p>
                        </Col>
                        <Col className='NewsHead text-center' lg={2} md={2} sm={2}>
                            <p><strong>Quebec</strong></p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid1 float-left' lg={6} md={6} sm={6}>
                            <p>Basic personal amount</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>$11,138</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>$9,670</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>$11,305</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid2 float-left' lg={6} md={6} sm={6}>
                            <p>Spouse /equivalent amount</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>$11,138</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>$8,211</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>$11,305</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid1 float-left' lg={6} md={6} sm={6}>
                            <p>Age credit</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>$6,916</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>$4,721</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>$2,435</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid2 float-left' lg={6} md={6} sm={6}>
                            <p>Disability amount</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>$7,766</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>$7,812</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>$2,570</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid1 float-left' lg={6} md={6} sm={6}>
                            <p>Disability amount supplement for children n with severe disabilities</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>$4,530</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>$4,556</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid2 float-left' lg={6} md={6} sm={6}>
                            <p>Education credit (Full time)</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>$400/Per month</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>$520/Per month</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>8% of amount you paid</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid1 float-left' lg={6} md={6} sm={6}>
                            <p>Education credit (Part time)</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>$120/Per month</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>$156/Per month</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>8% of amount you paid</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid2 float-left' lg={6} md={6} sm={6}>
                            <p>Tuition & Education amount transferred</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>$5,000</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>$6,686</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>20% of lines54</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='NewsHead1 float-left' lg={6} md={6} sm={6}>
                            <p><strong>Ontario Tax Rates:</strong></p>
                        </Col>
                        <Col className='NewsHead1 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='NewsHead1 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='NewsHead1 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                    </Row>

                    <Row>
                        <Col className='grid1 float-left' lg={6} md={6} sm={6}>
                            <p>01st Tax Bracket</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>$39,723 or less</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>5.05%</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid2 float-left' lg={6} md={6} sm={6}>
                            <p>02nd Tax Bracket</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>$39,724 to $79,448</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>9.15%</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid1 float-left' lg={6} md={6} sm={6}>
                            <p>03rd Tax Bracket</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>$79,449 to $509,000</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>11.16%</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid2 float-left' lg={6} md={6} sm={6}>
                            <p>04th Tax Bracket</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>$509,001 and over</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>13.165%</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='NewsHead2 float-left' lg={6} md={6} sm={6}>
                            <p><strong>Federal Tax Rates:</strong></p>
                        </Col>
                        <Col className='NewsHead2 ' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='NewsHead2 ' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='NewsHead2 ' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid1 float-left' lg={6} md={6} sm={6}>
                            <p>01st Tax Bracket</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>Up to $43,953</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>15%</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid2 float-left' lg={6} md={6} sm={6}>
                            <p>02nd Tax Bracket</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>$43,954–$87,907</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>22%</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid1 float-left' lg={6} md={6} sm={6}>
                            <p>03rd Tax Bracket</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>$87,908–$136,270</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>26%</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid2 float-left' lg={6} md={6} sm={6}>
                            <p>04th Tax Bracket</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>	$136,271 and over</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>29%</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='NewsHead1 float-left' lg={6} md={6} sm={6}>
                            <p><strong>Quebec Tax Rates:</strong></p>
                        </Col>
                        <Col className='NewsHead1 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='NewsHead1 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='NewsHead1 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid1 float-left' lg={6} md={6} sm={6}>
                            <p>01st Tax Bracket</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>$41,495 or less</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>16%</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid2 float-left' lg={6} md={6} sm={6}>
                            <p>02nd Tax Bracket</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>$41,496 to $ 82,985</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>20%</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid1 float-left' lg={6} md={6} sm={6}>
                            <p>03rd Tax Bracket</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>	$82,986 to $100,970</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>24%</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid2 float-left' lg={6} md={6} sm={6}>
                            <p>04th Tax Bracket</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>$100,971 and over</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>	25.75%</p>
                        </Col>
                    </Row>

                    <Button className='Infobtn mt-2' variant="info">2013</Button>{' '}

                    <Row>
                        <Col className='NewsHead float-left' lg={6} md={6} sm={6}>
                            <p><strong>Basic Deduction’s</strong></p>
                        </Col>
                        <Col className='NewsHead text-center' lg={2} md={2} sm={2}>
                            <p><strong>Federal</strong></p>
                        </Col>
                        <Col className='NewsHead text-center' lg={2} md={2} sm={2}>
                            <p><strong>Ontario</strong></p>
                        </Col>
                        <Col className='NewsHead text-center' lg={2} md={2} sm={2}>
                            <p><strong>Quebec</strong></p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid1 float-left' lg={6} md={6} sm={6}>
                            <p>Basic personal amount</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>$11,038</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>$9,574</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>$11,195</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid2 float-left' lg={6} md={6} sm={6}>
                            <p>Spouse /equivalent amount</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>$11,038</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>$8,942</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>$11,195</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid1 float-left' lg={6} md={6} sm={6}>
                            <p>Age credit</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>$6,845</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>$4,674</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>$2,410</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid2 float-left' lg={6} md={6} sm={6}>
                            <p>Disability amount</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>$7,697</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>$7,735</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>$2,545</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid1 float-left' lg={6} md={6} sm={6}>
                            <p>Disability amount supplement for children with severe disabilities</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>$4,490</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>$12,246</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid2 float-left' lg={6} md={6} sm={6}>
                            <p>Education credit (Full time)</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>$520/per month</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>$515/per month</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>$3,770 by standard</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid1 float-left' lg={6} md={6} sm={6}>
                            <p>Education credit (Part time)</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>$85/per month</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>$154/per month</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>RL 8</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid2 float-left' lg={6} md={6} sm={6}>
                            <p>Tuition & Education amount transferred</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>$5,000</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>$6,620</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='NewsHead1 float-left' lg={6} md={6} sm={6}>
                            <p><strong>Ontario Tax Rates:</strong></p>
                        </Col>
                        <Col className='NewsHead1 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='NewsHead1 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='NewsHead1 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                    </Row>

                    <Row>
                        <Col className='grid1 float-left' lg={6} md={6} sm={6}>
                            <p>01st Tax Bracket</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>$39723 or less</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>5.05%</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid2 float-left' lg={6} md={6} sm={6}>
                            <p>02nd Tax Bracket</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>$39,724 to $79,448</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>9.15%</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid1 float-left' lg={6} md={6} sm={6}>
                            <p>03rd Tax Bracket</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>$79,449 to $509,000</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>11.16%</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid2 float-left' lg={6} md={6} sm={6}>
                            <p>04th Tax Bracket</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>$509,001 and over</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>13.16%</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='NewsHead2 float-left' lg={6} md={6} sm={6}>
                            <p><strong>Federal Tax Rates:</strong></p>
                        </Col>
                        <Col className='NewsHead2 ' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='NewsHead2 ' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='NewsHead2 ' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid1 float-left' lg={6} md={6} sm={6}>
                            <p>01st Tax Bracket</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>$43561 or less</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>15%</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid2 float-left' lg={6} md={6} sm={6}>
                            <p>02nd Tax Bracket</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>$43561 to $87123</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>22%</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid1 float-left' lg={6} md={6} sm={6}>
                            <p>03rd Tax Bracket</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>$87123 to $135054</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>26%</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid2 float-left' lg={6} md={6} sm={6}>
                            <p>04th Tax Bracket</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>	$135054 and over</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>29%</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='NewsHead1 float-left' lg={6} md={6} sm={6}>
                            <p><strong>Quebec Tax Rates:</strong></p>
                        </Col>
                        <Col className='NewsHead1 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='NewsHead1 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='NewsHead1 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid1 float-left' lg={6} md={6} sm={6}>
                            <p>01st Tax Bracket</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>$41095 or less</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>16%</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid2 float-left' lg={6} md={6} sm={6}>
                            <p>02nd Tax Bracket</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>$41095 to $82190</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>20%</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid1 float-left' lg={6} md={6} sm={6}>
                            <p>03rd Tax Bracket</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>$82190 and over</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>24%</p>
                        </Col>
                    </Row>

                    <Button className='Infobtn mt-2' variant="info">2012</Button>{' '}

                    <Row>
                        <Col className='NewsHead float-left' lg={6} md={6} sm={6}>
                            <p><strong>Basic Deduction’s</strong></p>
                        </Col>
                        <Col className='NewsHead text-center' lg={2} md={2} sm={2}>
                            <p><strong>Federal</strong></p>
                        </Col>
                        <Col className='NewsHead text-center' lg={2} md={2} sm={2}>
                            <p><strong>Ontario</strong></p>
                        </Col>
                        <Col className='NewsHead text-center' lg={2} md={2} sm={2}>
                            <p><strong>Quebec</strong></p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid1 float-left' lg={6} md={6} sm={6}>
                            <p>Basic personal amount</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>$10,822</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>$9,405</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>$10,925</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid2 float-left' lg={6} md={6} sm={6}>
                            <p>Spouse /equivalent amount</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>$10,822</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>$7,986</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>$10,925</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid1 float-left' lg={6} md={6} sm={6}>
                            <p>Age credit</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>$6,720</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>$4,592</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>$2,290</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid2 float-left' lg={6} md={6} sm={6}>
                            <p>Disability amount</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>$7,546</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>$7,598</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>$2,485</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid1 float-left' lg={6} md={6} sm={6}>
                            <p>Disability amount supplement for children with severe disabilities</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>$4,402</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>$12,030</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid2 float-left' lg={6} md={6} sm={6}>
                            <p>Education credit (Full time)</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>$465/per month</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>$506/per month</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>$3,770 by standard</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid1 float-left' lg={6} md={6} sm={6}>
                            <p>Education credit (Part time)</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>$140/per month</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>$151/per month</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>RL 8</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid2 float-left' lg={6} md={6} sm={6}>
                            <p>Tuition & Education amount transferred</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>$5,000</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>$6,503</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='NewsHead1 float-left' lg={6} md={6} sm={6}>
                            <p><strong>Ontario Tax Rates:</strong></p>
                        </Col>
                        <Col className='NewsHead1 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='NewsHead1 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='NewsHead1 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                    </Row>

                    <Row>
                        <Col className='grid1 float-left' lg={6} md={6} sm={6}>
                            <p>01st Tax Bracket</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>$39020 or less</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>5.05%</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid2 float-left' lg={6} md={6} sm={6}>
                            <p>02nd Tax Bracket</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>$39020 to $78043</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>9.15%</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid1 float-left' lg={6} md={6} sm={6}>
                            <p>03rd Tax Bracket</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>$78043 to $500000</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>11.16%</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid2 float-left' lg={6} md={6} sm={6}>
                            <p>04th Tax Bracket</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>$500000 and over</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>12.16%</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='NewsHead2 float-left' lg={6} md={6} sm={6}>
                            <p><strong>Federal Tax Rates:</strong></p>
                        </Col>
                        <Col className='NewsHead2 ' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='NewsHead2 ' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='NewsHead2 ' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid1 float-left' lg={6} md={6} sm={6}>
                            <p>01st Tax Bracket</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>$42707 or less</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>15%</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid2 float-left' lg={6} md={6} sm={6}>
                            <p>02nd Tax Bracket</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>$42707 to $85414</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>22%</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid1 float-left' lg={6} md={6} sm={6}>
                            <p>03rd Tax Bracket</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>$85414 to $132406</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>26%</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid2 float-left' lg={6} md={6} sm={6}>
                            <p>04th Tax Bracket</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>$132406 and over</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>29%</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='NewsHead1 float-left' lg={6} md={6} sm={6}>
                            <p><strong>Quebec Tax Rates:</strong></p>
                        </Col>
                        <Col className='NewsHead1 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='NewsHead1 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='NewsHead1 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid1 float-left' lg={6} md={6} sm={6}>
                            <p>01st Tax Bracket</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>$39060 or less</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>16%</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid2 float-left' lg={6} md={6} sm={6}>
                            <p>02nd Tax Bracket</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>$39606 to $78120</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>20%</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid1 float-left' lg={6} md={6} sm={6}>
                            <p>03rd Tax Bracket</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>$78120 and over</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>24%</p>
                        </Col>
                    </Row>
                    <Button className='Infobtn mt-2' variant="info">2011</Button>{' '}

                    <Row>
                        <Col className='NewsHead float-left' lg={6} md={6} sm={6}>
                            <p><strong>Basic Deduction’s</strong></p>
                        </Col>
                        <Col className='NewsHead text-center' lg={2} md={2} sm={2}>
                            <p><strong>Federal</strong></p>
                        </Col>
                        <Col className='NewsHead text-center' lg={2} md={2} sm={2}>
                            <p><strong>Ontario</strong></p>
                        </Col>
                        <Col className='NewsHead text-center' lg={2} md={2} sm={2}>
                            <p><strong>Quebec</strong></p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid1 float-left' lg={6} md={6} sm={6}>
                            <p>Basic personal amount</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>$10,527</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>$9,104</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>$10,640</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid2 float-left' lg={6} md={6} sm={6}>
                            <p>Spouse /equivalent amount</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>$10,527</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>$7,730</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>$10,640</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid1 float-left' lg={6} md={6} sm={6}>
                            <p>Age credit</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>$6,537</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>$4,445</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>$2,290</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid2 float-left' lg={6} md={6} sm={6}>
                            <p>Disability amount</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>$7,341</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>$7,355</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>$2,420</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid1 float-left' lg={6} md={6} sm={6}>
                            <p>Disability amount supplement for children with severe disabilities</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>$11,623</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>$11,645</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid2 float-left' lg={6} md={6} sm={6}>
                            <p>Education credit (Full time)</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>$465/per month</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>$490/per month</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>$3,930 by standard</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid1 float-left' lg={6} md={6} sm={6}>
                            <p>Education credit (Part time)</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>$140/per month</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>$147/per month</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>RL 8</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid2 float-left' lg={6} md={6} sm={6}>
                            <p>Tuition & Education amount transferred</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>$5,000</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>$6,295</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='NewsHead1 float-left' lg={6} md={6} sm={6}>
                            <p><strong>Ontario Tax Rates:</strong></p>
                        </Col>
                        <Col className='NewsHead1 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='NewsHead1 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='NewsHead1 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                    </Row>

                    <Row>
                        <Col className='grid1 float-left' lg={6} md={6} sm={6}>
                            <p>01st Tax Bracket</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>$37,774 or less</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>5.05%</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid2 float-left' lg={6} md={6} sm={6}>
                            <p>02nd Tax Bracket</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>$37,775 to $75,550</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>9.15%</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid1 float-left' lg={6} md={6} sm={6}>
                            <p>03rd Tax Bracket</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>$75,551 & up</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>11.16%</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='NewsHead2 float-left' lg={6} md={6} sm={6}>
                            <p><strong>Federal Tax Rates:</strong></p>
                        </Col>
                        <Col className='NewsHead2 ' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='NewsHead2 ' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='NewsHead2 ' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid1 float-left' lg={6} md={6} sm={6}>
                            <p>01st Tax Bracket</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>$41,544 or less</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>15%</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid2 float-left' lg={6} md={6} sm={6}>
                            <p>02nd Tax Bracket</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>$41,545 to $83,088</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>22%</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid1 float-left' lg={6} md={6} sm={6}>
                            <p>03rd Tax Bracket</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>$83,089 to $128,000</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>26%</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid2 float-left' lg={6} md={6} sm={6}>
                            <p>04th Tax Bracket</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>$128,001 and over</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>29%</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='NewsHead1 float-left' lg={6} md={6} sm={6}>
                            <p><strong>Quebec Tax Rates:</strong></p>
                        </Col>
                        <Col className='NewsHead1 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='NewsHead1 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='NewsHead1 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid1 float-left' lg={6} md={6} sm={6}>
                            <p>01st Tax Bracket</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>$39,060 or less</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>16%</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid2 float-left' lg={6} md={6} sm={6}>
                            <p>02nd Tax Bracket</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>$39,061 to $78,120</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>20%</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid1 float-left' lg={6} md={6} sm={6}>
                            <p>03rd Tax Bracket</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>$78,121 and over</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>24%</p>
                        </Col>
                    </Row>
                    <Button className='Infobtn mt-2' variant="info">2010</Button>{' '}

                    <Row>
                        <Col className='NewsHead float-left' lg={6} md={6} sm={6}>
                            <p><strong>Basic Deduction’s</strong></p>
                        </Col>
                        <Col className='NewsHead text-center' lg={2} md={2} sm={2}>
                            <p><strong>Federal</strong></p>
                        </Col>
                        <Col className='NewsHead text-center' lg={2} md={2} sm={2}>
                            <p><strong>Ontario</strong></p>
                        </Col>
                        <Col className='NewsHead text-center' lg={2} md={2} sm={2}>
                            <p><strong>Quebec</strong></p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid1 float-left' lg={6} md={6} sm={6}>
                            <p>Basic personal amount</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>	$10,382</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>$8,943</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>$10,505</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid2 float-left' lg={6} md={6} sm={6}>
                            <p>Spouse /equivalent amount</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>	$10,382</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>$7,594</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>$10,505</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid1 float-left' lg={6} md={6} sm={6}>
                            <p>Age credit</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>$6,446</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>$4,366</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>$2,260</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid2 float-left' lg={6} md={6} sm={6}>
                            <p>Disability amount</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>$7,239</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>$7,225</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>$2,390</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid1 float-left' lg={6} md={6} sm={6}>
                            <p>Disability amount supplement for children with severe disabilities</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>$4,223</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>$11,439</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid2 float-left' lg={6} md={6} sm={6}>
                            <p>Education credit (Full time)</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>$465/per month</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>$481/per month</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>$3,770 by standard</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid1 float-left' lg={6} md={6} sm={6}>
                            <p>Education credit (Part time)</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>$140/per month</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>$144/per month</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>RL 8</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid2 float-left' lg={6} md={6} sm={6}>
                            <p>Tuition & Education amount transferred</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>$5,000</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>$6,184</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='NewsHead1 float-left' lg={6} md={6} sm={6}>
                            <p><strong>Ontario Tax Rates:</strong></p>
                        </Col>
                        <Col className='NewsHead1 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='NewsHead1 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='NewsHead1 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                    </Row>

                    <Row>
                        <Col className='grid1 float-left' lg={6} md={6} sm={6}>
                            <p>01st Tax Bracket</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>$37,106 or less</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>5.05%</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid2 float-left' lg={6} md={6} sm={6}>
                            <p>02nd Tax Bracket</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>$37,107 to $74,214</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>9.15%</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid1 float-left' lg={6} md={6} sm={6}>
                            <p>03rd Tax Bracket</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>$74,215 & up</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>11.16%</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='NewsHead2 float-left' lg={6} md={6} sm={6}>
                            <p><strong>Federal Tax Rates:</strong></p>
                        </Col>
                        <Col className='NewsHead2 ' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='NewsHead2 ' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='NewsHead2 ' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid1 float-left' lg={6} md={6} sm={6}>
                            <p>01st Tax Bracket</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>$40,970 or less</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>15%</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid2 float-left' lg={6} md={6} sm={6}>
                            <p>02nd Tax Bracket</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>$40,971 to $81,941</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>22%</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid1 float-left' lg={6} md={6} sm={6}>
                            <p>03rd Tax Bracket</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>$81,942 to $127,021</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>26%</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid2 float-left' lg={6} md={6} sm={6}>
                            <p>04th Tax Bracket</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>$127,022 and over</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>29%</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='NewsHead1 float-left' lg={6} md={6} sm={6}>
                            <p><strong>Quebec Tax Rates:</strong></p>
                        </Col>
                        <Col className='NewsHead1 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='NewsHead1 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='NewsHead1 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid1 float-left' lg={6} md={6} sm={6}>
                            <p>01st Tax Bracket</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>$38,570 or less</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>16%</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid2 float-left' lg={6} md={6} sm={6}>
                            <p>02nd Tax Bracket</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>$38,571 to $77,140</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>20%</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid1 float-left' lg={6} md={6} sm={6}>
                            <p>03rd Tax Bracket</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>$77,141 and over</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>24%</p>
                        </Col>
                    </Row>
                    <Button className='Infobtn mt-2' variant="info">2009</Button>{' '}

                    <Row>
                        <Col className='NewsHead float-left' lg={6} md={6} sm={6}>
                            <p><strong>Basic Deduction’s</strong></p>
                        </Col>
                        <Col className='NewsHead text-center' lg={2} md={2} sm={2}>
                            <p><strong>Federal</strong></p>
                        </Col>
                        <Col className='NewsHead text-center' lg={2} md={2} sm={2}>
                            <p><strong>Ontario</strong></p>
                        </Col>
                        <Col className='NewsHead text-center' lg={2} md={2} sm={2}>
                            <p><strong>Quebec</strong></p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid1 float-left' lg={6} md={6} sm={6}>
                            <p>Basic personal amount</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>$9,600</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>$8,681</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>$10,215</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid2 float-left' lg={6} md={6} sm={6}>
                            <p>Spouse /equivalent amount</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>$9,600</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>$7,371</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>$10,215</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid1 float-left' lg={6} md={6} sm={6}>
                            <p>Age credit</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>$5,276</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>$4,239</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>$1,485 per person</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid2 float-left' lg={6} md={6} sm={6}>
                            <p>Disability amount</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>$7,021</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>$7,014</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>$2,325 per person</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid1 float-left' lg={6} md={6} sm={6}>
                            <p>Disability amount supplement for children with severe disabilities</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>$4,095</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>$4,091</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid2 float-left' lg={6} md={6} sm={6}>
                            <p>Education credit (Full time)</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>$465/per month</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>$468/per month</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>$3,770 by standard</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid1 float-left' lg={6} md={6} sm={6}>
                            <p>Education credit (Part time)</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>$140/per month</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>$140/per month</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>RL 8</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid2 float-left' lg={6} md={6} sm={6}>
                            <p>Tuition & Education amount transferred</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>$5,000</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>$6,003</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='NewsHead1 float-left' lg={6} md={6} sm={6}>
                            <p><strong>Ontario Tax Rates:</strong></p>
                        </Col>
                        <Col className='NewsHead1 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='NewsHead1 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='NewsHead1 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                    </Row>

                    <Row>
                        <Col className='grid1 float-left' lg={6} md={6} sm={6}>
                            <p>01st Tax Bracket</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>$36,000 or less</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>6.05%</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid2 float-left' lg={6} md={6} sm={6}>
                            <p>02nd Tax Bracket</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>$36,000 to $72,041</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>9.15%</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid1 float-left' lg={6} md={6} sm={6}>
                            <p>03rd Tax Bracket</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>$72,041 & up</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>11.16%</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='NewsHead2 float-left' lg={6} md={6} sm={6}>
                            <p><strong>Federal Tax Rates:</strong></p>
                        </Col>
                        <Col className='NewsHead2 ' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='NewsHead2 ' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='NewsHead2 ' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid1 float-left' lg={6} md={6} sm={6}>
                            <p>01st Tax Bracket</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>$37,885 or less</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>15%</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid2 float-left' lg={6} md={6} sm={6}>
                            <p>02nd Tax Bracket</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>$37,885 to $75,769</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>22%</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid1 float-left' lg={6} md={6} sm={6}>
                            <p>03rd Tax Bracket</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>$75,769 to $123,184</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>26%</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid2 float-left' lg={6} md={6} sm={6}>
                            <p>04th Tax Bracket</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>$123,184 and over</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>29%</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='NewsHead1 float-left' lg={6} md={6} sm={6}>
                            <p><strong>Quebec Tax Rates:</strong></p>
                        </Col>
                        <Col className='NewsHead1 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='NewsHead1 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='NewsHead1 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid1 float-left' lg={6} md={6} sm={6}>
                            <p>01st Tax Bracket</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>$37,500 or less</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>16%</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid2 float-left' lg={6} md={6} sm={6}>
                            <p>02nd Tax Bracket</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>$37,500 to $75,000</p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='grid2 text-center' lg={2} md={2} sm={2}>
                            <p>20%</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='grid1 float-left' lg={6} md={6} sm={6}>
                            <p>03rd Tax Bracket</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>$75,000 and over</p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p></p>
                        </Col>
                        <Col className='grid1 text-center' lg={2} md={2} sm={2}>
                            <p>24%</p>
                        </Col>
                    </Row>


                </Container>
            </Fragment>
        );
    }
}

export default NewsUpdates;