import React, { Component, Fragment } from 'react';
import BlogTop from '../components/BlogTop';
import VtacQuote from '../components/VtacQuote';
import Footer from '../components/Footer';
import NewTopNavigation from '../components/NewTopNavigation';
import BlogPart7 from '../components/BlogPart7';
import SearchByWord from '../components/SearchByWord';

class BlogPart7Page extends Component {
    render() {
        return (
            <Fragment>
                <NewTopNavigation title="Tips for a thriving first-time filing experience-V-Tac Accounting & Tax | The Professionals' Professional" />
                <BlogTop />
                <SearchByWord />
                <BlogPart7 />
                <VtacQuote />
                <Footer />
            </Fragment>
        );
    }
}

export default BlogPart7Page;