import React from 'react';
import Footer from '../components/Footer';
import NewTopNavigation from '../components/NewTopNavigation';
import NotFound from '../components/NotFound';

const NotFoundPage = () => {
    return (
        <div>
            <NewTopNavigation title="404-page not found! V-Tac Accounting & Tax | The Professionals' Professional"/>
            <NotFound />
            <Footer />
        </div>
    );
};

export default NotFoundPage;