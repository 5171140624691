import React, { Component, Fragment } from 'react';
import AccountingSection from '../components/AccountingSection';
import PageTop from '../components/PageTop';
import VtacQuote from '../components/VtacQuote';
import Footer from '../components/Footer';
import NewTopNavigation from '../components/NewTopNavigation';
class AccountingPage extends Component {

    render() {
        return (
            <Fragment>
                <NewTopNavigation title="Accounting-V-Tac Accounting & Tax | The Professionals' Professional" />
                <PageTop topPageTitle="Accounting" topPagesubTitle="V-Tac is built upon helping businesses grasp opportunities and solve problems through an array of financial services. Whatever a clients’ financial obligations may be, V-Tac focuses on their specific needs so they can play a better role in the economy. Our professionals provide detailed attention, with proven results." />
                <AccountingSection />
                <VtacQuote />
                <Footer />
            </Fragment>
        );
    }
}

export default AccountingPage;