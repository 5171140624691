import React, { Component, Fragment } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import TaxTips2 from '../asset/images/tax-small-3.jpg';
import TaxTips1 from '../asset/images/Tax Datess.png';
import TAXRATE from '../asset/images/TAX RATE.png';
import house from '../asset/images/house.jpg';
import Studenttax from '../asset/images/Student tax.png';
import tipsfsttimefiling2 from '../asset/images/5tips-fst-time-filing2.webp';
import taximplication1 from '../asset/images/tax-implication1.webp';
import SelfEmployedTaxpayers from '../asset/images/Self-Employed -Taxpayers.png';
import taxinmultiplestate1 from '../asset/images/tax-in-multiple-state1.png';
import taxdeduction2 from '../asset/images/tax-deduction-2.png';

class BlogPart2 extends Component {
    render() {
        return (
            <Fragment>
                <Container>
                    <Row>
                        <Col lg={8} md={6} sm={12}>
                            <div className='blogDetailDiv'>
                                <h2 className='blogTopTitle fs-1'> Important Tax Deadlines 2023 </h2>
                                <p className='blogDesc'>Staying on top of tax deadlines can be daunting, but having a calendar and making sure you're aware of the appropriate due dates will help you avoid penalties, interest, and extra fees from your tax lawyer. Preparing for tax filing often necessitates a lot of paperwork and documentation. To ensure a successful filing for your 2022 taxes, start planning early to make sure you have everything you need. The following are some of the most frequently encountered Canada Revenue Agency deadlines:</p>
                                <img variant="top" className='blog1PageImg2 mt-3 mb-3' src={TaxTips1}></img>
                                <h2 className='blogDescTitle'>The month of February</h2>
                                <p className='blogDesc'>The Canada Revenue Agency has declared that the filing of taxes will begin on February 20th. This gives you ample time to assemble all necessary records and receipts. If you are a business, you should make sure to maintain your bookkeeping throughout the year, so that when tax season comes around, you are prepared. Additionally, you should be aware of any deductions that you may be eligible for, such as moving expenses.</p>
                                <h2 className='blogDescTitle'>The month of March</h2>
                                <p className='blogDesc'>Check your 2021 Notice of Assessment to determine if you have additional room in your RRSP to make any last-minute contributions before the RRSP contribution deadline of March 1st, 2023. Filing your taxes in March gives you the opportunity to get your refund quickly or plan out how you'll pay back any taxes owed.</p>
                                <h2 className='blogDescTitle'>The month of April</h2>
                                <p className='blogDesc'>Tax consultants will be too busy at this time. Because the 31st of April is the last date for tax filing. But it falls on Sunday so the deadline is extended by one day.</p>
                                <h2 className='blogDescTitle'>The month of May</h2>
                                <p className='blogDesc'>Today, those owing taxes must pay their balance due, even if they are self-employed and not required to file until June 15th. This is because, although April is typically the most important tax month as taxes are historically due then, the last day of April falling on a weekend this year means taxes are actually due on Monday, May 1st, 2023.</p>
                                <h2 className='blogDescTitle'>The month of June</h2>
                                <p className='blogDesc'>Don't miss the deadline! The tax filing deadline for self-employed individuals is June 15th, 2023. Don't hesitate to seek help from the experts if you have any questions about when and how to file your taxes. V-TAC Tax Experts are available to guide you through the process. Make an appointment today!!</p>
                            </div>
                        </Col>
                        <Col lg={4} md={6} sm={12}>
                            <div className='relatedArticle'>
                                <h2 className='fs-3 '>Related Articles</h2>
                                <hr style={{ width: "100%", float: "left" }}></hr>
                            </div>
                            <Card className='blogCardRight float-right'>
                                <Link to="/ways-to-stay-ahead-of-tax-season" style={{ textDecoration: "none" }}>
                                    <div>
                                        <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                        <Card.Img variant="top" className='industryImg' src={TaxTips2} />
                                    </div>

                                    <Card.Body>
                                        <Card.Title className='blogCardRightTitle'>8 straight ways to stay ahead of tax season 2023</Card.Title>
                                    </Card.Body>
                                </Link>
                            </Card>
                            <Card className='blogCardRight float-right'>
                                <Link to="/federal-tax-brackets-rates-credits" style={{ textDecoration: "none" }}>
                                    <div>
                                        <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                        <Card.Img variant="top" className='industryImg' src={TAXRATE} />
                                    </div>

                                    <Card.Body>
                                        <Card.Title className='blogCardRightTitle'>Federal Tax Brackets, Rates & Credits 2023</Card.Title>
                                    </Card.Body>
                                </Link>
                            </Card>
                            <Card className='blogCardRight float-right'>
                                <Link to="/housing-benefit-and-the-way-to-apply" style={{ textDecoration: "none" }}>
                                    <div>
                                        <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                        <Card.Img variant="top" className='industryImg' src={house} />
                                    </div>

                                    <Card.Body>
                                        <Card.Title className='blogCardRightTitle'>Are you a renter in Canada? Learn about the Housing Benefit and the way to apply for it. </Card.Title>
                                    </Card.Body>
                                </Link>
                            </Card>
                            <Card className='blogCardRight'>
                                <Link to="/tips-for-tax-students" style={{ textDecoration: "none" }}>
                                    <div>
                                        <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                        <Card.Img variant="top" className='industryImg' src={Studenttax} />
                                    </div>

                                    <Card.Body>
                                        <Card.Title className='blogCardRightTitle'>Maximizing Your 2022 Return: Tips for Students</Card.Title>
                                    </Card.Body>
                                </Link>
                            </Card>

                            <Card className='blogCardRight float-right'>
                                <Link to="/tax-implications-for-having-multiple-employers" style={{ textDecoration: "none" }}>
                                    <div>
                                        <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                        <Card.Img variant="top" className='industryImg' src={taximplication1} />
                                    </div>

                                    <Card.Body>
                                        <Card.Title className='blogCardRightTitle'>Tax implications for having multiple employers</Card.Title>
                                    </Card.Body>
                                </Link>
                            </Card>

                           
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        );
    }
}

export default BlogPart2;