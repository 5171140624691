import React, { Fragment } from 'react';
import BlogTop from '../components/BlogTop';
import VtacQuote from '../components/VtacQuote';
import Footer from '../components/Footer';
import NewTopNavigation from '../components/NewTopNavigation';
import BlogPart9 from '../components/BlogPart9';
import SearchByWord from '../components/SearchByWord';

const BlogPart9Page = () => {
    return (
        <Fragment>
            <NewTopNavigation title="How to Filing Taxes in Multiple States When Moved-V-Tac Accounting & Tax | The Professionals' Professional" />
            <BlogTop />
            <SearchByWord />
            <BlogPart9 />
            <VtacQuote />
            <Footer />
        </Fragment>
    );
};

export default BlogPart9Page;