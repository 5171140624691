import React, { Component, Fragment } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

class CaseStudy extends Component {
    render() {
        return (
            <Fragment>
                <Container className='accSection'>
                    <Row>
                        <Col lg={12} md={12} sm={12} >
                            <h2 className='accTitle2'>Mark Makowich</h2>
                            <p className='accSubtitle'>In 2002 I opened my business Nexus Staffing Inc. with the grand dream of becoming a resounding success. I went through the initial stages of setting up the business, and excitedly opened the doors to welcome customers.</p>
                            <p className='accSubtitle'>I thought it would be easy to handle my taxes myself. So I waited until the last few days, and then decided to tackle the task. But it was way too much for me. It was late in the night, after grappling with the figures for hours, and then I picked up the phone and called V-Tac Accounting and Tax, a company that is not too far from my business place. Right away, in the night, V-Tac sent a staff member to pick up my papers.</p>
                            <p className='accSubtitle'>They handled my taxes that year, and have been doing so ever since. Now I know who to trust about my taxes. I just leave everything in the trustworthy, professional hands of my V-TAC colleagues.</p>
                            <p className='accSubtitle'>Mark Makowich, President, Nexus Staffing Inc.</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <h2 className='accTitle2 mt-4'>John L. Walker</h2>
                            <p className='accSubtitle'>We have used V-Tac Accounting and Tax Services as our tax and accounting advisors for a number of years and they have become very important to us, helping us to run our practice efficiently. We have developed a sense of trust in their professional service, and have come to learn that V-Tac Accounting and Tax Services is very experienced at what they do. We can recommend them for businesses like ours with full confidence. They handle all of our firm’s tax and accounting needs, and we feel like we have a trusted business partner in them. To anyone who is starting a new business, we would highly recommend the services of V-Tac Accounting and Tax Services.</p>
                            <p className='accSubtitle'>John L. Walker, Walker Sorensen LLP, Barristers & Solicitors</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <h2 className='accTitle2 mt-4'>Miracle Graphics Canada</h2>
                            <p className='accSubtitle'>“As a startup company, Miracle Graphics Canada, it was difficult to handle the bigger needs of my company while still dedicating time to tracking my account records that would help determine my company’s financial success. V-Tac grasped my growing company’s challenges by working closely with my staff to keep our finances in order and updated. I feel confident and certain about the decisions I make because of V-Tac. They have helped me stay financially informed about my company through their bookkeeping expertise and advisory, which has led to substantial growth. Retaining V-Tac as a business partner is one of the best decisions I have made for my company.”</p>
                            <p className='accSubtitle'>At V-Tac, we understand the difficulties of getting a business started all while staying on top of its financial upkeep. Bookkeeping is the backbone of any company’s economic success. V-Tac provides accurate recording of all your company’s transactions. That way you don’t have to worry about it, and simply let our professional bookkeepers handle all your accounting reports.</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <h2 className='accTitle2 mt-4'>Euro Maintenance System</h2>
                            <p className='accSubtitle'>“Every year, tax laws undergo an array of changes and modifications, making it difficult for any corporation to follow and stay informed. Tax season thus became such a stressful time because of these uncertainties. Our company Euro Maintenance System decided we needed a team of tax advisors to ensure our corporate taxes complied with regulations yet maximize our returns. With V-Tac, our business achieved all of this and more. Their experts are highly trained, knowledgeable, and responsive. They took the time to listen and understand our tax concerns, and offered us creative solutions and tax strategies to minimize penalties. We look forward to maintaining a partnership with them in the future.”</p>
                            <p className='accSubtitle'>Tax laws are never static and are subject to change. It is therefore essential that you acquire proper knowledge of tax laws to avoid risks. V-Tac’s tax consultants are well equipped and prepared to carefully interpret and analyze rules and laws, so you don’t have to. Let us impart our knowledge and expertise with you to better serve your financial interests.</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <h2 className='accTitle2 mt-4'>Metro Taps Inc.</h2>
                            <p className='accSubtitle'>“Tax time for my family has become a more pleasant experience. Before using V-Tac’s services, my husband and I always ran into some auditing difficulties with other tax firms. It was very inconvenient for our company Metro Taps Inc. and time-consuming. We felt our financial interests were not being acknowledged or taken care of. We wanted to find a firm we could trust to properly handle our personal taxes without worrying about being audited. We found that with V-Tac. My family has come to value V-Tac’s services and knowledge. They have exceeded all our expectations. Their staff is prompt, accurate, and above all trustworthy.”</p>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        );
    }
}

export default CaseStudy;