import React, { Component, Fragment } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faDownload } from '@fortawesome/free-solid-svg-icons';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Slide1 from '../asset/images/Slide1.jpg';
import Slide2 from '../asset/images/Slide2.jpg';
import Slide3 from '../asset/images/Slide3.jpg';
import Slide4 from '../asset/images/Slide4.jpg';
import Slide5 from '../asset/images/Slide5.jpg';

class BusinessIncorporation extends Component {
    render() {
        var settings = {
            arrows: false,
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 3000,
            fade: true,
            fadeSpeed: 1000,

            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        initialSlide: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        return (
            <Fragment>
                <Container fluid={true} className="accContainer">

                    <Container className='accSection'>
                        <div>
                            <h2 className='accTitle'>
                                Business Incorporation</h2>
                        </div>
                        <div>
                            <p className='accSubtitle'>We do both Federal and Provincial Incorporation. We provide official searches for full Ontario incorporation, Canada trademark registration and other related corporate services with minimum cost. We respond to your need quicker and faster so you can save more.</p>
                            <h2 className='accTitle2'>Our services include:</h2>
                        </div>
                        {/* <div className='accHr'></div> */}
                        <Row>
                            <Col lg={6} md={6} sm={12} >
                                <ul className='accUl'>
                                    <li><div><FontAwesomeIcon className="taxClinicIcon" icon={faCheckCircle} /></div><div className='taxClinicDiv'>CORPORATE NAME AND TRADEMARK SEARCHES</div></li>
                                    <li><div><FontAwesomeIcon className="taxClinicIcon" icon={faCheckCircle} /></div><div className='taxClinicDiv'>PROFIT AND NON-PROFIT INCORPORATION</div></li>
                                    <li><div><FontAwesomeIcon className="taxClinicIcon" icon={faCheckCircle} /></div><div className='taxClinicDiv'>CANADA TRADEMARK REGISTRATION</div></li>
                                    <li><div><FontAwesomeIcon className="taxClinicIcon" icon={faCheckCircle} /></div><div className='taxClinicDiv'>CORPORATE MINUTE BOOKS AND SEALS</div></li>
                                    <li><div><FontAwesomeIcon className="taxClinicIcon" icon={faCheckCircle} /></div><div className='taxClinicDiv'>ONTARIO CORPORATE PROFILE SEARCHES AND CERTIFICATES</div></li>
                                    <li><div><FontAwesomeIcon className="taxClinicIcon" icon={faCheckCircle} /></div><div className='taxClinicDiv'>ONTARIO CORPORATE AMENDMENT, DISSOLUTION AND REVIVING.</div></li>
                                </ul>
                            </Col>
                            <Col lg={6} md={6} sm={12}>

                                <div className='accSlide'>
                                    <Slider {...settings}>
                                        <div>
                                            <Row>
                                                <Col>
                                                    <a> <img className="clientImage img-fluid" src={Slide1} alt="meeting" /></a>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div>
                                            <Row>
                                                <Col>
                                                    <a> <img className="clientImage img-fluid" src={Slide2} alt="conference" /></a>
                                                </Col>
                                            </Row>
                                        </div>

                                        <div>
                                            <Row>
                                                <Col>
                                                    <a> <img className="clientImage img-fluid" src={Slide4} alt="V-tac-tax" /></a>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div>
                                            <Row>
                                                <Col>
                                                    <a> <img className="clientImage img-fluid" src={Slide5} alt="all employees"/></a>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div>
                                            <Row>
                                                <Col>
                                                    <a> <img className="clientImage img-fluid" src={Slide3} alt="team meeting" /></a>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Slider>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    <Container className='incorporationSec mt-5'>
                        <Row>
                            <Col lg={8} md={6} sm={12} >
                                <div className='downloadText'>
                                    <h2 >Please fill out the form then fax to us for your company incorporation</h2>
                                </div>
                            </Col>
                            <Col lg={4} md={6} sm={12}>
                                <div>
                                    <a href="/file/Vtac-Incorporation.pdf" target="_blank" download><Button className='downloadBtn'><FontAwesomeIcon className="downloadIcon" icon={faDownload} /> Download</Button></a>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Container>
            </Fragment>
        );
    }
}

export default BusinessIncorporation;