import React, { Component, Fragment } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Slide1 from '../asset/images/Slide1.jpg';
import Slide2 from '../asset/images/Slide2.jpg';
import Slide3 from '../asset/images/Slide3.jpg';
import Slide4 from '../asset/images/Slide4.jpg';
import Slide5 from '../asset/images/Slide5.jpg';

class PayrollProcessingSection extends Component {
    render() {
        var settings = {
            arrows: false,
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 3000,
            fade: true,
            fadeSpeed: 1000,



            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        initialSlide: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        return (
            <Fragment>
            <Container fluid={true} className="accContainer">

                <Container className='accSection'>
                    <div>
                        <h2 className='accTitle'>
                        Payroll Processing</h2>
                        <p className='accSubtitle'>We provide all kinds of payroll services as follows:</p>
                        <ul className='accUl'>
                            <li><FontAwesomeIcon className = "payrollicon" icon= {faCheckCircle} /><div className='PayrolProLi'>Preparation of cheques, reconciliation, and clearing.</div></li>
                            <li><FontAwesomeIcon className = "payrollicon" icon= {faCheckCircle} /><div className='PayrolProLi'>Direct bank deposits (to any Canadian financial institution).</div></li>
                            <li><FontAwesomeIcon className = "payrollicon" icon= {faCheckCircle} /><div className='PayrolProLi'>Arrangement of Employee Status and Standard Payroll Reports, Government Remittance of Statutory Deductions, WSIB, T4/T4As, and year-end summaries andadjustments.</div></li> 
                            <li><FontAwesomeIcon className = "payrollicon" icon= {faCheckCircle} /><div className='PayrolProLi'>Return on Equity (ROE) Certification</div></li>
                        </ul>
                    </div>
                    
                    <div className='mt-5'></div>
                    <Row>
                        <Col lg={6} md={6} sm={12}>

                            <div className='accSlide' >
                                <Slider {...settings}>
                                    <div>
                                        <Row>
                                            <Col>
                                                <a> <img className="clientImage img-fluid" src={Slide1} alt="meeting"/></a>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div>
                                        <Row>
                                            <Col>
                                                <a> <img className="clientImage img-fluid" src={Slide2} alt="conference" /></a>
                                            </Col>
                                        </Row>
                                    </div>

                                    <div>
                                        <Row>
                                            <Col>
                                                <a> <img className="clientImage img-fluid" src={Slide4}  alt="V-tac-tax"/></a>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div>
                                        <Row>
                                            <Col>
                                                <a> <img className="clientImage img-fluid" src={Slide5} alt="all employees" /></a>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div>
                                        <Row>
                                            <Col>
                                                <a> <img className="clientImage img-fluid" src={Slide3} alt="team meeting" /></a>
                                            </Col>
                                        </Row>
                                    </div>

                                </Slider>

                            </div>



                        </Col>
                        <Col lg={6} md={6} sm={12} >
                            <h2 className='accTitle2 mt-2'>
                            How Payroll Outsourcing Benefits You</h2>
                            <p className='accSubtitle'>
                                Rather than waste valuable time and resources in organizing your own payroll, why not let us handle it. In doing so, you can devote more time to creative financial pursuits to strengthen productivity and maximize profit.
                                </p>
                                <p className='accSubtitle'>
                                Our payroll department will ensure your priorities are covered. Through our strong knowledge, exceptional customer skills, responsiveness and extensive resources; we give you what you need, when you need it. We aim to satisfy your demands with a dependable payroll service that is accurate and convenient.
                                </p>
                        </Col>

                    </Row>
                    <div className='mt-5'></div>
                    <Container>
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <p className='description'>
                                The <span className='spancolor'>advantage</span>  of our rapid payroll service gives you the ability to review the accuracy of your employees’ pay cheques and make any necessary changes online – all from the convenience of your desk. Simply e-mail a copy of your payroll to your company’s accountant and we’ll send the reports right to you to view on your computer –eliminating the hassle of paper reports. You can also take advantage of our direct banking deposit as another payroll method. This way, your employees will always receive their funds on time.
                                </p>
                                <p className='description'>
                                Our <span className='spancolor'>affordable and reliable</span> payroll services can enhance the productivity, accuracy, and punctuality of your entire payroll process. Regardless of your company’s scale, our up-to-date software packages make it easy for you to collect information and calculate timesheets to produce your payroll without incurring penalties. VPM is a confidential service you can trust and rely upon.
                                </p>
                            </Col>
                        </Row>
                    </Container>

                </Container>
            </Container>
        </Fragment>
        );
    }
}

export default PayrollProcessingSection;