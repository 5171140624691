import React, { Component, Fragment } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import TAXTiis from '../asset/images/TAX Tiis.png';
import TAXRATE from '../asset/images/TAX RATE.png';
import house from '../asset/images/house.jpg';
import TAXDates from '../asset/images/TAX Dates.png';
import taximplication2 from '../asset/images/tax-implication2.webp';
import tipsfsttimefiling2 from '../asset/images/5tips-fst-time-filing2.webp';
import Studenttax from '../asset/images/Student tax.png';
import SelfEmployedTaxpayers from '../asset/images/Self-Employed -Taxpayers.png';
import taxinmultiplestate1 from '../asset/images/tax-in-multiple-state1.png';
import taxdeduction2 from '../asset/images/tax-deduction-2.png';
class BlogPart6 extends Component {
    render() {
        return (
            <Fragment>
                <Container>
                    <Row>
                        <Col lg={8} md={6} sm={12}>
                            <div className='blogDetailDiv'>
                                <h2 className='blogTopTitle fs-1'>Tax implications for having multiple employers</h2>
                                <p className='blogDesc'>In order to make ends meet, some people are finding it necessary to work for multiple employers or change jobs multiple times throughout the year, due to the recent high inflation rate in Canada.</p>
                                <p className='blogDesc'>Employers must have already provided T4 slips to employees, either in person, via mail, or on a secure HR website By the end of February. If you have multiple T4 slips to sort through this tax season, here are a few tips:</p>
                                <h2 className='blogDescTitle'>Multiple Jobs:</h2>
                                <p className='blogDesc'>If you hold multiple jobs, your total income from all sources is your taxable income. Depending on your total salary, you may find yourself in a higher tax bracket than before and therefore owe more in taxes than in previous years.</p>
                                <p className='blogDesc'>Your employers will deduct taxes from your pay using a formula that includes the basic personal amount, which is the minimum amount of money Canadians can earn before paying income taxes (currently set at $15,000). Both employers will assume that the basic personal amount is included in the calculations unless you indicate otherwise on the Form TD1 Personal Tax Credits Return (federal and provincial). If it is not indicated, the $15,000 will not be taxed, which can lead to a large tax bill.</p>
                                <img variant="top" className='blog1PageImg2 mt-4 mb-5' src={taximplication2}></img>
                                <p className='blogDesc'>When you start a new job, make sure to fill out Form TD1 correctly to specify that you are not eligible for the basic personal amount. Additionally, if you work in multiple provinces, ensure that you are familiar with the credits and benefits you are entitled to and that you do not account for these twice. This will help you avoid a large tax bill.</p>
                                <h2 className='blogDescTitle'>Including side hustles in income:</h2>
                                <p className='blogDesc'>If you have a combination of wages from your employers as well as income from your own side hustles, you will need to declare all of that income when filing your taxes. Most side hustles won't issue a T4, since you're essentially self-employed, but if you're working for a company such as Uber or DoorDash, they will provide you with an annual statement that you can use for your tax filing. This statement won't be sent to the government, unlike a T4.</p>
                                <p className='blogDesc'>If you drive for Uber, it's important to understand what you can claim as a business expense. This may include gas used for driving, car washes, installing winter tires, and other car maintenance (usually as a percentage based on the car's use for personal vs. business purposes).</p>

                                <p className='blogDesc'>If you are self-employed, you must fill out the Statement of Business or Professional Activities - forms T2125 and TP-80. Remember, no tax will be withheld from your earnings, so be sure to set aside the necessary funds to cover the taxes you owe. If you have more than $3,000 of tax due at the end of the year, you will need to make instalments towards the following year. Those who are self-employed have until June 15 to file their taxes. However, if you owe money, that payment is due on May 1, 2023.</p>
                                <h2 className='blogDescTitle'>Even if you don't get a T4, You must include income from cash or tips on your taxes:</h2>
                                <p className='blogDesc'>Line 10400 “Employment income not reported on a T4 slip” is the place to report income earned in cash or other unreported payments. In Quebec, workers must provide their employer with form TP-1019.4.V Register and Statement of Tips at the end of each pay period to report tips. For those who do not live in Quebec, it is wise to keep a detailed record of tips earned on a daily or biweekly basis in order to accurately report them to the government.</p>
                                <p className='blogDesc'>The government does not have a record of cash tips, except in cases where an employer adds a mandatory service charge to their clients' bills. This money will appear on the employee's T4 slip. However, the government has an estimate of how much money employees should make based on their industry, location, and hours. Therefore, it is best to be as accurate as possible when reporting income and to save all relevant documents for six years in case you receive a Notice of Assessment.</p>
                                <p className='blogDesc'>If you need assistance with understanding the filing process for taxes, <a href='https://docs.google.com/forms/d/e/1FAIpQLSdyebo_rQ394z07D_-AXSMG0tl0-Q8DZx4vvFdVc7aPfYvhtg/viewform?usp=sf_link' target="_blank"><strong style={{ color: "#005BAA" }}>Vtac Experts</strong></a> are available to provide guidance.</p>

                            </div>
                        </Col>

                        <Col lg={4} md={6} sm={12}>
                            <div className='relatedArticle'>
                                <h2 className='fs-3 '>Related Articles</h2>
                                <hr style={{ width: "100%", float: "left" }}></hr>
                            </div>
                            <Card className='blogCardRight' >
                                <Link to="/ways-to-stay-ahead-of-tax-season" style={{ textDecoration: "none" }}>
                                    <div>
                                        <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                        <Card.Img variant="top" className='industryImg' src={TAXTiis} />
                                    </div>

                                    <Card.Body>
                                        <Card.Title className='blogCardRightTitle'>8 straight ways to stay ahead of tax season 2023</Card.Title>
                                    </Card.Body>
                                </Link>
                            </Card>
                            <Card className='blogCardRight float-right'>
                                <Link to="/federal-tax-brackets-rates-credits" style={{ textDecoration: "none" }}>
                                    <div>
                                        <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                        <Card.Img variant="top" className='industryImg' src={TAXRATE} />
                                    </div>

                                    <Card.Body>
                                        <Card.Title className='blogCardRightTitle'>Federal Tax Brackets, Rates & Credits 2023</Card.Title>
                                    </Card.Body>
                                </Link>
                            </Card>
                            <Card className='blogCardRight float-right'>
                                <Link to="/housing-benefit-and-the-way-to-apply" style={{ textDecoration: "none" }}>
                                    <div>
                                        <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                        <Card.Img variant="top" className='industryImg' src={house} />
                                    </div>

                                    <Card.Body>
                                        <Card.Title className='blogCardRightTitle'>Are you a renter in Canada? Learn about the Housing Benefit and the way to apply for it. </Card.Title>
                                    </Card.Body>
                                </Link>
                            </Card>

                            <Card className='blogCardRight float-right'>
                                <Link to="/important-tax-deadlines" style={{ textDecoration: "none" }}>
                                    <div>
                                        <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                        <Card.Img variant="top" className='industryImg' src={TAXDates} />
                                    </div>

                                    <Card.Body>
                                        <Card.Title className='blogCardRightTitle'>Important Tax Deadlines 2023</Card.Title>
                                    </Card.Body>
                                </Link>
                            </Card>
                            <Card className='blogCardRight'>
                                <Link to="/tips-for-tax-students" style={{ textDecoration: "none" }}>
                                    <div>
                                        <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                        <Card.Img variant="top" className='industryImg' src={Studenttax} />
                                    </div>

                                    <Card.Body>
                                        <Card.Title className='blogCardRightTitle'>Maximizing Your 2022 Return: Tips for Students</Card.Title>
                                    </Card.Body>
                                </Link>
                            </Card>
                            <Card className='blogCardRight float-right'>
                                <Link to="/tips-for-a-great-first-time-filing-experience" style={{ textDecoration: "none" }}>
                                    <div>
                                        <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                        <Card.Img variant="top" className='industryImg' src={tipsfsttimefiling2} />
                                    </div>

                                    <Card.Body>
                                        <Card.Title className='blogCardRightTitle'>5 tips for a great first-time filing experience</Card.Title>
                                    </Card.Body>
                                </Link>
                            </Card>
                            <Card className='blogCardRight float-right'>
                                <Link to="/tips-for-self-employed-taxpayers" style={{ textDecoration: "none" }}>
                                    <div>
                                        <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                        <Card.Img variant="top" className='industryImg' src={SelfEmployedTaxpayers} />
                                    </div>

                                    <Card.Body>
                                        <Card.Title className='blogCardRightTitle'>Tips for Self-Employed Taxpayers</Card.Title>
                                    </Card.Body>
                                </Link>
                            </Card>
                            <Card className='blogCardRight float-right'>
                                <Link to="/how-to-filing-taxes-in-multiple-states-when-moved" style={{ textDecoration: "none" }}>
                                    <div>
                                        <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                        <Card.Img variant="top" className='industryImg' src={taxinmultiplestate1} />
                                    </div>

                                    <Card.Body>
                                        <Card.Title className='blogCardRightTitle'>How to Filing Taxes in Multiple States When Moved</Card.Title>
                                    </Card.Body>
                                </Link>
                            </Card>
                            <Card className='blogCardRight float-right'>
                                <Link to="/tax-deductions-for-medical-expenses-under-the-CRA" style={{ textDecoration: "none" }}>
                                    <div>
                                        <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                        <Card.Img variant="top" className='industryImg' src={taxdeduction2} alt="tax-deduction-for medical- expenses" />
                                    </div>

                                    <Card.Body>
                                        <Card.Title className='blogCardRightTitle'>Tax deductions for medical expenses under the CRA</Card.Title>
                                    </Card.Body>
                                </Link>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        );
    }
}

export default BlogPart6;