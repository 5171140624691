import React, { Component, Fragment } from 'react';
import CaseStudy from '../components/CaseStudy';
import PageTop from '../components/PageTop';
import VtacQuote from '../components/VtacQuote';
import Footer from '../components/Footer';
import NewTopNavigation from '../components/NewTopNavigation';
class CaseStudyPage extends Component {
    render() {
        return (
            <Fragment>
                <NewTopNavigation title="Case Study-V-Tac Accounting & Tax | The Professionals' Professional" />
                <PageTop topPageTitle="Case Study" topPagesubTitle="Finding trusted and experienced tax professionals can be a struggle, but that’s where V-Tac can help. Whatever your financial situation may be, we take your interests to heart and ensure that our tax services meet all your needs.Our tax professionals are here to protect your wealth, your interests, and your future." />
                <CaseStudy />
                <VtacQuote />
                <Footer />
            </Fragment>
        );
    }
}

export default CaseStudyPage;