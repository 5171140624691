import React, { Component, Fragment } from 'react';
import OurClients from '../components/OurClients';
import PageTop from '../components/PageTop';
import VtacQuote from '../components/VtacQuote';
import Footer from '../components/Footer';
import NewTopNavigation from '../components/NewTopNavigation';
class OurClientsPage extends Component {
    render() {
        return (
            <Fragment>
                <NewTopNavigation title="Our Clients-V-Tac Accounting & Tax | The Professionals' Professional" />
                <PageTop topPageTitle="Our Clients" topPagesubTitle="Our clients list ranges from: Doctors, Lawyers, Dentists, IT Consultants, Real Estate Agents, Home based business, Courier Services, Marketing, Advertising, Printing, Telemarketing Call Centers, Not-for-Profit and Charitable Organizations, Restaurants, Rental Property, Industry Cleaning, Capital Gain and Loss Investments and more." />
                <OurClients />
                <VtacQuote />
                <Footer />
            </Fragment>
        );
    }
}

export default OurClientsPage;