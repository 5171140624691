import React, { Fragment } from 'react';
import BlogTop from '../components/BlogTop';
import VtacQuote from '../components/VtacQuote';
import Footer from '../components/Footer';
import NewTopNavigation from '../components/NewTopNavigation';
import SearchByWord from '../components/SearchByWord';
import BlogPart13 from '../components/BlogPart13';

const BlogPart13Page = () => {
    return (
        <Fragment>
            <NewTopNavigation title="Essential Accounting Tips for Small Business Owners: A Guide to Financial Success
                -V-Tac Accounting & Tax | The Professionals' Professional" />
            <BlogTop />
            <SearchByWord />
            <BlogPart13 />
            <VtacQuote />
            <Footer />
        </Fragment>
    );
};

export default BlogPart13Page;