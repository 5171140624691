import React, { Component, Fragment } from 'react';
import Careers from '../components/Careers';
import PageTop from '../components/PageTop';
import VtacQuote from '../components/VtacQuote';
import Footer from '../components/Footer';
import NewTopNavigation from '../components/NewTopNavigation';
class CareersPage extends Component {
    render() {
        return (
            <Fragment>
                <NewTopNavigation title="Careers-V-Tac Accounting & Tax | The Professionals' Professional" />
                <PageTop topPageTitle="Careers" topPagesubTitle="V-Tac is a growing firm that provides opportunities for professional development and valuable experiences. At V-Tac, we are committed to seeking and cultivating outstanding professionals to add to the quality of our business services. By becoming a part of V-Tac, we are confident that your experience with us will help you gain useful knowledge and skills that will positively impact your career." />
                <Careers />
                <VtacQuote />
                <Footer />
            </Fragment>
        );
    }
}

export default CareersPage;