import React, { Component, Fragment } from 'react';
import UsefulLinks from '../components/UsefulLinks';
import PageTop2 from '../components/PageTop2';
import VtacQuote from '../components/VtacQuote';
import Footer from '../components/Footer';
import NewTopNavigation from '../components/NewTopNavigation';

class UsefulLinkPage extends Component {
    render() {
        return (
            <Fragment>
            <NewTopNavigation title="Useful Links-V-Tac Accounting & Tax | The Professionals' Professional" />
            <PageTop2 topPageTitle="Useful Links" topPagesubTitle="Some of the useful links related to Accounting and Tax are listed below"/>
            <UsefulLinks />
            <VtacQuote />
             <Footer />
           </Fragment>
        );
    }
}

export default UsefulLinkPage;