import React, { Component, Fragment } from 'react';
import PageTop2 from '../components/PageTop2';
import VtacQuote from '../components/VtacQuote';
import Footer from '../components/Footer';
import NewTopNavigation from '../components/NewTopNavigation';
import GoogleFrm from '../components/GoogleFrm';

class GoogleFrmPage extends Component {
    render() {
        return (
            <Fragment>
            <NewTopNavigation title="Google-Form V-Tac Accounting & Tax | The Professionals' Professional" />
            <PageTop2 topPageTitle="ENTER TO WIN 2023" topPagesubTitle="Enter to win 1 of 10 Tablets!" />
            <GoogleFrm/>
            <VtacQuote />
            <Footer />
        </Fragment>
         
        );
    }
}

export default GoogleFrmPage;