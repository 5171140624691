import React, { Fragment } from 'react';
import BlogTop from '../components/BlogTop';
import VtacQuote from '../components/VtacQuote';
import Footer from '../components/Footer';
import NewTopNavigation from '../components/NewTopNavigation';
import SearchByWord from '../components/SearchByWord';
import BlogPart12 from '../components/BlogPart12';

const BlogPart12Page = () => {
    return (
        <Fragment>
            <NewTopNavigation title="Fix Your Income Tax Return in Canada-V-Tac Accounting & Tax | The Professionals' Professional" />
            <BlogTop />
            <SearchByWord />
            <BlogPart12 />
            <VtacQuote />
            <Footer />
        </Fragment>
    );
};

export default BlogPart12Page;