import React, { Component, Fragment } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Slide1 from '../asset/images/Slide1.jpg';
import Slide2 from '../asset/images/Slide2.jpg';
import Slide3 from '../asset/images/Slide3.jpg';
import Slide4 from '../asset/images/Slide4.jpg';
import Slide5 from '../asset/images/Slide5.jpg';

class OtherServices extends Component {
    render() {
        var settings = {
            arrows: false,
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 3000,
            fade: true,
            fadeSpeed: 1000,



            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        initialSlide: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        return (
            <Fragment>
                <Container fluid={true} className="accContainer">

                    <Container className='accSection'>
                        <div>
                            <h2 className='accTitle'>
                                Other Services</h2>
                        </div>

                        {/* <div className='accHr'></div> */}
                        <Row>
                            <Col lg={6} md={6} sm={12}>

                                <div className='accSlide mt-3'>
                                    <Slider {...settings}>
                                        <div>
                                            <Row>
                                                <Col>
                                                    <a> <img className="clientImage img-fluid" src={Slide1} alt="meeting"/></a>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div>
                                            <Row>
                                                <Col>
                                                    <a> <img className="clientImage img-fluid" src={Slide2} alt="conference"/></a>
                                                </Col>
                                            </Row>
                                        </div>

                                        <div>
                                            <Row>
                                                <Col>
                                                    <a> <img className="clientImage img-fluid" src={Slide4} alt="V-tac-tax"/></a>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div>
                                            <Row>
                                                <Col>
                                                    <a> <img className="clientImage img-fluid" src={Slide5} alt="all employees" /></a>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div>
                                            <Row>
                                                <Col>
                                                    <a> <img className="clientImage img-fluid" src={Slide3} alt="team meeting"/></a>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Slider>
                                </div>
                            </Col>
                            <Col lg={6} md={6} sm={12} >
                                <div>
                                    <p className='accSubtitle'>We provide technical support in preparing Cash Flow Statements, Financial Statements, Tax Planning, Bank Reconciliation, HST and maintain an assortment of accounting data for clients in our digital system.</p>
                                </div>
                                <ul className='accUl'>
                                    <li><FontAwesomeIcon className="otherServiceBullet" icon={faCheckCircle} /><div className='otherServiceLi'>Complete and reconcile cash books and/or general ledger accounts.</div></li>
                                    <li><FontAwesomeIcon className="otherServiceBullet" icon={faCheckCircle} /><div className='otherServiceLi'>Prepare bank reconciliation statements (monthly, quarterly or annually, as required).</div></li>
                                    <li><FontAwesomeIcon className="otherServiceBullet" icon={faCheckCircle} /><div className='otherServiceLi'>Reconcile all balance sheet items for the fiscal year.</div></li>
                                    <li><FontAwesomeIcon className="otherServiceBullet" icon={faCheckCircle} /><div className='otherServiceLi'>Process year-end adjusting entries.</div></li>
                                    <li><FontAwesomeIcon className="otherServiceBullet" icon={faCheckCircle} /><div className='otherServiceLi'>Extract trial balance.</div></li>
                                    <li><FontAwesomeIcon className="otherServiceBullet" icon={faCheckCircle} /><div className='otherServiceLi'>Prepare and review complete accounting files.</div></li>
                                    <li><FontAwesomeIcon className="otherServiceBullet" icon={faCheckCircle} /><div className='otherServiceLi'>Carry out internal audits as required from time to time.</div></li>
                                    <li><FontAwesomeIcon className="otherServiceBullet" icon={faCheckCircle} /><div className='otherServiceLi'>Perform analytical review of financial statements.</div></li>
                                    <li><FontAwesomeIcon className="otherServiceBullet" icon={faCheckCircle} /><div className='otherServiceLi'>Prepare effective business plan and proposal.</div></li>
                                    <li><FontAwesomeIcon className="otherServiceBullet" icon={faCheckCircle} /><div className='otherServiceLi'>Prepare HST reports monthly, quarterly, semi-annually or annually, as required.</div></li>
                                    <li><FontAwesomeIcon className="otherServiceBullet" icon={faCheckCircle} /><div className='otherServiceLi'>Liaise with respective tax authorities on behalf of clients, as required.</div></li>
                                    <li><FontAwesomeIcon className="otherServiceBullet" icon={faCheckCircle} /><div className='otherServiceLi'>Provide training and assistance on bookkeeping to company personnel.</div></li>
                                    <li><FontAwesomeIcon className="otherServiceBullet" icon={faCheckCircle} /><div className='otherServiceLi'>Prepare management reports for company executives.</div></li>
                                    <li><FontAwesomeIcon className="otherServiceBullet" icon={faCheckCircle} /><div className='otherServiceLi'>Compile financial statements.</div></li>

                                </ul>
                            </Col>
                        </Row>
                    </Container>
                </Container>
            </Fragment>
        );
    }
}

export default OtherServices;