import React, { Component, Fragment } from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Container, Row, Col } from 'react-bootstrap';
import demoImg from '../asset/images/username-logo-png-180x180.png';

export default class Summary extends Component {

    render() {
        var settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
            autoplay: true,
            autoplaySpeed: 5000,

            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,

                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        return (

            <Fragment>
                <Container fluid={true} className="clientFeedBackBnr  p-0">
                    <div className="clientFeedBackBnrOverlay">
                        <Container >
                            <Row>
                                <Col lg={12} md={12} sm={12}>
                                    <div data-aos="fade-down"
                                        data-aos-easing="linear"
                                        data-aos-duration="1000"
                                        data-aos-once="true"><h2 className='serviceMainTitle text-center mt-5'>Client Success Stories</h2></div>

                                </Col>
                            </Row>
                            <Slider {...settings}>
                                <div>
                                    <Row>
                                        <Col>
                                            <div>
                                                <h2 className='clientDescription'>
                                                    We have used V-Tac Accounting Services for the past couple of years and have found their staff to be patient and trustworthy. They always answer all of my questions and keep me organized.
                                                </h2>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div>
                                                <img className="FeedBackImg" src={demoImg} />
                                            </div>

                                            <div className='FeedBackDiv'>
                                                <p className='FeedBacktClientName'><strong>Josef Karol</strong></p>
                                                <p className='FeedBackLtd'>Dairy Dell Bar & Grill Inc</p>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <div>
                                    <Row>
                                        <Col>
                                            <div>
                                                <h2 className='clientDescription'>
                                                    I have many friends always complaining about how dissatisfied they are with their accounting services. Luckily, this has never been the case for me. I am always happy with V-Tac’s services because they are honest and take proper care of my taxes.
                                                </h2>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div>
                                                <img className="FeedBackImg" src={demoImg} />
                                            </div>

                                            <div className='FeedBackDiv'>
                                                <p className='FeedBacktClientName'><strong>Greg Dengis</strong></p>
                                                <p className='FeedBackLtd'>Dengis Construction Ltd</p>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <div>
                                    <Row>
                                        <Col>
                                            <div>
                                                <h2 className='clientDescription'>
                                                    One of the main issues I had with previous firms was time constraint. With V-Tac, however, I developed an immense amount of trust in their services because they’re always dependable. I would definitely recommend their services to anyone.
                                                </h2>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div>
                                                <img className="FeedBackImg" src={demoImg} />
                                            </div>

                                            <div className='FeedBackDiv'>
                                                <p className='FeedBacktClientName'><strong>Israel Weinstock</strong></p>
                                                <p className='FeedBackLtd'>Doar Mailing Services Inc</p>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Slider>
                        </Container>
                    </div>
                </Container>
            </Fragment>
        )
    }
}
