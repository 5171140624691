import React from 'react';
import { Link } from 'react-router-dom';
import img404 from '../asset/images/404-img-edited.png';
const NotFound = () => {
    return (
        <div style={{textAlign:"center", marginTop:"13rem"}}>
             <h2 style={{color:"#005baa"}}>Sorry!</h2>
             <h1>We can't seem to find the resource you're looking for.</h1>
             <p>Please make sure your internet connectivity is okay and the URL is correct</p>
             <img  className='mb-5' src={img404}></img>
             <div>
            {/* <Link to="/"><button className='btn btn-danger mt-5 mb-5'> Go Back To Homepage</button></Link>  */}
             </div>
             
        </div>
    );
};

export default NotFound;