import React, { Component, Fragment } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import Accounting from '../asset/images/Accounting.jpg';
import Bookkepping from '../asset/images/Book.jpg';
import Taxation from '../asset/images/Taxation.jpg';
import Payroll from '../asset/images/PayrollProcess.jpg';
import Taxclinic from '../asset/images/TaxClinic.jpg';
import Business from '../asset/images/Incorporation.jpg';
import {Link } from "react-router-dom";


class AllService extends Component {
    render() {
        return (
            <Fragment>
                <Container fluid={true} className="accContainer">

                    <Container className='accSection'>
                        <div>
                            <h2 className='accTitle'>
                                Our Services</h2>
                        </div>
                        <div>
                            <p className='accSubtitle'>We recognize that our <span className='spancolor'>quality services</span> are a result of our exceptionally trained staff. Our team works hard to contribute their knowledge in helping businesses function effectively while forming strong relationships by building rapport and increasing economic growth. V-Tac employees take pride in the way they deliver personal attention and accommodate customers’ needs through the best practical solutions our company has to offer. Our expertise, flexibility, and creativity are incomparable and set us apart from mainstream competitors, thereby making us the 0better and more productive alternative.</p>
                        </div>
                        <div className='accHr mt-5'></div>
                        <Container>
                            <Row>
                                <Col lg={6} md={6} sm={12}>
                                    <div className="serviceCard text-center" >

                                        <img className="logoImage" src={Accounting} />
                                        <h2 className="serviceName" >ACCOUNTING</h2>
                                        <p className="serviceDescription">V-Tac is built upon helping businesses grasp opportunities and solve problems through an array of financial services. Whatever a clients’ financial obligations may be, V-Tac focuses on their specific needs so they can play a better role in the economy. Our professionals provide detailed attention, with proven results.</p>
                                        <Link to="/accounting"><Button className="Storybutton text-center mt-4"><span>Read More</span></Button></Link>
                                    </div>
                                </Col>
                                <Col lg={6} md={6} sm={12}>
                                    <div className="serviceCard text-center">
                                        <img className="logoImage" src={Bookkepping} />
                                        <h2 className="serviceName">BOOKKEEPING</h2>
                                        <p className="serviceDescription">At V-Tac Accounting, we provide solutions to these bookkeeping dilemmas. Our dependable services eliminate the problems of employee retention. You have the ability to control your costs to stay within your budget. Our skilled bookkeepers will personally assist at whatever time is most convenient for you.</p>
                                        <Link to="/bookkeeping"><Button className="Storybutton text-center mt-4"><span>Read More</span></Button></Link>
                                    </div>
                                </Col>
                                </Row>
                                <Row className='mt-5'>
                                <Col lg={6} md={6} sm={12}>
                                    <div className="serviceCard text-center">
                                        <img className="logoImage" src={Taxation} />
                                        <h2 className="serviceName">TAXATION</h2>
                                        <p className="serviceDescription">To excel in today’s murky economy, V-Tac maintains a competitive advantage by offering reputable knowledge and staying up to date with new tax laws. V-Tac provides comprehensible and accurate tax services to individuals, self-employed persons, growing businesses, and corporations.</p>
                                        <Link to="/taxation"><Button className="Storybutton text-center mt-4"><span>Read More</span></Button></Link>
                                    </div>
                                </Col>
                           
                                <Col lg={6} md={6} sm={12}>
                                    <div className="serviceCard text-center" >

                                        <img className="logoImage" src={Payroll} />
                                        <h2 className="serviceName" >PAYROLL PROCESSING</h2>
                                        <p className="serviceDescription">Rather than waste valuable time and resources in organizing your own payroll, why not let us handle it. In doing so, you can devote more time to creative financial pursuits to strengthen productivity and maximize profit.</p>
                                        <Link to="/payroll-processing" ><Button className="Storybutton text-center mt-4"><span>Read More</span></Button></Link>
                                    </div>
                                </Col>
                                </Row>
                            <Row className='mt-5'>
                                <Col lg={6} md={6} sm={12}>
                                    <div className="serviceCard text-center">
                                        <img className="logoImage" src={Taxclinic} />
                                        <h2 className="serviceName">TAX-CLINIC</h2>
                                        <p className="serviceDescription">At V-Tac we value our customers and because we do, we take their needs into consideration. Our firm is cognizant of some of the health risks and medical implications that may prevent a customer from dropping by to use our services. We are here to make your taxes.</p>
                                        <Link to="/tax-clinic"><Button className="Storybutton text-center mt-4"><span>Read More</span></Button></Link>
                                    </div>
                                </Col>
                                <Col lg={6} md={6} sm={12}>
                                    <div className="serviceCard text-center">
                                        <img className="logoImage" src={Business} />
                                        <h2 className="serviceName">BUSINESS INCORPORATION</h2>
                                        <p className="serviceDescription">We do both Federal and Provincial Incorporation. We provide official searches for full Ontario incorporation, Canada trademark registration and other related corporate services with minimum cost. We respond to your need quicker and faster so you can save more.</p>
                                        <Link to="/business-incorporation" ><Button className="Storybutton text-center mt-4"><span>Read More</span></Button></Link>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </Container>
                </Container>
            </Fragment>
        );
    }
}

export default AllService;