import React, { Fragment } from 'react';
import BlogTop from '../../components/BlogTop';
import VtacQuote from '../../components/VtacQuote';
import Footer from '../../components/Footer';
import NewTopNavigation from '../../components/NewTopNavigation';
import SearchByWord from '../../components/SearchByWord';
import OutsourcingAccounting from '../../components/Blogs/OutsourcingAccounting';

const OutsourcingAccountingPage = () => {
    return (
        <Fragment>
            <NewTopNavigation title="Outsourcing Accounting Services-Pros and Cons for Businesses | A Guide to Financial Success
                -V-Tac Accounting & Tax | The Professionals' Professional" />
            <BlogTop />
            <SearchByWord />
            <OutsourcingAccounting />
            <VtacQuote />
            <Footer />
        </Fragment>
    );
};

export default OutsourcingAccountingPage;