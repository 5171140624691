import React, { Component, Fragment } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import TAXTiis from '../asset/images/TAX Tiis.png';
import TAXRATE from '../asset/images/TAX RATE.png';
import house from '../asset/images/house.jpg';
import TAXDates from '../asset/images/TAX Dates.png';
import tipsfsttimefiling2 from '../asset/images/5tips-fst-time-filing2.webp';
import taximplication1 from '../asset/images/tax-implication1.webp';
import Studenttax from '../asset/images/Student tax.png';
import SelfEmployedTaxpayers from '../asset/images/Self-Employed -Taxpayers.png';
import SelfEmployedTaxpayers1 from '../asset/images/Self-Employed -Taxpayers1.png';
import taxinmultiplestate1 from '../asset/images/tax-in-multiple-state1.png';
import taxdeduction2 from '../asset/images/tax-deduction-2.png';



class BlogPart8 extends Component {
    render() {
        return (
            <Fragment>
                <Container>
                    <Row>
                        <Col lg={8} md={6} sm={12}>
                            <div className='blogDetailDiv'>
                                <h2 className='blogTopTitle fs-1'>Tips for Self-Employed Taxpayers</h2>
                                <p className='blogDesc'>As a freelancer, independent contractor, or gig worker, you are basically running your own business. That means you don't get a T4 slip that reports your income for the year, so it's up to you to report and pay taxes on what you make. V-tac is here to help you understand and file your taxes, whether you're making extra cash delivering groceries through Instacart, or if you're an Uber driver.</p>
                                <h2 className='blogDescTitle'>Do these tips apply to you?</h2>
                                <p className='blogDesc'>If you do something with the intention of making money, this is considered a business according to the Canada Revenue Agency (CRA) and Revenu Québec. Examples of business income include:</p>
                                <ul className='blogDesc'>
                                    <li>Making extra money can come from side hustles such as selling handmade candles on Etsy.</li>
                                    <li>Running a local dog-walking service, taking on errand jobs through TaskRabbit.</li>
                                    <li>Delivering food with SkipTheDishes or Uber Eats.</li>
                                    <li>Renting out your home or cottage through Airbnb.</li>
                                    <li>Working as a freelance artist or programmer.</li>
                                </ul>
                                <p className='blogDesc'>If your business name is followed by lnc., Ltd., or Co., then it is incorporated. Visit us for help filing a T2 Corporation Income Tax Return.</p>
                                <h2 className='blogDescTitle'>Save your records:</h2>
                                <img variant="top" className='blog1PageImg2 mt-4 mb-5' src={SelfEmployedTaxpayers1} alt="tips for self-employed-taxpayers"></img>
                                <p className='blogDesc'>When running your own business, it is vital to maintain an organized structure and keep track of the money you are making (and spending).</p>
                                <p className='blogDesc'>It is best practice to save all documents related to your business in a folder, sorted by date. This includes invoices sent to clients, as well as receipts for any work-related expenses (e.g. gas or supplies).</p>
                                <p className='blogDesc'>It is important to retain all documents for at least 6 years as the Canada Revenue Agency and Revenu Québec can request to view them at any time if your return is chosen for a detailed review.</p>
                                <h2 className='blogDescTitle'>Report all income, including cash:</h2>
                                <p className='blogDesc'>No matter how you earn money through freelance work, it must be reported on your tax return using the Statement of Business or Professional Activities (T2125 and TP-80). Failure to report this income can lead to hefty penalties.</p>
                                <p className='blogDesc'>If you don't include all your income on your return, you might miss out on federal and provincial/territorial tax credits and benefit programs, such as the GST/HST Credit and the Canada Child Benefit. Even if your total income isn't higher than the basic personal amount (meaning you won't owe federal taxes on it), reporting what you've earned on your return can still be beneficial. Reporting your total income can help you determine your eligibility for these credits and programs, as well as give you more room to contribute to investments like your RRSP or TFSA.</p>
                                <p className='blogDesc'>If you make money from your hobby on a regular basis, like taking headshots of your friends for a fee, that's a sign that your hobby has become a business. In this case, your income must be reported on your tax return. However, if you make occasional earnings from your hobby, such as taking photos at a neighbour's family reunion or taking headshots of your niece for an acting school application, this income does not need to be reported.</p>
                                <h2 className='blogDescTitle'>Claim work-related expenses to reduce your taxes:</h2>
                                <p className='blogDesc'>Did you know that you could potentially lower the amount of taxes you owe by claiming your work-related expenses? Depending on your line of work, there may be certain expenses that you can include on your tax return. </p>
                                <p className='blogDesc'>Ride-sharing drivers may need to incur expenses for vehicle maintenance, such as replacing brake fluid or installing winter tires. They could also be required to provide supplies for passengers, such as water or snacks. Additionally, if you are renting your home, costs for stocking toilet paper for guests or paying for a cleaning service after their stay may be able to be claimed.</p>
                                <p className='blogDesc'>Come to our office and get assistance from one of our Tax Experts to learn what deductions you can claim on your taxes.</p>
                                <h2 className='blogDescTitle'>Determine when to charge GST/HST.</h2>
                                <img variant="top" className='blog1PageImg2 mt-4 mb-5' src={tipsfsttimefiling2} alt="tips for self-employed-taxpayers"></img>
                                <p className='blogDesc'>If your business is earning over $30,000, you may need to register for a GST/HST number and start charging your customers GST/HST.</p>
                                <p className='blogDesc'>Here are four scenarios for when it is time to charge GST/HST:</p>
                                <h2 className='blogDescTitle'>Scenario 1:</h2>
                                <p className='blogDesc'>If you anticipate that your business's revenue will exceed $30,000 in the near future, you may want to register for a GST/HST number right away. This way, you can receive any GST/HST paid back from the government on your business expenses, including start-up costs.</p>
                                <h2 className='blogDescTitle'>Scenario 2:</h2>
                                <p className='blogDesc'>Once you make more than $30,000 in sales in a three-month period (also known as a quarter), you are no longer considered a small supplier. On the day the sale goes through that took you over the $30,000 threshold, you must begin charging GST/HST on that sale and any sales after that, even if you have not registered yet. You will then have 29 days to register for a GST/HST number with the government.</p>
                                <h2 className='blogDescTitle'>Scenario 3:</h2>
                                <p className='blogDesc'>If your business has made more than $30,000 in revenue over the past four calendar quarters, you will be considered a small supplier for those four quarters plus the next month. After the additional month, you will need to register for GST/HST and add it to all of your sales. You will have 29 days from the first day of the second month to register.</p>
                                <h2 className='blogDescTitle'>Scenario 4: </h2>
                                <p className='blogDesc'>If your business brings in revenues over $30,000 in two consecutive calendar quarters, you'll be considered a small supplier for one month after the limit is exceeded. After this additional month, you must start charging GST/HST on all sales. You have 29 days from the first day of the month following the two quarters to register.</p>
                                <h2 className='blogDescTitle'>About CPP/QPP and EI?</h2>
                                <p className='blogDesc'>If your total self-employment income (plus pensionable employment income) is more than $3,500, you must make both employer and employee contributions to the Canada Pension Plan (CPP) or Québec Pension Plan (QPP). The amount of CPP/QPP contributions to be made on self-employment income is calculated on the Schedule 8 form.</p>
                                <h2 className='blogDescTitle'>How does your full-time employment impact your involvement in this project?</h2>
                                <p className='blogDesc'>The income you earn from side gigs does not affect the way you report the income from your full-time job. Additionally, the earnings from your full-time job will not count towards the $30,000 threshold for registering for a GST/HST number. However, having multiple sources of income could change your tax bracket, which could lead to you paying more tax this year than in previous years.</p>
                                <h2 className='blogDescTitle'>Take the opportunity to use extra time to get your return ready:</h2>
                                <p className='blogDesc'>If you are self-employed, filing your taxes can be a bit more complicated. However, the CRA and Revenu Québec provide you with an extra few weeks to file your return, with the due date of June 15, 2023.</p>
                                <p className='blogDesc'>Although the filing deadline is extended, if you owe federal or Québec taxes for the year, payments must still be made by May 1, 2023.</p>
                                <p className='blogDesc'>If you and your spouse are filing your returns together and only one of you is self-employed, you can both still file by June 15, provided that any balance owing is paid by May 1.</p>
                                <h2 className='blogDescTitle' style={{ color: "#F89A1B" }}>If an audit is triggered, there are certain steps that self-employed individuals can take:</h2>
                                <img variant="top" className='blog1PageImg2 mt-4 mb-5' src={SelfEmployedTaxpayers} alt="tips for self-employed-taxpayers"></img>
                                <h2 className='blogDescTitle'>You're your own boss:</h2>
                                <p className='blogDesc'>People who are self-employed are more likely to be audited by the CRA because they don't have a T4 slip from an employer to verify their income. This makes it difficult to hide other income or make mistakes in calculations since there is no employer to provide the CRA with wage information. Additionally, self-employed income isn't taxed at the source so taxpayers are responsible for their own calculations and payment of taxes, which can lead to more room for error.</p>
                                <h2 className='blogDescTitle'>Your tax return is significantly different from what it was in previous years:</h2>
                                <p className='blogDesc'>If you are filing for more or less income than you have in years past, you may be at risk of an audit. It is important to be prepared and keep excellent records of your business activities, as this may be a determining factor in the audit. No matter the reason for the discrepancy, such as the pandemic or supply chain issues, having a well documented financial history can save you time and money in the long run. detailed records of your income and expenses to support your tax filings.</p>
                                <h2 className='blogDescTitle'>In years past, there have been tax returns with mistakes:</h2>
                                <p className='blogDesc'>If you have made mistakes on your taxes in the past, the CRA may assume you will make mistakes again. It is likely that you will be on the list for regular audits for a long period of time, so it is important to be prepared for the possibility of an audit notification.</p>
                                <h2 className='blogDescTitle'>Your claims seem excessive:</h2>
                                <p className='blogDesc'>Many people claim their home office or vehicle as a business expense, but if the amount seems too high, the CRA will investigate. For example, if you are claiming a specific portion of your house as a home office, they will likely speak with you. Similarly, if you are an Uber or SkiptheDishes driver and you claim that 100% of your car's use is for business, the CRA will want to talk to you. It's uncommon for a car to only be used for work purposes. Any discrepancy between this filing and your previous ones or those of others in similar circumstances will trigger an investigation from the CRA. </p>
                                <h2 className='blogDescTitle'>You are utilizing losses from your business year after year to reduce income from other sources:</h2>
                                <p className='blogDesc'>The Canada Revenue Agency (CRA) will want to ensure that any business that is claiming losses is in fact a legitimate commercial enterprise.</p>
                                <h2 className='blogDescTitle'>The first sign that the CRA is auditing you:</h2>
                                <p className='blogDesc'>An auditor may contact you by mail, phone, or both. Remember that the CRA does not text, email, or ask for payment with gift cards or cryptocurrency. They will also not use pressure tactics to get funds from you, especially before an audit.</p>
                                <h2 className='blogDescTitle'>What does the auditor require?</h2>
                                <p className='blogDesc'>The auditor may ask to do the audit in person at your office or home office, or your representative's office. If not, they’ll conduct it at a field office. If the office is not in your region, they’ll ask you to send the necessary documents. Be prepared to provide invoices, receipts, past years’ filings, credit history, property ownership information, spouse or common-law partner’s return, or any other information indicated on your return. The auditor may need further information or clarification from you during the process.</p>
                                <h2 className='blogDescTitle'>Agree/Disagree</h2>
                                <p className='blogDesc'>After reviewing your records, the auditor may issue a letter of completion and close your file if your submission was accurate. Otherwise, they may file a notice of reassessment to inform you of the difference in taxes owed or refunded. If you disagree with their findings, you have one year from the filing deadline or 90 days from the Notice of Reassessment mailing date to file an objection. If your objection is denied, you can appeal to the Tax Court of Canada.</p>
                                <h2 className='blogDescTitle' style={{ color: "#F89A1B", fontSize: "1.8rem" }}>Tips to Avoid an Audit</h2>
                                <h2 className='blogDescTitle'>Excellent documentation has been maintained:</h2>
                                <p className='blogDesc'>Business owners must ensure they organize their records, invoices, receipts, and other financial documents in a way that is both clear and accessible. Government regulations require that these documents must be maintained for a minimum of six years. To help manage the record-keeping process, there are a variety of software solutions available. If you are using electronic records and filing, make sure to have a backup hard drive or cloud storage in case of any technical issues. Additionally, if you are using paper copies, we recommend also backing them up electronically for safekeeping.</p>
                                <h2 className='blogDescTitle'>If possible, try to stay away from solely relying on cash transactions:</h2>
                                <p className='blogDesc'>If you're a business that relies heavily on cash transactions, you can expect the CRA to be particularly vigilant when reviewing your return. Even though you might be following the rules to the letter, the CRA is aware that other businesses may have taken advantage of cash payments and not reported their total revenue accurately. Hair salons and restaurants are often cash-based or have a more balanced mix between cash, debit and credit payments, but in this day and age, electronic point-of-sale is much more common.</p>
                                <h2 className='blogDescTitle'>Limit the number of family members on the payroll to a reasonable amount:</h2>
                                <p className='blogDesc'>It is unusual for your daughter Alena to be both an hr and a full-time marketer. If too many family members are claiming to be employees of the business and have other sources of income, the CRA may investigate the situation. It is important to make sure that your parents, spouse and children are not all employed by the law firm, as this could be seen as a red flag.</p>
                                <p className='blogDesc'>Need help in filing tax? Talk to our <Link to="/request-quote" style={{ color: "#005baa" }}><strong>Tax Cosultants</strong></Link></p>




                            </div>
                        </Col>

                        <Col lg={4} md={6} sm={12}>
                            <div className='relatedArticle'>
                                <h2 className='fs-3 '>Related Articles</h2>
                                <hr style={{ width: "100%", float: "left" }}></hr>
                            </div>
                            <Card className='blogCardRight' >
                                <Link to="/ways-to-stay-ahead-of-tax-season" style={{ textDecoration: "none" }}>
                                    <div>
                                        <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                        <Card.Img variant="top" className='industryImg' src={TAXTiis} />
                                    </div>

                                    <Card.Body>
                                        <Card.Title className='blogCardRightTitle'>8 straight ways to stay ahead of tax season 2023</Card.Title>
                                    </Card.Body>
                                </Link>
                            </Card>
                            <Card className='blogCardRight float-right'>
                                <Link to="/federal-tax-brackets-rates-credits" style={{ textDecoration: "none" }}>
                                    <div>
                                        <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                        <Card.Img variant="top" className='industryImg' src={TAXRATE} />
                                    </div>

                                    <Card.Body>
                                        <Card.Title className='blogCardRightTitle'>Federal Tax Brackets, Rates & Credits 2023</Card.Title>
                                    </Card.Body>
                                </Link>
                            </Card>
                            <Card className='blogCardRight float-right'>
                                <Link to="/housing-benefit-and-the-way-to-apply" style={{ textDecoration: "none" }}>
                                    <div>
                                        <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                        <Card.Img variant="top" className='industryImg' src={house} />
                                    </div>

                                    <Card.Body>
                                        <Card.Title className='blogCardRightTitle'>Are you a renter in Canada? Learn about the Housing Benefit and the way to apply for it. </Card.Title>
                                    </Card.Body>
                                </Link>
                            </Card>

                            <Card className='blogCardRight float-right'>
                                <Link to="/important-tax-deadlines" style={{ textDecoration: "none" }}>
                                    <div>
                                        <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                        <Card.Img variant="top" className='industryImg' src={TAXDates} />
                                    </div>

                                    <Card.Body>
                                        <Card.Title className='blogCardRightTitle'>Important Tax Deadlines 2023</Card.Title>
                                    </Card.Body>
                                </Link>
                            </Card>
                            <Card className='blogCardRight'>
                                <Link to="/tips-for-tax-students" style={{ textDecoration: "none" }}>
                                    <div>
                                        <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                        <Card.Img variant="top" className='industryImg' src={Studenttax} />
                                    </div>

                                    <Card.Body>
                                        <Card.Title className='blogCardRightTitle'>Maximizing Your 2022 Return: Tips for Students</Card.Title>
                                    </Card.Body>
                                </Link>
                            </Card>
                            <Card className='blogCardRight float-right'>
                                <Link to="/tax-implications-for-having-multiple-employers" style={{ textDecoration: "none" }}>
                                    <div>
                                        <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                        <Card.Img variant="top" className='industryImg' src={taximplication1} />
                                    </div>

                                    <Card.Body>
                                        <Card.Title className='blogCardRightTitle'>Tax implications for having multiple employers</Card.Title>
                                    </Card.Body>
                                </Link>
                            </Card>

                            <Card className='blogCardRight float-right'>
                                <Link to="/how-to-filing-taxes-in-multiple-states-when-moved" style={{ textDecoration: "none" }}>
                                    <div>
                                        <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                        <Card.Img variant="top" className='industryImg' src={taxinmultiplestate1} />
                                    </div>

                                    <Card.Body>
                                        <Card.Title className='blogCardRightTitle'>How to Filing Taxes in Multiple States When Moved</Card.Title>
                                    </Card.Body>
                                </Link>
                            </Card>
                            <Card className='blogCardRight float-right'>
                                <Link to="/tax-deductions-for-medical-expenses-under-the-CRA" style={{ textDecoration: "none" }}>
                                    <div>
                                        <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                        <Card.Img variant="top" className='industryImg' src={taxdeduction2} alt="tax-deduction-for medical- expenses" />
                                    </div>

                                    <Card.Body>
                                        <Card.Title className='blogCardRightTitle'>Tax deductions for medical expenses under the CRA</Card.Title>
                                    </Card.Body>
                                </Link>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        );
    }
}

export default BlogPart8;