import React, { Component, Fragment } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Slide1 from '../asset/images/Slide1.jpg';
import Slide2 from '../asset/images/Slide2.jpg';
import Slide3 from '../asset/images/Slide3.jpg';
import Slide4 from '../asset/images/Slide4.jpg';
import Slide5 from '../asset/images/Slide5.jpg';


class AccountingSection extends Component {
    render() {
        var settings = {
            arrows: false,
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 3000,
            fade: true,
            fadeSpeed: 1000,



            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        initialSlide: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        return (
            <Fragment>
                <Container fluid={true} className="accContainer">

                    <Container className='accSection'>
                        <div>
                            <h2 className='accTitle'>
                                Accounting</h2>
                        </div>
                        <div>
                            <p className='accSubtitle'><span className='spancolor'>V-Tac</span> is built upon helping businesses grasp opportunities and solve problems through an array of financial services.</p>
                        </div>
                        <Row className='mt-4'>
                            <Col lg={6} md={6} sm={12} >
                                <ul className='accUl'>
                                    <li><span><FontAwesomeIcon className="iconBullet" icon={faCheckCircle} /></span><div className='spanLi'>Bookkeeping</div></li>
                                    <li><FontAwesomeIcon className="iconBullet" icon={faCheckCircle} /><div className='spanLi'>Taxation</div></li>
                                    <li><FontAwesomeIcon className="iconBullet" icon={faCheckCircle} /><div className='spanLi'>Personal Tax</div></li>
                                    <li><FontAwesomeIcon className="iconBullet" icon={faCheckCircle} /><div className='spanLi'>Self-Employed Tax</div></li>
                                    <li><FontAwesomeIcon className="iconBullet" icon={faCheckCircle} /><div className='spanLi'>Corporate Tax</div></li>
                                    <li><FontAwesomeIcon className="iconBullet" icon={faCheckCircle} /><div className='spanLi'>Business Planning</div></li>
                                    <li><FontAwesomeIcon className="iconBullet" icon={faCheckCircle} /><div className='spanLi'>Payroll Processing</div></li>
                                </ul>
                            </Col>
                            <Col lg={6} md={6} sm={12}>

                                <div className='accSlide mt-4'>
                                    <Slider {...settings}>
                                        <div>
                                            <Row>
                                                <Col>
                                                    <a> <img className="clientImage img-fluid" src={Slide1} alt="meeting"/></a>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div>
                                            <Row>
                                                <Col>
                                                    <a> <img className="clientImage img-fluid" src={Slide2} alt="conference" /></a>
                                                </Col>
                                            </Row>
                                        </div>

                                        <div>
                                            <Row>
                                                <Col>
                                                    <a> <img className="clientImage img-fluid" src={Slide4} alt="Vtac-tax" /></a>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div>
                                            <Row>
                                                <Col>
                                                    <a> <img className="clientImage img-fluid " src={Slide5} alt="All employees" /></a>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div>
                                            <Row>
                                                <Col>
                                                    <a> <img className="clientImage img-fluid" src={Slide3} alt="Team meeting" /></a>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Slider>
                                </div>
                            </Col>
                        </Row>
                        <Container className='mt-4'>
                            <Row>
                                <Col lg={12} md={12} sm={12}>
                                    <p className='description'>
                                        <span className='spancolor'></span> Whatever a clients financial obligations may be, V-Tac focuses on their specific needs so they can play a better role in the economy. Our professionals provide attention to detail, with proven results, for:
                                    </p>
                                    <p className='description'>
                                        <span className='spancolor'>File Preparation:</span> Documentation of adequate and suitable procedures of financial operations.
                                    </p>
                                    <p className='description'>
                                        <span className='spancolor'>Financial Statement Preparation:</span> Ample disclosure notes and compliance with meeting legislative acts.
                                    </p>
                                    <p className='description'>
                                        Efficacious auditing and timely preparation of financial files demonstrates our firms knowledge, integrity and sensible practices. We provide informative services to our clients to help them maintain their transparency and accountability within the capital market.
                                    </p>
                                </Col>
                            </Row>
                        </Container>
                    </Container>
                </Container>
            </Fragment>
        );
    }
}

export default AccountingSection;