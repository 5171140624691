import React, { Component, Fragment } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import fedarelTaxRate from '../asset/images/TAX RATE.png';
import TaxTips2 from '../asset/images/house.jpg';
import TAXDates from '../asset/images/TAX Dates.png';
import TaxTips1 from '../asset/images/Tax tips In-2023.jpg';
import StudenttaxV from '../asset/images/TAXXX.png';
import tipsfsttimefiling from '../asset/images/5tips-fst-time-filing.webp';
import taximplication1 from '../asset/images/tax-implication1.webp';
import SelfEmployedTaxpayers from '../asset/images/Self-Employed -Taxpayers.png';
import taxinmultiplestate from '../asset/images/tax-in-multiple-state.png';
import taxdeduction1 from '../asset/images/tax-deduction-1.png';
import essentialpointstounderstand from '../asset/images/essential-points-to understand.jpg';
import fixincome from '../asset/images/fix-income.jpg';
import accountingtips from '../asset/images/accounting-tips.jpg';
import AccountingSerivce from '../asset/images/accounting-service.jpg';
import understdBookkeeping from '../asset/images/understd-bookkeeping.jpg';

class TaxTips extends Component {
    render() {
        return (
            <Fragment>
                <Container className='SectionBetween mt-5'>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='text-center container mb-5' style={{ width: "70%" }}><h2 className="blogTopTitle">Tips For Tax</h2>
                                <p className='blogDesc text-center'>Our team is dedicated to helping you achieve your financial goals by offering free advice, tips, and tools.We hope these tips and ideas will help you get the most out of your tax experience!</p></div>

                            <p className='blogDesc'><strong>Search by keyword</strong></p>
                            <div className='mb-5'>
                                <Link to="/tips-for-a-great-first-time-filing-experience"><Button className='tax-tip-key-btn ' variant="outline-primary">Tax Tips</Button>{' '}</Link>
                                <Link to="/tips-for-self-employed-taxpayers"><Button className='tax-tip-key-btn ' variant="outline-primary">Self-Employed</Button>{' '}</Link>
                                <Link to="/important-tax-deadlines"><Button className='tax-tip-key-btn ' variant="outline-primary">Deadline</Button>{' '}</Link>
                                <Link to="/tips-for-tax-students"> <Button className='tax-tip-key-btn ' variant="outline-primary">Students</Button>{' '}</Link>
                                <Link to="/tax-deductions-for-medical-expenses-under-the-CRA"> <Button className='tax-tip-key-btn ' variant="outline-primary">Medical</Button>{' '}</Link>
                                <Link to="/housing-benefit-and-the-way-to-apply"><Button className='tax-tip-key-btn ' variant="outline-primary">House</Button>{' '}</Link>
                                <Link to="/federal-tax-brackets-rates-credits"><Button className='tax-tip-key-btn ' variant="outline-primary">Federal Tax</Button>{' '}</Link>
                                <Link to="/how-to-filing-taxes-in-multiple-states-when-moved"><Button className='tax-tip-key-btn ' variant="outline-primary">Multiple states</Button>{' '}</Link>
                                <Link to="/fix-your-income-tax-return-in-Canada"><Button className='tax-tip-key-btn ' variant="outline-primary">Tax Return</Button>{' '}</Link>
                                <Link to="/remote-tax-filing">  <Button className='tax-tip-key-btn ' variant="outline-primary">Remote Filing</Button>{' '}</Link>

                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg={6} md={6} sm={12} data-aos="zoom-in" data-aos-duration="1500"
                            data-aos-once="true">
                            <Link to="/ways-to-stay-ahead-of-tax-season" style={{ textDecoration: "none" }}>
                                <Card className='taxTipsCard float-right'>
                                    <div>
                                        <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                        <Card.Img variant="top" className='taxTipsImg' src={TaxTips1} />
                                        <div className='industryImgoverly'>
                                            <Link to="/ways-to-stay-ahead-of-tax-season" class="industryicon">
                                                <FontAwesomeIcon icon={faPlusCircle} />
                                            </Link>
                                        </div>
                                    </div>

                                    <Card.Body>
                                        <Card.Title className='taxTipscardTtle'>8 straight ways to stay ahead of tax season 2023.</Card.Title>
                                        <Card.Text className='commonDesc'>
                                            Are you getting the most out of your taxes? Individuals must take advantage of potential deductions and credits that could lead to a bigger tax refund. Doing your taxes correctly ensures you receive the maximum refund for which you are eligible. Don't let years of receipts pile up in a drawer; take the time to maximize your
                                            &nbsp; <strong style={{ color: "#005BAA" }}><FontAwesomeIcon icon={faArrowRight} /></strong>
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>

                        <Col lg={6} md={6} sm={12} data-aos="zoom-in" data-aos-duration="1500"
                            data-aos-once="true">
                            <Link to="/important-tax-deadlines" style={{ textDecoration: "none" }}>
                                <Card className='taxTipsCard float-right'>
                                    <div>
                                        <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                        <Card.Img variant="top" className='taxTipsImg' src={TAXDates} />
                                        <div className='industryImgoverly'>
                                            <Link to="/important-tax-deadlines" class="industryicon">
                                                <FontAwesomeIcon icon={faPlusCircle} />
                                            </Link>
                                        </div>
                                    </div>

                                    <Card.Body>
                                        <Card.Title className='taxTipscardTtle'>Important Tax Deadlines by Month 2023.</Card.Title>
                                        <Card.Text className='commonDesc'>
                                            Staying on top of tax deadlines can be daunting, but having a calendar and making sure you're aware of the appropriate due dates will help you avoid penalties, interest, and extra fees from your tax lawyer. To ensure a successful filing for your 2022 taxes, start planning early to make sure you have everything you need  &nbsp; <strong style={{ color: "#005BAA" }}> <FontAwesomeIcon icon={faArrowRight} /></strong>

                                        </Card.Text>
                                        {/* <Button variant="primary" className='tax-tip-btn mt-2'><span>READ MORE</span></Button>{' '} */}

                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>

                    </Row>
                    <Row>
                        <Col lg={4} md={6} sm={12} data-aos="zoom-in" data-aos-duration="1500"
                            data-aos-once="true">
                            <Link to="/federal-tax-brackets-rates-credits" style={{ textDecoration: "none" }}>
                                <Card className='taxTipsCard float-right'>
                                    <div>
                                        <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                        <Card.Img variant="top" className='taxTipsImg2' src={fedarelTaxRate} />
                                        <div className='industryImgoverly'>
                                            <Link to="/federal-tax-brackets-Rates-Credits" class="industryicon">
                                                <FontAwesomeIcon icon={faPlusCircle} />
                                            </Link>
                                        </div>
                                    </div>

                                    <Card.Body>
                                        <Card.Title className='taxTipscardTtle'>Federal Tax Brackets, Rates & Credits 2023.</Card.Title>
                                        <Card.Text className='commonDesc'>
                                            Understand how Canadian personal income taxes are calculated fairly with this guide. Learn the 2022 tax brackets and rates, how they work, and how much you may pay depending on your income. Plus, optimize your return with tax credits to be in the lowest possible tax bracket &nbsp;<strong style={{ color: "#005BAA" }}> <FontAwesomeIcon icon={faArrowRight} /></strong>
                                        </Card.Text>
                                        {/* <Button variant="primary" className='tax-tip-btn mt-2'><span>READ MORE</span></Button>{' '} */}

                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>


                        <Col lg={4} md={6} sm={12} data-aos="zoom-in" data-aos-duration="1500"
                            data-aos-once="true">
                            <Link to="/housing-benefit-and-the-way-to-apply" style={{ textDecoration: "none" }}>
                                <Card className='taxTipsCard'>

                                    <div>
                                        <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                        <Card.Img variant="top" className='taxTipsImg2' src={TaxTips2} />
                                        <div className='industryImgoverly'>
                                            <Link to="/housing-benefit-and-the-way-to-apply" class="industryicon">
                                                <FontAwesomeIcon icon={faPlusCircle} />
                                            </Link>
                                        </div>
                                    </div>

                                    <Card.Body>
                                        <Card.Title className='taxTipscardTtle'>Are you a renter in Canada? Learn about the Housing Benefit and the way to apply for it.</Card.Title>
                                        <Card.Text className='commonDesc'>
                                            Are you a Canadian renter with a low income? If so, you can now apply for the Housing Benefit! This one-time tax-free payment is offered to those with an adjusted net income of less than $20,000 for individuals or $35,000 for families &nbsp;<strong style={{ color: "#005BAA" }}> <FontAwesomeIcon icon={faArrowRight} /></strong>
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>
                        <Col lg={4} md={6} sm={12}
                            data-aos="zoom-in"
                            data-aos-duration="1500"
                            data-aos-once="true">

                            <Card className='taxTipsCard'>
                                <Link to="/tips-for-tax-students" style={{ textDecoration: "none" }}>
                                    <div>
                                        <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                        <Card.Img variant="top" className='taxTipsImg2' src={StudenttaxV} />
                                        <div className='industryImgoverly'>
                                            <Link to="/tips-for-tax-students" class="industryicon">
                                                <FontAwesomeIcon icon={faPlusCircle} />
                                            </Link>
                                        </div>
                                    </div>

                                    <Card.Body>
                                        <Card.Title className='taxTipscardTtle'>Maximizing Your 2022 Return: Tips for Students.</Card.Title>
                                        <Card.Text className='commonDesc'>
                                            If you are a full or part-time post-secondary student, you can benefit from various credits that can boost your refund or lower the taxes you owe when filing your return.Claiming tuition payments, student loan interest, and even moving expenses can help you get a better outcome when &nbsp; <strong style={{ color: "#005BAA" }}> <FontAwesomeIcon icon={faArrowRight} /></strong>
                                        </Card.Text>
                                    </Card.Body>
                                </Link>
                            </Card>

                        </Col>


                        <Col lg={4} md={6} sm={12} data-aos="zoom-in" data-aos-duration="1500"
                            data-aos-once="true">
                            <Link to="/tax-implications-for-having-multiple-employers" style={{ textDecoration: "none" }}>
                                <Card className='taxTipsCard'>
                                    <div>
                                        <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                        <Card.Img variant="top" className='taxTipsImg2' src={taximplication1} />
                                        <div className='industryImgoverly'>
                                            <Link to="/tax-implications-for-having-multiple-employers" class="industryicon">
                                                <FontAwesomeIcon icon={faPlusCircle} />
                                            </Link>
                                        </div>
                                    </div>

                                    <Card.Body>
                                        <Card.Title className='taxTipscardTtle'>Tax implications for having multiple employers.</Card.Title>
                                        <Card.Text className='commonDesc'>
                                            In order to make ends meet, some people are finding it necessary to work for multiple employers or change jobs multiple times throughout the year, due to the recent high inflation rate in Canada.Employers must have already provided T4 slips to  &nbsp;                                            <strong style={{ color: "#005BAA" }}> <FontAwesomeIcon icon={faArrowRight} /></strong>
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>


                        <Col lg={4} md={6} sm={12} data-aos="zoom-in" data-aos-duration="1500"
                            data-aos-once="true">
                            <Link to="/tips-for-a-great-first-time-filing-experience" style={{ textDecoration: "none" }}>
                                <Card className='taxTipsCard'>
                                    <div>
                                        <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                        <Card.Img variant="top" className='taxTipsImg2' src={tipsfsttimefiling} />
                                        <div className='industryImgoverly'>
                                            <Link to="/tips-for-a-great-first-time-filing-experience" class="industryicon">
                                                <FontAwesomeIcon icon={faPlusCircle} />
                                            </Link>
                                        </div>
                                    </div>

                                    <Card.Body>
                                        <Card.Title className='taxTipscardTtle'>5 tips for a great first-time filing experience.</Card.Title>
                                        <Card.Text className='commonDesc'>
                                            Filing your first tax return in Canada doesn't have to be a daunting experience. Whether you're a first-time filer or prepping for the upcoming tax season, use these five helpful tips to simplify the process and make sure you get the most out  &nbsp;
                                            <strong style={{ color: "#005BAA" }}> <FontAwesomeIcon icon={faArrowRight} /></strong>

                                        </Card.Text>
                                        {/* <Button variant="primary" className='tax-tip-btn mt-2'><span>READ MORE</span></Button>{' '} */}

                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>
                        <Col lg={4} md={6} sm={12} data-aos="zoom-in" data-aos-duration="1500"
                            data-aos-once="true">
                            <Link to="/tips-for-self-employed-taxpayers" style={{ textDecoration: "none" }}>
                                <Card className='taxTipsCard'>
                                    <div>
                                        <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                        <Card.Img variant="top" className='taxTipsImg2' src={SelfEmployedTaxpayers} />
                                        <div className='industryImgoverly'>
                                            <Link to="/tips-for-self-employed-taxpayers" class="industryicon">
                                                <FontAwesomeIcon icon={faPlusCircle} />
                                            </Link>
                                        </div>
                                    </div>

                                    <Card.Body>
                                        <Card.Title className='taxTipscardTtle'>Tips for Self-Employed Taxpayers.</Card.Title>
                                        <Card.Text className='commonDesc'>
                                            As a freelancer, independent contractor, or gig worker, you are basically running your own business. That means you don't get a T4 slip that reports your income for the year, so it's up to you to report and pay taxes on what you make. V-tac is here to help you understand and file your &nbsp;
                                            <strong style={{ color: "#005BAA" }}><FontAwesomeIcon icon={faArrowRight} /></strong>

                                        </Card.Text>
                                        {/* <Button variant="primary" className='tax-tip-btn mt-2'><span>READ MORE</span></Button>{' '} */}

                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>
                        <Col lg={4} md={6} sm={12} data-aos="zoom-in" data-aos-duration="1500"
                            data-aos-once="true">
                            <Link to="/how-to-filing-taxes-in-multiple-states-when-moved" style={{ textDecoration: "none" }}>
                                <Card className='taxTipsCard'>
                                    <div>
                                        <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                        <Card.Img variant="top" className='taxTipsImg3' src={taxinmultiplestate} />
                                        <div className='industryImgoverly'>
                                            <Link to="how-to-filing-taxes-in-multiple-states-when-moved" className="industryicon">
                                                <FontAwesomeIcon icon={faPlusCircle} />
                                            </Link>
                                        </div>
                                    </div>

                                    <Card.Body>
                                        <Card.Title className='taxTipscardTtle'> How to Filing Taxes in Multiple States When Moved.</Card.Title>
                                        <Card.Text className='commonDesc'>
                                            During  tax time, you need to do more if you moved last year from one state to another. You had to get settled in your new community, make it feel like home for yourself and your family, register to vote in your new state, and get a new  &nbsp;
                                            <strong style={{ color: "#005BAA" }}><FontAwesomeIcon icon={faArrowRight} /></strong>

                                        </Card.Text>
                                        {/* <Button variant="primary" className='tax-tip-btn mt-2'><span>READ MORE</span></Button>{' '} */}

                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>
                        <Col lg={4} md={6} sm={12} data-aos="zoom-in" data-aos-duration="1500"
                            data-aos-once="true">
                            <Link to="/tax-deductions-for-medical-expenses-under-the-CRA" style={{ textDecoration: "none" }}>
                                <Card className='taxTipsCard'>
                                    <div>
                                        <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                        <Card.Img variant="top" className='taxTipsImg3' src={taxdeduction1} alt="Tax deductions for medical expenses" />
                                        <div className='industryImgoverly'>
                                            <Link to="/tax-deductions-for-medical-expenses-under-the-CRA" class="industryicon">
                                                <FontAwesomeIcon icon={faPlusCircle} />
                                            </Link>
                                        </div>
                                    </div>

                                    <Card.Body>
                                        <Card.Title className='taxTipscardTtle'>Tax deductions for medical expenses under the CRA.</Card.Title>
                                        <Card.Text className='commonDesc'>
                                            As you approach retirement, it can be difficult to manage rising healthcare costs. To bridge the gap between your limited budget and increasing medical expenses consider the benefits of a reverse mortgage and filing CRA medical expense claims  &nbsp;
                                            <strong style={{ color: "#005BAA" }}> <FontAwesomeIcon icon={faArrowRight} /></strong>

                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>
                        <Col lg={4} md={6} sm={12} data-aos="zoom-in" data-aos-duration="1500"
                            data-aos-once="true">
                            <Link to="/essential-points-to-understand-regarding-capital-gains" style={{ textDecoration: "none" }}>
                                <Card className='taxTipsCard'>
                                    <div>
                                        <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                        <Card.Img variant="top" className='taxTipsImg3' src={essentialpointstounderstand} alt="Tax deductions for medical expenses" />
                                        <div className='industryImgoverly'>
                                            <Link to="/essential-points-to-understand-regarding-capital-gains" class="industryicon">
                                                <FontAwesomeIcon icon={faPlusCircle} />
                                            </Link>
                                        </div>
                                    </div>

                                    <Card.Body>
                                        <Card.Title className='taxTipscardTtle'>6 essential points to understand regarding capital gains.</Card.Title>
                                        <Card.Text className='commonDesc'>
                                            If you've recently sold a property for a higher value than what you originally bought it for or if the value of your stocks increased from your initial investment, then congratulations on earning a profit from your investment! However to  &nbsp;
                                            <strong style={{ color: "#005BAA" }}> <FontAwesomeIcon icon={faArrowRight} /></strong>

                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>
                        <Col lg={4} md={6} sm={12} data-aos="zoom-in" data-aos-duration="1500"
                            data-aos-once="true">
                            <Link to="/fix-your-income-tax-return-in-Canada" style={{ textDecoration: "none" }}>
                                <Card className='taxTipsCard'>
                                    <div>
                                        <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                        <Card.Img variant="top" className='taxTipsImg3' src={fixincome} alt="Tax deductions for medical expenses" />
                                        <div className='industryImgoverly'>
                                            <Link to="/fix-your-income-tax-return-in-Canada" class="industryicon">
                                                <FontAwesomeIcon icon={faPlusCircle} />
                                            </Link>
                                        </div>
                                    </div>

                                    <Card.Body>
                                        <Card.Title className='taxTipscardTtle'>Fix Your Income Tax Return in Canada.</Card.Title>
                                        <Card.Text className='commonDesc'>
                                            Filing your income tax return in Canada can be a challenging task, but it's crucial to ensure that your taxes are accurately reported to avoid any potential legal issues or penalties. However, mistakes can happen, and if you realize that you've made an error on your income tax return  &nbsp;
                                            <strong style={{ color: "#005BAA" }}> <FontAwesomeIcon icon={faArrowRight} /></strong>

                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>
                        <Col lg={4} md={6} sm={12} data-aos="zoom-in" data-aos-duration="1500"
                            data-aos-once="true">
                            <Link to="/accounting-tips-for-small-business-owners-a-guide-to-financial-success" style={{ textDecoration: "none" }}>
                                <Card className='taxTipsCard'>
                                    <div>
                                        <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                        <Card.Img variant="top" className='taxTipsImg3' src={accountingtips} alt="accounting-tips" />
                                        <div className='industryImgoverly'>
                                            <Link to="/accounting-tips-for-small-business-owners-a-guide-to-financial-success" class="industryicon">
                                                <FontAwesomeIcon icon={faPlusCircle} />
                                            </Link>
                                        </div>
                                    </div>

                                    <Card.Body>
                                        <Card.Title className='taxTipscardTtle'>10 Essential Accounting Tips for Small Business Owners: A Guide to Financial Success.</Card.Title>
                                        <Card.Text className='commonDesc'>
                                            Running a small business comes with numerous challenges, and effective financial management is crucial for success. In this blog, we will explore ten essential accounting tips that every small business owner should implement to ensure   &nbsp;
                                            <strong style={{ color: "#005BAA" }}> <FontAwesomeIcon icon={faArrowRight} /></strong>

                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>
                        <Col lg={4} md={6} sm={12} data-aos="zoom-in" data-aos-duration="1500"
                            data-aos-once="true">
                            <Link to="/outsourcing-accounting-services-pros-and-cons-for-businesses" style={{ textDecoration: "none" }}>
                                <Card className='taxTipsCard'>
                                    <div>
                                        <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                        <Card.Img variant="top" className='taxTipsImg3' src={AccountingSerivce} alt="Accounting Serivce" />
                                        <div className='industryImgoverly'>
                                            <Link to="/outsourcing-accounting-services-pros-and-cons-for-businesses" class="industryicon">
                                                <FontAwesomeIcon icon={faPlusCircle} />
                                            </Link>
                                        </div>
                                    </div>

                                    <Card.Body>
                                        <Card.Title className='taxTipscardTtle'>Outsourcing Accounting Services: Pros and Cons for Businesses.</Card.Title>
                                        <Card.Text className='commonDesc'>
                                            Introduction: In today's fast-paced business landscape, organizations are constantly seeking ways to optimize their operations and focus on core competencies. One area that businesses often consider outsourcing is accounting services. Outsourcing accounting functions can offer   &nbsp;
                                            <strong style={{ color: "#005BAA" }}> <FontAwesomeIcon icon={faArrowRight} /></strong>

                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>
                        <Col lg={4} md={6} sm={12} data-aos="zoom-in" data-aos-duration="1500"
                            data-aos-once="true">
                            <Link to="/understanding-the-importance-of-accurate-bookkeeping-for-Your-business" style={{ textDecoration: "none" }}>
                                <Card className='taxTipsCard'>
                                    <div>
                                        <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                        <Card.Img variant="top" className='taxTipsImg3' src={understdBookkeeping} alt="understanding Bookkeeping" />
                                        <div className='industryImgoverly'>
                                            <Link to="/understanding-the-importance-of-accurate-bookkeeping-for-Your-business" class="industryicon">
                                                <FontAwesomeIcon icon={faPlusCircle} />
                                            </Link>
                                        </div>
                                    </div>

                                    <Card.Body>
                                        <Card.Title className='taxTipscardTtle'>Understanding the Importance of Accurate Bookkeeping for Your Business.</Card.Title>
                                        <Card.Text className='commonDesc'>
                                            Bookkeeping is an essential function for any business, regardless of its size or industry. It involves the recording, organizing, and maintaining of financial transactions, providing a clear and accurate picture of the company's financial health.   &nbsp;
                                            <strong style={{ color: "#005BAA" }}> <FontAwesomeIcon icon={faArrowRight} /></strong>

                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>
                    </Row>

                </Container>
            </Fragment>
        );
    }
}

export default TaxTips;