import React, { Component, Fragment } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import TAXTiis from '../asset/images/TAX Tiis.png';
import TAXRATE from '../asset/images/TAX RATE.png';
import house from '../asset/images/house.jpg';
import TAXDates from '../asset/images/TAX Dates.png';
import taximplication1 from '../asset/images/tax-implication1.webp';
import Studenttax from '../asset/images/Student tax.png';
import multiplestate3 from '../asset/images/tax-in-multiple-state3.png';
import taxdeduction2 from '../asset/images/tax-deduction-2.png';

class BlogPart9 extends Component {
    render() {
        return (
            <Fragment>
                <Container>
                    <Row>
                        <Col lg={8} md={6} sm={12}>
                            <div className='blogDetailDiv'>
                                <h2 className='blogTopTitle fs-1'>How to Filing Taxes in Multiple States When Moved</h2>
                                <p className='blogDesc'>During  tax time, you need to do more if you moved last year from one state to another. You had to get settled in your new community, make it feel like home for yourself and your family, register to vote in your new state, and get a new driver’s license.</p>
                                <p className='blogDesc'>Sorry to say: you will be required to file three tax returns this year: one federal, and one for each of the two states you are moving from and to. Unless you are fortunate enough to be moving from or to a state without an income tax, you will need to file a tax return for both.</p>
                                <p className='blogDesc'>Don't worry if you're new to filing multiple state tax returns. V-tac is here to help you every step of the way. If you have any questions, our tax experts are available to assist you by <Link to="/contact" style={{ color: "#005baa", textDecoration:"none" }}><strong>mail or phone</strong></Link> , so you can get the answers you need from the comfort of your own home.</p>
                                <h2 className='blogDescTitle'>Filing Part-Yearly Resident Tax Returns</h2>
                                <img variant="top" className='blog1PageImg2 mt-4 mb-5' src={multiplestate3} alt="tips for self-employed-taxpayers"></img>
                                <p className='blogDesc'>For the year you move, you will need to file a part-year resident tax return for both states. However, you won't need to pay double the state tax. Each state taxes only the income earned within its borders. Therefore, you will simply need to divide your income between the two state tax returns.</p>
                                <p className='blogDesc'>If you moved on 10th August, then the income you earned up until that date will be reported on the tax return you file for the state you used to live in, while the income you earned after that date will be taxed by your current state of residence.</p>
                                <p className='blogDesc'>If you earned income from interest or dividends that were paid out evenly throughout the year, you should divide it based on the number of days you spent in each location. For example, if you moved from one location on 10th August, you would divide the income equally between the two locations.</p>
                                <p className='blogDesc'>If you know the exact amount of income you earned in each state, for instance, if you closed a savings account in your old location and opened one in your new location, you can report the income earned in each state separately.</p>
                                <p className='blogDesc'>If you are a resident of one state at the end of the year and are required to report all your income to that state, you may be concerned that you could end up paying double state tax on some of your payments if you must also report it to your old state.</p>
                                <p className='blogDesc'>When you move to a new state, you may have to report the same income to both your old and new state. This can mean that you have to pay a higher amount of tax on the same income when submitting your tax return in your new state.</p>
                                <p className='blogDesc'>However, don't worry! On your tax return for your new state, you can claim a tax credit for the taxes paid to your old state on the same income. This tax credit will help to make up for the extra tax that you had to pay on the same income to both states.</p>
                               
                               
                                <h2 className='blogDescTitle'><Link to="/"  style={{ color: "#f89a1c", textDecoration:"none" }}>V-Tac</Link> Is Here to Help You</h2>
                                
                                <p className='blogDesc'>Don't fret about understanding the tax implications of relocating to a new state -<Link to="/" style={{ color: "#005baa", textDecoration:"none",fontWeight:"600" }}> V-tac Accounting & Tax</Link> will ask you straightforward questions about yourself and provide you with the deductions and credits you are qualified for, based on your responses.</p>
                                <p className='blogDesc'>Need help with your taxes? Our experts have an average of 20 years of experience, so you can trust your taxes are in good hands. Our team will prepare, sign and file your taxes, giving you peace of mind that your taxes are done correctly. And yes <Link to="/remote-tax-filing"  style={{ color: "#005baa", textDecoration:"none",fontWeight:"600" }}>remote filling</Link> is on now.</p>
                            </div>
                        </Col>

                        <Col lg={4} md={6} sm={12}>
                            <div className='relatedArticle'>
                                <h2 className='fs-3 '>Related Articles</h2>
                                <hr style={{ width: "100%", float: "left" }}></hr>
                            </div>
                            <Card className='blogCardRight' >
                                <Link to="/ways-to-stay-ahead-of-tax-season" style={{ textDecoration: "none" }}>
                                    <div>
                                        <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                        <Card.Img variant="top" className='industryImg' src={TAXTiis} />
                                    </div>

                                    <Card.Body>
                                        <Card.Title className='blogCardRightTitle'>8 straight ways to stay ahead of tax season 2023</Card.Title>
                                    </Card.Body>
                                </Link>
                            </Card>
                            <Card className='blogCardRight float-right'>
                                <Link to="/federal-tax-brackets-rates-credits" style={{ textDecoration: "none" }}>
                                    <div>
                                        <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                        <Card.Img variant="top" className='industryImg' src={TAXRATE} />
                                    </div>

                                    <Card.Body>
                                        <Card.Title className='blogCardRightTitle'>Federal Tax Brackets, Rates & Credits 2023</Card.Title>
                                    </Card.Body>
                                </Link>
                            </Card>
                            <Card className='blogCardRight float-right'>
                                <Link to="/housing-benefit-and-the-way-to-apply" style={{ textDecoration: "none" }}>
                                    <div>
                                        <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                        <Card.Img variant="top" className='industryImg' src={house} />
                                    </div>

                                    <Card.Body>
                                        <Card.Title className='blogCardRightTitle'>Are you a renter in Canada? Learn about the Housing Benefit and the way to apply for it. </Card.Title>
                                    </Card.Body>
                                </Link>
                            </Card>

                            <Card className='blogCardRight float-right'>
                                <Link to="/important-tax-deadlines" style={{ textDecoration: "none" }}>
                                    <div>
                                        <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                        <Card.Img variant="top" className='industryImg' src={TAXDates} />
                                    </div>

                                    <Card.Body>
                                        <Card.Title className='blogCardRightTitle'>Important Tax Deadlines 2023</Card.Title>
                                    </Card.Body>
                                </Link>
                            </Card>
                            <Card className='blogCardRight'>
                                <Link to="/tips-for-tax-students" style={{ textDecoration: "none" }}>
                                    <div>
                                        <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                        <Card.Img variant="top" className='industryImg' src={Studenttax} />
                                    </div>

                                    <Card.Body>
                                        <Card.Title className='blogCardRightTitle'>Maximizing Your 2022 Return: Tips for Students</Card.Title>
                                    </Card.Body>
                                </Link>
                            </Card>
                            <Card className='blogCardRight float-right'>
                                <Link to="/tax-implications-for-having-multiple-employers" style={{ textDecoration: "none" }}>
                                    <div>
                                        <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                        <Card.Img variant="top" className='industryImg' src={taximplication1} />
                                    </div>

                                    <Card.Body>
                                        <Card.Title className='blogCardRightTitle'>Tax implications for having multiple employers</Card.Title>
                                    </Card.Body>
                                </Link>
                            </Card>
                            <Card className='blogCardRight float-right'>
                                <Link to="/tax-deductions-for-medical-expenses-under-the-CRA" style={{ textDecoration: "none" }}>
                                    <div>
                                        <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                        <Card.Img variant="top" className='industryImg' src={taxdeduction2} />
                                    </div>

                                    <Card.Body>
                                        <Card.Title className='blogCardRightTitle'>Tax deductions for medical expenses under the CRA</Card.Title>
                                    </Card.Body>
                                </Link>
                            </Card>
                            
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        );
    }
}

export default BlogPart9;