import React, { Component, Fragment } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import Accounting from '../asset/images/Accounting.jpg';
import Bookkepping from '../asset/images/Book.jpg';
import Taxation from '../asset/images/Taxation.jpg';
import Payroll from '../asset/images/PayrollProcess.jpg';
import Taxclinic from '../asset/images/TaxClinic.jpg';
import Business from '../asset/images/Incorporation.jpg';
import { Link } from "react-router-dom";

export default class Services extends Component {
    render() {
        return (
            <Fragment>
                <Container fluid="true" className="text-center aboutContainer sectionBetween">
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div data-aos="fade-down"
                                data-aos-easing="linear"
                                data-aos-duration="1000"
                                data-aos-once="true">
                                <h2 className="serviceMainTitle mt-5 mb-5">OUR SERVICES</h2>
                            </div>

                        </Col>
                    </Row>
                    <div>

                        <Container>
                            <Row>
                                <Col lg={4} md={6} sm={12}>
                                    <div className="serviceCard text-center"  data-aos="zoom-in" data-aos-duration="1500"
                                            data-aos-once="true">

                                        <img className="logoImage" src={Accounting} alt="Accounting" />
                                        <h2 className="serviceName" >ACCOUNTING</h2>
                                        <p className="serviceDescription">V-Tac is built upon helping businesses grasp opportunities and solve problems through an array of financial services. Whatever a clients’ financial obligations may be, V-Tac focuses on their specific needs so they can play a better role in the economy. Our professionals provide detailed attention, with proven results.</p>
                                        <Link to="/accounting" className="serveLink text-center"><a>Read More</a></Link>
                                    </div>
                                </Col>
                                <Col lg={4} md={6} sm={12}>
                                    <div className="serviceCard text-center" data-aos="zoom-in" data-aos-duration="1500"
                                            data-aos-once="true">
                                        <img className="logoImage" src={Bookkepping} alt="Bookkepping" />
                                        <h2 className="serviceName">BOOKKEEPING</h2>
                                        <p className="serviceDescription">At V-Tac Accounting, we provide solutions to these bookkeeping dilemmas. Our dependable services eliminate the problems of employee retention. You have the ability to control your costs to stay within your budget. Our skilled bookkeepers will personally assist at whatever time is most convenient for you.</p>
                                        <Link to="/bookkeeping" className="serveLink text-center"><a >Read More</a></Link>
                                    </div>
                                </Col>
                                <Col lg={4} md={6} sm={12}>
                                    <div className="serviceCard text-center" data-aos="zoom-in" data-aos-duration="1500"
                                            data-aos-once="true">
                                        <img className="logoImage" src={Taxation} alt="Taxation" />
                                        <h2 className="serviceName">TAXATION</h2>
                                        <p className="serviceDescription">To excel in today’s murky economy, V-Tac maintains a competitive advantage by offering reputable knowledge and staying up to date with new tax laws. V-Tac provides comprehensible and accurate tax services to individuals, self-employed persons, growing businesses, and corporations.</p>
                                        <Link to="/taxation" className="serveLink text-center"><a>Read More</a></Link>
                                    </div>
                                </Col>

                                <Col lg={4} md={6} sm={12}>
                                    <div className="serviceCard text-center" data-aos="zoom-in" data-aos-duration="1500"
                                            data-aos-once="true">

                                        <img className="logoImage" src={Payroll} alt="Payroll processing" />
                                        <h2 className="serviceName" >PAYROLL PROCESSING</h2>
                                        <p className="serviceDescription">Rather than waste valuable time and resources in organizing your own payroll, why not let us handle it. In doing so, you can devote more time to creative financial pursuits to strengthen productivity and maximize profit.</p>
                                        <Link to="/payroll-processing" className="serveLink text-center"><a>Read More</a></Link>
                                    </div>
                                </Col>
                                <Col lg={4} md={6} sm={12}>
                                    <div className="serviceCard text-center" data-aos="zoom-in" data-aos-duration="1500"
                                            data-aos-once="true">
                                        <img className="logoImage" src={Taxclinic} alt="Taxclinic" />
                                        <h2 className="serviceName">TAX-CLINIC</h2>
                                        <p className="serviceDescription">At V-Tac we value our customers and because we do, we take their needs into consideration. Our firm is cognizant of some of the health risks and medical implications that may prevent a customer from dropping by to use our services. We are here to make your taxes.</p>
                                        <Link to="/tax-clinic" className="serveLink text-center"><a>Read More</a></Link>
                                    </div>
                                </Col>
                                <Col lg={4} md={6} sm={12}>
                                    <div className="serviceCard text-center" data-aos="zoom-in" data-aos-duration="1500"
                                            data-aos-once="true">
                                        <img className="logoImage" src={Business} alt="Business Incorporation" />
                                        <h2 className="serviceName">BUSINESS INCORPORATION</h2>
                                        <p className="serviceDescription">We do both Federal and Provincial Incorporation. We provide official searches for full Ontario incorporation, Canada trademark registration and other related corporate services with minimum cost. We respond to your need quicker and faster so you can save more.</p>
                                        <Link to="/business-incorporation" className="serveLink text-center"><a>Read More</a></Link>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={12} md={12} sm={12}>
                                    <Link to="/services"><Button className='servicebtn mt-5 mb-5'data-aos="fade-up"
                                data-aos-easing="linear"
                                data-aos-duration="1000"
                                data-aos-once="true">Explore all Services</Button></Link>
                                </Col>
                            </Row>
                        </Container>
                    </div>

                </Container>
            </Fragment>
        )
    }
}
