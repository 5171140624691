import React, { Component, Fragment } from 'react';
import Covid19 from '../components/Covid19';
import PageTop2 from '../components/PageTop2';
import VtacQuote from '../components/VtacQuote';
import Footer from '../components/Footer';
import NewTopNavigation from '../components/NewTopNavigation';
class Covid19Page extends Component {
    render() {
        return (
            <Fragment>
                <NewTopNavigation title="COVID-19 Update!-V-Tac Accounting & Tax | The Professionals' Professional" />
                <PageTop2 topPageTitle="COVID-19 Update!" topPagesubTitle="You may be eligible for the interest free $40K Loan!" />
                <Covid19 />
                <VtacQuote />
                <Footer />
            </Fragment>
        );
    }
}

export default Covid19Page;