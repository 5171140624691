import React, { useState } from 'react';
import { MapContainer, Marker, TileLayer, Tooltip } from "react-leaflet";
import leaflet from "leaflet";
import osm from "../BasicMap/OsmProviders";
import { useRef } from "react";
import "leaflet/dist/leaflet.css";
import cities from "../BasicMap/cities.json"

const markerIcon = new leaflet.Icon({
    iconUrl: require("../BasicMap/marker.png"),
    iconSize: [40, 40],
    iconAnchor: [17, 46], //[left/right, top/bottom]
    popupAnchor: [0, -46], //[left/right, top/bottom]

});

const BasicMap = () => {
    //const [center, setCenter] = useState({ lat: 23.6850, lng: 90.3563 });
    const [center, setCenter] = useState({ lat: 43.651070, lng: -79.347015 });
    const ZOOM_LEVEL = 5;
    const mapRef = useRef();
    // const resetMap = () => {
    //     // Reset the map view to the initial center and zoom level
    //     mapRef.current.setView(center, ZOOM_LEVEL);
    //   };
   
    return (
        <>
            <h1 title="React Leaflet Map Example" />

            <div className="row">
                <div className="col text-center">

                    <div className="col leaflet-container">
                        <MapContainer center={center} zoom={ZOOM_LEVEL} ref={mapRef}>
                            {/* <button onClick={resetMap}>Reset Zoom</button> */}
                      
                            <TileLayer
                                url={osm.maptiler.url}
                                attribution={osm.maptiler.attribution}
                            />

                            {cities.map((city, index) => (
                                <Marker
                                    position={[city.lat, city.lng]}
                                    icon={markerIcon}
                                    key={index}

                                >
                                    <Tooltip>
                                        <b>
                                            {city.company}<br /> {city.city}<br />{city.address}<br />{city.country}
                                        </b>
                                    </Tooltip>
                                </Marker>
                            ))}
                            
                        </MapContainer>
                       
                    </div>
                </div>
            </div>
           
        </>
    );
};

export default BasicMap;