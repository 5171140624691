import React, { Component, Fragment } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import SinceServe from '../asset/images/Sinceserve.png';
import AOS from 'aos';
import 'aos/dist/aos.css';

export default class AboutDescription extends Component {

    componentDidMount() {
        AOS.init();
    }
    render() {
        return (
            <Fragment>
                <Container fluid={true} className="sectionBetween">
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div data-aos="fade-down"
                                data-aos-easing="linear"
                                data-aos-duration="1000"
                                data-aos-once="true">
                                <h1 className="serviceMainTitle text-center">Welcome to V-Tac Accounting & Tax! </h1>
                            </div>

                        </Col>
                    </Row>
                    <div>
                        <Container>
                            <div className='hr'></div>
                            <Row>
                                <Col lg={8} md={6} sm={12}>
                                    <div data-aos="fade-right"
                                        data-aos-anchor="#example-anchor"
                                        data-aos-offset="500"
                                        data-aos-duration="2000"
                                        data-aos-once="true">
                                        <p className="serviceDescription mt-4">V-Tac was established in 1997 and introduced its accounting and taxation services to Canada. Over the years, V-Tac has continued to expand its operations and accrue a diverse clientele because of its reputable knowledge and reliable financial services. V-Tac has been providing over 20 years of solid expertise in a variety of financial areas such as: bookkeeping, accounting, business consulting, corporate taxes, personal income tax and payroll services across Canada.</p>
                                    </div>
                                </Col>
                                <Col lg={4} md={6} sm={12}>
                                    <div className="SinceImage" data-aos="fade-left"
                                        data-aos-anchor="#example-anchor"
                                        data-aos-offset="500"
                                        data-aos-duration="2000"
                                        data-aos-once="true"><img src={SinceServe} alt="since1997" /></div>
                                </Col>
                            </Row>
                        </Container>
                    </div>

                </Container>
            </Fragment>
        )
    }
}
