import React, { Component, Fragment } from 'react';
import TaxationSection from '../components/TaxationSection';
import PageTop from '../components/PageTop';
import VtacQuote from '../components/VtacQuote';
import Footer from '../components/Footer';
import NewTopNavigation from '../components/NewTopNavigation';
class TaxationPage extends Component {
    render() {
        return (
            <Fragment>
                <NewTopNavigation title="Taxation-V-Tac Accounting & Tax | The Professionals' Professional" />
                <PageTop topPageTitle="Taxation" topPagesubTitle="To excel in today’s murky economy, V-Tac maintains a competitive advantage by offering reputable knowledge and staying up to date with new tax laws. Our tax professionals are trained to properly assess and file your income tax returns so that you receive the most benefit from deductions and credits." />
                <TaxationSection />
                <VtacQuote />
                <Footer />
            </Fragment>
        );
    }
}

export default TaxationPage;