import React, { Component, Fragment } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import TAXTiis from '../asset/images/TAX Tiis.png';
import TAXRATE from '../asset/images/TAX RATE.png';
import house from '../asset/images/house.jpg';
import TAXDates from '../asset/images/TAX Dates.png';
import TaxTips1 from '../asset/images/Student tax.png';
import Studenttax from '../asset/images/Student Taxx.png';
import tipsfsttimefiling2 from '../asset/images/5tips-fst-time-filing2.webp';
import taximplication1 from '../asset/images/tax-implication1.webp';
import SelfEmployedTaxpayers from '../asset/images/Self-Employed -Taxpayers.png';
import taxinmultiplestate1 from '../asset/images/tax-in-multiple-state1.png';
import taxdeduction2 from '../asset/images/tax-deduction-2.png';


class BlogPart5 extends Component {
    render() {
        return (
            <Fragment>
                <Container>
                    <Row>
                        <Col lg={8} md={6} sm={12}>
                            <div className='blogDetailDiv'>
                                <h2 className='blogTopTitle fs-1'> Maximizing Your 2022 Return: Tips for Students</h2>
                                <p className='blogDesc'>If you are a full or part-time post-secondary student, you can benefit from various credits that can boost your refund or lower the taxes you owe when filing your return. Claiming tuition payments, student loan interest, and even moving expenses can help you get a better outcome when you finish your taxes.</p>
                                <h2 className='blogDescTitle'>This is what you should be aware of when filing your taxes for the year 2022:</h2>
                                <img variant="top" className='blog1PageImg2 mt-4 mb-5' src={TaxTips1} alt="tips-for-student"></img>
                                <h2 className='blogDescTitle'>Don't forget to take advantage of the federal tuition tax credit!</h2>
                                <p className='blogDesc'>If you paid more than $100 in tuition fees to take a course at a school, you may be eligible for a federal tuition tax credit. You can also use the credit to claim fees you paid to an approved educational institution to learn a new skill or prepare for a job, as well as fees for taking an occupational, trade, or professional exam. Just make sure to keep your receipt.</p>
                                <p className='blogDesc'>You need your T2202 certificate to show how much tuition you paid for the year. You can get this form from your school's website. It's usually ready in February. You can save this credit for later or share it with a family member to help them pay fewer taxes.</p>
                                <h2 className='blogDescTitle'>Have you asked for the Canadian training credit?</h2>
                                <p className='blogDesc'>If you paid for post-secondary courses in 2021, you may be eligible for the Canada training credit. To qualify, you must be between 25 and 65 years old and have a Canada training limit of $750 for 2022 (up from $500 in 2021). You can find your Canada training limit on your 2021 Notice of Assessment.</p>
                                <p className='blogDesc'>If you are eligible for the Canada training credit, you can get your money back from the government. This is different from the tuition tax credit, which only reduces the amount of tax you owe. You can use both credits in the same year, but the Canada training credit will reduce your tuition tax credit.</p>
                                <h2 className='blogDescTitle'>Some government money that used to be available is no longer available.</h2>
                                <p className='blogDesc'>If you are from Alberta, Ontario, or Saskatchewan, you can still use the leftover amount from your provincial tuition credit from past years to reduce your tax amount. You can also use any unused federal education or textbook amounts for the same purpose in 2022. Note that these carryover amounts are only for the student and cannot be given to other family members.</p>
                                <h2 className='blogDescTitle'>Don't forget to get money back for the interest you paid on your student loans!</h2>
                                <p className='blogDesc'>You can reduce the amount of tax you owe by claiming the interest you've paid on your student loans. If your loan was issued in the last 5 years, you can claim this interest when filing your taxes in 2022.</p>
                                <ul className='blogDesc'>
                                    <li>The Canada Student Loans Act;</li>
                                    <li>Act respecting financial assistance for education expenses (residents of Québec);</li>
                                    <li>The Canada Student Financial Assistance Act;</li>
                                    <li>The Canada Apprentice Loan Act;</li>
                                    <li>A similar provincial or territorial government law; or</li>
                                    <li>A law in a Canadian province (other than Québec) about giving money to college or university students (who live in Québec).</li>
                                </ul>
                                <p className='blogDesc'>If you have a student loan, you may be eligible for a credit. You can use this credit to reduce the amount of interest you pay on your loan. This credit only applies to student loans from Canadian post-secondary institutions, not loans from other countries or other types of personal loans. If you don't use the full credit this year, you can use the unused amount in the following five years. You cannot give this credit to someone else; it must be claimed by you or someone related to you.</p>
                                <h2 className='blogDescTitle'>Do you have children and are you a student?</h2>
                                <img variant="top" className='blog1PageImg2 mt-4 mb-5' src={Studenttax} alt="tips-for-student"></img>
                                <p className='blogDesc'>You might be able to get money back on your taxes if you pay for someone to take care of your child so you can go to class. This includes daycare, babysitters, camps, and other types of childcare.</p>
                                <h2 className='blogDescTitle'>Do you have a disability and go to school?</h2>

                                <p className='blogDesc'>Students with disabilities may have extra costs that other students don't. To help cover these costs, they can use special tax credits and deductions.</p>
                                <p className='blogDesc'>If you need things like page-turners or note-taking services to help you with your studies, you may be able to get money back on your taxes. You will need to show a prescription or note from a doctor to prove that you need the items.</p>
                                <h2 className='blogDescTitle'>You can pay less taxes by taking advantage of the scholarship exemption</h2>
                                <p className='blogDesc'>If you were a student between 2021 and 2022, you may be able to get some financial help in the form of scholarships, grants, and bursaries. Full-time students can get a scholarship exemption, which means any income you receive from these awards won’t be taxed as long as it isn’t more than your living expenses, tuition, and other costs. Part-time students can get a scholarship exemption of up to $500 plus tuition and materials costs, or the same as a full-time student if they have a disability. If you’re not a student, your scholarship exemption is still $500. Research grants are taxable income, but you can deduct your expenses when you report them on your return.</p>
                                <h2 className='blogDescTitle'>Did you get money back for the costs of your move?</h2>
                                <p className='blogDesc'>If you move more than 40 kilometers away from home to go to college or university in 2022, you can claim the cost of your move. This includes transportation, storage, travel, and temporary living. To be eligible, you must be a full-time student and be earning money related to your move, such as a scholarship, research grant, or prize.</p>
                            </div>
                        </Col>

                        <Col lg={4} md={6} sm={12}>
                            <div className='relatedArticle'>
                                <h2 className='fs-3 '>Related Articles</h2>
                                <hr style={{ width: "100%", float: "left" }}></hr>
                            </div>
                            <Card className='blogCardRight' >
                                <Link to="/ways-to-stay-ahead-of-tax-season" style={{ textDecoration: "none" }}>
                                    <div>
                                        <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                        <Card.Img variant="top" className='industryImg' src={TAXTiis} />
                                    </div>

                                    <Card.Body>
                                        <Card.Title className='blogCardRightTitle'>8 straight ways to stay ahead of tax season 2023</Card.Title>
                                    </Card.Body>
                                </Link>
                            </Card>
                            <Card className='blogCardRight float-right'>
                                <Link to="/federal-tax-brackets-rates-credits" style={{ textDecoration: "none" }}>
                                    <div>
                                        <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                        <Card.Img variant="top" className='industryImg' src={TAXRATE} />
                                    </div>

                                    <Card.Body>
                                        <Card.Title className='blogCardRightTitle'>Federal Tax Brackets, Rates & Credits 2023</Card.Title>
                                    </Card.Body>
                                </Link>
                            </Card>
                            <Card className='blogCardRight float-right'>
                                <Link to="/housing-benefit-and-the-way-to-apply" style={{ textDecoration: "none" }}>
                                    <div>
                                        <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                        <Card.Img variant="top" className='industryImg' src={house} />
                                    </div>

                                    <Card.Body>
                                        <Card.Title className='blogCardRightTitle'>Are you a renter in Canada? Learn about the Housing Benefit and the way to apply for it. </Card.Title>
                                    </Card.Body>
                                </Link>
                            </Card>

                            <Card className='blogCardRight float-right'>
                                <Link to="/important-tax-deadlines" style={{ textDecoration: "none" }}>
                                    <div>
                                        <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                        <Card.Img variant="top" className='industryImg' src={TAXDates} />
                                    </div>

                                    <Card.Body>
                                        <Card.Title className='blogCardRightTitle'>Important Tax Deadlines 2023</Card.Title>
                                    </Card.Body>
                                </Link>
                            </Card>

                            <Card className='blogCardRight float-right'>
                                <Link to="/tax-implications-for-having-multiple-employers" style={{ textDecoration: "none" }}>
                                    <div>
                                        <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                        <Card.Img variant="top" className='industryImg' src={taximplication1} />
                                    </div>

                                    <Card.Body>
                                        <Card.Title className='blogCardRightTitle'>Tax implications for having multiple employers</Card.Title>
                                    </Card.Body>
                                </Link>
                            </Card>

                            <Card className='blogCardRight float-right'>
                                <Link to="/tips-for-a-great-first-time-filing-experience" style={{ textDecoration: "none" }}>
                                    <div>
                                        <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                        <Card.Img variant="top" className='industryImg' src={tipsfsttimefiling2} />
                                    </div>

                                    <Card.Body>
                                        <Card.Title className='blogCardRightTitle'>5 tips for a great first-time filing experience</Card.Title>
                                    </Card.Body>
                                </Link>
                            </Card>
                            <Card className='blogCardRight float-right'>
                                <Link to="/tips-for-self-employed-taxpayers" style={{ textDecoration: "none" }}>
                                    <div>
                                        <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                        <Card.Img variant="top" className='industryImg' src={SelfEmployedTaxpayers} />
                                    </div>

                                    <Card.Body>
                                        <Card.Title className='blogCardRightTitle'>Tips for Self-Employed Taxpayers</Card.Title>
                                    </Card.Body>
                                </Link>
                            </Card>
                            <Card className='blogCardRight float-right'>
                                <Link to="/how-to-filing-taxes-in-multiple-states-when-moved" style={{ textDecoration: "none" }}>
                                    <div>
                                        <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                        <Card.Img variant="top" className='industryImg' src={taxinmultiplestate1} />
                                    </div>

                                    <Card.Body>
                                        <Card.Title className='blogCardRightTitle'>How to Filing Taxes in Multiple States When Moved</Card.Title>
                                    </Card.Body>
                                </Link>
                            </Card>
                            <Card className='blogCardRight float-right'>
                                <Link to="/tax-deductions-for-medical-expenses-under-the-CRA" style={{ textDecoration: "none" }}>
                                    <div>
                                        <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                        <Card.Img variant="top" className='industryImg' src={taxdeduction2} alt="tax-deduction-for medical-expenses" />
                                    </div>

                                    <Card.Body>
                                        <Card.Title className='blogCardRightTitle'>Tax deductions for medical expenses under the CRA</Card.Title>
                                    </Card.Body>
                                </Link>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        );
    }
}

export default BlogPart5;