import React, { Component, Fragment } from 'react';
import AllService from '../components/AllService';
import PageTop from '../components/PageTop';
import VtacQuote from '../components/VtacQuote';
import Footer from '../components/Footer';
import NewTopNavigation from '../components/NewTopNavigation';
class AllServicePage extends Component {
    render() {
        return (
            <Fragment>
                <NewTopNavigation title="Services-V-Tac Accounting & Tax | The Professionals' Professional" />
                <PageTop topPageTitle="Our Services" topPagesubTitle="V-Tac makes every effort to provide professional services to its diverse clients that range from small businesses to large corporations. Our staff affords invaluable knowledge that is intended to benefit our clients to help them succeed and financially grow. We take pride in working closely with our customers to better understand their goals and expectations in order to create better solutions for positive outcomes." />
                <AllService />
                <VtacQuote />
                <Footer />
            </Fragment>
        );
    }
}

export default AllServicePage;