import React, { Component, Fragment } from 'react';
import BookkipingSection from '../components/BookkipingSection';
import PageTop from '../components/PageTop';
import VtacQuote from '../components/VtacQuote';
import Footer from '../components/Footer';
import NewTopNavigation from '../components/NewTopNavigation';
class BookkipingPage extends Component {
    render() {
        return (
            <Fragment>
                <NewTopNavigation title="Bookkeeping-V-Tac Accounting & Tax | The Professionals' Professional" />
                <PageTop  topPageTitle="Bookkeeping" topPagesubTitle="At V-Tac Accounting, we provide solutions to bookkeeping dilemmas. Our dependable services eliminate the problems of employee retention. You have the ability to control your costs to stay within your budget. Our skilled bookkeepers will personally assist at whatever time is most convenient for you."/>
                <BookkipingSection />
                <VtacQuote />
                <Footer />
            </Fragment>
        );
    }
}

export default BookkipingPage;