import React, { Component, Fragment } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';


class Covid19 extends Component {
    render() {
        return (
            <Fragment>
                <Container fluid={true} className="accContainer">

                    <Container className='accSection'>
                        <div>
                            <h2 className='accTitle'>
                                COVID-19 Update!</h2>
                        </div>
                        <Row>
                            <Col lg={12} md={12} sm={12} >
                                <div>
                                    <p className='covidSubtitle'>The Government of Canada had recently announced its Canada Emergency Business Account to deliver much-needed financial relief by providing eligible businesses with access to a $40,000 loan.</p>
                                    <p className='accSubtitle'>Your organization may be eligible to enroll in the Canada Emergency Business.
                                        <br></br> Account through your bank. Here’s a quick overview, for more details please contact your bank:</p>
                                    <h2 className='accSubtitle'><strong>Features of the Loan:</strong></h2>
                                </div>
                                <div>
                                    <ul className='accUl'>
                                        <li><FontAwesomeIcon className="otherServiceBullet" icon={faCheckCircle} /><div className='otherServiceLi'>This program will provide $25 billion in total funding consisting of interest-free, partially forgivable loans of up to $40,000 to small businesses and not-for-profits in all sectors and regions of Canada.</div></li>
                                        <li><FontAwesomeIcon className="otherServiceBullet" icon={faCheckCircle} /><div className='otherServiceLi'>To qualify, organizations will need to demonstrate they paid between $50,000 and $1 million in total payroll in 2019.</div></li>
                                        <li><FontAwesomeIcon className="otherServiceBullet" icon={faCheckCircle} /><div className='otherServiceLi'>To avail the loan, your business should not be a holding company.</div></li>
                                        <li><FontAwesomeIcon className="otherServiceBullet" icon={faCheckCircle} /><div className='otherServiceLi'>It’s a $40,000 loan to help eligible businesses pay for operating expenses, payroll and other non-deferrable expenses which are critical to sustain business continuity.</div></li>
                                        <li><FontAwesomeIcon className="otherServiceBullet" icon={faCheckCircle} /><div className='otherServiceLi'>No interest may apply until January 1, 2023.</div></li>
                                        <li><FontAwesomeIcon className="otherServiceBullet" icon={faCheckCircle} /><div className='otherServiceLi'>Until December 31, 2020, the Canada Emergency Business Account will be funded as a revolving line of credit for $40,000.</div></li>
                                        <li><FontAwesomeIcon className="otherServiceBullet" icon={faCheckCircle} /><div className='otherServiceLi'>You have to submit your organization’s 2019 T4 Summary of Remuneration Paid to the Bank.</div></li>
                                        <li><FontAwesomeIcon className="otherServiceBullet" icon={faCheckCircle} /><div className='otherServiceLi'>If you have more than one business owner, your bank may ask for more documents.</div></li>
                                    </ul>
                                </div>
                                <div>
                                    <p className='accSubtitle'><strong>To apply and for more information on this loan, please talk to your bank.</strong></p>
                                    <p className='accSubtitle'>Please <span className='spancolor'>feel free</span> to contact us for any information or clarification. We continue to remain open at V-TAC, Monday – Friday between 9 a.m. and 5 p.m. Drop off your documents with us and pick them up the next day. We are also accessible via e-mail and fax, feel free to send us your files and we’ll file your taxes for you.</p>
                                    <p className='accSubtitle'>We hope you and your family are staying safe during this pandemic crisis. Please call us at any time if you need assistance, we’re here to help.</p>
                                    <p className='accSubtitle'>Stay safe,<br></br>
                                        V-TAC Tax<br></br>
                                        www.vtac.ca</p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Container>
            </Fragment>
        );
    }
}

export default Covid19;