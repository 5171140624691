import React, { Component, Fragment } from 'react'
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import { Container, Row, Col } from 'react-bootstrap';

export default class Summary extends Component {
    render() {
        return (
            <Fragment>
                <Container fluid={true} className="SummarySection p-0">
                    <div>
                        <Container className="text-center">
                            <Row>
                                <Col lg={3} md={6} sm={12} >
                                    {/* <img className = "logoImage" src={ Accounting } /> */}
                                    <h2 className="countNumber mt-5">
                                        <CountUp start={0} end={100} duration={1}>
                                            {({ countUpRef, start }) => (

                                                <VisibilitySensor onChange={start}>
                                                    <span ref={countUpRef} />
                                                </VisibilitySensor>

                                            )}
                                        </CountUp>
                                        %
                                    </h2>
                                    <h4 className="countTitle">CA Based</h4>
                                    <div style={{ width: "30%" }} className="container summaryHr mb-5"><hr /></div>
                                </Col>

                                <Col lg={3} md={6} sm={12} >

                                    <h2 className="countNumber mt-5">
                                        <CountUp start={0} end={25} duration={1}>
                                            {({ countUpRef, start }) => (

                                                <VisibilitySensor onChange={start}>
                                                    <span ref={countUpRef} />
                                                </VisibilitySensor>

                                            )}
                                        </CountUp>
                                        + Years
                                    </h2>
                                    <h4 className="countTitle">Experience</h4>
                                    <div style={{ width: "30%" }} className="container summaryHr mb-5"><hr /></div>
                                </Col>
                                <Col lg={3} md={6} sm={12} >
                                    <h2 className="countNumber mt-5">
                                        <CountUp start={0} end={10000} duration={1} separator=",">
                                            {({ countUpRef, start }) => (

                                                <VisibilitySensor onChange={start}>
                                                    <span ref={countUpRef} />
                                                </VisibilitySensor>

                                            )}
                                        </CountUp>
                                        +
                                    </h2>
                                    <h4 className="countTitle">TAX Clients</h4>
                                    <div style={{ width: "30%" }} className="container summaryHr mb-5"><hr /></div>
                                </Col>
                                <Col lg={3} md={6} sm={12} >

                                    <div className='text-center'>
                                        <h2 className="countNumber mt-5">
                                            <CountUp start={0} end={1200} duration={1} separator=",">
                                                {({ countUpRef, start }) => (

                                                    <VisibilitySensor onChange={start}>
                                                        <span ref={countUpRef} />
                                                    </VisibilitySensor>

                                                )}
                                            </CountUp>
                                            +

                                        </h2>
                                        <h4 className="countTitle">Corporate Clients</h4>
                                        <div style={{ width: "30%" }} className="container summaryHr mb-5"><hr /></div>

                                    </div>
                                </Col>

                            </Row>

                        </Container>

                    </div>

                </Container>
            </Fragment>
        )
    }
}
