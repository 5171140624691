import React, { Component, Fragment } from 'react';
import NewsUpdates from '../components/NewsUpdates';
import PageTop from '../components/PageTop';
import VtacQuote from '../components/VtacQuote';
import Footer from '../components/Footer';
import NewTopNavigation from '../components/NewTopNavigation';

class NewsUpdatesPage extends Component {
    render() {
        return (
            <Fragment>
                <NewTopNavigation title="News Updates-V-Tac Accounting & Tax | The Professionals' Professional" />
                <PageTop topPageTitle="News and Updates" topPagesubTitle="WHAT’S NEW ON FEDERAL AND PROVINCIAL GOVERNMENT TAX DEDUCTIONS FOR ON. AND QC." />
                <NewsUpdates />
                <VtacQuote />
                <Footer />
            </Fragment>
        );
    }
}

export default NewsUpdatesPage;