import React, { Component, Fragment } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import demoImg from '../asset/images/username-logo-png-180x180.png';

class OurTestimonials extends Component {
    render() {
        return (
            <Fragment>
                <Container className='incorporationSec'>
                    <div>
                        <h2 className='accTitle'>
                            Our Testimonials
                        </h2>
                    </div>
                    <div className='accHr'></div>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='testHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='Testtitle'>
                                    We have used V-Tac Accounting Services for the past couple of years and have found their staff to be patient and trustworthy. They always answer all of my questions and keep me organized.
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>

                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>Josef Karol</strong></p>
                                <p className='TestLtd'>Dairy Dell Bar & Grill Inc</p>
                            </div>
                        </Col>
                    </Row>
                    <Row className='mt-5'>
                        <Col lg={12} md={12} sm={12}>
                            <div className='testHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='Testtitle'>
                                    I have many friends always complaining about how dissatisfied they are with their accounting services. Luckily, this has never been the case for me. I am always happy with V-Tac’s services because they are honest and take proper care of my taxes.
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>

                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>Greg Dengis</strong></p>
                                <p className='TestLtd'>Dengis Construction Ltd</p>
                            </div>
                        </Col>
                    </Row>
                    <Row className='mt-5'>
                        <Col lg={12} md={12} sm={12}>
                            <div className='testHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='Testtitle'>
                                    One of the main issues I had with previous firms was time constraint. With V-Tac, however, I developed an immense amount of trust in their services because they’re always dependable. I would definitely recommend their services to anyone.
                                </h2></div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>

                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>Israel Weinstock</strong></p>
                                <p className='TestLtd'>Doar Mailing Services Inc</p>
                            </div>
                        </Col>
                    </Row>
                    <Row className='mt-5'>
                        <Col lg={12} md={12} sm={12}>
                            <div className='testHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='Testtitle'>
                                    Affordable, quick, and accurate. I couldn’t be happier with my tax returns.
                                </h2></div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>

                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>Adnan</strong></p>
                                <p className='TestLtd'>2013626 Ontario Inc - Coffee Time</p>
                            </div>
                        </Col>
                    </Row>
                    <Row className='mt-5'>
                        <Col lg={12} md={12} sm={12}>
                            <div className='testHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='Testtitle'>
                                    The services rendered by V-Tac are consistently efficient. They give me the freedom to focus on the needs of my company and my clientele. I look forward to continuing my professional relationship with them in the years to come.
                                </h2></div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>

                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>Richard Holyoke</strong></p>
                                <p className='TestLtd'>Holyoke Stone Works Inc</p>
                            </div>
                        </Col>
                    </Row>
                    <Row className='mt-5'>
                        <Col lg={12} md={12} sm={12}>
                            <div className='testHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='Testtitle'>
                                    The exceptional staff at V-Tac work hard to prepare and review our complete bookkeeping records. They are competent, reliable, adaptive, and professional. They give us peace of mind.
                                </h2></div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>

                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>Darko Trifunovic</strong></p>
                                <p className='TestLtd'>Neighbourhood Landscaping Inc</p>
                            </div>
                        </Col>
                    </Row>
                    <Row className='mt-5'>
                        <Col lg={12} md={12} sm={12}>
                            <div className='testHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='Testtitle'>
                                    V-Tac’s strong technical knowledge, expertise, and integrity are incomparable. My family and I trust their professional staff with both our business and personal finance issues. We intend to continue being their faithful customers.
                                </h2></div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>

                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>Gustavo Cruz</strong></p>
                                <p className='TestLtd'>Surnet Communications Inc</p>
                            </div>
                        </Col>
                    </Row>
                    <Row className='mt-5'>
                        <Col lg={12} md={12} sm={12}>
                            <div className='testHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='Testtitle'>
                                    We have been working with V-Tac Accounting and Services for many years. They have saved us money in taxes by carefully analyzing our files and delivering the highest standard of service quality. We are always impressed.
                                </h2></div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>

                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>Fesih Z Mert</strong></p>
                                <p className='TestLtd'>Maple Stucco & Wall System</p>
                            </div>
                        </Col>
                    </Row>
                    <Row className='mt-5'>
                        <Col lg={12} md={12} sm={12}>
                            <div className='testHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='Testtitle'>
                                    For once, I am happy that my personal taxes were filed correctly and did not result in me being audited. V-Tac’s services are fast, reliable, and most of all professional.
                                </h2></div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>

                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>Danniel Lewis</strong></p>
                                <p className='TestLtd'>Dann Service Corporation</p>
                            </div>
                        </Col>
                    </Row>
                    <Row className='mt-5'>
                        <Col lg={12} md={12} sm={12}>
                            <div className='testHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='Testtitle'>
                                    V-Tac has consistently advised with us creative business solutions and effective tax strategies to boost our profit and growth. They are resourceful and always willing to provide well-researched solutions.
                                </h2></div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>

                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>Aref Zahed</strong></p>
                                <p className='TestLtd'>Zayn Media Inc</p>
                            </div>
                        </Col>
                    </Row>
                    <Row className='mt-5'>
                        <Col lg={12} md={12} sm={12}>
                            <div className='testHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='Testtitle'>
                                    The staff at V-Tac have always been courteous and competent in delivering all the necessary work in a timely manner. I have come to rely heavily on their business solutions and knowledge while taking advantage of their tax/ accounting advice to further my financial goals.
                                </h2></div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>

                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>Enver Coovadia</strong></p>
                                <p className='TestLtd'>J W Car Care</p>
                            </div>
                        </Col>
                    </Row>
                    <Row className='mt-5'>
                        <Col lg={12} md={12} sm={12}>
                            <div className='testHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='Testtitle'>
                                    I have been a long time customer of V-Tac Accounting and Professional Services. During this time, I have experienced useful tax advice that I trust and benefit from.
                                </h2></div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>

                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>Aijaz Muhammad</strong></p>
                                <p className='TestLtd'>NCSC Inc</p>
                            </div>
                        </Col>
                    </Row>
                    <Row className='mt-5'>
                        <Col lg={12} md={12} sm={12}>
                            <div className='testHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='Testtitle'>
                                    I like using V-Tac’s services because they always deliver practical solutions to complex financial issues. They prioritize my business interests, which assures me that my finances are in good hands.
                                </h2></div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>

                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>Reid</strong></p>
                                <p className='TestLtd'>Full Truth Church of God Deliverance</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        );
    }
}

export default OurTestimonials;