import React, { Component, Fragment } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import img1 from '../asset/images/1-1.gif';
import img2 from '../asset/images/1-2.gif';
import img3 from '../asset/images/1-3.gif';
import img6 from '../asset/images/1-6.gif';
import img7 from '../asset/images/1-7.gif';

class AccountingSoftware extends Component {
    render() {
        return (
            <Fragment>
                <Container className='incorporationSec'>
                    <div>
                        <h2 className='accTitle'>
                        Accounting Software</h2>
                    </div>
                    <div className='accHr'></div>
                    <Row>
                        <Col lg={12} md={12} sm={12} >
                            <div className='text-center '>
                                <a> <img className="accSoftImg2" src={img1} /></a>
                                <a> <img className="accSoftImg2" src={img2} /></a>
                                <a> <img className="accSoftImg2" src={img3} /></a>
                                <a> <img className="accSoftImg2" src={img6} /></a>
                                <a> <img className="accSoftImg2" src={img7} /></a>
                            </div>

                        </Col>

                    </Row>
                </Container>
            </Fragment>
        );
    }
}

export default AccountingSoftware;