import React, { Component, Fragment } from 'react';
import BusinessPlanLoan from '../components/BusinessPlanLoan';
import PageTop from '../components/PageTop';
import VtacQuote from '../components/VtacQuote';
import Footer from '../components/Footer';
import NewTopNavigation from '../components/NewTopNavigation';
class BusinessPlanLoanPage extends Component {
    render() {
        return (
            <Fragment>
           
            <NewTopNavigation title="Business Plan and Loans-V-Tac Accounting & Tax | The Professionals' Professional" />
            <PageTop topPageTitle="Business Plan and Loans" topPagesubTitle="We will work hard to find the best solutions for your current business needs. Get in touch with our consultants to learn more about our business planning services to achieve your goals."/>
            <BusinessPlanLoan />
            <VtacQuote />
            <Footer />
        </Fragment>
        );
    }
}

export default BusinessPlanLoanPage;