import React, { Component, Fragment } from 'react';
import AboutUs from '../components/AboutUs';
import PageTop2 from '../components/PageTop2';
import VtacQuote from '../components/VtacQuote';
import Footer from '../components/Footer';
import NewTopNavigation from '../components/NewTopNavigation';


class AboutUsPage extends Component {
    render() {
        return (
            <Fragment>
                <NewTopNavigation title="About-V-Tac Accounting & Tax | The Professionals' Professional" />
                <PageTop2 topPageTitle="About Us" topPagesubTitle="V-Tac has been providing over 20 years of solid expertise in a variety of financial areas such as: bookkeeping, accounting, business consulting, corporate taxes, personal income tax and payroll services across Canada." />
                <AboutUs />
                <VtacQuote />
                <Footer />
            </Fragment>
        );
    }
}

export default AboutUsPage;