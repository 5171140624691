import React, { Component, Fragment } from 'react';
import BlogTop from '../components/BlogTop';
import VtacQuote from '../components/VtacQuote';
import Footer from '../components/Footer';
import NewTopNavigation from '../components/NewTopNavigation';
import BlogPart1 from '../components/BlogPart1';
import SearchByWord from '../components/SearchByWord';

class BlogPart1Page extends Component {
    render() {
        return (
            <Fragment>
                <NewTopNavigation title="8 straight ways to stay ahead of tax season 2023-V-Tac Accounting & Tax | The Professionals' Professional" />
                {/* <PageTop2 topPageTitle="Tips For Tax" topPagesubTitle="V-Tac has been providing over 20 years of solid expertise in a variety of financial areas such as: bookkeeping, accounting, business consulting, corporate taxes, personal income tax and payroll services across Canada." /> */}
                <BlogTop />
                <SearchByWord />
                <BlogPart1 />
                <VtacQuote />
                <Footer />
            </Fragment>
        );
    }
}

export default BlogPart1Page;