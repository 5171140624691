import React, { Fragment, useEffect, useState } from 'react';
import { Col, Container, Row, Form, Button } from 'react-bootstrap';
import axios from 'axios';
import Slide5 from '../asset/images/Slide5.jpg';
import Slide4 from '../asset/images/Slide4.jpg';

const RequestQuote = () => {
    const [validated, setValidated] = useState(false);

    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        phone: "",
        emailAddress: "",
        serviceType: "Accounting",
        hearFrom: "Google Search",
    });



    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    // clear form data
    const clearForm = () => {
        setFormData({
            firstName: "",
            lastName: "",
            phone: "",
            emailAddress: "",
            serviceType: "Accounting",
            hearFrom: "Google Search",
        })
    }

    // Scroll to top on page load
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    const vtacFormSubmit = async (event) => {
        event.preventDefault();

        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
            return;
        }

        try {
            const response = await axios.post('https://vtacemail.vtac.ca/vtacQuoteReq', formData);
            // const response = await axios.post('http://localhost:5000/vtacQuoteReq', formData);

            if (response.status === 200) {
                clearForm();
                window.location.href = "https://www.vtac.ca/request-quotation-success";
                // window.location.href = "http://localhost:3000/request-quotation-success";
                console.log('Form data submitted successfully!');
            }
        } catch (error) {
            console.error('Error submitting form data:', error.message);
        }
    };

    return (
        <Fragment>
            <Container fluid={true} className="accContainer" >

                <Container className='accSection' >
                    <div className='reqHeader'>
                        <h2>
                            Request Quote</h2>
                    </div>
                    <div className='mt-3'></div>
                    <Container className='SectionBetween'>
                        <Row>
                            <Col lg={6} md={6} sm={12}>
                                <div className=" quoteCard">
                                    <div id="RQForm">
                                        <Form onSubmit={vtacFormSubmit}>
                                            <Form.Group >
                                                <Form.Label className='formLabel'>First Name*</Form.Label>
                                                <Form.Control type="text" name='firstName' value={formData.firstName} onChange={handleChange} required />
                                            </Form.Group>


                                            <Form.Group >
                                                <Form.Label className='formLabel'>Last Name*</Form.Label>
                                                <Form.Control type="text" name='lastName' value={formData.lastName} onChange={handleChange} required />
                                            </Form.Group>


                                            <Form.Group >
                                                <Form.Label className='formLabel'>Phone*</Form.Label>
                                                <Form.Control type="text" name='phone' value={formData.phone} onChange={handleChange} required />
                                            </Form.Group>


                                            <Form.Group >
                                                <Form.Label className='formLabel'>Email*</Form.Label>
                                                <Form.Control type="email" name='emailAddress' value={formData.emailAddress} onChange={handleChange} required />
                                            </Form.Group>


                                            <Form.Group >
                                                <Form.Label className='formLabel'>Type of Service*</Form.Label>
                                                <select className='quoteDropdown' name='serviceType' value={formData.serviceType} onChange={handleChange} required>
                                                    <option value={"Accounting"}>Accounting</option>
                                                    <option value={"Bookkeeping"}>Bookkeeping</option>
                                                    <option value={"Payroll Processing"}>Payroll Processing</option>
                                                    <option value={"Income Tax"}>Income Tax</option>
                                                    <option value={"Business Incorporation"}>Business Incorporation</option>
                                                </select>
                                            </Form.Group>
                                            <Form.Group >
                                                <Form.Label className='formLabel'>How'd you hear about us ?*</Form.Label>
                                                <select className='quoteDropdown' name='hearFrom' value={formData.hearFrom} onChange={handleChange} >
                                                    {/* <option value={""}>How'd you hear about us ?*</option> */}
                                                    <option value={"Google Search"}>Google Search</option>
                                                    <option value={"Social Media"}>Social Media</option>
                                                    <option value={"Magazine Ad"}>Magazine Ad</option>
                                                    <option value={"Word Of Mouth"}>Word Of Mouth</option>
                                                    <option value={"Promotional Calender"}>Promotional Calender</option>
                                                    <option value={"Others"}>Others</option>
                                                </select>
                                            </Form.Group>
                                            <Button name='submitBtn' className="RequestQuoteBtn mt-5" variant="primary" type="submit">
                                                <span>Submit</span>
                                            </Button>
                                        </Form>

                                    </div>
                                    {/* <div id='SuccessMSgDiv'>
                                            <h1 className='SuccessMsg'>Thank you for reaching out to us. We will be in touch shortly.</h1>
                                            <p className='SuccessMsgDesc'>For urgent issues, please call us at: </p>
                                            <p className='SuccessMsgDesc mb-5' style={{ color: "#E6001F", cursor: "pointer" }}><strong>1-866-882-2829</strong></p>
                                        </div> */}
                                </div>
                            </Col>
                            <Col lg={6} md={6} sm={12}>
                                <div className='reqImgDiv'>
                                    <img className="REQImg" src={Slide5} alt="v-tac-tax-clinic" title='v-tac-tax-clinic' />
                                    <img className="REQImg" src={Slide4} alt="v-tac-tax" title='v-tac-tax' />
                                </div>
                            </Col>
                        </Row>

                    </Container>

                </Container>
            </Container>
        </Fragment>
    );
}


export default RequestQuote;