import React, { Component, Fragment } from 'react';
import SendQuotationSms from '../components/SendQuotationSms';
import PageTop from '../components/PageTop';
import VtacQuote from '../components/VtacQuote';
import Footer from '../components/Footer';
import NewTopNavigation from '../components/NewTopNavigation';

class SendQuotationSmsPage extends Component {
    render() {
        return (
            <Fragment>
            <NewTopNavigation title="Request-Success | The Professionals' Professional" />
            <PageTop topPageAccSoftTitle=""/>
            <SendQuotationSms />
            <VtacQuote />
             <Footer />
           </Fragment>
        );
    }
}

export default SendQuotationSmsPage;