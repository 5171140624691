import React, { Component, Fragment } from 'react';
import PayrollProcessingSection from '../components/PayrollProcessingSection';
import PageTop from '../components/PageTop';
import VtacQuote from '../components/VtacQuote';
import Footer from '../components/Footer';
import NewTopNavigation from '../components/NewTopNavigation';
class PayrollProcessingPage extends Component {
    render() {
        return (
            <Fragment>
                <NewTopNavigation title="Payroll Processing-V-Tac Accounting & Tax | The Professionals' Professional" />
                <PageTop topPageTitle="Payroll Processing" topPagesubTitle="Rather than waste valuable time and resources in organizing your own payroll, why not let us handle it. In doing so, you can devote more time to creative financial pursuits to strengthen productivity and maximize profit. We aim to satisfy your demands with a dependable, accurate and convenient payroll service." />
                <PayrollProcessingSection />
                <VtacQuote />
                <Footer />
            </Fragment>
        );
    }
}

export default PayrollProcessingPage;