import React, { Component, Fragment } from 'react';
import TopSlideShow from '../components/TopSlideShow';
import AboutVtac from '../components/AboutVtac';
import Summary from '../components/Summary';
import Services from '../components/Services';
import Client from '../components/Client';
import GetInTouch from '../components/GetInTouch';
import ClientFeedback from '../components/ClientFeedback';
import VtacQuote from '../components/VtacQuote';
import Footer from '../components/Footer';
import NewTopNavigation from '../components/NewTopNavigation';
class HomePage extends Component {
  render() {
    return (
      <Fragment>
        <NewTopNavigation title="Home-V-Tac Accounting & Tax | The Professionals' Professional" />
        <TopSlideShow />
        <AboutVtac />
        <Services />
        <Summary />
        <Client />
        <GetInTouch />
        <ClientFeedback />
        <VtacQuote />
        <Footer />
      </Fragment>
    );
  }
}

export default HomePage;