import React, { Component, Fragment } from 'react';
import PageTop2 from '../components/PageTop2';
import VtacQuote from '../components/VtacQuote';
import Footer from '../components/Footer';
import NewTopNavigation from '../components/NewTopNavigation';
import GoogleReview from '../components/GoogleReview';

class GoogleReviewPage extends Component {
    render() {
        return (
            <Fragment>
                <NewTopNavigation title="Clients-review-V-Tac Accounting & Tax | The Professionals' Professional" />
                <PageTop2 topPageaAuthor="by Luqman Haley" topPageTitle="Clients Review" topPagesubTitle='"I have been coming to V-TAC accounting at this location for about 20 years Without any problem whatsoever The best in the West."' />
                <GoogleReview />
                <VtacQuote />
                <Footer />
            </Fragment>
        );
    }
}

export default GoogleReviewPage;