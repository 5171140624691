import React, { Component, Fragment } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import TAXTiis from '../asset/images/TAX Tiis.png';
import TAXRATE from '../asset/images/TAX RATE.png';
import house from '../asset/images/house.jpg';
import TAXDates from '../asset/images/TAX Dates.png';
import taximplication1 from '../asset/images/tax-implication1.webp';
import Studenttax from '../asset/images/Student tax.png';
import taxdeduction4 from '../asset/images/tax-deduction-4.png';
import taxdeduction2 from '../asset/images/tax-deduction-2.png';
import tipsfsttimefiling2 from '../asset/images/5tips-fst-time-filing2.webp';
// import taxinmultiplestate from '../asset/images/tax-in-multiple-state.png';
import taxinmultiplestate from '../asset/images/essential- points-to-understand-1.png';
import essentialpointstounderstand2 from '../asset/images/essential- points-to-understand-2.png';

class BlogPart11 extends Component {
    render() {
        return (
            <Fragment>
                <Container>
                    <Row>
                        <Col lg={8} md={6} sm={12}>
                            <div className='blogDetailDiv'>
                                <h2 className='blogTopTitle fs-1'>6 essential points to understand regarding capital gains</h2>

                                <p className='blogDesc'>If you've recently sold a property for a higher value than what you originally bought it for or if the value of your stocks increased from your initial investment, then congratulations on earning a profit from your investment! However, it's important to keep in mind that you may be required to report a capital gain on your tax return.</p>
                                <p className='blogDesc'>If you sell certain investments such as shares, bonds, debts, lands or buildings at a profit, you will have what's called a capital gain. It is important to report this amount on Schedule 3 of your tax return. To help you understand how capital gains can impact your tax situation, here are six important things to know.</p>
                                <img variant="top" className='blog1PageImg2 mt-4 mb-5' src={essentialpointstounderstand2} alt="essential-points-to-understand" />


                                <ul className='blogDesc' style={{ listStyle: "order" }}>
                                    <li><strong>Discover the taxation of capital gains:</strong>  When it comes to capital gains, 50% of the profit you make from selling an investment is subject to taxation. The amount of tax you pay is determined by your annual income, as your personal tax rate is applied to the total amount of income reported. The higher your income tax bracket, the more tax you'll owe on your capital gains. To illustrate, if you sold a building for $500,000 after buying it for $400,000, your profit would be $100,000. As 50% of that profit is taxable, you would report a taxable capital gain of $50,000 and your personal tax rate would be applied to your total income reported to determine your tax liability.</li>
                                    <li><strong>Distinguish between capital gains and losses:</strong> When you sell an investment for less than what you paid for it, you incur a capital loss. You can offset this loss against any capital gains you earned during the year, which can help to reduce your tax liability. If your capital losses exceed your capital gains, you'll have unused losses. These unused losses can be carried back up to 3 years to offset taxable gains in those years, or carried forward to offset future taxable gains.</li>
                                    <li><strong>Prepare your documents:</strong> When reporting capital gains, it is essential to possess documents that pertain to the acquisition (when you bought the investment) and disposition (when you sold or transferred it) of the investment. It is important to keep detailed records of information such as:

                                        <ul className='blogDesc' >
                                            <li>The purchase price</li>
                                            <li>The date of the investment purchase.</li>
                                            <li>Any applicable fees, including commissions and other related expenses.</li>
                                            <li>The adjusted cost base (ACB), which includes the cost of the investment and all expenses associated with its acquisition, such as commissions, legal fees, and any additional upgrades or enhancements.</li>
                                            <li>For stocks, it is necessary to maintain records of the number of shares sold, along with the name of the fund or corporation and the type of shares.</li>
                                            <p className='blogDesc'><strong>You may need to refer to multiple documents to obtain the necessary information, which means you will have several papers to keep track of:</strong> </p>

                                            <li>In the case of selling Canadian securities such as shares or mutual funds, your broker will provide you with a T5008 slip (or an RL-18 slip if you reside in Québec), which you will need to report on your tax return. Your broker will also send a copy of the slip to the Canada Revenue Agency (CRA) and Revenu Québec (if applicable) on your behalf.</li>
                                            <li>In case you sold a building or a plot of land, you will not receive any information slip. Nevertheless, it is essential to maintain all the documents relevant to the sale, as the Canada Revenue Agency (CRA) or Revenu Québec may request to inspect them.</li>
                                            <li>In case you are a beneficiary of an estate or possess mutual funds that you have not sold yet, you will receive a T3 slip (or an RL-16 slip, if you are a Québec resident) that will report the income from your trust.</li>
                                            <li>In case you own mutual funds through a corporation rather than a trust, you will receive a T5 slip (or an RL-3 slip for Québec residents).</li>
                                            <li>When you're a member of a business partnership, you'll receive a slip called T5013 (or RL-15 for Québec residents) from the partnership. This slip will report your share of the partnership income or loss.</li>
                                        </ul>

                                    </li>
                                    <p>It is important to have all your documents organized when it comes time to file your taxes. Keeping records of all the necessary information for at least 6 years can save you in case the CRA or Revenu Québec request to see them, especially if your return is selected for a detailed review.</p>

                                    <img variant="top" className='blog1PageImg2 mt-4 mb-5' src={taxinmultiplestate} alt="essential-points-to-understand" />

                                    <li><strong>Establish whether your gain is the result of a sale or a gift:</strong> If you gift your investment to someone who is not your spouse, or sells it to a family member who is not your spouse at a price lower than its fair market value (FMV), you will be taxed based on the same amount of capital gains that you would have earned if you had sold the investment for its FMV, which is the highest price at which the investment is valued in Canadian dollars. This implies that even if you sell or gift the investment for a lower price, you may still have to pay tax on a higher amount of capital gains. When you sell your investment to someone who is not your spouse or family member for less than its value, the capital gains will be based on the actual sale price. For instance, if you purchased a building for $400,000 and its fair market value (FMV) is currently $600,000, your capital gain will depend on how much you sell it for. Here are four possible scenarios to illustrate this:</li>

                                    <p className='blogDesc'><strong style={{ color: "#005BAA" }}>You sell the building for its FMV:</strong> Assuming that you sell the building for its fair market value (FMV) of $600,000, your capital gain that is subject to tax will be $100,000.</p>
                                    <p className='blogDesc'><strong style={{ color: "#005BAA" }}>You sell the building to a family member:</strong> Selling your investment to a family member for less than its FMV means that the government will consider your investment sold for its FMV. In this case, even if you sold the building for $500,000, your taxable capital gain will still be $100,000 based on the FMV of $600,000.</p>
                                    <p className='blogDesc'><strong style={{ color: "#005BAA" }}>property is being gifted to the family member:</strong> Transferring the ownership of the building to a family member as a gift would be deemed as selling the investment at its fair market value by the government. Consequently, you would still be liable for the capital gain tax of $100,000 on the property.</p>
                                    <p className='blogDesc'><strong style={{ color: "#005BAA" }}>If the building is sold to an individual who is not a member of your family:</strong> If the building is sold to an individual who is not a member of your family: If the building is sold to a non-family member for $500,000, the government will calculate the taxable capital gain based on the actual selling price. This implies that you would be liable for a capital gain tax of $50,000.</p>


                                    <li><strong>Determine whether you are eligible to divide the capital gain with your spouse:</strong> Typically, it is not possible to divide capital gains with your spouse or common-law partner to decrease your tax obligations due to the Canada Revenue Agency's attribution rules. These rules indicate that if you transfer an investment to your spouse, you would be responsible for reporting any capital gains or losses that emerge from the transfer on your tax return, not your spouse.<br></br>
                                        If you and your spouse jointly bought an investment, the capital gains would be divided based on the proportion of each person's investment. For example, if both of you paid an equal amount towards purchasing a cottage a few years ago, then the resulting capital gains from selling the property would be split equally between both of you.

                                    </li>
                                    <li><strong>Develop a strategy to manage your capital gain:</strong> If you anticipate that you will sell your investment at a higher price than the purchase price, it is possible to implement pre-emptive measures to reduce the tax liability on your gains. For instance, selling underperforming stocks can offset the gains and result in a capital loss. Alternatively, increasing your contribution to an RRSP can reduce your tax payable for the year.<br></br>
                                        If your income varies, it is possible to report your capital gain during a year when your income is lower than normal. Doing so can result in being taxed in a lower tax bracket, which could lead to a reduced tax liability on your capital gain during the time of filing.

                                    </li>
                                </ul>

                                <p className='blogDesc'>Are you still unsure about how to report your capital gains or losses? Talk to our tax consultant, Book an <Link to="/contact"> appointment!</Link>
                                </p>

                            </div>
                        </Col>

                        <Col lg={4} md={6} sm={12}>
                            <div className='relatedArticle'>
                                <h2 className='fs-3 '>Related Articles</h2>
                                <hr style={{ width: "100%", float: "left" }}></hr>
                            </div>
                            <Card className='blogCardRight' >
                                <Link to="/ways-to-stay-ahead-of-tax-season" style={{ textDecoration: "none" }}>
                                    <div>
                                        <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                        <Card.Img variant="top" className='industryImg' src={TAXTiis} />
                                    </div>

                                    <Card.Body>
                                        <Card.Title className='blogCardRightTitle'>8 straight ways to stay ahead of tax season 2023</Card.Title>
                                    </Card.Body>
                                </Link>
                            </Card>
                            <Card className='blogCardRight float-right'>
                                <Link to="/federal-tax-brackets-rates-credits" style={{ textDecoration: "none" }}>
                                    <div>
                                        <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                        <Card.Img variant="top" className='industryImg' src={TAXRATE} />
                                    </div>

                                    <Card.Body>
                                        <Card.Title className='blogCardRightTitle'>Federal Tax Brackets, Rates & Credits 2023</Card.Title>
                                    </Card.Body>
                                </Link>
                            </Card>
                            <Card className='blogCardRight float-right'>
                                <Link to="/housing-benefit-and-the-way-to-apply" style={{ textDecoration: "none" }}>
                                    <div>
                                        <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                        <Card.Img variant="top" className='industryImg' src={house} />
                                    </div>

                                    <Card.Body>
                                        <Card.Title className='blogCardRightTitle'>Are you a renter in Canada? Learn about the Housing Benefit and the way to apply for it. </Card.Title>
                                    </Card.Body>
                                </Link>
                            </Card>

                            <Card className='blogCardRight float-right'>
                                <Link to="/important-tax-deadlines" style={{ textDecoration: "none" }}>
                                    <div>
                                        <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                        <Card.Img variant="top" className='industryImg' src={TAXDates} />
                                    </div>

                                    <Card.Body>
                                        <Card.Title className='blogCardRightTitle'>Important Tax Deadlines 2023</Card.Title>
                                    </Card.Body>
                                </Link>
                            </Card>
                            <Card className='blogCardRight'>
                                <Link to="/tips-for-tax-students" style={{ textDecoration: "none" }}>
                                    <div>
                                        <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                        <Card.Img variant="top" className='industryImg' src={Studenttax} />
                                    </div>

                                    <Card.Body>
                                        <Card.Title className='blogCardRightTitle'>Maximizing Your 2022 Return: Tips for Students</Card.Title>
                                    </Card.Body>
                                </Link>
                            </Card>
                            <Card className='blogCardRight float-right'>
                                <Link to="/tax-implications-for-having-multiple-employers" style={{ textDecoration: "none" }}>
                                    <div>
                                        <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                        <Card.Img variant="top" className='industryImg' src={taximplication1} />
                                    </div>

                                    <Card.Body>
                                        <Card.Title className='blogCardRightTitle'>Tax implications for having multiple employers</Card.Title>
                                    </Card.Body>
                                </Link>
                            </Card>
                            <Card className='blogCardRight float-right'>
                                <Link to="/tips-for-a-great-first-time-filing-experience" style={{ textDecoration: "none" }}>
                                    <div>
                                        <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                        <Card.Img variant="top" className='industryImg' src={tipsfsttimefiling2} />
                                    </div>

                                    <Card.Body>
                                        <Card.Title className='blogCardRightTitle'>5 tips for a great first-time filing experience</Card.Title>
                                    </Card.Body>
                                </Link>
                            </Card>
                            <Card className='blogCardRight float-right'>
                                <Link to="/how-to-filing-taxes-in-multiple-states-when-moved" style={{ textDecoration: "none" }}>
                                    <div>
                                        <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                        <Card.Img variant="top" className='industryImg' src={taxinmultiplestate} />
                                    </div>

                                    <Card.Body>
                                        <Card.Title className='blogCardRightTitle'>How to Filing Taxes in Multiple States When Moved</Card.Title>
                                    </Card.Body>
                                </Link>
                            </Card>

                        </Col>
                    </Row>
                </Container>
            </Fragment>
        );
    }
}

export default BlogPart11;