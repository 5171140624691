import React, { Component, Fragment } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import VTacTax from '../asset/images/VTac-Tax.jpg';
import VTacTax1 from '../asset/images/vtac-tax-photo13.jpg';
import VTacTax2 from '../asset/images/efile.jpg';
import VTacTax3 from '../asset/images/business-tax.jpg';
import VTacTax4 from '../asset/images/Corporate-Tax2.jpg';

class TaxationSection extends Component {
    componentDidMount() {
        document.getElementById("div1").style.display = "block";
        document.getElementById("div2").style.display = "none";
        document.getElementById("div3").style.display = "none";
        document.getElementById("div4").style.display = "none";
    }

    FAQnavHandleClick(id) {

        if (id === "nav1") {
            document.getElementById("div1").style.display = "block";
            document.getElementById("div2").style.display = "none";
            document.getElementById("div3").style.display = "none";
            document.getElementById("div4").style.display = "none";
        }
        else if (id === 'nav2') {
            document.getElementById("div1").style.display = "none";
            document.getElementById("div2").style.display = "block";
            document.getElementById("div3").style.display = "none";
            document.getElementById("div4").style.display = "none";
        }
        else if (id === 'nav3') {
            document.getElementById("div3").style.display = "block";
            document.getElementById("div1").style.display = "none";
            document.getElementById("div2").style.display = "none";
            document.getElementById("div4").style.display = "none";
        }
        else if (id === 'nav4') {
            document.getElementById("div1").style.display = "none";
            document.getElementById("div2").style.display = "none";
            document.getElementById("div3").style.display = "none";
            document.getElementById("div4").style.display = "block";
           


        }
    }

    handleClick(id) {
        var coll = document.getElementById(id);
        coll.classList.toggle("Nactive");
        var content = coll.nextElementSibling;
        if (content.style.maxHeight) {
            content.style.maxHeight = null;
        } else {
            content.style.maxHeight = content.scrollHeight + "px";
        }
    }

    render() {
        return (
            <Fragment>
                <Container fluid={true} className="accContainer">

                    <Container className='accSection'>
                        <div>
                            <h2 className='accTitle'>
                                Taxation</h2>
                        </div>
                        <div>
                            <p className='accSubtitle'>To excel in today’s murky economy, V-Tac maintains a competitive advantage by offering reputable knowledge and staying up to date with new tax laws. Our tax professionals are trained to properly assess and file your income tax returns so that you receive the most benefit from deductions and credits.</p>
                        </div>
                        <div className='mt-5'></div>
                        <Row>
                            <Col lg={6} md={6} sm={12} >
                                <div id="container">
                                    <img id="image" className='img-fluid' style={{width:"100%", height:"auto"}} title='V-TAC Tax' alt="V-TAC Tax" src={VTacTax} />
                                </div>
                            </Col>
                            <Col lg={6} md={6} sm={12}>
                                <p className='accSubtitle'>To excel in today’s murky economy, V-Tac maintains a competitive advantage by offering reputable knowledge and staying up to date with new tax laws. V-Tac provides comprehensible and accurate tax services to individuals, self-employed persons, growing businesses, and corporations.</p>
                                <p className='accSubtitle'>Our <span className='spancolor'>tax professionals</span> are trained to properly assess and file your income tax returns so that you receive the most benefit from deductions and credits. We analyze tax files, provide suitable advice, develop ethical business strategies, and sustain a close bond with federal and provincial tax authorities to make business negotiations on your behalf.</p>
                            </Col>
                        </Row>
                       
                        <Container>
                            <Row>
                                <Col lg={12} md={12} sm={12}>
                                    <p className='description'>
                                        <span className='spancolor'>Our systems</span>  are designed to quickly process a variety of financial services without interruption to better serve you. In case of an audit, we are prepared to assist you by addressing your concerns and directly speak with auditors to provide the quickest resolution for you. We work hard to make sure our tax services are accurate, timely and most of all, convenient for our clients. We build rapport and strong professional ties with our customers to cultivate future collaborations.
                                    </p>
                                </Col>
                            </Row>
                        </Container>

                        <Container className='mt-5'>
                            <div className='faqnavbar' >
                                <a className='active' id='nav1' onClick={() => this.FAQnavHandleClick('nav1')}>Personal tax</a>
                                <a id='nav2' onClick={() => this.FAQnavHandleClick('nav2')}>E-file</a>
                                <a id='nav3' onClick={() => this.FAQnavHandleClick('nav3')}>BUSINESS TAX</a>
                                <a id='nav4' onClick={() => this.FAQnavHandleClick('nav4')}>CORPORATE TAX</a>
                            </div>
                            <div className='Ncard mt-3' id='div1'>
                                <Container>
                                    <Row>
                                        <Col lg={6} md={12} sm={12}>
                                            <p className='accSubtitle'>V-Tac provides affordable and accurate personal tax services to individuals. We work closely with our clients to minimize costs and increase their tax refund. You can rest assure that your account records will be handled responsibly, accurately, and professionally so as to curtail your chances of being audited.</p>
                                            <p className='accSubtitle'>Our client base is unlimited. We provide useful tax services in the following areas:</p>
                                            <ul className='accSubtitle'>
                                                <li>Employment income</li>
                                                <li>Commissioned employees</li>
                                                <li>Disability and social assistance</li>
                                                <li>Newly landed immigrants and students</li>
                                                <li>Seniors and retirees</li>
                                                <li>Small business owners</li>
                                                <li>To guarantee a fast and accurate tax return, always keep hard copies of your Account related files at hand.</li>
                                            </ul>
                                        </Col>
                                        <Col lg={6} md={12} sm={12}>
                                            <div id="container">
                                                <img id="image" className='img-fluid' title='V-TAC Tax' alt='V-TAC Tax' src={VTacTax1} />
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                            <div className='Ncard mt-3' id='div2'>
                                <Container>
                                    <Row>
                                        <Col lg={6} md={12} sm={12}>
                                            <p className='accSubtitle'>E-File is an automated system that allows registered service providers to submit income tax return information directly to CRA. Majority of Canadians are eligible to use E-File. Usually CRA provides an acceptance file within three hours after submitting income tax information by E-Filer. It is very important to ensure the accuracy of the preparation of tax return for first-time acceptance.</p>
                                            <p className='accSubtitle'><strong>Benefits:</strong></p>
                                            <ul className='accSubtitle'>
                                                <li>Fast refund – 10-14 business days.</li>
                                                <li>Paperless – tax return information is transmitted directly to CRA terminals.</li>
                                                <li>Direct deposit – income tax refund, GST/HST credit payment and Child Tax Benefit could be deposited to taxpayer account directly.</li>
                                                <li>Greater accuracy – series of checks performed by special computer programs and filters.</li>
                                                <li>Taxpayer has to show all necessary documents to E-File service provider and keep all information slips, receipts and other supporting documents used to prepare return for six years (in case CRA asks to see them).</li>
                                            </ul>
                                        </Col>
                                        <Col lg={6} md={12} sm={12} >
                                            <div id="container">
                                                <img id="image" className='img-fluid' title='V-TAC Tax' src={VTacTax2} />
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                            <div className='Ncard mt-3' id='div3'>
                                <Container>
                                    <Row>
                                        <Col lg={6} md={12} sm={12}>
                                            <p className='accSubtitle'><strong>Self-Employed Taxes</strong></p>
                                            <p className='accSubtitle'>If you receive any remuneration for services or products you provide and are not an employee, you are self-employed. Such income can be earned from a business that you solely own or share partnership with. Self-employment income must always be reported on your personal income tax return (T1) and never in your employment income.</p>
                                            <p className='accSubtitle'>A person can report their business income based on a fiscal period, typically around December 31st (year-end). You can deduct expenses made throughout the fiscal year as long as the expenses were directed towards income earning. It is therefore pertinent that you maintain proof of your self-employment expenses to support your claims and avoid tax penalties. A self-employed person should pay any outstanding taxes before April 30th. Self-employed taxes should be submitted to Revenue Canada before June 15th.</p>
                                            <p className='accSubtitle'>Our tax professionals are prepared to handle all your tax filing so that you can devote more time and energy into the growth of your business. We will try our best to make sure that all of your expense claims do not result in unnecessary tax audits.</p>

                                        </Col>
                                        <Col lg={6} md={12} sm={12}>
                                            <div id="container">
                                                <img id="image" className='img-fluid' title='V-TAC Tax' src={VTacTax3} />
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                            <div className='Ncard mt-3' id='div4'>
                                <Container>
                                    <Row>
                                        <Col lg={6} md={12} sm={12}>
                                            <p className='accSubtitle'>Although corporations act as independent financial organisms, they are still required to file a corporate income tax return every year (T2). A corporation must file their corporate income tax return (T2) within 90 days from the end of the fiscal year regardless if there is no tax payable. In the event that a corporation has to pay any outstanding taxes, corporations must either pay it all at once or through a negotiated installment plan created in advance.</p>
                                            <p className='accSubtitle'>It also has to attach complete financial statements, GIFI, and the necessary schedules to the T2 return. Corporations can choose any fiscal ending date for the first year in business.</p>
                                            <p className='accSubtitle'>As a company, it is important to keep in mind that the correctness of a corporate income tax return depends largely on the structure and accuracy of its bookkeeping records. For this reason, V-Tac strives to help company’s maintain a solid foundation in bookkeeping. We are confident that our services will provide you with sound knowledge and diligence to prepare a solid corporate income tax return.</p>
                                            <p className='accSubtitle'>Our professionals have the knowledge and experience to minimize corporate tax payable amounts. Corporate tax planning takes into account a substantial amount of taxes, including income tax, payroll tax and capital tax. We will, of course, organize your tax return and deal with the Canada Revenue Agency as necessary. At V-Tac, we can help you analyze the tax impact of business decisions and select the best formation for your projected transactions and new investments.</p>
                                        </Col>
                                        <Col lg={6} md={12} sm={12}>
                                            <div id="container">
                                                <img id="image" className='img-fluid' title='V-TAC Tax' src={VTacTax4} />
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </Container>
                    </Container>

                </Container>
            </Fragment>
        );
    }
}

export default TaxationSection;