import React, { Component, Fragment } from 'react';
import { Navbar, Nav, Row, Col, Container } from 'react-bootstrap';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import ZaynMediaInc from '../asset/images/Zayn_Media_Inc.jpg';
import ZaomalConsultant from '../asset/images/Zaomal-Consultant.jpg';
import yorklionssteelband from '../asset/images/york-lions-steel-band.jpg';
import WillyTransportLtd from '../asset/images/Willy-Transport-Ltd.jpg';
import walkersorensen from '../asset/images/walker-sorensen.jpg';
import TrisanGenrealContractingInc from '../asset/images/Trisan-Genreal-Contracting-Inc.jpg';
import torontohawkssoccer from '../asset/images/toronto-hawks-soccer.jpg';
import tastypita from '../asset/images/tasty-pita.jpg';
import sourcemomenthealthcare from '../asset/images/source-moment-health-care.jpg';
import SolutionsConsultingLtd from '../asset/images/Solutions-Consulting-Ltd.jpg';
import SolutionRecycling from '../asset/images/Solution-Recycling.jpg';
import royalpioneerdoorandwindow from '../asset/images/royal-pioneer-door-and-window.jpg';
import ResourceRecycling from '../asset/images/Resource-Recycling.jpg';
import raywhite from '../asset/images/ray-white.jpg';
import PriorientCorporation from '../asset/images/Priorient-Corporation.jpg';
import nexusstaffing from '../asset/images/nexus-staffing.jpg';
import mouthpublicity from '../asset/images/mouth-publicity.jpg';
import mollymaid from '../asset/images/molly_maid.jpg';
import MiracleGraphicsCanada from '../asset/images/Miracle-Graphics-Canada.jpg';
import MBAStaffingSolution from '../asset/images/MBA-Staffing-Solution.jpg';
import MBADriverServices from '../asset/images/MBA-Driver-Services.jpg';
import mamaspizza from '../asset/images/mamaspizza.jpg';
import macshowsystems from '../asset/images/mac-show-systems.jpg';
import KroumvsInstruments from '../asset/images/Kroumvs-Instruments.jpg';
import korbitec from '../asset/images/korbitec.jpg';
import KFC from '../asset/images/KFC.jpg';
import jwcarcare from '../asset/images/jw-carcare.jpg';
import JaneBloorRestaurant from '../asset/images/Jane-Bloor-Restaurant.jpg';
import InteriorRenovationsSuppliesLtd from '../asset/images/Interior-Renovations-Supplies-Ltd.jpg';
import herocertifiedburgers from '../asset/images/hero-certified-burgers.jpg';
import GoExpress from '../asset/images/Go-Express.jpg';
import GabersCorporation from '../asset/images/Gabers-Corporation.jpg';
import funrise from '../asset/images/funrise.jpg';
import FullTruth from '../asset/images/Full-Truth-Church-Of-God-Deliverance-Centre-Inc..jpg';
import fentonsmithlaw from '../asset/images/fenton-smith-law.jpg';
import fakebeauty from '../asset/images/fake-beauty.jpg';
import esso from '../asset/images/esso.jpg';
import DRDorarMed from '../asset/images/DR.-Dorar-Med-Care-Inc.jpg';
import DengisConstructionLtd from '../asset/images/Dengis-Construction-Ltd.jpg';
import DairyGrill from '../asset/images/Dairy-Dell-Bar-and-Grill.jpg';
import DMAutomotive from '../asset/images/D-M-Automotive.jpg';
import chellseyinstitute from '../asset/images/chellsey-institute.jpg';
import chefofindia from '../asset/images/chef-of-india.jpg';
import BKAluminumLtd from '../asset/images/BK-Aluminum-Ltd.jpg';
import altamiramaintenance from '../asset/images/altamira-maintenance..jpg';
import AlliEnterprise from '../asset/images/Alli-Enterprise.jpg';
import aitasjanapeseschool from '../asset/images/aitas-janapese-school.jpg';
import AiAEngineersLtd from '../asset/images/AiA-Engineers-Ltd.jpg';
import affyspremiumgrill from '../asset/images/affys-premium-grill.jpg';
import ATRPropertyHolding from '../asset/images/A-T-R-Property-Holding-Inc..jpg';
import spices from '../asset/images/39-spices.jpg';
import spaceageclosets from '../asset/images/spaceage-closets.jpg';



class Client extends Component {
  render() {
    var settings = {
      dots: false,
      arrows: true,
      infinite: true,
      speed: 6000,
      slidesToShow: 7,
      slidesToScroll: 5,
      autoplay: true,
      autoplaySpeed: 3000,


      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 6,
            slidesToScroll: 6,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        }
      ]
    };
    return (
      <Fragment>

        <Container fluid={true} className="sectionBetween">
          <div data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration="1000"
            data-aos-once="true"><h2 className='serviceMainTitle text-center mb-5'>Our Clients</h2>
          </div>
          <div className='sectionBetween'>
            <Row>
              <Col>
                <Slider {...settings}>
                  <div>
                    <Row>
                      <Col >
                        <a> <img className="allClientImage" src={ZaynMediaInc} /></a>
                      </Col>
                    </Row>
                  </div>
                  <div>
                    <Row>
                      <Col >
                        <a> <img className="allClientImage" src={ZaomalConsultant} /></a>
                      </Col>
                    </Row>
                  </div>
                  <div>
                    <Row>
                      <Col >
                        <a> <img className="allClientImage" src={yorklionssteelband} /></a>
                      </Col>
                    </Row>
                  </div>
                  <div>
                    <Row>
                      <Col>
                        <a> <img className="allClientImage" src={WillyTransportLtd} /></a>
                      </Col>
                    </Row>
                  </div>
                  <div>
                    <Row>
                      <Col>
                        <a> <img className="allClientImage" src={walkersorensen} /></a>
                      </Col>
                    </Row>
                  </div>
                  <div>
                    <Row>
                      <Col>
                        <a> <img className="allClientImage" src={TrisanGenrealContractingInc} /></a>
                      </Col>
                    </Row>
                  </div>
                  <div>
                    <Row>
                      <Col>
                        <a> <img className="allClientImage" src={torontohawkssoccer} /></a>
                      </Col>
                    </Row>
                  </div>
                  <div>
                    <Row>
                      <Col>
                        <a> <img className="allClientImage" src={tastypita} /></a>
                      </Col>
                    </Row>
                  </div>
                  <div>
                    <Row>
                      <Col>
                        <a> <img className="allClientImage" src={sourcemomenthealthcare} /></a>
                      </Col>
                    </Row>
                  </div>
                  <div>
                    <Row>
                      <Col>
                        <a> <img className="allClientImage" src={SolutionsConsultingLtd} /></a>
                      </Col>
                    </Row>
                  </div>
                  <div>
                    <Row>
                      <Col>
                        <a> <img className="allClientImage" src={SolutionRecycling} /></a>
                      </Col>
                    </Row>
                  </div>
                  <div>
                    <Row>
                      <Col>
                        <a> <img className="allClientImage" src={royalpioneerdoorandwindow} /></a>
                      </Col>
                    </Row>
                  </div>
                  <div>
                    <Row>
                      <Col>
                        <a> <img className="allClientImage" src={ResourceRecycling} /></a>
                      </Col>
                    </Row>
                  </div>
                  <div>
                    <Row>
                      <Col>
                        <a> <img className="allClientImage" src={raywhite} /></a>
                      </Col>
                    </Row>
                  </div>
                  <div>
                    <Row>
                      <Col>
                        <a> <img className="allClientImage" src={PriorientCorporation} /></a>
                      </Col>
                    </Row>
                  </div>
                  <div>
                    <Row>
                      <Col>
                        <a> <img className="allClientImage" src={nexusstaffing} /></a>
                      </Col>
                    </Row>
                  </div>
                  <div>
                    <Row>
                      <Col>
                        <a> <img className="allClientImage" src={mouthpublicity} /></a>
                      </Col>
                    </Row>
                  </div>
                  <div>
                    <Row>
                      <Col>
                        <a> <img className="allClientImage" src={mollymaid} /></a>
                      </Col>
                    </Row>
                  </div>
                  <div>
                    <Row>
                      <Col>
                        <a> <img className="allClientImage" src={MiracleGraphicsCanada} /></a>
                      </Col>
                    </Row>
                  </div>
                  <div>
                    <Row>
                      <Col>
                        <a> <img className="allClientImage" src={MBAStaffingSolution} /></a>
                      </Col>
                    </Row>
                  </div>
                  <div>
                    <Row>
                      <Col>
                        <a> <img className="allClientImage" src={MBADriverServices} /></a>
                      </Col>
                    </Row>
                  </div>
                  <div>
                    <Row>
                      <Col>
                        <a> <img className="allClientImage" src={mamaspizza} /></a>
                      </Col>
                    </Row>
                  </div>
                  <div>
                    <Row>
                      <Col>
                        <a> <img className="allClientImage" src={macshowsystems} /></a>
                      </Col>
                    </Row>
                  </div>
                  <div>
                    <Row>
                      <Col>
                        <a> <img className="allClientImage" src={KroumvsInstruments} /></a>
                      </Col>
                    </Row>
                  </div>
                  <div>
                    <Row>
                      <Col>
                        <a> <img className="allClientImage" src={korbitec} /></a>
                      </Col>
                    </Row>
                  </div>
                  <div>
                    <Row>
                      <Col>
                        <a> <img className="allClientImage" src={KFC} /></a>
                      </Col>
                    </Row>
                  </div>
                  <div>
                    <Row>
                      <Col>
                        <a> <img className="allClientImage" src={jwcarcare} /></a>
                      </Col>
                    </Row>
                  </div>
                  <div>
                    <Row>
                      <Col>
                        <a> <img className="allClientImage" src={JaneBloorRestaurant} /></a>
                      </Col>
                    </Row>
                  </div>
                  <div>
                    <Row>
                      <Col>
                        <a> <img className="allClientImage" src={InteriorRenovationsSuppliesLtd} /></a>
                      </Col>
                    </Row>
                  </div>
                  <div>
                    <Row>
                      <Col>
                        <a> <img className="allClientImage" src={herocertifiedburgers} /></a>
                      </Col>
                    </Row>
                  </div>
                  <div>
                    <Row>
                      <Col>
                        <a> <img className="allClientImage" src={GoExpress} /></a>
                      </Col>
                    </Row>
                  </div>
                  <div>
                    <Row>
                      <Col>
                        <a> <img className="allClientImage" src={GabersCorporation} /></a>
                      </Col>
                    </Row>
                  </div>
                  <div>
                    <Row>
                      <Col>
                        <a> <img className="allClientImage" src={funrise} /></a>
                      </Col>
                    </Row>
                  </div>
                  <div>
                    <Row>
                      <Col>
                        <a> <img className="allClientImage" src={FullTruth} /></a>
                      </Col>
                    </Row>
                  </div>
                  <div>
                    <Row>
                      <Col>
                        <a> <img className="allClientImage" src={fentonsmithlaw} /></a>
                      </Col>
                    </Row>
                  </div>
                  <div>
                    <Row>
                      <Col>
                        <a> <img className="allClientImage" src={fakebeauty} /></a>
                      </Col>
                    </Row>
                  </div>
                  <div>
                    <Row>
                      <Col>
                        <a> <img className="allClientImage" src={esso} /></a>
                      </Col>
                    </Row>
                  </div>
                  <div>
                    <Row>
                      <Col>
                        <a> <img className="allClientImage" src={DRDorarMed} /></a>
                      </Col>
                    </Row>
                  </div>
                  <div>
                    <Row>
                      <Col>
                        <a> <img className="allClientImage" src={DengisConstructionLtd} /></a>
                      </Col>
                    </Row>
                  </div>
                  <div>
                    <Row>
                      <Col>
                        <a> <img className="allClientImage" src={DairyGrill} /></a>
                      </Col>
                    </Row>
                  </div>
                  <div>
                    <Row>
                      <Col>
                        <a> <img className="allClientImage" src={DMAutomotive} /></a>
                      </Col>
                    </Row>
                  </div>
                  <div>
                    <Row>
                      <Col>
                        <a> <img className="allClientImage" src={chellseyinstitute} /></a>
                      </Col>
                    </Row>
                  </div>
                  <div>
                    <Row>
                      <Col>
                        <a> <img className="allClientImage" src={chefofindia} /></a>
                      </Col>
                    </Row>
                  </div>
                  <div>
                    <Row>
                      <Col>
                        <a> <img className="allClientImage" src={BKAluminumLtd} /></a>
                      </Col>
                    </Row>
                  </div>
                  <div>
                    <Row>
                      <Col>
                        <a> <img className="allClientImage" src={altamiramaintenance} /></a>
                      </Col>
                    </Row>
                  </div>
                  <div>
                    <Row>
                      <Col>
                        <a> <img className="allClientImage" src={AlliEnterprise} /></a>
                      </Col>
                    </Row>
                  </div>
                  <div>
                    <Row>
                      <Col>
                        <a> <img className="allClientImage" src={aitasjanapeseschool} /></a>
                      </Col>
                    </Row>
                  </div>
                  <div>
                    <Row>
                      <Col>
                        <a> <img className="allClientImage" src={AiAEngineersLtd} /></a>
                      </Col>
                    </Row>
                  </div>
                  <div>
                    <Row>
                      <Col>
                        <a> <img className="allClientImage" src={affyspremiumgrill} /></a>
                      </Col>
                    </Row>
                  </div>
                  <div>
                    <Row>
                      <Col>
                        <a> <img className="allClientImage" src={ATRPropertyHolding} /></a>
                      </Col>
                    </Row>
                  </div>
                  <div>
                    <Row>
                      <Col>
                        <a> <img className="allClientImage" src={spices} /></a>
                      </Col>
                    </Row>
                  </div>
                  <div>
                    <Row>
                      <Col>
                        <a> <img className="allClientImage" src={spaceageclosets} /></a>
                      </Col>
                    </Row>
                  </div>
                </Slider>
              </Col>
            </Row>
          </div>
        </Container>
      </Fragment>
    );
  }
}

export default Client;