import React, { Component, Fragment } from 'react';
import FAQ from '../components/FAQ';
import PageTop from '../components/PageTop';
import VtacQuote from '../components/VtacQuote';
import Footer from '../components/Footer';
import NewTopNavigation from '../components/NewTopNavigation';

class FAQPage extends Component {
    render() {
        return (
            <Fragment>
                <NewTopNavigation title="FAQs-V-Tac Accounting & Tax | The Professionals' Professional" />
                <PageTop topPageAccSoftTitle="Frequently Asked Questions" />
                <FAQ />
                <VtacQuote />
                <Footer />
            </Fragment>
        );
    }
}

export default FAQPage;