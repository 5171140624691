import React, { Component, Fragment } from 'react';
import BlogTop from '../components/BlogTop';
import VtacQuote from '../components/VtacQuote';
import Footer from '../components/Footer';
import NewTopNavigation from '../components/NewTopNavigation';
import BlogPart3 from '../components/BlogPart3';
import SearchByWord from '../components/SearchByWord';

class BlogPart3Page extends Component {
    render() {
        return (
            <Fragment>
                <NewTopNavigation title="Federal Tax Brackets, Rates & Credits 2023-V-Tac Accounting & Tax | The Professionals' Professional" />
                <BlogTop />
                <SearchByWord/>
                <BlogPart3/>
                <VtacQuote />
                <Footer />
            </Fragment>
        );
    }
}

export default BlogPart3Page;