import React, { Component, Fragment } from 'react';
import { Row, Col, Container, Card } from 'react-bootstrap';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Link } from 'react-router-dom';

import TAXTiis from '../asset/images/TAX Tiis.png';
import TAXRATE from '../asset/images/TAX RATE.png';
import house from '../asset/images/house.jpg';
import TAXDates from '../asset/images/TAX Dates.png';
import taximplication1 from '../asset/images/tax-implication1.webp';
import Studenttax from '../asset/images/Student tax.png';
import taxdeduction2 from '../asset/images/tax-deduction-2.png';
import fixincome from '../asset/images/fix-income.jpg';


class BlogSlide extends Component {
    render() {
        var settings = {
            slidesToShow: 5,
            slidesToScroll: 1,
            infinite: true,
            autoplay: true,
            speed: 10000,
            autoplaySpeed: 0,
            cssEase: 'linear',
            arrows: false,


            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 5,
                        slidesToScroll: 5,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 950,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        return (
            <Fragment>

                <Container fluid className="sectionBetween" style={{ backgroundColor: "rgb(248,154,27,.9)" }}>
                    <Container className='pt-4 pb-5' >
                        <div data-aos="fade-down"
                            data-aos-easing="linear"
                            data-aos-duration="1000"
                            data-aos-once="true"><p className='fs-2 fw-bold text-center' style={{color:"white"}}>Our Key Guidelines & Tips</p>
                        </div>
                        <div>
                            <Row className='mt-5'>
                                <Col>
                                    <Slider {...settings}>
                                        <div>
                                            <Row>
                                                <Col>
                                                    <Card className='blogCardforSlide' >
                                                        <Link to="/ways-to-stay-ahead-of-tax-season" style={{ textDecoration: "none" }}>
                                                            <div>
                                                                <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                                                <Card.Img variant="top" className='blog-slide-image' src={TAXTiis} />
                                                            </div>

                                                            <Card.Body>
                                                                <Card.Title className='blogCardRightTitle'>8 straight ways to stay ahead of tax season 2023</Card.Title>
                                                            </Card.Body>
                                                        </Link>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div>
                                            <Row>
                                                <Col>
                                                    <Card className='blogCardforSlide'>
                                                        <Link to="/federal-tax-brackets-rates-credits" style={{ textDecoration: "none" }}>
                                                            <div>
                                                                <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                                                <Card.Img variant="top" className='blog-slide-image' src={TAXRATE} />
                                                            </div>

                                                            <Card.Body>
                                                                <Card.Title className='blogCardRightTitle'>Federal Tax Brackets, Rates & Credits 2023</Card.Title>
                                                            </Card.Body>
                                                        </Link>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div>
                                            <Row>
                                                <Col>
                                                    <Card className='blogCardforSlide'>
                                                        <Link to="/housing-benefit-and-the-way-to-apply" style={{ textDecoration: "none" }}>
                                                            <div>
                                                                <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                                                <Card.Img variant="top" className='blog-slide-image' src={house} />
                                                            </div>

                                                            <Card.Body>
                                                                <Card.Title className='blogCardRightTitle'>Are you a renter in Canada?</Card.Title>
                                                            </Card.Body>
                                                        </Link>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div>
                                            <Row>
                                                <Col>
                                                    <Card className='blogCardforSlide'>
                                                        <Link to="/important-tax-deadlines" style={{ textDecoration: "none" }}>
                                                            <div>
                                                                <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                                                <Card.Img variant="top" className='blog-slide-image' src={TAXDates} />
                                                            </div>

                                                            <Card.Body>
                                                                <Card.Title className='blogCardRightTitle'>Important Tax Deadlines 2023</Card.Title>
                                                            </Card.Body>
                                                        </Link>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div>
                                            <Row>
                                                <Col>
                                                    <Card className='blogCardforSlide'>
                                                        <Link to="/tips-for-tax-students" style={{ textDecoration: "none" }}>
                                                            <div>
                                                                <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                                                <Card.Img variant="top" className='blog-slide-image' src={Studenttax} />
                                                            </div>

                                                            <Card.Body>
                                                                <Card.Title className='blogCardRightTitle'>Tips for Students</Card.Title>
                                                            </Card.Body>
                                                        </Link>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div>
                                            <Row>
                                                <Col>
                                                    <Card className='blogCardforSlide '>
                                                        <Link to="/tax-implications-for-having-multiple-employers" style={{ textDecoration: "none" }}>
                                                            <div>
                                                                <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                                                <Card.Img variant="top" className='blog-slide-image' src={taximplication1} />
                                                            </div>

                                                            <Card.Body>
                                                                <Card.Title className='blogCardRightTitle'>Tax implications for having multiple employers</Card.Title>
                                                            </Card.Body>
                                                        </Link>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div>
                                            <Row>
                                                <Col>
                                                    <Card className='blogCardforSlide'>
                                                        <Link to="/tax-deductions-for-medical-expenses-under-the-CRA" style={{ textDecoration: "none" }}>
                                                            <div>
                                                                <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                                                <Card.Img variant="top" className='blog-slide-image' src={taxdeduction2} />
                                                            </div>

                                                            <Card.Body>
                                                                <Card.Title className='blogCardRightTitle'>Tax deductions for medical expenses under the CRA</Card.Title>
                                                            </Card.Body>
                                                        </Link>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div>
                                            <Row>
                                                <Col>
                                                    <Card className='blogCardforSlide'>
                                                        <Link to="/fix-your-income-tax-return-in-Canada" style={{ textDecoration: "none" }}>
                                                            <div>
                                                                <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                                                <Card.Img variant="top" className='blog-slide-image' src={fixincome} />
                                                            </div>

                                                            <Card.Body>
                                                                <Card.Title className='blogCardRightTitle'>Fix Your Income Tax Return in Canada</Card.Title>
                                                            </Card.Body>
                                                        </Link>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        </div>

                                    </Slider>
                                </Col>
                            </Row>
                        </div>
                    </Container>

                </Container>
            </Fragment>
        );
    }
}

export default BlogSlide;