import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const SearchByWord = () => {
    return (
        <>
            <Container>
                <Row>
                    <Col>
                        <div className='mb-2 p-3'>
                            <p className='blogDesc'><strong>Search by keyword</strong></p>

                            <Link to="/tips-for-a-great-first-time-filing-experience"><Button className='tax-tip-key-btn ' variant="outline-primary">Tax Tips</Button>{' '}</Link>
                            <Link to="/tips-for-self-employed-taxpayers"><Button className='tax-tip-key-btn ' variant="outline-primary">Self-Employed</Button>{' '}</Link>
                            <Link to="/important-tax-deadlines"><Button className='tax-tip-key-btn ' variant="outline-primary">Deadline</Button>{' '}</Link>
                            <Link to="/tips-for-tax-students"> <Button className='tax-tip-key-btn ' variant="outline-primary">Students</Button>{' '}</Link>
                            <Link to="/tax-deductions-for-medical-expenses-under-the-CRA"> <Button className='tax-tip-key-btn ' variant="outline-primary">Medical</Button>{' '}</Link>
                            <Link to="/housing-benefit-and-the-way-to-apply"><Button className='tax-tip-key-btn ' variant="outline-primary">House</Button>{' '}</Link>
                            <Link to="/federal-tax-brackets-rates-credits"><Button className='tax-tip-key-btn ' variant="outline-primary">Federal Tax</Button>{' '}</Link>
                            <Link to="/how-to-filing-taxes-in-multiple-states-when-moved"><Button className='tax-tip-key-btn ' variant="outline-primary">Multiple states</Button>{' '}</Link>
                            <Link to="/fix-your-income-tax-return-in-Canada">  <Button className='tax-tip-key-btn ' variant="outline-primary">Tax Return</Button>{' '}</Link>
                            <Link to="/remote-tax-filing">  <Button className='tax-tip-key-btn ' variant="outline-primary">Remote Filing</Button>{' '}</Link>
                            <hr ></hr>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default SearchByWord;