import React, { Component, Fragment } from 'react';
import BlogTop from '../components/BlogTop';
import VtacQuote from '../components/VtacQuote';
import Footer from '../components/Footer';
import NewTopNavigation from '../components/NewTopNavigation';
import SearchByWord from '../components/SearchByWord';
import BlogPart11 from '../components/BlogPart11';


class BlogPart11Page extends Component {
    render() {
        return (
            <Fragment>
                <NewTopNavigation title="6 essential points to understand regarding capital gains-V-Tac Accounting & Tax | The Professionals' Professional" />
                <BlogTop />
                <SearchByWord />
                <BlogPart11 />
                <VtacQuote />
                <Footer />
            </Fragment>
        );
    }
}

export default BlogPart11Page;