import React, { Component, Fragment } from 'react';
import OurTestimonials from '../components/OurTestimonials';
import PageTop3 from '../components/PageTop3';
import VtacQuote from '../components/VtacQuote';
import Footer from '../components/Footer';
import NewTopNavigation from '../components/NewTopNavigation';

class OurTestimonialPage extends Component {
    render() {
        return (
            <Fragment>
                <NewTopNavigation title="Our Testimonials-V-Tac Accounting & Tax | The Professionals' Professional" />
                <PageTop3 topPageTitle="Our Testimonials" topPagesubTitle="Learn what our clients have to say about our exceptional services" />
                <OurTestimonials />
                <VtacQuote />
                <Footer />
            </Fragment>
        );
    }
}

export default OurTestimonialPage;