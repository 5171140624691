import React, { Component, Fragment } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Slide1 from '../asset/images/Slide1.jpg';
import Slide2 from '../asset/images/Slide2.jpg';
import Slide3 from '../asset/images/Slide3.jpg';
import Slide4 from '../asset/images/Slide4.jpg';
import Slide5 from '../asset/images/Slide5.jpg';

class BookkipingSection extends Component {
    render() {
        var settings = {
            arrows: false,
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 3000,
            fade: true,
            fadeSpeed: 1000,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        initialSlide: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        return (
            <Fragment>
                <Container fluid={true} className="accContainer">

                    <Container className='accSection'>
                        <div>
                            <h2 className='accTitle'>
                                In-House Bookkeeping</h2>
                        </div>
                        <div>
                            <p className='accSubtitle'>As with any company, we know that finding an exceptional bookkeeper is just as hard as keeping one. The risk of hiring a bad one not only costs you money but harms the efficiency of your business. Likewise, having a great bookkeeper raises the risk of you losing them because of the demand for qualified bookkeepers. All this wastes company time and money.</p>
                            <p className='accSubtitle'>At <span className='spancolor'>V-Tac</span> Accounting, we provide solutions to these bookkeeping dilemmas. Our dependable services eliminate the problems of employee retention. You have the ability to control your costs to stay within your budget. Our skilled bookkeepers will personally assist you in your office or at whatever time is most convenient for you. We will ensure that the work is properly done to reflect your company’s financial standing and provide effective advice to any troubles looming ahead.</p>
                        </div>
                        <Row>
                            <Col lg={6} md={6} sm={12}>

                                <div className='accSlide mt-5' >
                                    <Slider {...settings}>
                                        <div>
                                            <Row>
                                                <Col>
                                                    <a> <img className="clientImage img-fluid" src={Slide1} alt="meeting" /></a>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div>
                                            <Row>
                                                <Col>
                                                    <a> <img className="clientImage img-fluid" src={Slide2} alt="conference" /></a>
                                                </Col>
                                            </Row>
                                        </div>

                                        <div>
                                            <Row>
                                                <Col>
                                                    <a> <img className="clientImage img-fluid" src={Slide4} alt="Vtac-tax" /></a>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div>
                                            <Row>
                                                <Col>
                                                    <a> <img className="clientImage img-fluid" src={Slide5} alt="All employees" /></a>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div>
                                            <Row>
                                                <Col>
                                                    <a> <img className="clientImage img-fluid" src={Slide3} alt="Team meeting" /></a>
                                                </Col>
                                            </Row>
                                        </div>

                                    </Slider>
                                </div>
                            </Col>
                            <Col lg={6} md={6} sm={12} >
                                <h2 className='accTitle2 mt-5'>
                                    Offsite Bookkeeping</h2>
                                <p className='accSubtitle'>Another alternate solution V-Tac Accounting provides is <span className='spancolor'>offsite bookkeeping.</span> We simply come to your office and pick up all the needed paperwork to complete your bookkeeping records. We will devote all the necessary attention that your company’s bookkeeping requires for your financial success. Taking advantage of this service means you will always have a clear financial picture. Such valuable information can help you address significant questions about your company’s stability, progress, and growth. Plus, it will help you stay organized and stress-free when tax season comes around.</p>
                            </Col>
                        </Row>
                        <Container className='mt-5'>
                            <Row>
                                <Col lg={12} md={12} sm={12}>
                                    <p className='description mt-5'>
                                        If you have any lingering questions or need more details, get in touch with us so that we can better understand your business needs. <span className='spancolor'>Note:</span> For your convenience, we ask that you maintain a master file of your tax and accounting records for accurate service.
                                    </p>
                                </Col>
                            </Row>
                        </Container>
                    </Container>
                </Container>
            </Fragment>

        );
    }
}

export default BookkipingSection;