import React, { Component, Fragment } from 'react';
import { Col, Container, Row, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faYoutube, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faPhone, faCheckCircle, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";


export default class Footer extends Component {
    render() {
        return (
            <Fragment>
                <Container fluid={true} className="footerSection" >
                    <Container className=''>
                        <Row>
                            <Col lg={8} md={6} sm={12}>
                                <Row>
                                    <Col lg={4} md={6} sm={12} className="mt-5">
                                        <h2 className="FooterTitle"> Get In Touch! </h2>
                                        <div>
                                            <div className="footerLinkDiv mt-3">
                                                <a href="https://www.linkedin.com/company/v-tac-accounting-&-tax/" target='_blank' className="footerSocialLink"><FontAwesomeIcon className='footerfontAwsome' icon={faLinkedin} /></a>
                                                <a href="https://twitter.com/vtacaccouting" target='_blank' className="footerSocialLink"><FontAwesomeIcon className='footerfontAwsome' icon={faTwitter} /></a>
                                                <a href="//www.facebook.com/vtacaccountingandtax" target='_blank' className='footerSocialLink' ><FontAwesomeIcon className='footerfontAwsome' icon={faFacebook} /></a><br />
                                                <a style={{ pointerEvents: "none" }} className='footerSocialLink' ><FontAwesomeIcon className='footerfontAwsome' icon={faYoutube} /></a>

                                            </div>
                                            <p className='footerAdressSubtitle mt-3'><FontAwesomeIcon style={{ color: '#f89a1c' }} icon={faPhone} /> 1-866-882-2829 (Toll Free)<br /></p>
                                            <p className="footerAdressSubtitle mt-3"><FontAwesomeIcon style={{ color: '#f89a1c' }} icon={faEnvelope} /><strong> info@vtac.ca</strong> </p>
                                            <p className='footerAdressSubtitle' style={{ color: "#F89A1C" }}><strong>Office Hours:</strong> </p>
                                            <p className="footerAdressSubtitle" >Monday – Friday 9:00 am to 8:00 pm<br></br>
                                                Saturday – 9:00 am to 2:00 pm<br></br>
                                                Sunday – Closed</p>
                                        </div>
                                    </Col>
                                    <Col lg={4} md={6} sm={12} className="mt-5">

                                        <div>
                                            <h2 className="FooterTitle"> Our Services </h2>

                                            <Link to="/accounting" className="footerLink"><FontAwesomeIcon className="FootericonBullet" icon={faAngleRight} /><a onClick={() => {
                                                window.scrollTo({ top: 0, behavior: 'smooth' });
                                            }}> Accounting</a></Link><br></br>
                                            <Link to="/bookkeeping" className="footerLink"><FontAwesomeIcon className="FootericonBullet" icon={faAngleRight} /><a onClick={() => {
                                                window.scrollTo({ top: 0, behavior: 'smooth' });
                                            }}> Bookkeeping</a></Link><br></br>
                                            <Link to="/taxation" className="footerLink"><FontAwesomeIcon className="FootericonBullet" icon={faAngleRight} /><a onClick={() => {
                                                window.scrollTo({ top: 0, behavior: 'smooth' });
                                            }}> Taxation</a></Link><br></br>
                                            <Link to="/payroll-processing" className="footerLink"><FontAwesomeIcon className="FootericonBullet" icon={faAngleRight} /><a onClick={() => {
                                                window.scrollTo({ top: 0, behavior: 'smooth' });
                                            }}> Payroll Processing</a></Link><br></br>
                                            <Link to="/tax-clinic" className="footerLink"><FontAwesomeIcon className="FootericonBullet" icon={faAngleRight} /><a onClick={() => {
                                                window.scrollTo({ top: 0, behavior: 'smooth' });
                                            }}> Tax-Clinic</a></Link><br></br>
                                            <Link to="/business-incorporation" className="footerLink" ><FontAwesomeIcon className="FootericonBullet" icon={faAngleRight} /><a onClick={() => {
                                                window.scrollTo({ top: 0, behavior: 'smooth' });
                                            }}> Business Incorporation</a></Link><br></br>

                                        </div>

                                    </Col>
                                    <Col lg={4} md={6} sm={12} className="mt-5">

                                        <div>
                                            <h2 className="FooterTitle"> Company </h2>

                                            <Link to="/about-us" className="footerLink"><FontAwesomeIcon className="FootericonBullet" icon={faAngleRight} /><a onClick={() => {
                                                window.scrollTo({ top: 0, behavior: 'smooth' });
                                            }}> About Us</a></Link><br></br>
                                            <Link to="/our-testimonials" className="footerLink"><FontAwesomeIcon className="FootericonBullet" icon={faAngleRight} /><a onClick={() => {
                                                window.scrollTo({ top: 0, behavior: 'smooth' });
                                            }}> Testimonials</a></Link><br></br>
                                            <Link to="/covid-19-update" className="footerLink"><FontAwesomeIcon className="FootericonBullet" icon={faAngleRight} /><a onClick={() => {
                                                window.scrollTo({ top: 0, behavior: 'smooth' });
                                            }}> COVID-19 UPDATE!</a></Link><br></br>
                                            <Link to="/contest" className="footerLink"><FontAwesomeIcon className="FootericonBullet" icon={faAngleRight} /><a onClick={() => {
                                                window.scrollTo({ top: 0, behavior: 'smooth' });
                                            }}> Join Our Contest</a></Link><br></br>
                                        </div>

                                    </Col></Row>


                            </Col>
                            <Col lg={4} md={6} sm={12}>
                                <Row><Col className="mt-5">
                                    <h2 className="FooterTitle "> Office Address </h2>
                                    <p className='quebacDes'>ONTARIO</p>
                                    <p className='footerAdressSubtitle'>100 King St. W., Suite 5700<br></br>
                                        Toronto ON M5X 1C7<br />
                                        T: 416.425.9897<br />
                                        F: 416.769.7218</p>
                                    {/* <hr className="hrFooter" /><br></br> */}
                                    <p className='footerAdressSubtitle'>401 Bay Street Suite #1600<br></br>
                                        Toronto ON M5H 2Y4<br />
                                        T: 416.646.6750<br />
                                        F: 416.769.7218</p>
                                    {/* <hr className="hrFooter" /> */}
                                    <p className='footerAdressSubtitle'>881 Jane Street #207<br></br>
                                        Toronto ON M6N 4C4<br></br>
                                        T: 416.769.9957<br />
                                        F: 416.769.7218</p>

                                </Col>
                                    <Col className="mt-5">
                                        <p className='quebacDes mt-5' >
                                            QUEBEC
                                        </p>
                                        <p className='footerAdressSubtitle'>
                                            7099B av De L’Epee<br></br>
                                            Montreal, QC H3N 2C9<br />
                                            T: 514.272.9777<br />
                                            F: 514.272.9777
                                        </p>
                                        <p className='footerAdressSubtitle'>
                                            152 Rue Gendron<br></br>
                                            Chateauguay, QC J6J 3G6<br />
                                            T: 450.692.7779<br />
                                            F: 450.692.7154
                                        </p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </Container>
                <Container fluid={true} className="copyrightSec">
                    <div className='container'>
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <p className="copyrightLink">
                                    © 2022 V-TAC Accounting & Tax. All Rights Reserved.
                                </p>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </Fragment>
        )
    }
}
