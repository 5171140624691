import React, { Component, Fragment } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

class UsefulLinks extends Component {
    render() {
        return (
            <Fragment>
                <Container fluid={true} className="accContainer">

                    <Container className='accSection'>
                        <div>
                            <h2 className='accTitle'>

                                Useful Links</h2>
                        </div>
                        <div className='accHr'></div>
                        <Row>
                            <Col lg={12} md={12} sm={12} >
                                <div>
                                    <ul className='accUl'>
                                        <li><FontAwesomeIcon className="otherServiceBullet" icon={faCheckCircle} /><a href="//www.vtacpayroll.ca" target='_blank' className='usefulLinkLi'>VPM (Your Payroll Solutions)</a></li>
                                        <li><FontAwesomeIcon className="otherServiceBullet" icon={faCheckCircle} /><a href="//www.canada.ca/en/revenue-agency/cra-canada.html?utm_campaign=not-applicable&utm_medium=redirect&utm_source=cra-arc.gc.ca_redirect" target='_blank' className='usefulLinkLi'>Canada Revenue Agency (CRA)</a></li>
                                        <li><FontAwesomeIcon className="otherServiceBullet" icon={faCheckCircle} /><a href="https://www.ontario.ca/page/ministry-finance" target='_blank' className='usefulLinkLi'>Ministry Of Finance Ontario </a></li>
                                        <li><FontAwesomeIcon className="otherServiceBullet" icon={faCheckCircle} /><a href=" https://www.ontario.ca/document/employer-health-tax-eht" target='_blank' className='usefulLinkLi'>Ontario Health Tax</a></li>
                                        <li><FontAwesomeIcon className="otherServiceBullet" icon={faCheckCircle} /><a href=" https://www.canada.ca/en/services/benefits/publicpensions/cpp.html" target='_blank' className='usefulLinkLi'>Canada Pension Plan</a></li>
                                        <li><FontAwesomeIcon className="otherServiceBullet" icon={faCheckCircle} /><a href=" https://www.canada.ca/en/services/business/start/support-financing.html" target='_blank' className='usefulLinkLi'> Services For Entrepreneurs</a></li>
                                        <li><FontAwesomeIcon className="otherServiceBullet" icon={faCheckCircle} /><a href="https://www.canada.ca/en/news.html" target='_blank' className='usefulLinkLi'>Canada News Centre</a></li>
                                        <li><FontAwesomeIcon className="otherServiceBullet" icon={faCheckCircle} /><a href=" https://www.canada.ca/en/department-justice.html" target='_blank' className='usefulLinkLi'>Department Of Justice Canada</a></li>
                                        <li><FontAwesomeIcon className="otherServiceBullet" icon={faCheckCircle} /><a href=" https://www.ontario.ca/page/ministry-seniors-accessibility" target='_blank' className='usefulLinkLi'>Ministry for Seniors and Accessibility</a></li>
                                        <li><FontAwesomeIcon className="otherServiceBullet" icon={faCheckCircle} /><a href=" https://www.canada.ca/en/employment-social-development/campaigns/seniors.html" target='_blank' className='usefulLinkLi'>Seniors Canada</a></li>
                                        <li><FontAwesomeIcon className="otherServiceBullet" icon={faCheckCircle} /><a href="//www.wsib.ca/en" target='_blank' className='usefulLinkLi'>Worker Safety Insurance Board (WSIB)</a></li>
                                        <li><FontAwesomeIcon className="otherServiceBullet" icon={faCheckCircle} /><a href="//www.xe.com" target='_blank' className='usefulLinkLi'>Currency Converter</a></li>
                                        <li><FontAwesomeIcon className="otherServiceBullet" icon={faCheckCircle} /><a href="//www.statcan.gc.ca" target='_blank' className='usefulLinkLi'>Statistic Canada</a></li>
                                        <li><FontAwesomeIcon className="otherServiceBullet" icon={faCheckCircle} /><a href="//www.bdc.ca/en" target='_blank' className='usefulLinkLi'>Business Development Bank of Canada (BDC)</a></li>
                                        <li><FontAwesomeIcon className="otherServiceBullet" icon={faCheckCircle} /><a href="//www.rbc.com/about-rbc.html" target='_blank' className='usefulLinkLi'>Royal Bank of Canada (RBC)</a></li>
                                        <li><FontAwesomeIcon className="otherServiceBullet" icon={faCheckCircle} /><a href="//www.bmo.com/main/personal" target='_blank' className='usefulLinkLi'>Bank of Montreal (BMO)</a></li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Container>
            </Fragment>
        );
    }
}

export default UsefulLinks;