import React, { Component, Fragment } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import ZaynMediaInc from '../asset/images/Zayn_Media_Inc.jpg';
import ZaomalConsultant from '../asset/images/Zaomal-Consultant.jpg';
import yorklionssteelband from '../asset/images/york-lions-steel-band.jpg';
import WillyTransportLtd from '../asset/images/Willy-Transport-Ltd.jpg';
import walkersorensen from '../asset/images/walker-sorensen.jpg';
import TrisanGenrealContractingInc from '../asset/images/Trisan-Genreal-Contracting-Inc.jpg';
import torontohawkssoccer from '../asset/images/toronto-hawks-soccer.jpg';
import tastypita from '../asset/images/tasty-pita.jpg';
import sourcemomenthealthcare from '../asset/images/source-moment-health-care.jpg';
import SolutionsConsultingLtd from '../asset/images/Solutions-Consulting-Ltd.jpg';
import SolutionRecycling from '../asset/images/Solution-Recycling.jpg';
import royalpioneerdoorandwindow from '../asset/images/royal-pioneer-door-and-window.jpg';
import ResourceRecycling from '../asset/images/Resource-Recycling.jpg';
import raywhite from '../asset/images/ray-white.jpg';
import PriorientCorporation from '../asset/images/Priorient-Corporation.jpg';
import nexusstaffing from '../asset/images/nexus-staffing.jpg';
import mouthpublicity from '../asset/images/mouth-publicity.jpg';
import mollymaid from '../asset/images/molly_maid.jpg';
import MiracleGraphicsCanada from '../asset/images/Miracle-Graphics-Canada.jpg';
import MBAStaffingSolution from '../asset/images/MBA-Staffing-Solution.jpg';
import MBADriverServices from '../asset/images/MBA-Driver-Services.jpg';
import mamaspizza from '../asset/images/mamaspizza.jpg';
import macshowsystems from '../asset/images/mac-show-systems.jpg';
import KroumvsInstruments from '../asset/images/Kroumvs-Instruments.jpg';
import korbitec from '../asset/images/korbitec.jpg';
import KFC from '../asset/images/KFC.jpg';
import jwcarcare from '../asset/images/jw-carcare.jpg';
import JaneBloorRestaurant from '../asset/images/Jane-Bloor-Restaurant.jpg';
import InteriorRenovationsSuppliesLtd from '../asset/images/Interior-Renovations-Supplies-Ltd.jpg';
import herocertifiedburgers from '../asset/images/hero-certified-burgers.jpg';
import GoExpress from '../asset/images/Go-Express.jpg';
import GabersCorporation from '../asset/images/Gabers-Corporation.jpg';
import funrise from '../asset/images/funrise.jpg';
import FullTruth from '../asset/images/Full-Truth-Church-Of-God-Deliverance-Centre-Inc..jpg';
import fentonsmithlaw from '../asset/images/fenton-smith-law.jpg';
import fakebeauty from '../asset/images/fake-beauty.jpg';
import esso from '../asset/images/esso.jpg';
import DRDorarMed from '../asset/images/DR.-Dorar-Med-Care-Inc.jpg';
import DengisConstructionLtd from '../asset/images/Dengis-Construction-Ltd.jpg';
import DairyGrill from '../asset/images/Dairy-Dell-Bar-and-Grill.jpg';
import DMAutomotive from '../asset/images/D-M-Automotive.jpg';
import chellseyinstitute from '../asset/images/chellsey-institute.jpg';
import chefofindia from '../asset/images/chef-of-india.jpg';
import BKAluminumLtd from '../asset/images/BK-Aluminum-Ltd.jpg';
import altamiramaintenance from '../asset/images/altamira-maintenance..jpg';
import AlliEnterprise from '../asset/images/Alli-Enterprise.jpg';
import aitasjanapeseschool from '../asset/images/aitas-janapese-school.jpg';
import AiAEngineersLtd from '../asset/images/AiA-Engineers-Ltd.jpg';
import affyspremiumgrill from '../asset/images/affys-premium-grill.jpg';
import ATRPropertyHolding from '../asset/images/A-T-R-Property-Holding-Inc..jpg';
import spices from '../asset/images/39-spices.jpg';
import spaceageclosets from '../asset/images/spaceage-closets.jpg';


class OurClients extends Component {
    render() {
        return (
            <Fragment>
                <Container className='incorporationSec'>
                    <div>
                        <h2 className='accTitle'>
                            Some of our respective clients:</h2>
                    </div>
                    <div className='accHr'></div>
                    <Row>
                        <Col lg={2} md={4} sm={6} >
                            <a> <img className="accSoftImg" src={ZaynMediaInc} /></a>
                        </Col>
                        <Col lg={2} md={4} sm={6} >
                            <a> <img className="accSoftImg" src={ZaomalConsultant} /></a>
                        </Col>
                        <Col lg={2} md={4} sm={6} >
                            <a> <img className="accSoftImg" src={yorklionssteelband} /></a>
                        </Col>
                        <Col lg={2} md={4} sm={6} >
                            <a> <img className="accSoftImg" src={WillyTransportLtd} /></a>
                        </Col>
                        <Col lg={2} md={4} sm={6} >
                            <a> <img className="accSoftImg" src={walkersorensen} /></a>
                        </Col>
                        <Col lg={2} md={4} sm={6} >
                            <a> <img className="accSoftImg" src={TrisanGenrealContractingInc} /></a>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={2} md={4} sm={6} >
                        <a> <img className="accSoftImg" src={torontohawkssoccer} /></a>
                        </Col>
                        <Col lg={2} md={4} sm={12} >
                        <a> <img className="accSoftImg" src={tastypita} /></a>
                        </Col>
                        <Col lg={2} md={4} sm={12} >
                        <a> <img className="accSoftImg" src={sourcemomenthealthcare} /></a>
                        </Col>
                        <Col lg={2} md={4} sm={12} >
                        <a> <img className="accSoftImg" src={SolutionsConsultingLtd} /></a>
                        </Col>
                        <Col lg={2} md={4} sm={12} >
                        <a> <img className="accSoftImg" src={SolutionRecycling} /></a>
                        </Col>
                        <Col lg={2} md={4} sm={12} >
                        <a> <img className="accSoftImg" src={royalpioneerdoorandwindow} /></a>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={2} md={4} sm={12}>
                        <a> <img className="accSoftImg" src={ResourceRecycling} /></a>
                        </Col>
                        <Col lg={2} md={4} sm={12}>
                        <a> <img className="accSoftImg" src={raywhite} /></a>
                        </Col>
                        <Col lg={2} md={4} sm={12}>
                        <a> <img className="accSoftImg" src={PriorientCorporation} /></a>
                        </Col>
                        <Col lg={2} md={4} sm={12}>
                        <a> <img className="accSoftImg" src={nexusstaffing} /></a>
                        </Col>
                        <Col lg={2} md={4} sm={12}>
                        <a> <img className="accSoftImg" src={mouthpublicity} /></a>
                        </Col>
                        <Col lg={2} md={4} sm={12}>
                        <a><img className="accSoftImg" src={spaceageclosets} /></a>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={2} md={4} sm={12}>
                        <a> <img className="accSoftImg" src={mollymaid} /></a>
                        </Col>
                        <Col lg={2} md={4} sm={12}>
                        <a> <img className="accSoftImg" src={MiracleGraphicsCanada} /></a>
                        </Col>
                        <Col lg={2} md={4} sm={12}>
                        <a> <img className="accSoftImg" src={MBAStaffingSolution} /></a>
                        </Col>
                        <Col lg={2} md={4} sm={12}>
                        <a> <img className="accSoftImg" src={MBADriverServices} /></a>
                        </Col>
                        <Col lg={2} md={4} sm={12}>
                        <a> <img className="accSoftImg" src={mamaspizza} /></a>
                        </Col>
                        <Col lg={2} md={4} sm={12}>
                        <a> <img className="accSoftImg" src={macshowsystems} /></a>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={2} md={4} sm={12}>
                        <a><img className="accSoftImg" src={KroumvsInstruments} /></a>
                        </Col>
                        <Col lg={2} md={4} sm={12}>
                        <a><img className="accSoftImg" src={korbitec} /></a>
                        </Col>
                        <Col lg={2} md={4} sm={12}>
                        <a><img className="accSoftImg" src={KFC} /></a>
                        </Col>
                        <Col lg={2} md={4} sm={12}>
                        <a><img className="accSoftImg" src={jwcarcare} /></a>
                        </Col>
                        <Col lg={2} md={4} sm={12}>
                        <a><img className="accSoftImg" src={JaneBloorRestaurant} /></a>
                        </Col>
                        <Col lg={2} md={4} sm={12}>
                        <a><img className="accSoftImg" src={InteriorRenovationsSuppliesLtd} /></a>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={2} md={4} sm={12}>
                        <a><img className="accSoftImg" src={herocertifiedburgers} /></a>
                        </Col>
                        <Col lg={2} md={4} sm={12}>
                        <a><img className="accSoftImg" src={GoExpress} /></a>
                        </Col>
                        <Col lg={2} md={4} sm={12}>
                        <a><img className="accSoftImg" src={GabersCorporation} /></a>
                        </Col>
                        <Col lg={2} md={4} sm={12}>
                        <a><img className="accSoftImg" src={funrise} /></a>
                        </Col>
                        <Col lg={2} md={4} sm={12}>
                        <a><img className="accSoftImg" src={FullTruth} /></a>
                        </Col>
                        <Col lg={2} md={4} sm={12}>
                        <a><img className="accSoftImg" src={fentonsmithlaw} /></a>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={2} md={4} sm={12}>
                        <a><img className="accSoftImg" src={fakebeauty} /></a>
                        </Col>
                        <Col lg={2} md={4} sm={12}>
                        <a><img className="accSoftImg" src={esso} /></a>
                        </Col>
                        <Col lg={2} md={4} sm={12}>
                        <a><img className="accSoftImg" src={DRDorarMed} /></a>
                        </Col>
                        <Col lg={2} md={4} sm={12}>
                        <a><img className="accSoftImg" src={DengisConstructionLtd} /></a>
                        </Col>
                        <Col lg={2} md={4} sm={12}>
                        <a><img className="accSoftImg" src={DairyGrill} /></a>
                        </Col>
                        <Col lg={2} md={4} sm={12}>
                        <a><img className="accSoftImg" src={DMAutomotive} /></a>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={2} md={4} sm={12}>
                        <a><img className="accSoftImg" src={chellseyinstitute} /></a>
                        </Col>
                        <Col lg={2} md={4} sm={12}>
                        <a><img className="accSoftImg" src={chefofindia} /></a>
                        </Col>
                        <Col lg={2} md={4} sm={12}>
                        <a><img className="accSoftImg" src={BKAluminumLtd} /></a>
                        </Col>
                        <Col lg={2} md={4} sm={12}>
                        <a><img className="accSoftImg" src={altamiramaintenance} /></a>
                        </Col>
                        <Col lg={2} md={4} sm={12}>
                        <a><img className="accSoftImg" src={AlliEnterprise} /></a>
                        </Col>
                        <Col lg={2} md={4} sm={12}>
                        <a><img className="accSoftImg" src={aitasjanapeseschool} /></a>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={2} md={4} sm={12}>
                        <a><img className="accSoftImg" src={AiAEngineersLtd} /></a>
                        </Col>
                        <Col lg={2} md={4} sm={12}>
                        <a><img className="accSoftImg" src={affyspremiumgrill} /></a>
                        </Col>
                        <Col lg={2} md={4} sm={12}>
                        <a><img className="accSoftImg" src={ATRPropertyHolding} /></a>
                        </Col>
                        <Col lg={2} md={4} sm={12}>
                        <a><img className="accSoftImg" src={spices} /></a>
                        </Col>                       
                    </Row>                    
                </Container>
            </Fragment>
        );
    }
}

export default OurClients;