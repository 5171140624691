import React, { Component, Fragment } from 'react';
import AccountingSoftware from '../components/AccountingSoftware';
import PageTop from '../components/PageTop';
import VtacQuote from '../components/VtacQuote';
import Footer from '../components/Footer';
import NewTopNavigation from '../components/NewTopNavigation';
class AccountingSoftwarePage extends Component {
    render() {
        return (
            <Fragment>
                <NewTopNavigation title="Accounting Software-V-Tac Accounting & Tax | The Professionals' Professional" />
                <PageTop topPageAccSoftTitle="Accounting Software" />
                <AccountingSoftware />
                <VtacQuote />
                <Footer />
            </Fragment>
        );
    }
}

export default AccountingSoftwarePage;