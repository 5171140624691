import React, { Component, Fragment } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Slide1 from '../asset/images/Slide1.jpg';
import Slide2 from '../asset/images/Slide2.jpg';
import Slide3 from '../asset/images/Slide3.jpg';
import Slide4 from '../asset/images/Slide4.jpg';
import Slide5 from '../asset/images/Slide5.jpg';
import vtactaxClinic from '../asset/images/vtac-tax-Clinic.jpg';
import vtactaxClinic2 from '../asset/images/vtac-tax-Clinic-2.jpg';

class VtacTaxClinic extends Component {
    render() {
        var settings = {
            arrows: false,
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 3000,
            fade: true,
            fadeSpeed: 1000,



            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        initialSlide: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        return (
            <Fragment>
                <Container fluid={true} className="accContainer">

                    <Container className='accSection'>
                        <div>
                            <h2 className='accTitle'>
                                V-Tac Tax Clinic
                            </h2>
                            <p className='TaxClinicSubtitle'>At V-Tac we value our customers and because we do, we take their needs into consideration. Our firm is cognizant of some of the health risks and medical implications that may prevent a customer from dropping by to use our services. For this reason, we like to customize all our accounting features so that every client can enjoy flexible, affordable, and accurate tax filing services whenever and wherever they need them.</p>
                            <p className='TaxClinicSubtitle'>Our <span className='spancolor'>Tax Clinic</span> provides at-home income tax services for seniors who lack mobility or the capacity to drop by our offices unassisted because of medical reasons. Our tax consultants will deliver the same standard of service, reliability, and attentiveness you would receive if at our offices. We are here to make your taxes and accounting easier for you and your loved ones.</p>
                            <h2 className='accTitle mt-4'>Managing Your Income Tax</h2>
                            <p className='TaxClinicSubtitle'>We believe that our clients deserve tax services that are trustworthy, competent, and accurate. Thus, we know how important it is for you to discover an accounting firm that can properly handles your tax needs. V-Tac provides personalized services and advice on a complete spectrum of tax services. By working with us, you will enjoy added benefits and services you can take advantage of, such as</p>
                        </div>
                        <div className='mt-5'></div>
                        <Row>
                            <Col lg={6} md={6} sm={12} >
                                <h2 className='accTitle'>Advantages</h2>
                                <ul className='accUl'>
                                    <li><div><FontAwesomeIcon className="taxClinicIcon" icon={faCheckCircle} /></div><div className='taxClinicDiv'>CANADA’S RETIREMENT INCOME TAX PREPARATION SERVICE</div></li>
                                    <li><div><FontAwesomeIcon className="taxClinicIcon" icon={faCheckCircle} /></div><div className='taxClinicDiv'>AT-HOME TAX SERVICES</div></li>
                                    <li><div><FontAwesomeIcon className="taxClinicIcon" icon={faCheckCircle} /></div><div className='taxClinicDiv'>SENSIBLE, HIGH-QUALITY, AND RAPID SERVICES THAT ARE GUARANTEED</div></li>
                                </ul>
                            </Col>
                            <Col lg={6} md={6} sm={12}>

                                <div className='accSlide'>
                                    <Slider {...settings}>
                                        <div>
                                            <Row>
                                                <Col>
                                                    <a> <img className="clientImage img-fluid" src={Slide1} alt="meeting" /></a>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div>
                                            <Row>
                                                <Col>
                                                    <a> <img className="clientImage img-fluid" src={Slide2} alt="conference" /></a>
                                                </Col>
                                            </Row>
                                        </div>

                                        <div>
                                            <Row>
                                                <Col>
                                                    <a> <img className="clientImage img-fluid" src={Slide4} alt="V-tac-tax" /></a>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div>
                                            <Row>
                                                <Col>
                                                    <a> <img className="clientImage img-fluid" src={Slide5} alt="all employees"/></a>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div>
                                            <Row>
                                                <Col>
                                                    <a> <img className="clientImage img-fluid" src={Slide3} alt="team meeting" /></a>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div>
                                            <Row>
                                                <Col>
                                                    <a> <img className="clientImage" src={vtactaxClinic} alt="v-tac-tax-clinic" /></a>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div>
                                            <Row>
                                                <Col>
                                                    <a> <img className="clientImage" src={vtactaxClinic2}  alt="v-tac-tax-clinic"/></a>
                                                </Col>
                                            </Row>
                                        </div>

                                    </Slider>

                                </div>



                            </Col>
                        </Row>
                        <div className='mt-5'></div>
                        <Container>
                            <Row>
                                <Col lg={12} md={12} sm={12}>
                                    <p className='taxClinicdesc mt-3'>
                                        <span className='spancolor'></span> To find out more about what V-Tac can do for you, contact us at
                                    </p>
                                    <p className='taxClinicSubdesc'>
                                        Telephone number <span style={{color:"#E6001F"}}>1-866-882-2829</span>
                                    </p>
                                </Col>
                            </Row>
                        </Container>
                    </Container>
                </Container>
            </Fragment>
        );
    }
}

export default VtacTaxClinic;