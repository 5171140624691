import React, { Component, Fragment } from 'react';
import VtacTaxClinic from '../components/VtacTaxClinic';
import PageTop from '../components/PageTop';
import VtacQuote from '../components/VtacQuote';
import Footer from '../components/Footer';
import NewTopNavigation from '../components/NewTopNavigation';
class VtacTaxClinicPage extends Component {
    render() {
        return (
            <Fragment>
                <NewTopNavigation title="V-Tac-Tax-Clinic-V-Tac Accounting & Tax | The Professionals' Professional" />
                <PageTop topPageTitle="V-Tac Tax Clinic" topPagesubTitle="At V-Tac we value our customers and always take their needs into consideration. Our firm is cognizant of some of the health risks and medical implications that may prevent a customer from dropping by to use our services. We are here to make your taxes and accounting easier for you and your loved ones." />
                <VtacTaxClinic />
                <VtacQuote />
                <Footer />
            </Fragment>

        );
    }
}

export default VtacTaxClinicPage;