import React, { Component, Fragment } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import demoImg from '../asset/images/username-logo-png-180x180.png';
import googlelogo from '../asset/images/googlelogo.png';
import fbstar from '../asset/images/fb-star.png';
import googleReview1 from '../asset/images/1.jpg';
import googleReview2 from '../asset/images/2.jpg';


class GoogleReview extends Component {
    render() {
        return (
            <Fragment>
                <Container className='accSection'>
                    <div>
                        <h2 className='accTitle'>
                            Clients Review
                        </h2>
                    </div>
                    <div className='accHr'></div>
                    <Row><Col lg={12} md={12} sm={12}>
                        <img className="img-fluid " style={{ height: 'auto', width: '100%' }} src={googleReview2} />

                    </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>Mo Goodman</strong> &nbsp; <a href='https://www.google.com/search?q=vtac+accounting+toronto&newwindow=1&bih=964&biw=2048&hl=en&sxsrf=AJOqlzVO4uETOdZdvgE05MRcsR8djpoKyg%3A1677356611124&ei=Q276Y9D5BvyfseMPwZSjsAc&ved=0ahUKEwiQp96nwLH9AhX8T2wGHUHKCHYQ4dUDCA4&uact=5&oq=vtac+accounting+toronto&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIFCCEQoAEyBQghEKABMgUIIRCgAToECCMQJzoGCAAQBRAeOgUIABCGAzoHCAAQHhDxBDoECAAQHjoFCAAQgAQ6CgguEK8BEMcBECc6CgguEMcBEK8BEEM6BAgAEEM6BQgAEJECOgoIABCABBAUEIcCOgcIABCABBAKOg4ILhCABBDHARCvARDLAToJCAAQFhAeEPEEOgYIABAWEB46BwghEKABEApKBAhBGAFQjAdYhGtgk3JoAXAAeACAAZgBiAGCGpIBBDAuMjSYAQCgAQHAAQE&sclient=gws-wiz-serp' target="_blank"><img className="brandicon" src={googlelogo} alt="Google" /></a></p>
                                <p className='TestLtd'><FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                </p>
                            </div>
                        </Col>

                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    Vtac accounting has been doing my corporation taxes for years now.  I have never had a bad experience there.  The service is always quick and smooth.  Great place to take any of your tax needs.
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>Darth V</strong> &nbsp; <a href='https://www.google.com/search?q=vtac+accounting+toronto&newwindow=1&bih=964&biw=2048&hl=en&sxsrf=AJOqlzVO4uETOdZdvgE05MRcsR8djpoKyg%3A1677356611124&ei=Q276Y9D5BvyfseMPwZSjsAc&ved=0ahUKEwiQp96nwLH9AhX8T2wGHUHKCHYQ4dUDCA4&uact=5&oq=vtac+accounting+toronto&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIFCCEQoAEyBQghEKABMgUIIRCgAToECCMQJzoGCAAQBRAeOgUIABCGAzoHCAAQHhDxBDoECAAQHjoFCAAQgAQ6CgguEK8BEMcBECc6CgguEMcBEK8BEEM6BAgAEEM6BQgAEJECOgoIABCABBAUEIcCOgcIABCABBAKOg4ILhCABBDHARCvARDLAToJCAAQFhAeEPEEOgYIABAWEB46BwghEKABEApKBAhBGAFQjAdYhGtgk3JoAXAAeACAAZgBiAGCGpIBBDAuMjSYAQCgAQHAAQE&sclient=gws-wiz-serp' target="_blank"><img className="brandicon" src={googlelogo} alt="Google" /></a></p>
                                <p className='TestLtd'><FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                </p>
                            </div>
                        </Col>

                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    Been coming here for years, Nazim and his staff are top notch in everything they do. Very highly recommended!
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>Nuno Inacio</strong> &nbsp; <a href='https://www.google.com/search?q=vtac+accounting+toronto&newwindow=1&bih=964&biw=2048&hl=en&sxsrf=AJOqlzVO4uETOdZdvgE05MRcsR8djpoKyg%3A1677356611124&ei=Q276Y9D5BvyfseMPwZSjsAc&ved=0ahUKEwiQp96nwLH9AhX8T2wGHUHKCHYQ4dUDCA4&uact=5&oq=vtac+accounting+toronto&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIFCCEQoAEyBQghEKABMgUIIRCgAToECCMQJzoGCAAQBRAeOgUIABCGAzoHCAAQHhDxBDoECAAQHjoFCAAQgAQ6CgguEK8BEMcBECc6CgguEMcBEK8BEEM6BAgAEEM6BQgAEJECOgoIABCABBAUEIcCOgcIABCABBAKOg4ILhCABBDHARCvARDLAToJCAAQFhAeEPEEOgYIABAWEB46BwghEKABEApKBAhBGAFQjAdYhGtgk3JoAXAAeACAAZgBiAGCGpIBBDAuMjSYAQCgAQHAAQE&sclient=gws-wiz-serp' target="_blank"><img className="brandicon" src={googlelogo} alt="Google" /></a></p>
                                <p className='TestLtd'><FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                </p>
                            </div>
                        </Col>

                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    Have been doing my taxes and accounting here for over 20 years service is amazing.. for sure recommend for anyone looking for a knowledgeable accountant.
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>Meaghan M</strong> &nbsp; <a href='https://www.google.com/search?q=vtac+accounting+toronto&newwindow=1&bih=964&biw=2048&hl=en&sxsrf=AJOqlzVO4uETOdZdvgE05MRcsR8djpoKyg%3A1677356611124&ei=Q276Y9D5BvyfseMPwZSjsAc&ved=0ahUKEwiQp96nwLH9AhX8T2wGHUHKCHYQ4dUDCA4&uact=5&oq=vtac+accounting+toronto&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIFCCEQoAEyBQghEKABMgUIIRCgAToECCMQJzoGCAAQBRAeOgUIABCGAzoHCAAQHhDxBDoECAAQHjoFCAAQgAQ6CgguEK8BEMcBECc6CgguEMcBEK8BEEM6BAgAEEM6BQgAEJECOgoIABCABBAUEIcCOgcIABCABBAKOg4ILhCABBDHARCvARDLAToJCAAQFhAeEPEEOgYIABAWEB46BwghEKABEApKBAhBGAFQjAdYhGtgk3JoAXAAeACAAZgBiAGCGpIBBDAuMjSYAQCgAQHAAQE&sclient=gws-wiz-serp' target="_blank"><img className="brandicon" src={googlelogo} alt="Google" /></a></p>
                                <p className='TestLtd'><FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                </p>
                            </div>
                        </Col>

                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    Always fast and courteous service! Honest and trustworthy. Don’t hesitate to give them a try!
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>Sean Sicard</strong> &nbsp; <a href='https://www.google.com/search?q=vtac+accounting+toronto&newwindow=1&bih=964&biw=2048&hl=en&sxsrf=AJOqlzVO4uETOdZdvgE05MRcsR8djpoKyg%3A1677356611124&ei=Q276Y9D5BvyfseMPwZSjsAc&ved=0ahUKEwiQp96nwLH9AhX8T2wGHUHKCHYQ4dUDCA4&uact=5&oq=vtac+accounting+toronto&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIFCCEQoAEyBQghEKABMgUIIRCgAToECCMQJzoGCAAQBRAeOgUIABCGAzoHCAAQHhDxBDoECAAQHjoFCAAQgAQ6CgguEK8BEMcBECc6CgguEMcBEK8BEEM6BAgAEEM6BQgAEJECOgoIABCABBAUEIcCOgcIABCABBAKOg4ILhCABBDHARCvARDLAToJCAAQFhAeEPEEOgYIABAWEB46BwghEKABEApKBAhBGAFQjAdYhGtgk3JoAXAAeACAAZgBiAGCGpIBBDAuMjSYAQCgAQHAAQE&sclient=gws-wiz-serp' target="_blank"><img className="brandicon" src={googlelogo} alt="Google" /></a></p>
                                <p className='TestLtd'><FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                </p>
                            </div>
                        </Col>

                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    Ive been coming to V-TAC for 15 years and would never trust anyone else with my taxes. Nazim is professional, proficient and kind. There is no one better!
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>Omari B</strong> &nbsp; <a href='https://www.google.com/search?q=vtac+accounting+toronto&newwindow=1&bih=964&biw=2048&hl=en&sxsrf=AJOqlzVO4uETOdZdvgE05MRcsR8djpoKyg%3A1677356611124&ei=Q276Y9D5BvyfseMPwZSjsAc&ved=0ahUKEwiQp96nwLH9AhX8T2wGHUHKCHYQ4dUDCA4&uact=5&oq=vtac+accounting+toronto&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIFCCEQoAEyBQghEKABMgUIIRCgAToECCMQJzoGCAAQBRAeOgUIABCGAzoHCAAQHhDxBDoECAAQHjoFCAAQgAQ6CgguEK8BEMcBECc6CgguEMcBEK8BEEM6BAgAEEM6BQgAEJECOgoIABCABBAUEIcCOgcIABCABBAKOg4ILhCABBDHARCvARDLAToJCAAQFhAeEPEEOgYIABAWEB46BwghEKABEApKBAhBGAFQjAdYhGtgk3JoAXAAeACAAZgBiAGCGpIBBDAuMjSYAQCgAQHAAQE&sclient=gws-wiz-serp' target="_blank"><img className="brandicon" src={googlelogo} alt="Google" /></a></p>
                                <p className='TestLtd'><FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                </p>
                            </div>
                        </Col>

                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    VTAC has been doing my business and personal taxes for over 15y years. Their service professional and their staff is extremely  knowledgeable ! I would highly recommend them to anyone!
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>Omari Bellot</strong> &nbsp; <a href='https://www.google.com/search?q=vtac+accounting+toronto&newwindow=1&bih=964&biw=2048&hl=en&sxsrf=AJOqlzVO4uETOdZdvgE05MRcsR8djpoKyg%3A1677356611124&ei=Q276Y9D5BvyfseMPwZSjsAc&ved=0ahUKEwiQp96nwLH9AhX8T2wGHUHKCHYQ4dUDCA4&uact=5&oq=vtac+accounting+toronto&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIFCCEQoAEyBQghEKABMgUIIRCgAToECCMQJzoGCAAQBRAeOgUIABCGAzoHCAAQHhDxBDoECAAQHjoFCAAQgAQ6CgguEK8BEMcBECc6CgguEMcBEK8BEEM6BAgAEEM6BQgAEJECOgoIABCABBAUEIcCOgcIABCABBAKOg4ILhCABBDHARCvARDLAToJCAAQFhAeEPEEOgYIABAWEB46BwghEKABEApKBAhBGAFQjAdYhGtgk3JoAXAAeACAAZgBiAGCGpIBBDAuMjSYAQCgAQHAAQE&sclient=gws-wiz-serp' target="_blank"><img className="brandicon" src={googlelogo} alt="Google" /></a></p>
                                <p className='TestLtd'><FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                </p>
                            </div>
                        </Col>

                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    Have been going to VTAC over 15 years. They are the best!
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>leshada lee</strong> &nbsp; <a href='https://www.google.com/search?q=vtac+accounting+toronto&newwindow=1&bih=964&biw=2048&hl=en&sxsrf=AJOqlzVO4uETOdZdvgE05MRcsR8djpoKyg%3A1677356611124&ei=Q276Y9D5BvyfseMPwZSjsAc&ved=0ahUKEwiQp96nwLH9AhX8T2wGHUHKCHYQ4dUDCA4&uact=5&oq=vtac+accounting+toronto&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIFCCEQoAEyBQghEKABMgUIIRCgAToECCMQJzoGCAAQBRAeOgUIABCGAzoHCAAQHhDxBDoECAAQHjoFCAAQgAQ6CgguEK8BEMcBECc6CgguEMcBEK8BEEM6BAgAEEM6BQgAEJECOgoIABCABBAUEIcCOgcIABCABBAKOg4ILhCABBDHARCvARDLAToJCAAQFhAeEPEEOgYIABAWEB46BwghEKABEApKBAhBGAFQjAdYhGtgk3JoAXAAeACAAZgBiAGCGpIBBDAuMjSYAQCgAQHAAQE&sclient=gws-wiz-serp' target="_blank"><img className="brandicon" src={googlelogo} alt="Google" /></a></p>
                                <p className='TestLtd'><FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                </p>
                            </div>
                        </Col>

                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    Fantastic service every time!
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>Solange Rama</strong> &nbsp; <a href='https://www.google.com/search?q=vtac+accounting+toronto&newwindow=1&bih=964&biw=2048&hl=en&sxsrf=AJOqlzVO4uETOdZdvgE05MRcsR8djpoKyg%3A1677356611124&ei=Q276Y9D5BvyfseMPwZSjsAc&ved=0ahUKEwiQp96nwLH9AhX8T2wGHUHKCHYQ4dUDCA4&uact=5&oq=vtac+accounting+toronto&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIFCCEQoAEyBQghEKABMgUIIRCgAToECCMQJzoGCAAQBRAeOgUIABCGAzoHCAAQHhDxBDoECAAQHjoFCAAQgAQ6CgguEK8BEMcBECc6CgguEMcBEK8BEEM6BAgAEEM6BQgAEJECOgoIABCABBAUEIcCOgcIABCABBAKOg4ILhCABBDHARCvARDLAToJCAAQFhAeEPEEOgYIABAWEB46BwghEKABEApKBAhBGAFQjAdYhGtgk3JoAXAAeACAAZgBiAGCGpIBBDAuMjSYAQCgAQHAAQE&sclient=gws-wiz-serp' target="_blank"><img className="brandicon" src={googlelogo} alt="Google" /></a></p>
                                <p className='TestLtd'><FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                </p>
                            </div>
                        </Col>

                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    We’ve being doing our personal and corporation tax with V-TAC for a few years now and we very much recommend Nazim if you want someone knowledgeable, very organized and friendly working with you. Nazim and his team are always available to answer any questions and concerns and are always looking out for our best interest.
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>vivigarcia07</strong> &nbsp; <a href='https://www.google.com/search?q=vtac+accounting+toronto&newwindow=1&bih=964&biw=2048&hl=en&sxsrf=AJOqlzVO4uETOdZdvgE05MRcsR8djpoKyg%3A1677356611124&ei=Q276Y9D5BvyfseMPwZSjsAc&ved=0ahUKEwiQp96nwLH9AhX8T2wGHUHKCHYQ4dUDCA4&uact=5&oq=vtac+accounting+toronto&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIFCCEQoAEyBQghEKABMgUIIRCgAToECCMQJzoGCAAQBRAeOgUIABCGAzoHCAAQHhDxBDoECAAQHjoFCAAQgAQ6CgguEK8BEMcBECc6CgguEMcBEK8BEEM6BAgAEEM6BQgAEJECOgoIABCABBAUEIcCOgcIABCABBAKOg4ILhCABBDHARCvARDLAToJCAAQFhAeEPEEOgYIABAWEB46BwghEKABEApKBAhBGAFQjAdYhGtgk3JoAXAAeACAAZgBiAGCGpIBBDAuMjSYAQCgAQHAAQE&sclient=gws-wiz-serp' target="_blank"><img className="brandicon" src={googlelogo} alt="Google" /></a></p>
                                <p className='TestLtd'><FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                </p>
                            </div>
                        </Col>

                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    I have been going to Nazim for the past two years and it is always a pleasure to go see him, his staff is extremely lovely and professional Nazim is extremely knowledgable and efficient. I recommend his services 100%
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>Sam Max</strong> &nbsp; <a href='https://www.google.com/search?q=vtac+accounting+toronto&newwindow=1&bih=964&biw=2048&hl=en&sxsrf=AJOqlzVO4uETOdZdvgE05MRcsR8djpoKyg%3A1677356611124&ei=Q276Y9D5BvyfseMPwZSjsAc&ved=0ahUKEwiQp96nwLH9AhX8T2wGHUHKCHYQ4dUDCA4&uact=5&oq=vtac+accounting+toronto&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIFCCEQoAEyBQghEKABMgUIIRCgAToECCMQJzoGCAAQBRAeOgUIABCGAzoHCAAQHhDxBDoECAAQHjoFCAAQgAQ6CgguEK8BEMcBECc6CgguEMcBEK8BEEM6BAgAEEM6BQgAEJECOgoIABCABBAUEIcCOgcIABCABBAKOg4ILhCABBDHARCvARDLAToJCAAQFhAeEPEEOgYIABAWEB46BwghEKABEApKBAhBGAFQjAdYhGtgk3JoAXAAeACAAZgBiAGCGpIBBDAuMjSYAQCgAQHAAQE&sclient=gws-wiz-serp' target="_blank"><img className="brandicon" src={googlelogo} alt="Google" /></a></p>
                                <p className='TestLtd'><FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                </p>
                            </div>
                        </Col>

                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    Excellent service & great experience
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>Marcella Charles</strong> &nbsp; <a href='https://www.google.com/search?q=vtac+accounting+toronto&newwindow=1&bih=964&biw=2048&hl=en&sxsrf=AJOqlzVO4uETOdZdvgE05MRcsR8djpoKyg%3A1677356611124&ei=Q276Y9D5BvyfseMPwZSjsAc&ved=0ahUKEwiQp96nwLH9AhX8T2wGHUHKCHYQ4dUDCA4&uact=5&oq=vtac+accounting+toronto&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIFCCEQoAEyBQghEKABMgUIIRCgAToECCMQJzoGCAAQBRAeOgUIABCGAzoHCAAQHhDxBDoECAAQHjoFCAAQgAQ6CgguEK8BEMcBECc6CgguEMcBEK8BEEM6BAgAEEM6BQgAEJECOgoIABCABBAUEIcCOgcIABCABBAKOg4ILhCABBDHARCvARDLAToJCAAQFhAeEPEEOgYIABAWEB46BwghEKABEApKBAhBGAFQjAdYhGtgk3JoAXAAeACAAZgBiAGCGpIBBDAuMjSYAQCgAQHAAQE&sclient=gws-wiz-serp' target="_blank"><img className="brandicon" src={googlelogo} alt="Google" /></a></p>
                                <p className='TestLtd'><FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                </p>
                            </div>
                        </Col>

                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    Always  on point and good in service
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>Austin Ross</strong> &nbsp; <a href='https://www.google.com/search?q=vtac+accounting+toronto&newwindow=1&bih=964&biw=2048&hl=en&sxsrf=AJOqlzVO4uETOdZdvgE05MRcsR8djpoKyg%3A1677356611124&ei=Q276Y9D5BvyfseMPwZSjsAc&ved=0ahUKEwiQp96nwLH9AhX8T2wGHUHKCHYQ4dUDCA4&uact=5&oq=vtac+accounting+toronto&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIFCCEQoAEyBQghEKABMgUIIRCgAToECCMQJzoGCAAQBRAeOgUIABCGAzoHCAAQHhDxBDoECAAQHjoFCAAQgAQ6CgguEK8BEMcBECc6CgguEMcBEK8BEEM6BAgAEEM6BQgAEJECOgoIABCABBAUEIcCOgcIABCABBAKOg4ILhCABBDHARCvARDLAToJCAAQFhAeEPEEOgYIABAWEB46BwghEKABEApKBAhBGAFQjAdYhGtgk3JoAXAAeACAAZgBiAGCGpIBBDAuMjSYAQCgAQHAAQE&sclient=gws-wiz-serp' target="_blank"><img className="brandicon" src={googlelogo} alt="Google" /></a></p>
                                <p className='TestLtd'><FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                </p>
                            </div>
                        </Col>

                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    Great Staff, Great Service, highly recommended to all of my clients.
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>karlana Hosten-Ellis</strong> &nbsp; <a href='https://www.google.com/search?q=vtac+accounting+toronto&newwindow=1&bih=964&biw=2048&hl=en&sxsrf=AJOqlzVO4uETOdZdvgE05MRcsR8djpoKyg%3A1677356611124&ei=Q276Y9D5BvyfseMPwZSjsAc&ved=0ahUKEwiQp96nwLH9AhX8T2wGHUHKCHYQ4dUDCA4&uact=5&oq=vtac+accounting+toronto&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIFCCEQoAEyBQghEKABMgUIIRCgAToECCMQJzoGCAAQBRAeOgUIABCGAzoHCAAQHhDxBDoECAAQHjoFCAAQgAQ6CgguEK8BEMcBECc6CgguEMcBEK8BEEM6BAgAEEM6BQgAEJECOgoIABCABBAUEIcCOgcIABCABBAKOg4ILhCABBDHARCvARDLAToJCAAQFhAeEPEEOgYIABAWEB46BwghEKABEApKBAhBGAFQjAdYhGtgk3JoAXAAeACAAZgBiAGCGpIBBDAuMjSYAQCgAQHAAQE&sclient=gws-wiz-serp' target="_blank"><img className="brandicon" src={googlelogo} alt="Google" /></a></p>
                                <p className='TestLtd'><FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                </p>
                            </div>
                        </Col>

                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    Excellent experience and very efficient.
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>Mohammed Dashti</strong> &nbsp; <a href='https://www.google.com/search?q=vtac+accounting+toronto&newwindow=1&bih=964&biw=2048&hl=en&sxsrf=AJOqlzVO4uETOdZdvgE05MRcsR8djpoKyg%3A1677356611124&ei=Q276Y9D5BvyfseMPwZSjsAc&ved=0ahUKEwiQp96nwLH9AhX8T2wGHUHKCHYQ4dUDCA4&uact=5&oq=vtac+accounting+toronto&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIFCCEQoAEyBQghEKABMgUIIRCgAToECCMQJzoGCAAQBRAeOgUIABCGAzoHCAAQHhDxBDoECAAQHjoFCAAQgAQ6CgguEK8BEMcBECc6CgguEMcBEK8BEEM6BAgAEEM6BQgAEJECOgoIABCABBAUEIcCOgcIABCABBAKOg4ILhCABBDHARCvARDLAToJCAAQFhAeEPEEOgYIABAWEB46BwghEKABEApKBAhBGAFQjAdYhGtgk3JoAXAAeACAAZgBiAGCGpIBBDAuMjSYAQCgAQHAAQE&sclient=gws-wiz-serp' target="_blank"><img className="brandicon" src={googlelogo} alt="Google" /></a></p>
                                <p className='TestLtd'><FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                </p>
                            </div>
                        </Col>

                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    We have been doing our business tax with Vtac over 20 year and they are great. We are so happy there services.
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>Ida Ks</strong> &nbsp; <a href='https://www.google.com/search?q=vtac+accounting+toronto&newwindow=1&bih=964&biw=2048&hl=en&sxsrf=AJOqlzVO4uETOdZdvgE05MRcsR8djpoKyg%3A1677356611124&ei=Q276Y9D5BvyfseMPwZSjsAc&ved=0ahUKEwiQp96nwLH9AhX8T2wGHUHKCHYQ4dUDCA4&uact=5&oq=vtac+accounting+toronto&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIFCCEQoAEyBQghEKABMgUIIRCgAToECCMQJzoGCAAQBRAeOgUIABCGAzoHCAAQHhDxBDoECAAQHjoFCAAQgAQ6CgguEK8BEMcBECc6CgguEMcBEK8BEEM6BAgAEEM6BQgAEJECOgoIABCABBAUEIcCOgcIABCABBAKOg4ILhCABBDHARCvARDLAToJCAAQFhAeEPEEOgYIABAWEB46BwghEKABEApKBAhBGAFQjAdYhGtgk3JoAXAAeACAAZgBiAGCGpIBBDAuMjSYAQCgAQHAAQE&sclient=gws-wiz-serp' target="_blank"><img className="brandicon" src={googlelogo} alt="Google" /></a></p>
                                <p className='TestLtd'><FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                </p>
                            </div>
                        </Col>

                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    My family has been doing out taxes with V-TAC for 12-13 years now. Highly recommended. :) Great people and quick service.
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>Monique Morgan</strong> &nbsp; <a href='https://www.google.com/search?q=vtac+accounting+toronto&newwindow=1&bih=964&biw=2048&hl=en&sxsrf=AJOqlzVO4uETOdZdvgE05MRcsR8djpoKyg%3A1677356611124&ei=Q276Y9D5BvyfseMPwZSjsAc&ved=0ahUKEwiQp96nwLH9AhX8T2wGHUHKCHYQ4dUDCA4&uact=5&oq=vtac+accounting+toronto&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIFCCEQoAEyBQghEKABMgUIIRCgAToECCMQJzoGCAAQBRAeOgUIABCGAzoHCAAQHhDxBDoECAAQHjoFCAAQgAQ6CgguEK8BEMcBECc6CgguEMcBEK8BEEM6BAgAEEM6BQgAEJECOgoIABCABBAUEIcCOgcIABCABBAKOg4ILhCABBDHARCvARDLAToJCAAQFhAeEPEEOgYIABAWEB46BwghEKABEApKBAhBGAFQjAdYhGtgk3JoAXAAeACAAZgBiAGCGpIBBDAuMjSYAQCgAQHAAQE&sclient=gws-wiz-serp' target="_blank"><img className="brandicon" src={googlelogo} alt="Google" /></a></p>
                                <p className='TestLtd'><FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                </p>
                            </div>
                        </Col>

                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    We have been  doing our business taxes and accounting work with V-TAC accounting for twenty years. They are highly reccommended as one of the most professional friendly caring environment.
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>Maryann Kinsella</strong> &nbsp; <a href='https://www.google.com/search?q=vtac+accounting+toronto&newwindow=1&bih=964&biw=2048&hl=en&sxsrf=AJOqlzVO4uETOdZdvgE05MRcsR8djpoKyg%3A1677356611124&ei=Q276Y9D5BvyfseMPwZSjsAc&ved=0ahUKEwiQp96nwLH9AhX8T2wGHUHKCHYQ4dUDCA4&uact=5&oq=vtac+accounting+toronto&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIFCCEQoAEyBQghEKABMgUIIRCgAToECCMQJzoGCAAQBRAeOgUIABCGAzoHCAAQHhDxBDoECAAQHjoFCAAQgAQ6CgguEK8BEMcBECc6CgguEMcBEK8BEEM6BAgAEEM6BQgAEJECOgoIABCABBAUEIcCOgcIABCABBAKOg4ILhCABBDHARCvARDLAToJCAAQFhAeEPEEOgYIABAWEB46BwghEKABEApKBAhBGAFQjAdYhGtgk3JoAXAAeACAAZgBiAGCGpIBBDAuMjSYAQCgAQHAAQE&sclient=gws-wiz-serp' target="_blank"><img className="brandicon" src={googlelogo} alt="Google" /></a></p>
                                <p className='TestLtd'><FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                </p>
                            </div>
                        </Col>

                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    Been with Vtac many many years for business and personal! Nazeem is by far the absolute best. Honest and definitely works for his clients I recommend vtac 100%
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>Cass Kinsella</strong> &nbsp; <a href='https://www.google.com/search?q=vtac+accounting+toronto&newwindow=1&bih=964&biw=2048&hl=en&sxsrf=AJOqlzVO4uETOdZdvgE05MRcsR8djpoKyg%3A1677356611124&ei=Q276Y9D5BvyfseMPwZSjsAc&ved=0ahUKEwiQp96nwLH9AhX8T2wGHUHKCHYQ4dUDCA4&uact=5&oq=vtac+accounting+toronto&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIFCCEQoAEyBQghEKABMgUIIRCgAToECCMQJzoGCAAQBRAeOgUIABCGAzoHCAAQHhDxBDoECAAQHjoFCAAQgAQ6CgguEK8BEMcBECc6CgguEMcBEK8BEEM6BAgAEEM6BQgAEJECOgoIABCABBAUEIcCOgcIABCABBAKOg4ILhCABBDHARCvARDLAToJCAAQFhAeEPEEOgYIABAWEB46BwghEKABEApKBAhBGAFQjAdYhGtgk3JoAXAAeACAAZgBiAGCGpIBBDAuMjSYAQCgAQHAAQE&sclient=gws-wiz-serp' target="_blank"><img className="brandicon" src={googlelogo} alt="Google" /></a></p>
                                <p className='TestLtd'><FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                </p>
                            </div>
                        </Col>

                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    V tac accounting is just wonderful!
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>Lucy Cruz</strong> &nbsp; <a href='https://www.google.com/search?q=vtac+accounting+toronto&newwindow=1&bih=964&biw=2048&hl=en&sxsrf=AJOqlzVO4uETOdZdvgE05MRcsR8djpoKyg%3A1677356611124&ei=Q276Y9D5BvyfseMPwZSjsAc&ved=0ahUKEwiQp96nwLH9AhX8T2wGHUHKCHYQ4dUDCA4&uact=5&oq=vtac+accounting+toronto&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIFCCEQoAEyBQghEKABMgUIIRCgAToECCMQJzoGCAAQBRAeOgUIABCGAzoHCAAQHhDxBDoECAAQHjoFCAAQgAQ6CgguEK8BEMcBECc6CgguEMcBEK8BEEM6BAgAEEM6BQgAEJECOgoIABCABBAUEIcCOgcIABCABBAKOg4ILhCABBDHARCvARDLAToJCAAQFhAeEPEEOgYIABAWEB46BwghEKABEApKBAhBGAFQjAdYhGtgk3JoAXAAeACAAZgBiAGCGpIBBDAuMjSYAQCgAQHAAQE&sclient=gws-wiz-serp' target="_blank"><img className="brandicon" src={googlelogo} alt="Google" /></a></p>
                                <p className='TestLtd'><FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                </p>
                            </div>
                        </Col>

                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    Nasim and his people are very profesional and patient, we've been doing our taxes with them for 20 years.
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>richard valverde</strong> &nbsp; <a href='https://www.google.com/search?q=vtac+accounting+toronto&newwindow=1&bih=964&biw=2048&hl=en&sxsrf=AJOqlzVO4uETOdZdvgE05MRcsR8djpoKyg%3A1677356611124&ei=Q276Y9D5BvyfseMPwZSjsAc&ved=0ahUKEwiQp96nwLH9AhX8T2wGHUHKCHYQ4dUDCA4&uact=5&oq=vtac+accounting+toronto&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIFCCEQoAEyBQghEKABMgUIIRCgAToECCMQJzoGCAAQBRAeOgUIABCGAzoHCAAQHhDxBDoECAAQHjoFCAAQgAQ6CgguEK8BEMcBECc6CgguEMcBEK8BEEM6BAgAEEM6BQgAEJECOgoIABCABBAUEIcCOgcIABCABBAKOg4ILhCABBDHARCvARDLAToJCAAQFhAeEPEEOgYIABAWEB46BwghEKABEApKBAhBGAFQjAdYhGtgk3JoAXAAeACAAZgBiAGCGpIBBDAuMjSYAQCgAQHAAQE&sclient=gws-wiz-serp' target="_blank"><img className="brandicon" src={googlelogo} alt="Google" /></a></p>
                                <p className='TestLtd'><FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                </p>
                            </div>
                        </Col>

                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    This office does the taxes for my whole family and they are very professional.
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>D Collisson (PKD)</strong> &nbsp; <a href='https://www.google.com/search?q=vtac+accounting+toronto&newwindow=1&bih=964&biw=2048&hl=en&sxsrf=AJOqlzVO4uETOdZdvgE05MRcsR8djpoKyg%3A1677356611124&ei=Q276Y9D5BvyfseMPwZSjsAc&ved=0ahUKEwiQp96nwLH9AhX8T2wGHUHKCHYQ4dUDCA4&uact=5&oq=vtac+accounting+toronto&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIFCCEQoAEyBQghEKABMgUIIRCgAToECCMQJzoGCAAQBRAeOgUIABCGAzoHCAAQHhDxBDoECAAQHjoFCAAQgAQ6CgguEK8BEMcBECc6CgguEMcBEK8BEEM6BAgAEEM6BQgAEJECOgoIABCABBAUEIcCOgcIABCABBAKOg4ILhCABBDHARCvARDLAToJCAAQFhAeEPEEOgYIABAWEB46BwghEKABEApKBAhBGAFQjAdYhGtgk3JoAXAAeACAAZgBiAGCGpIBBDAuMjSYAQCgAQHAAQE&sclient=gws-wiz-serp' target="_blank"><img className="brandicon" src={googlelogo} alt="Google" /></a></p>
                                <p className='TestLtd'><FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                </p>
                            </div>
                        </Col>

                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    Been with V-Tac for 20 years. Very satisfied  with the excellent friendly service.
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>Junior Musheni</strong> &nbsp; <a href='https://www.google.com/search?q=vtac+accounting+toronto&newwindow=1&bih=964&biw=2048&hl=en&sxsrf=AJOqlzVO4uETOdZdvgE05MRcsR8djpoKyg%3A1677356611124&ei=Q276Y9D5BvyfseMPwZSjsAc&ved=0ahUKEwiQp96nwLH9AhX8T2wGHUHKCHYQ4dUDCA4&uact=5&oq=vtac+accounting+toronto&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIFCCEQoAEyBQghEKABMgUIIRCgAToECCMQJzoGCAAQBRAeOgUIABCGAzoHCAAQHhDxBDoECAAQHjoFCAAQgAQ6CgguEK8BEMcBECc6CgguEMcBEK8BEEM6BAgAEEM6BQgAEJECOgoIABCABBAUEIcCOgcIABCABBAKOg4ILhCABBDHARCvARDLAToJCAAQFhAeEPEEOgYIABAWEB46BwghEKABEApKBAhBGAFQjAdYhGtgk3JoAXAAeACAAZgBiAGCGpIBBDAuMjSYAQCgAQHAAQE&sclient=gws-wiz-serp' target="_blank"><img className="brandicon" src={googlelogo} alt="Google" /></a></p>
                                <p className='TestLtd'><FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                </p>
                            </div>
                        </Col>

                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    Very great service the accountant was super nice and crystal clear in his explanation. I 100% recommend them.
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>Sash G</strong> &nbsp; <a href='https://www.google.com/search?q=vtac+accounting+toronto&newwindow=1&bih=964&biw=2048&hl=en&sxsrf=AJOqlzVO4uETOdZdvgE05MRcsR8djpoKyg%3A1677356611124&ei=Q276Y9D5BvyfseMPwZSjsAc&ved=0ahUKEwiQp96nwLH9AhX8T2wGHUHKCHYQ4dUDCA4&uact=5&oq=vtac+accounting+toronto&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIFCCEQoAEyBQghEKABMgUIIRCgAToECCMQJzoGCAAQBRAeOgUIABCGAzoHCAAQHhDxBDoECAAQHjoFCAAQgAQ6CgguEK8BEMcBECc6CgguEMcBEK8BEEM6BAgAEEM6BQgAEJECOgoIABCABBAUEIcCOgcIABCABBAKOg4ILhCABBDHARCvARDLAToJCAAQFhAeEPEEOgYIABAWEB46BwghEKABEApKBAhBGAFQjAdYhGtgk3JoAXAAeACAAZgBiAGCGpIBBDAuMjSYAQCgAQHAAQE&sclient=gws-wiz-serp' target="_blank"><img className="brandicon" src={googlelogo} alt="Google" /></a></p>
                                <p className='TestLtd'><FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                </p>
                            </div>
                        </Col>

                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    The best, I have been going her for over 14yrs + recommended others and always the best experience. Don't forget the friendliest staff
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>Rozie S</strong> &nbsp; <a href='https://www.google.com/search?q=vtac+accounting+toronto&newwindow=1&bih=964&biw=2048&hl=en&sxsrf=AJOqlzVO4uETOdZdvgE05MRcsR8djpoKyg%3A1677356611124&ei=Q276Y9D5BvyfseMPwZSjsAc&ved=0ahUKEwiQp96nwLH9AhX8T2wGHUHKCHYQ4dUDCA4&uact=5&oq=vtac+accounting+toronto&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIFCCEQoAEyBQghEKABMgUIIRCgAToECCMQJzoGCAAQBRAeOgUIABCGAzoHCAAQHhDxBDoECAAQHjoFCAAQgAQ6CgguEK8BEMcBECc6CgguEMcBEK8BEEM6BAgAEEM6BQgAEJECOgoIABCABBAUEIcCOgcIABCABBAKOg4ILhCABBDHARCvARDLAToJCAAQFhAeEPEEOgYIABAWEB46BwghEKABEApKBAhBGAFQjAdYhGtgk3JoAXAAeACAAZgBiAGCGpIBBDAuMjSYAQCgAQHAAQE&sclient=gws-wiz-serp' target="_blank"><img className="brandicon" src={googlelogo} alt="Google" /></a></p>
                                <p className='TestLtd'><FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                </p>
                            </div>
                        </Col>

                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    Excellent accountant! Been coming to VTAC for many years. Polite and professional company for personal and business taxes.
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>dennise ngo</strong> &nbsp; <a href='https://www.google.com/search?q=vtac+accounting+toronto&newwindow=1&bih=964&biw=2048&hl=en&sxsrf=AJOqlzVO4uETOdZdvgE05MRcsR8djpoKyg%3A1677356611124&ei=Q276Y9D5BvyfseMPwZSjsAc&ved=0ahUKEwiQp96nwLH9AhX8T2wGHUHKCHYQ4dUDCA4&uact=5&oq=vtac+accounting+toronto&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIFCCEQoAEyBQghEKABMgUIIRCgAToECCMQJzoGCAAQBRAeOgUIABCGAzoHCAAQHhDxBDoECAAQHjoFCAAQgAQ6CgguEK8BEMcBECc6CgguEMcBEK8BEEM6BAgAEEM6BQgAEJECOgoIABCABBAUEIcCOgcIABCABBAKOg4ILhCABBDHARCvARDLAToJCAAQFhAeEPEEOgYIABAWEB46BwghEKABEApKBAhBGAFQjAdYhGtgk3JoAXAAeACAAZgBiAGCGpIBBDAuMjSYAQCgAQHAAQE&sclient=gws-wiz-serp' target="_blank"><img className="brandicon" src={googlelogo} alt="Google" /></a></p>
                                <p className='TestLtd'><FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                </p>
                            </div>
                        </Col>

                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    I've been doing my tax here for 15 yes and I am so happy with my return. Nazim is the best!!
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>Criss Criss</strong> &nbsp; <a href='https://www.google.com/search?q=vtac+accounting+toronto&newwindow=1&bih=964&biw=2048&hl=en&sxsrf=AJOqlzVO4uETOdZdvgE05MRcsR8djpoKyg%3A1677356611124&ei=Q276Y9D5BvyfseMPwZSjsAc&ved=0ahUKEwiQp96nwLH9AhX8T2wGHUHKCHYQ4dUDCA4&uact=5&oq=vtac+accounting+toronto&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIFCCEQoAEyBQghEKABMgUIIRCgAToECCMQJzoGCAAQBRAeOgUIABCGAzoHCAAQHhDxBDoECAAQHjoFCAAQgAQ6CgguEK8BEMcBECc6CgguEMcBEK8BEEM6BAgAEEM6BQgAEJECOgoIABCABBAUEIcCOgcIABCABBAKOg4ILhCABBDHARCvARDLAToJCAAQFhAeEPEEOgYIABAWEB46BwghEKABEApKBAhBGAFQjAdYhGtgk3JoAXAAeACAAZgBiAGCGpIBBDAuMjSYAQCgAQHAAQE&sclient=gws-wiz-serp' target="_blank"><img className="brandicon" src={googlelogo} alt="Google" /></a></p>
                                <p className='TestLtd'><FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                </p>
                            </div>
                        </Col>

                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    Every year we make our taxes here, very proffesional team, lovely location, job done very fast , always happy. Thank you vtac team. 😊
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>P G</strong> &nbsp; <a href='https://www.google.com/search?q=vtac+accounting+toronto&newwindow=1&bih=964&biw=2048&hl=en&sxsrf=AJOqlzVO4uETOdZdvgE05MRcsR8djpoKyg%3A1677356611124&ei=Q276Y9D5BvyfseMPwZSjsAc&ved=0ahUKEwiQp96nwLH9AhX8T2wGHUHKCHYQ4dUDCA4&uact=5&oq=vtac+accounting+toronto&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIFCCEQoAEyBQghEKABMgUIIRCgAToECCMQJzoGCAAQBRAeOgUIABCGAzoHCAAQHhDxBDoECAAQHjoFCAAQgAQ6CgguEK8BEMcBECc6CgguEMcBEK8BEEM6BAgAEEM6BQgAEJECOgoIABCABBAUEIcCOgcIABCABBAKOg4ILhCABBDHARCvARDLAToJCAAQFhAeEPEEOgYIABAWEB46BwghEKABEApKBAhBGAFQjAdYhGtgk3JoAXAAeACAAZgBiAGCGpIBBDAuMjSYAQCgAQHAAQE&sclient=gws-wiz-serp' target="_blank"><img className="brandicon" src={googlelogo} alt="Google" /></a></p>
                                <p className='TestLtd'><FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                </p>
                            </div>
                        </Col>

                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    VTac accounting, Nazim, and team have been apart of our lives for more than 22 years.  Nazim and team have been professional, efficient, dependable, and accurate over two decades.  Nazim has handled personal and business taxes for myself, husband, daughter, and countless friends and family.  I have had my taxes done with Nazim since I was a teen, as a student, as a self employed person, an employee, and while unemployed and going through chemotherapy.   He cares about his clients and always makes time for you.  He provides an ease of doing business and it truly feels like youre visiting family when you walk into this office! Thank you Nazim and team you are truly amazing!
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>Colleen Ramage</strong> &nbsp; <a href='https://www.google.com/search?q=vtac+accounting+toronto&newwindow=1&bih=964&biw=2048&hl=en&sxsrf=AJOqlzVO4uETOdZdvgE05MRcsR8djpoKyg%3A1677356611124&ei=Q276Y9D5BvyfseMPwZSjsAc&ved=0ahUKEwiQp96nwLH9AhX8T2wGHUHKCHYQ4dUDCA4&uact=5&oq=vtac+accounting+toronto&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIFCCEQoAEyBQghEKABMgUIIRCgAToECCMQJzoGCAAQBRAeOgUIABCGAzoHCAAQHhDxBDoECAAQHjoFCAAQgAQ6CgguEK8BEMcBECc6CgguEMcBEK8BEEM6BAgAEEM6BQgAEJECOgoIABCABBAUEIcCOgcIABCABBAKOg4ILhCABBDHARCvARDLAToJCAAQFhAeEPEEOgYIABAWEB46BwghEKABEApKBAhBGAFQjAdYhGtgk3JoAXAAeACAAZgBiAGCGpIBBDAuMjSYAQCgAQHAAQE&sclient=gws-wiz-serp' target="_blank"><img className="brandicon" src={googlelogo} alt="Google" /></a></p>
                                <p className='TestLtd'><FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                </p>
                            </div>
                        </Col>

                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    I’ve been getting our payroll and corporate taxes done at V-Tac for over 10 years! They offer excellent service and competitive rates. They were also able to offer guidance for my growing business, advised when it was time to incorporate, and completed that process for us.
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>P Robinson</strong> &nbsp; <a href='https://www.google.com/search?q=vtac+accounting+toronto&newwindow=1&bih=964&biw=2048&hl=en&sxsrf=AJOqlzVO4uETOdZdvgE05MRcsR8djpoKyg%3A1677356611124&ei=Q276Y9D5BvyfseMPwZSjsAc&ved=0ahUKEwiQp96nwLH9AhX8T2wGHUHKCHYQ4dUDCA4&uact=5&oq=vtac+accounting+toronto&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIFCCEQoAEyBQghEKABMgUIIRCgAToECCMQJzoGCAAQBRAeOgUIABCGAzoHCAAQHhDxBDoECAAQHjoFCAAQgAQ6CgguEK8BEMcBECc6CgguEMcBEK8BEEM6BAgAEEM6BQgAEJECOgoIABCABBAUEIcCOgcIABCABBAKOg4ILhCABBDHARCvARDLAToJCAAQFhAeEPEEOgYIABAWEB46BwghEKABEApKBAhBGAFQjAdYhGtgk3JoAXAAeACAAZgBiAGCGpIBBDAuMjSYAQCgAQHAAQE&sclient=gws-wiz-serp' target="_blank"><img className="brandicon" src={googlelogo} alt="Google" /></a></p>
                                <p className='TestLtd'><FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                </p>
                            </div>
                        </Col>

                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    V tac I am happy  for your service
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>Artie Rodriguez</strong> &nbsp; <a href='https://www.google.com/search?q=vtac+accounting+toronto&newwindow=1&bih=964&biw=2048&hl=en&sxsrf=AJOqlzVO4uETOdZdvgE05MRcsR8djpoKyg%3A1677356611124&ei=Q276Y9D5BvyfseMPwZSjsAc&ved=0ahUKEwiQp96nwLH9AhX8T2wGHUHKCHYQ4dUDCA4&uact=5&oq=vtac+accounting+toronto&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIFCCEQoAEyBQghEKABMgUIIRCgAToECCMQJzoGCAAQBRAeOgUIABCGAzoHCAAQHhDxBDoECAAQHjoFCAAQgAQ6CgguEK8BEMcBECc6CgguEMcBEK8BEEM6BAgAEEM6BQgAEJECOgoIABCABBAUEIcCOgcIABCABBAKOg4ILhCABBDHARCvARDLAToJCAAQFhAeEPEEOgYIABAWEB46BwghEKABEApKBAhBGAFQjAdYhGtgk3JoAXAAeACAAZgBiAGCGpIBBDAuMjSYAQCgAQHAAQE&sclient=gws-wiz-serp' target="_blank"><img className="brandicon" src={googlelogo} alt="Google" /></a></p>
                                <p className='TestLtd'><FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                </p>
                            </div>
                        </Col>

                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    Been coming here over 10+ years, really good service. Staff are very friendly. Very little waiting times.
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>Salli William</strong> &nbsp; <a href='https://www.google.com/search?q=vtac+accounting+toronto&newwindow=1&bih=964&biw=2048&hl=en&sxsrf=AJOqlzVO4uETOdZdvgE05MRcsR8djpoKyg%3A1677356611124&ei=Q276Y9D5BvyfseMPwZSjsAc&ved=0ahUKEwiQp96nwLH9AhX8T2wGHUHKCHYQ4dUDCA4&uact=5&oq=vtac+accounting+toronto&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIFCCEQoAEyBQghEKABMgUIIRCgAToECCMQJzoGCAAQBRAeOgUIABCGAzoHCAAQHhDxBDoECAAQHjoFCAAQgAQ6CgguEK8BEMcBECc6CgguEMcBEK8BEEM6BAgAEEM6BQgAEJECOgoIABCABBAUEIcCOgcIABCABBAKOg4ILhCABBDHARCvARDLAToJCAAQFhAeEPEEOgYIABAWEB46BwghEKABEApKBAhBGAFQjAdYhGtgk3JoAXAAeACAAZgBiAGCGpIBBDAuMjSYAQCgAQHAAQE&sclient=gws-wiz-serp' target="_blank"><img className="brandicon" src={googlelogo} alt="Google" /></a></p>
                                <p className='TestLtd'><FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                </p>
                            </div>
                        </Col>

                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    Great service! Reliable
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>vini m</strong> &nbsp; <a href='https://www.google.com/search?q=vtac+accounting+toronto&newwindow=1&bih=964&biw=2048&hl=en&sxsrf=AJOqlzVO4uETOdZdvgE05MRcsR8djpoKyg%3A1677356611124&ei=Q276Y9D5BvyfseMPwZSjsAc&ved=0ahUKEwiQp96nwLH9AhX8T2wGHUHKCHYQ4dUDCA4&uact=5&oq=vtac+accounting+toronto&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIFCCEQoAEyBQghEKABMgUIIRCgAToECCMQJzoGCAAQBRAeOgUIABCGAzoHCAAQHhDxBDoECAAQHjoFCAAQgAQ6CgguEK8BEMcBECc6CgguEMcBEK8BEEM6BAgAEEM6BQgAEJECOgoIABCABBAUEIcCOgcIABCABBAKOg4ILhCABBDHARCvARDLAToJCAAQFhAeEPEEOgYIABAWEB46BwghEKABEApKBAhBGAFQjAdYhGtgk3JoAXAAeACAAZgBiAGCGpIBBDAuMjSYAQCgAQHAAQE&sclient=gws-wiz-serp' target="_blank"><img className="brandicon" src={googlelogo} alt="Google" /></a></p>
                                <p className='TestLtd'><FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                </p>
                            </div>
                        </Col>

                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    Amazing service, been coming here since 2018 and won’t stop coming.
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>Wayne Murray</strong> &nbsp; <a href='https://www.google.com/search?q=vtac+accounting+toronto&newwindow=1&bih=964&biw=2048&hl=en&sxsrf=AJOqlzVO4uETOdZdvgE05MRcsR8djpoKyg%3A1677356611124&ei=Q276Y9D5BvyfseMPwZSjsAc&ved=0ahUKEwiQp96nwLH9AhX8T2wGHUHKCHYQ4dUDCA4&uact=5&oq=vtac+accounting+toronto&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIFCCEQoAEyBQghEKABMgUIIRCgAToECCMQJzoGCAAQBRAeOgUIABCGAzoHCAAQHhDxBDoECAAQHjoFCAAQgAQ6CgguEK8BEMcBECc6CgguEMcBEK8BEEM6BAgAEEM6BQgAEJECOgoIABCABBAUEIcCOgcIABCABBAKOg4ILhCABBDHARCvARDLAToJCAAQFhAeEPEEOgYIABAWEB46BwghEKABEApKBAhBGAFQjAdYhGtgk3JoAXAAeACAAZgBiAGCGpIBBDAuMjSYAQCgAQHAAQE&sclient=gws-wiz-serp' target="_blank"><img className="brandicon" src={googlelogo} alt="Google" /></a></p>
                                <p className='TestLtd'><FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                </p>
                            </div>
                        </Col>

                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    I been coming here for a couple of years, after my dad introduced me, since he has been coming here since forever. I haven’t had a bad experience once. The workers here are all friendly and very efficient.
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>cristian sanchez</strong> &nbsp; <a href='https://www.google.com/search?q=vtac+accounting+toronto&newwindow=1&bih=964&biw=2048&hl=en&sxsrf=AJOqlzVO4uETOdZdvgE05MRcsR8djpoKyg%3A1677356611124&ei=Q276Y9D5BvyfseMPwZSjsAc&ved=0ahUKEwiQp96nwLH9AhX8T2wGHUHKCHYQ4dUDCA4&uact=5&oq=vtac+accounting+toronto&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIFCCEQoAEyBQghEKABMgUIIRCgAToECCMQJzoGCAAQBRAeOgUIABCGAzoHCAAQHhDxBDoECAAQHjoFCAAQgAQ6CgguEK8BEMcBECc6CgguEMcBEK8BEEM6BAgAEEM6BQgAEJECOgoIABCABBAUEIcCOgcIABCABBAKOg4ILhCABBDHARCvARDLAToJCAAQFhAeEPEEOgYIABAWEB46BwghEKABEApKBAhBGAFQjAdYhGtgk3JoAXAAeACAAZgBiAGCGpIBBDAuMjSYAQCgAQHAAQE&sclient=gws-wiz-serp' target="_blank"><img className="brandicon" src={googlelogo} alt="Google" /></a></p>
                                <p className='TestLtd'><FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                </p>
                            </div>
                        </Col>

                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    Great tax services! Have been going for years with my family. Never encountered any issues. Super friendly and knowledgeable.
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>Latifa O</strong> &nbsp; <a href='https://www.google.com/search?q=vtac+accounting+toronto&newwindow=1&bih=964&biw=2048&hl=en&sxsrf=AJOqlzVO4uETOdZdvgE05MRcsR8djpoKyg%3A1677356611124&ei=Q276Y9D5BvyfseMPwZSjsAc&ved=0ahUKEwiQp96nwLH9AhX8T2wGHUHKCHYQ4dUDCA4&uact=5&oq=vtac+accounting+toronto&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIFCCEQoAEyBQghEKABMgUIIRCgAToECCMQJzoGCAAQBRAeOgUIABCGAzoHCAAQHhDxBDoECAAQHjoFCAAQgAQ6CgguEK8BEMcBECc6CgguEMcBEK8BEEM6BAgAEEM6BQgAEJECOgoIABCABBAUEIcCOgcIABCABBAKOg4ILhCABBDHARCvARDLAToJCAAQFhAeEPEEOgYIABAWEB46BwghEKABEApKBAhBGAFQjAdYhGtgk3JoAXAAeACAAZgBiAGCGpIBBDAuMjSYAQCgAQHAAQE&sclient=gws-wiz-serp' target="_blank"><img className="brandicon" src={googlelogo} alt="Google" /></a></p>
                                <p className='TestLtd'><FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                </p>
                            </div>
                        </Col>

                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    Good service, my family came here for many years. Very reliable and quick service at a good price.
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>Yusuf Osman</strong> &nbsp; <a href='https://www.google.com/search?q=vtac+accounting+toronto&newwindow=1&bih=964&biw=2048&hl=en&sxsrf=AJOqlzVO4uETOdZdvgE05MRcsR8djpoKyg%3A1677356611124&ei=Q276Y9D5BvyfseMPwZSjsAc&ved=0ahUKEwiQp96nwLH9AhX8T2wGHUHKCHYQ4dUDCA4&uact=5&oq=vtac+accounting+toronto&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIFCCEQoAEyBQghEKABMgUIIRCgAToECCMQJzoGCAAQBRAeOgUIABCGAzoHCAAQHhDxBDoECAAQHjoFCAAQgAQ6CgguEK8BEMcBECc6CgguEMcBEK8BEEM6BAgAEEM6BQgAEJECOgoIABCABBAUEIcCOgcIABCABBAKOg4ILhCABBDHARCvARDLAToJCAAQFhAeEPEEOgYIABAWEB46BwghEKABEApKBAhBGAFQjAdYhGtgk3JoAXAAeACAAZgBiAGCGpIBBDAuMjSYAQCgAQHAAQE&sclient=gws-wiz-serp' target="_blank"><img className="brandicon" src={googlelogo} alt="Google" /></a></p>
                                <p className='TestLtd'><FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                </p>
                            </div>
                        </Col>

                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    Very quick and easy. Great customer service.
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>kay bee</strong> &nbsp; <a href='https://www.google.com/search?q=vtac+accounting+toronto&newwindow=1&bih=964&biw=2048&hl=en&sxsrf=AJOqlzVO4uETOdZdvgE05MRcsR8djpoKyg%3A1677356611124&ei=Q276Y9D5BvyfseMPwZSjsAc&ved=0ahUKEwiQp96nwLH9AhX8T2wGHUHKCHYQ4dUDCA4&uact=5&oq=vtac+accounting+toronto&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIFCCEQoAEyBQghEKABMgUIIRCgAToECCMQJzoGCAAQBRAeOgUIABCGAzoHCAAQHhDxBDoECAAQHjoFCAAQgAQ6CgguEK8BEMcBECc6CgguEMcBEK8BEEM6BAgAEEM6BQgAEJECOgoIABCABBAUEIcCOgcIABCABBAKOg4ILhCABBDHARCvARDLAToJCAAQFhAeEPEEOgYIABAWEB46BwghEKABEApKBAhBGAFQjAdYhGtgk3JoAXAAeACAAZgBiAGCGpIBBDAuMjSYAQCgAQHAAQE&sclient=gws-wiz-serp' target="_blank"><img className="brandicon" src={googlelogo} alt="Google" /></a></p>
                                <p className='TestLtd'><FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                </p>
                            </div>
                        </Col>

                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    The best service in Toronto, I've been doing my taxes for 10 years now!!
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>James Guglielmin</strong> &nbsp; <a href='https://www.google.com/search?q=vtac+accounting+toronto&newwindow=1&bih=964&biw=2048&hl=en&sxsrf=AJOqlzVO4uETOdZdvgE05MRcsR8djpoKyg%3A1677356611124&ei=Q276Y9D5BvyfseMPwZSjsAc&ved=0ahUKEwiQp96nwLH9AhX8T2wGHUHKCHYQ4dUDCA4&uact=5&oq=vtac+accounting+toronto&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIFCCEQoAEyBQghEKABMgUIIRCgAToECCMQJzoGCAAQBRAeOgUIABCGAzoHCAAQHhDxBDoECAAQHjoFCAAQgAQ6CgguEK8BEMcBECc6CgguEMcBEK8BEEM6BAgAEEM6BQgAEJECOgoIABCABBAUEIcCOgcIABCABBAKOg4ILhCABBDHARCvARDLAToJCAAQFhAeEPEEOgYIABAWEB46BwghEKABEApKBAhBGAFQjAdYhGtgk3JoAXAAeACAAZgBiAGCGpIBBDAuMjSYAQCgAQHAAQE&sclient=gws-wiz-serp' target="_blank"><img className="brandicon" src={googlelogo} alt="Google" /></a></p>
                                <p className='TestLtd'><FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                </p>
                            </div>
                        </Col>

                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    The best I ever since 2005 my FRIST time with doing tax .  They are wonderful service for the deaf!  Very help to solved taxes for me!! Very satisfied company! Thank you very much
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>Manny Amaral</strong> &nbsp; <a href='https://www.google.com/search?q=vtac+accounting+toronto&newwindow=1&bih=964&biw=2048&hl=en&sxsrf=AJOqlzVO4uETOdZdvgE05MRcsR8djpoKyg%3A1677356611124&ei=Q276Y9D5BvyfseMPwZSjsAc&ved=0ahUKEwiQp96nwLH9AhX8T2wGHUHKCHYQ4dUDCA4&uact=5&oq=vtac+accounting+toronto&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIFCCEQoAEyBQghEKABMgUIIRCgAToECCMQJzoGCAAQBRAeOgUIABCGAzoHCAAQHhDxBDoECAAQHjoFCAAQgAQ6CgguEK8BEMcBECc6CgguEMcBEK8BEEM6BAgAEEM6BQgAEJECOgoIABCABBAUEIcCOgcIABCABBAKOg4ILhCABBDHARCvARDLAToJCAAQFhAeEPEEOgYIABAWEB46BwghEKABEApKBAhBGAFQjAdYhGtgk3JoAXAAeACAAZgBiAGCGpIBBDAuMjSYAQCgAQHAAQE&sclient=gws-wiz-serp' target="_blank"><img className="brandicon" src={googlelogo} alt="Google" /></a></p>
                                <p className='TestLtd'><FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                </p>
                            </div>
                        </Col>

                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    Nazim is nothing less than amazing, Professional, and friendly. Answers all your questions. And very informative. I have been going to V-tac for over 18 years and plan on staying for 40 more.
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>Jurgen M</strong> &nbsp; <a href='https://www.google.com/search?q=vtac+accounting+toronto&newwindow=1&bih=964&biw=2048&hl=en&sxsrf=AJOqlzVO4uETOdZdvgE05MRcsR8djpoKyg%3A1677356611124&ei=Q276Y9D5BvyfseMPwZSjsAc&ved=0ahUKEwiQp96nwLH9AhX8T2wGHUHKCHYQ4dUDCA4&uact=5&oq=vtac+accounting+toronto&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIFCCEQoAEyBQghEKABMgUIIRCgAToECCMQJzoGCAAQBRAeOgUIABCGAzoHCAAQHhDxBDoECAAQHjoFCAAQgAQ6CgguEK8BEMcBECc6CgguEMcBEK8BEEM6BAgAEEM6BQgAEJECOgoIABCABBAUEIcCOgcIABCABBAKOg4ILhCABBDHARCvARDLAToJCAAQFhAeEPEEOgYIABAWEB46BwghEKABEApKBAhBGAFQjAdYhGtgk3JoAXAAeACAAZgBiAGCGpIBBDAuMjSYAQCgAQHAAQE&sclient=gws-wiz-serp' target="_blank"><img className="brandicon" src={googlelogo} alt="Google" /></a></p>
                                <p className='TestLtd'><FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                </p>
                            </div>
                        </Col>

                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    Very good company is worth it.
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>Kevin Stolte</strong> &nbsp; <a href='https://www.google.com/search?q=vtac+accounting+toronto&newwindow=1&bih=964&biw=2048&hl=en&sxsrf=AJOqlzVO4uETOdZdvgE05MRcsR8djpoKyg%3A1677356611124&ei=Q276Y9D5BvyfseMPwZSjsAc&ved=0ahUKEwiQp96nwLH9AhX8T2wGHUHKCHYQ4dUDCA4&uact=5&oq=vtac+accounting+toronto&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIFCCEQoAEyBQghEKABMgUIIRCgAToECCMQJzoGCAAQBRAeOgUIABCGAzoHCAAQHhDxBDoECAAQHjoFCAAQgAQ6CgguEK8BEMcBECc6CgguEMcBEK8BEEM6BAgAEEM6BQgAEJECOgoIABCABBAUEIcCOgcIABCABBAKOg4ILhCABBDHARCvARDLAToJCAAQFhAeEPEEOgYIABAWEB46BwghEKABEApKBAhBGAFQjAdYhGtgk3JoAXAAeACAAZgBiAGCGpIBBDAuMjSYAQCgAQHAAQE&sclient=gws-wiz-serp' target="_blank"><img className="brandicon" src={googlelogo} alt="Google" /></a></p>
                                <p className='TestLtd'><FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                </p>
                            </div>
                        </Col>

                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    For 17 years I have been greeted with a friendly and professional welcome at V-TAC.   Nazim and his team have looked after my personal and business requirements in a timely fashion and I can rest assured it is done correct the first time.  Thank V-TAC.
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>jagang thejust</strong> &nbsp; <a href='https://www.google.com/search?q=vtac+accounting+toronto&newwindow=1&bih=964&biw=2048&hl=en&sxsrf=AJOqlzVO4uETOdZdvgE05MRcsR8djpoKyg%3A1677356611124&ei=Q276Y9D5BvyfseMPwZSjsAc&ved=0ahUKEwiQp96nwLH9AhX8T2wGHUHKCHYQ4dUDCA4&uact=5&oq=vtac+accounting+toronto&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIFCCEQoAEyBQghEKABMgUIIRCgAToECCMQJzoGCAAQBRAeOgUIABCGAzoHCAAQHhDxBDoECAAQHjoFCAAQgAQ6CgguEK8BEMcBECc6CgguEMcBEK8BEEM6BAgAEEM6BQgAEJECOgoIABCABBAUEIcCOgcIABCABBAKOg4ILhCABBDHARCvARDLAToJCAAQFhAeEPEEOgYIABAWEB46BwghEKABEApKBAhBGAFQjAdYhGtgk3JoAXAAeACAAZgBiAGCGpIBBDAuMjSYAQCgAQHAAQE&sclient=gws-wiz-serp' target="_blank"><img className="brandicon" src={googlelogo} alt="Google" /></a></p>
                                <p className='TestLtd'><FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                </p>
                            </div>
                        </Col>

                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    Outstanding service, very responsive and efficient service from the VTAC group. VTAC are very responsible and professional when dealing with my taxes.  Thank you VTAC for simply being the best!
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>Dan (Toronto)</strong> &nbsp; <a href='https://www.google.com/search?q=vtac+accounting+toronto&newwindow=1&bih=964&biw=2048&hl=en&sxsrf=AJOqlzVO4uETOdZdvgE05MRcsR8djpoKyg%3A1677356611124&ei=Q276Y9D5BvyfseMPwZSjsAc&ved=0ahUKEwiQp96nwLH9AhX8T2wGHUHKCHYQ4dUDCA4&uact=5&oq=vtac+accounting+toronto&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIFCCEQoAEyBQghEKABMgUIIRCgAToECCMQJzoGCAAQBRAeOgUIABCGAzoHCAAQHhDxBDoECAAQHjoFCAAQgAQ6CgguEK8BEMcBECc6CgguEMcBEK8BEEM6BAgAEEM6BQgAEJECOgoIABCABBAUEIcCOgcIABCABBAKOg4ILhCABBDHARCvARDLAToJCAAQFhAeEPEEOgYIABAWEB46BwghEKABEApKBAhBGAFQjAdYhGtgk3JoAXAAeACAAZgBiAGCGpIBBDAuMjSYAQCgAQHAAQE&sclient=gws-wiz-serp' target="_blank"><img className="brandicon" src={googlelogo} alt="Google" /></a></p>
                                <p className='TestLtd'><FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                </p>
                            </div>
                        </Col>

                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    We have been doing our taxes with VTAC for almost 20 years, we keep coming back for the friendly and professional services that they provide.  Would recommend many time over!
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>Alfredo Torres</strong> &nbsp; <a href='https://www.google.com/search?q=vtac+accounting+toronto&newwindow=1&bih=964&biw=2048&hl=en&sxsrf=AJOqlzVO4uETOdZdvgE05MRcsR8djpoKyg%3A1677356611124&ei=Q276Y9D5BvyfseMPwZSjsAc&ved=0ahUKEwiQp96nwLH9AhX8T2wGHUHKCHYQ4dUDCA4&uact=5&oq=vtac+accounting+toronto&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIFCCEQoAEyBQghEKABMgUIIRCgAToECCMQJzoGCAAQBRAeOgUIABCGAzoHCAAQHhDxBDoECAAQHjoFCAAQgAQ6CgguEK8BEMcBECc6CgguEMcBEK8BEEM6BAgAEEM6BQgAEJECOgoIABCABBAUEIcCOgcIABCABBAKOg4ILhCABBDHARCvARDLAToJCAAQFhAeEPEEOgYIABAWEB46BwghEKABEApKBAhBGAFQjAdYhGtgk3JoAXAAeACAAZgBiAGCGpIBBDAuMjSYAQCgAQHAAQE&sclient=gws-wiz-serp' target="_blank"><img className="brandicon" src={googlelogo} alt="Google" /></a></p>
                                <p className='TestLtd'><FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                </p>
                            </div>
                        </Col>

                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    Great service
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>Michele Natale</strong> &nbsp; <a href='https://www.google.com/search?q=vtac+accounting+toronto&newwindow=1&bih=964&biw=2048&hl=en&sxsrf=AJOqlzVO4uETOdZdvgE05MRcsR8djpoKyg%3A1677356611124&ei=Q276Y9D5BvyfseMPwZSjsAc&ved=0ahUKEwiQp96nwLH9AhX8T2wGHUHKCHYQ4dUDCA4&uact=5&oq=vtac+accounting+toronto&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIFCCEQoAEyBQghEKABMgUIIRCgAToECCMQJzoGCAAQBRAeOgUIABCGAzoHCAAQHhDxBDoECAAQHjoFCAAQgAQ6CgguEK8BEMcBECc6CgguEMcBEK8BEEM6BAgAEEM6BQgAEJECOgoIABCABBAUEIcCOgcIABCABBAKOg4ILhCABBDHARCvARDLAToJCAAQFhAeEPEEOgYIABAWEB46BwghEKABEApKBAhBGAFQjAdYhGtgk3JoAXAAeACAAZgBiAGCGpIBBDAuMjSYAQCgAQHAAQE&sclient=gws-wiz-serp' target="_blank"><img className="brandicon" src={googlelogo} alt="Google" /></a></p>
                                <p className='TestLtd'><FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                </p>
                            </div>
                        </Col>

                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    V-Tac accounting is a very amazing place.  Everyone is very well mannered and well educated on their business.
                                    Staff members walked me through processes step by step and were very patient.  I am very lucky to be a client for the past 6yrs +
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>marzar davis</strong> &nbsp; <a href='https://www.google.com/search?q=vtac+accounting+toronto&newwindow=1&bih=964&biw=2048&hl=en&sxsrf=AJOqlzVO4uETOdZdvgE05MRcsR8djpoKyg%3A1677356611124&ei=Q276Y9D5BvyfseMPwZSjsAc&ved=0ahUKEwiQp96nwLH9AhX8T2wGHUHKCHYQ4dUDCA4&uact=5&oq=vtac+accounting+toronto&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIFCCEQoAEyBQghEKABMgUIIRCgAToECCMQJzoGCAAQBRAeOgUIABCGAzoHCAAQHhDxBDoECAAQHjoFCAAQgAQ6CgguEK8BEMcBECc6CgguEMcBEK8BEEM6BAgAEEM6BQgAEJECOgoIABCABBAUEIcCOgcIABCABBAKOg4ILhCABBDHARCvARDLAToJCAAQFhAeEPEEOgYIABAWEB46BwghEKABEApKBAhBGAFQjAdYhGtgk3JoAXAAeACAAZgBiAGCGpIBBDAuMjSYAQCgAQHAAQE&sclient=gws-wiz-serp' target="_blank"><img className="brandicon" src={googlelogo} alt="Google" /></a></p>
                                <p className='TestLtd'><FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                </p>
                            </div>
                        </Col>

                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    I will most encourage anyone to do there taxes with Mr Zazim Zafor at V-TAC Accounting,he's a true professional who looks out for the best interest for his customers, I have been doing my taxes with him for years now, never once have I been disappointed.
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>Bharat Mistry</strong> &nbsp; <a href='https://www.google.com/search?q=vtac+accounting+toronto&newwindow=1&bih=964&biw=2048&hl=en&sxsrf=AJOqlzVO4uETOdZdvgE05MRcsR8djpoKyg%3A1677356611124&ei=Q276Y9D5BvyfseMPwZSjsAc&ved=0ahUKEwiQp96nwLH9AhX8T2wGHUHKCHYQ4dUDCA4&uact=5&oq=vtac+accounting+toronto&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIFCCEQoAEyBQghEKABMgUIIRCgAToECCMQJzoGCAAQBRAeOgUIABCGAzoHCAAQHhDxBDoECAAQHjoFCAAQgAQ6CgguEK8BEMcBECc6CgguEMcBEK8BEEM6BAgAEEM6BQgAEJECOgoIABCABBAUEIcCOgcIABCABBAKOg4ILhCABBDHARCvARDLAToJCAAQFhAeEPEEOgYIABAWEB46BwghEKABEApKBAhBGAFQjAdYhGtgk3JoAXAAeACAAZgBiAGCGpIBBDAuMjSYAQCgAQHAAQE&sclient=gws-wiz-serp' target="_blank"><img className="brandicon" src={googlelogo} alt="Google" /></a></p>
                                <p className='TestLtd'><FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                </p>
                            </div>
                        </Col>

                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    Hi. I have been coming here to do my taxes and family taxes with Nazim for 20 plus years. Very happy with the service fast and efficient, polite and very helpful friendly. My boys still come here and they are very happy. I live in Milton.
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>Abdul Mango</strong> &nbsp; <a href='https://www.google.com/search?q=vtac+accounting+toronto&newwindow=1&bih=964&biw=2048&hl=en&sxsrf=AJOqlzVO4uETOdZdvgE05MRcsR8djpoKyg%3A1677356611124&ei=Q276Y9D5BvyfseMPwZSjsAc&ved=0ahUKEwiQp96nwLH9AhX8T2wGHUHKCHYQ4dUDCA4&uact=5&oq=vtac+accounting+toronto&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIFCCEQoAEyBQghEKABMgUIIRCgAToECCMQJzoGCAAQBRAeOgUIABCGAzoHCAAQHhDxBDoECAAQHjoFCAAQgAQ6CgguEK8BEMcBECc6CgguEMcBEK8BEEM6BAgAEEM6BQgAEJECOgoIABCABBAUEIcCOgcIABCABBAKOg4ILhCABBDHARCvARDLAToJCAAQFhAeEPEEOgYIABAWEB46BwghEKABEApKBAhBGAFQjAdYhGtgk3JoAXAAeACAAZgBiAGCGpIBBDAuMjSYAQCgAQHAAQE&sclient=gws-wiz-serp' target="_blank"><img className="brandicon" src={googlelogo} alt="Google" /></a></p>
                                <p className='TestLtd'><FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                </p>
                            </div>
                        </Col>

                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    Start file taxes here with my parents about 15 years ago, now I file taxes here with my kids.
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>Thomas Campean</strong> &nbsp; <a href='https://www.google.com/search?q=vtac+accounting+toronto&newwindow=1&bih=964&biw=2048&hl=en&sxsrf=AJOqlzVO4uETOdZdvgE05MRcsR8djpoKyg%3A1677356611124&ei=Q276Y9D5BvyfseMPwZSjsAc&ved=0ahUKEwiQp96nwLH9AhX8T2wGHUHKCHYQ4dUDCA4&uact=5&oq=vtac+accounting+toronto&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIFCCEQoAEyBQghEKABMgUIIRCgAToECCMQJzoGCAAQBRAeOgUIABCGAzoHCAAQHhDxBDoECAAQHjoFCAAQgAQ6CgguEK8BEMcBECc6CgguEMcBEK8BEEM6BAgAEEM6BQgAEJECOgoIABCABBAUEIcCOgcIABCABBAKOg4ILhCABBDHARCvARDLAToJCAAQFhAeEPEEOgYIABAWEB46BwghEKABEApKBAhBGAFQjAdYhGtgk3JoAXAAeACAAZgBiAGCGpIBBDAuMjSYAQCgAQHAAQE&sclient=gws-wiz-serp' target="_blank"><img className="brandicon" src={googlelogo} alt="Google" /></a></p>
                                <p className='TestLtd'><FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                </p>
                            </div>
                        </Col>

                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    Great services. I would definitely recommend.
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>esther malangyaon</strong> &nbsp; <a href='https://www.google.com/search?q=vtac+accounting+toronto&newwindow=1&bih=964&biw=2048&hl=en&sxsrf=AJOqlzVO4uETOdZdvgE05MRcsR8djpoKyg%3A1677356611124&ei=Q276Y9D5BvyfseMPwZSjsAc&ved=0ahUKEwiQp96nwLH9AhX8T2wGHUHKCHYQ4dUDCA4&uact=5&oq=vtac+accounting+toronto&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIFCCEQoAEyBQghEKABMgUIIRCgAToECCMQJzoGCAAQBRAeOgUIABCGAzoHCAAQHhDxBDoECAAQHjoFCAAQgAQ6CgguEK8BEMcBECc6CgguEMcBEK8BEEM6BAgAEEM6BQgAEJECOgoIABCABBAUEIcCOgcIABCABBAKOg4ILhCABBDHARCvARDLAToJCAAQFhAeEPEEOgYIABAWEB46BwghEKABEApKBAhBGAFQjAdYhGtgk3JoAXAAeACAAZgBiAGCGpIBBDAuMjSYAQCgAQHAAQE&sclient=gws-wiz-serp' target="_blank"><img className="brandicon" src={googlelogo} alt="Google" /></a></p>
                                <p className='TestLtd'><FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                </p>
                            </div>
                        </Col>

                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    Every year I get the same professional service. Happy environment, convenient and I always look forward seeing my account and his staff. Thank You for all that you do!
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>Yanah Mumtanha</strong> &nbsp; <a href='https://www.google.com/search?q=vtac+accounting+toronto&newwindow=1&bih=964&biw=2048&hl=en&sxsrf=AJOqlzVO4uETOdZdvgE05MRcsR8djpoKyg%3A1677356611124&ei=Q276Y9D5BvyfseMPwZSjsAc&ved=0ahUKEwiQp96nwLH9AhX8T2wGHUHKCHYQ4dUDCA4&uact=5&oq=vtac+accounting+toronto&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIFCCEQoAEyBQghEKABMgUIIRCgAToECCMQJzoGCAAQBRAeOgUIABCGAzoHCAAQHhDxBDoECAAQHjoFCAAQgAQ6CgguEK8BEMcBECc6CgguEMcBEK8BEEM6BAgAEEM6BQgAEJECOgoIABCABBAUEIcCOgcIABCABBAKOg4ILhCABBDHARCvARDLAToJCAAQFhAeEPEEOgYIABAWEB46BwghEKABEApKBAhBGAFQjAdYhGtgk3JoAXAAeACAAZgBiAGCGpIBBDAuMjSYAQCgAQHAAQE&sclient=gws-wiz-serp' target="_blank"><img className="brandicon" src={googlelogo} alt="Google" /></a></p>
                                <p className='TestLtd'><FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                </p>
                            </div>
                        </Col>

                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    Excellent Service! Highly recommended!
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>Shaji Cherian</strong> &nbsp; <a href='https://www.google.com/search?q=vtac+accounting+toronto&newwindow=1&bih=964&biw=2048&hl=en&sxsrf=AJOqlzVO4uETOdZdvgE05MRcsR8djpoKyg%3A1677356611124&ei=Q276Y9D5BvyfseMPwZSjsAc&ved=0ahUKEwiQp96nwLH9AhX8T2wGHUHKCHYQ4dUDCA4&uact=5&oq=vtac+accounting+toronto&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIFCCEQoAEyBQghEKABMgUIIRCgAToECCMQJzoGCAAQBRAeOgUIABCGAzoHCAAQHhDxBDoECAAQHjoFCAAQgAQ6CgguEK8BEMcBECc6CgguEMcBEK8BEEM6BAgAEEM6BQgAEJECOgoIABCABBAUEIcCOgcIABCABBAKOg4ILhCABBDHARCvARDLAToJCAAQFhAeEPEEOgYIABAWEB46BwghEKABEApKBAhBGAFQjAdYhGtgk3JoAXAAeACAAZgBiAGCGpIBBDAuMjSYAQCgAQHAAQE&sclient=gws-wiz-serp' target="_blank"><img className="brandicon" src={googlelogo} alt="Google" /></a></p>
                                <p className='TestLtd'><FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                </p>
                            </div>
                        </Col>

                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    We have been doing tax returns with V-TAC for over 22 years now and extremely please with the service we are getting.
                                    Nazim is very professional as well as friendly and keep good connection with all his clients and does this with passion and do it in an organized manner
                                    We will recommend anyone to do tax return with V-TAC
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>debora aguiar</strong> &nbsp; <a href='https://www.google.com/search?q=vtac+accounting+toronto&newwindow=1&bih=964&biw=2048&hl=en&sxsrf=AJOqlzVO4uETOdZdvgE05MRcsR8djpoKyg%3A1677356611124&ei=Q276Y9D5BvyfseMPwZSjsAc&ved=0ahUKEwiQp96nwLH9AhX8T2wGHUHKCHYQ4dUDCA4&uact=5&oq=vtac+accounting+toronto&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIFCCEQoAEyBQghEKABMgUIIRCgAToECCMQJzoGCAAQBRAeOgUIABCGAzoHCAAQHhDxBDoECAAQHjoFCAAQgAQ6CgguEK8BEMcBECc6CgguEMcBEK8BEEM6BAgAEEM6BQgAEJECOgoIABCABBAUEIcCOgcIABCABBAKOg4ILhCABBDHARCvARDLAToJCAAQFhAeEPEEOgYIABAWEB46BwghEKABEApKBAhBGAFQjAdYhGtgk3JoAXAAeACAAZgBiAGCGpIBBDAuMjSYAQCgAQHAAQE&sclient=gws-wiz-serp' target="_blank"><img className="brandicon" src={googlelogo} alt="Google" /></a></p>
                                <p className='TestLtd'><FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                </p>
                            </div>
                        </Col>

                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                Great place . Great service best place to go. They really do an amazing job
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>D B</strong> &nbsp; <a href='https://www.google.com/search?q=vtac+accounting+toronto&newwindow=1&bih=964&biw=2048&hl=en&sxsrf=AJOqlzVO4uETOdZdvgE05MRcsR8djpoKyg%3A1677356611124&ei=Q276Y9D5BvyfseMPwZSjsAc&ved=0ahUKEwiQp96nwLH9AhX8T2wGHUHKCHYQ4dUDCA4&uact=5&oq=vtac+accounting+toronto&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIFCCEQoAEyBQghEKABMgUIIRCgAToECCMQJzoGCAAQBRAeOgUIABCGAzoHCAAQHhDxBDoECAAQHjoFCAAQgAQ6CgguEK8BEMcBECc6CgguEMcBEK8BEEM6BAgAEEM6BQgAEJECOgoIABCABBAUEIcCOgcIABCABBAKOg4ILhCABBDHARCvARDLAToJCAAQFhAeEPEEOgYIABAWEB46BwghEKABEApKBAhBGAFQjAdYhGtgk3JoAXAAeACAAZgBiAGCGpIBBDAuMjSYAQCgAQHAAQE&sclient=gws-wiz-serp' target="_blank"><img className="brandicon" src={googlelogo} alt="Google" /></a></p>
                                <p className='TestLtd'><FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                </p>
                            </div>
                        </Col>

                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                They have been doing my personal and corporate taxes for many years. Very knowledgeable, quick and responsive. Very positive experience. Would highly recommend.
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>Daniella M</strong> &nbsp; <a href='https://www.google.com/search?q=vtac+accounting+toronto&newwindow=1&bih=964&biw=2048&hl=en&sxsrf=AJOqlzVO4uETOdZdvgE05MRcsR8djpoKyg%3A1677356611124&ei=Q276Y9D5BvyfseMPwZSjsAc&ved=0ahUKEwiQp96nwLH9AhX8T2wGHUHKCHYQ4dUDCA4&uact=5&oq=vtac+accounting+toronto&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIFCCEQoAEyBQghEKABMgUIIRCgAToECCMQJzoGCAAQBRAeOgUIABCGAzoHCAAQHhDxBDoECAAQHjoFCAAQgAQ6CgguEK8BEMcBECc6CgguEMcBEK8BEEM6BAgAEEM6BQgAEJECOgoIABCABBAUEIcCOgcIABCABBAKOg4ILhCABBDHARCvARDLAToJCAAQFhAeEPEEOgYIABAWEB46BwghEKABEApKBAhBGAFQjAdYhGtgk3JoAXAAeACAAZgBiAGCGpIBBDAuMjSYAQCgAQHAAQE&sclient=gws-wiz-serp' target="_blank"><img className="brandicon" src={googlelogo} alt="Google" /></a></p>
                                <p className='TestLtd'><FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                </p>
                            </div>
                        </Col>

                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                Nazim is always very professional and willing to go the extra step to ensure the client is happy with the returns.
I have been a client of V-tax accounting for 13 years and always recommend
V-tac
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>Anneisha</strong> &nbsp; <a href='https://www.google.com/search?q=vtac+accounting+toronto&newwindow=1&bih=964&biw=2048&hl=en&sxsrf=AJOqlzVO4uETOdZdvgE05MRcsR8djpoKyg%3A1677356611124&ei=Q276Y9D5BvyfseMPwZSjsAc&ved=0ahUKEwiQp96nwLH9AhX8T2wGHUHKCHYQ4dUDCA4&uact=5&oq=vtac+accounting+toronto&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIFCCEQoAEyBQghEKABMgUIIRCgAToECCMQJzoGCAAQBRAeOgUIABCGAzoHCAAQHhDxBDoECAAQHjoFCAAQgAQ6CgguEK8BEMcBECc6CgguEMcBEK8BEEM6BAgAEEM6BQgAEJECOgoIABCABBAUEIcCOgcIABCABBAKOg4ILhCABBDHARCvARDLAToJCAAQFhAeEPEEOgYIABAWEB46BwghEKABEApKBAhBGAFQjAdYhGtgk3JoAXAAeACAAZgBiAGCGpIBBDAuMjSYAQCgAQHAAQE&sclient=gws-wiz-serp' target="_blank"><img className="brandicon" src={googlelogo} alt="Google" /></a></p>
                                <p className='TestLtd'><FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                </p>
                            </div>
                        </Col>

                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                V-TAC accounting & Tax has been the place where I go to do my taxes every year. I am happy with their services and never had any issues with my tax return. I had recommended them to my friends and families and I will definitely recommend them to you. After more than 15 years of no regrets... i guarantee you'll be happy with their services as well.
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>Joe Xerri</strong> &nbsp; <a href='https://www.google.com/search?q=vtac+accounting+toronto&newwindow=1&bih=964&biw=2048&hl=en&sxsrf=AJOqlzVO4uETOdZdvgE05MRcsR8djpoKyg%3A1677356611124&ei=Q276Y9D5BvyfseMPwZSjsAc&ved=0ahUKEwiQp96nwLH9AhX8T2wGHUHKCHYQ4dUDCA4&uact=5&oq=vtac+accounting+toronto&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIFCCEQoAEyBQghEKABMgUIIRCgAToECCMQJzoGCAAQBRAeOgUIABCGAzoHCAAQHhDxBDoECAAQHjoFCAAQgAQ6CgguEK8BEMcBECc6CgguEMcBEK8BEEM6BAgAEEM6BQgAEJECOgoIABCABBAUEIcCOgcIABCABBAKOg4ILhCABBDHARCvARDLAToJCAAQFhAeEPEEOgYIABAWEB46BwghEKABEApKBAhBGAFQjAdYhGtgk3JoAXAAeACAAZgBiAGCGpIBBDAuMjSYAQCgAQHAAQE&sclient=gws-wiz-serp' target="_blank"><img className="brandicon" src={googlelogo} alt="Google" /></a></p>
                                <p className='TestLtd'><FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                </p>
                            </div>
                        </Col>

                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                I’ve been coming to Nazim since 1999. Absolutely one of the nicest ppl I’ve ever met. Very professional and respectful.
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>Nalini Mistry</strong> &nbsp; <a href='https://www.google.com/search?q=vtac+accounting+toronto&newwindow=1&bih=964&biw=2048&hl=en&sxsrf=AJOqlzVO4uETOdZdvgE05MRcsR8djpoKyg%3A1677356611124&ei=Q276Y9D5BvyfseMPwZSjsAc&ved=0ahUKEwiQp96nwLH9AhX8T2wGHUHKCHYQ4dUDCA4&uact=5&oq=vtac+accounting+toronto&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIFCCEQoAEyBQghEKABMgUIIRCgAToECCMQJzoGCAAQBRAeOgUIABCGAzoHCAAQHhDxBDoECAAQHjoFCAAQgAQ6CgguEK8BEMcBECc6CgguEMcBEK8BEEM6BAgAEEM6BQgAEJECOgoIABCABBAUEIcCOgcIABCABBAKOg4ILhCABBDHARCvARDLAToJCAAQFhAeEPEEOgYIABAWEB46BwghEKABEApKBAhBGAFQjAdYhGtgk3JoAXAAeACAAZgBiAGCGpIBBDAuMjSYAQCgAQHAAQE&sclient=gws-wiz-serp' target="_blank"><img className="brandicon" src={googlelogo} alt="Google" /></a></p>
                                <p className='TestLtd'><FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                </p>
                            </div>
                        </Col>

                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                Vtac is a great place. been doing my taxes for over 10yrs.
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>J</strong> &nbsp; <a href='https://www.google.com/search?q=vtac+accounting+toronto&newwindow=1&bih=964&biw=2048&hl=en&sxsrf=AJOqlzVO4uETOdZdvgE05MRcsR8djpoKyg%3A1677356611124&ei=Q276Y9D5BvyfseMPwZSjsAc&ved=0ahUKEwiQp96nwLH9AhX8T2wGHUHKCHYQ4dUDCA4&uact=5&oq=vtac+accounting+toronto&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIFCCEQoAEyBQghEKABMgUIIRCgAToECCMQJzoGCAAQBRAeOgUIABCGAzoHCAAQHhDxBDoECAAQHjoFCAAQgAQ6CgguEK8BEMcBECc6CgguEMcBEK8BEEM6BAgAEEM6BQgAEJECOgoIABCABBAUEIcCOgcIABCABBAKOg4ILhCABBDHARCvARDLAToJCAAQFhAeEPEEOgYIABAWEB46BwghEKABEApKBAhBGAFQjAdYhGtgk3JoAXAAeACAAZgBiAGCGpIBBDAuMjSYAQCgAQHAAQE&sclient=gws-wiz-serp' target="_blank"><img className="brandicon" src={googlelogo} alt="Google" /></a></p>
                                <p className='TestLtd'><FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                </p>
                            </div>
                        </Col>

                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                Best tax place !!!!
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>Theresa Cumberbatch</strong> &nbsp; <a href='https://www.google.com/search?q=vtac+accounting+toronto&newwindow=1&bih=964&biw=2048&hl=en&sxsrf=AJOqlzVO4uETOdZdvgE05MRcsR8djpoKyg%3A1677356611124&ei=Q276Y9D5BvyfseMPwZSjsAc&ved=0ahUKEwiQp96nwLH9AhX8T2wGHUHKCHYQ4dUDCA4&uact=5&oq=vtac+accounting+toronto&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIFCCEQoAEyBQghEKABMgUIIRCgAToECCMQJzoGCAAQBRAeOgUIABCGAzoHCAAQHhDxBDoECAAQHjoFCAAQgAQ6CgguEK8BEMcBECc6CgguEMcBEK8BEEM6BAgAEEM6BQgAEJECOgoIABCABBAUEIcCOgcIABCABBAKOg4ILhCABBDHARCvARDLAToJCAAQFhAeEPEEOgYIABAWEB46BwghEKABEApKBAhBGAFQjAdYhGtgk3JoAXAAeACAAZgBiAGCGpIBBDAuMjSYAQCgAQHAAQE&sclient=gws-wiz-serp' target="_blank"><img className="brandicon" src={googlelogo} alt="Google" /></a></p>
                                <p className='TestLtd'><FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                </p>
                            </div>
                        </Col>

                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                I highly recommend Nazim
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>H G</strong> &nbsp; <a href='https://www.google.com/search?q=vtac+accounting+toronto&newwindow=1&bih=964&biw=2048&hl=en&sxsrf=AJOqlzVO4uETOdZdvgE05MRcsR8djpoKyg%3A1677356611124&ei=Q276Y9D5BvyfseMPwZSjsAc&ved=0ahUKEwiQp96nwLH9AhX8T2wGHUHKCHYQ4dUDCA4&uact=5&oq=vtac+accounting+toronto&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIFCCEQoAEyBQghEKABMgUIIRCgAToECCMQJzoGCAAQBRAeOgUIABCGAzoHCAAQHhDxBDoECAAQHjoFCAAQgAQ6CgguEK8BEMcBECc6CgguEMcBEK8BEEM6BAgAEEM6BQgAEJECOgoIABCABBAUEIcCOgcIABCABBAKOg4ILhCABBDHARCvARDLAToJCAAQFhAeEPEEOgYIABAWEB46BwghEKABEApKBAhBGAFQjAdYhGtgk3JoAXAAeACAAZgBiAGCGpIBBDAuMjSYAQCgAQHAAQE&sclient=gws-wiz-serp' target="_blank"><img className="brandicon" src={googlelogo} alt="Google" /></a></p>
                                <p className='TestLtd'><FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                </p>
                            </div>
                        </Col>

                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                Great reliable and fast service and return processing time.
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>Isida Gjergo</strong> &nbsp; <a href='https://www.google.com/search?q=vtac+accounting+toronto&newwindow=1&bih=964&biw=2048&hl=en&sxsrf=AJOqlzVO4uETOdZdvgE05MRcsR8djpoKyg%3A1677356611124&ei=Q276Y9D5BvyfseMPwZSjsAc&ved=0ahUKEwiQp96nwLH9AhX8T2wGHUHKCHYQ4dUDCA4&uact=5&oq=vtac+accounting+toronto&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIFCCEQoAEyBQghEKABMgUIIRCgAToECCMQJzoGCAAQBRAeOgUIABCGAzoHCAAQHhDxBDoECAAQHjoFCAAQgAQ6CgguEK8BEMcBECc6CgguEMcBEK8BEEM6BAgAEEM6BQgAEJECOgoIABCABBAUEIcCOgcIABCABBAKOg4ILhCABBDHARCvARDLAToJCAAQFhAeEPEEOgYIABAWEB46BwghEKABEApKBAhBGAFQjAdYhGtgk3JoAXAAeACAAZgBiAGCGpIBBDAuMjSYAQCgAQHAAQE&sclient=gws-wiz-serp' target="_blank"><img className="brandicon" src={googlelogo} alt="Google" /></a></p>
                                <p className='TestLtd'><FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                </p>
                            </div>
                        </Col>

                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                Great service!!! Very welcoming and professional service!
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>Little Creative</strong> &nbsp; <a href='https://www.google.com/search?q=vtac+accounting+toronto&newwindow=1&bih=964&biw=2048&hl=en&sxsrf=AJOqlzVO4uETOdZdvgE05MRcsR8djpoKyg%3A1677356611124&ei=Q276Y9D5BvyfseMPwZSjsAc&ved=0ahUKEwiQp96nwLH9AhX8T2wGHUHKCHYQ4dUDCA4&uact=5&oq=vtac+accounting+toronto&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIFCCEQoAEyBQghEKABMgUIIRCgAToECCMQJzoGCAAQBRAeOgUIABCGAzoHCAAQHhDxBDoECAAQHjoFCAAQgAQ6CgguEK8BEMcBECc6CgguEMcBEK8BEEM6BAgAEEM6BQgAEJECOgoIABCABBAUEIcCOgcIABCABBAKOg4ILhCABBDHARCvARDLAToJCAAQFhAeEPEEOgYIABAWEB46BwghEKABEApKBAhBGAFQjAdYhGtgk3JoAXAAeACAAZgBiAGCGpIBBDAuMjSYAQCgAQHAAQE&sclient=gws-wiz-serp' target="_blank"><img className="brandicon" src={googlelogo} alt="Google" /></a></p>
                                <p className='TestLtd'><FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    Vtac has been doing my taxes and accounting for over 20 years now. I am more than happy with the personalized service that I have received. What I am most happy with is the honesty that the account uses. It makes me feel comfortable that no corners are being cut, (because I want to sleep well at night, not worried if the tax man will come after me). For me doing things the right way is the best way. The staff are professional, curtious and receptive to my needs. I highly recommend VTAC for personal taxes and accounting.
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>

                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>Ramon Cruz</strong> &nbsp; <a href='https://www.google.com/search?q=vtac+accounting+toronto&newwindow=1&bih=964&biw=2048&hl=en&sxsrf=AJOqlzVO4uETOdZdvgE05MRcsR8djpoKyg%3A1677356611124&ei=Q276Y9D5BvyfseMPwZSjsAc&ved=0ahUKEwiQp96nwLH9AhX8T2wGHUHKCHYQ4dUDCA4&uact=5&oq=vtac+accounting+toronto&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIFCCEQoAEyBQghEKABMgUIIRCgAToECCMQJzoGCAAQBRAeOgUIABCGAzoHCAAQHhDxBDoECAAQHjoFCAAQgAQ6CgguEK8BEMcBECc6CgguEMcBEK8BEEM6BAgAEEM6BQgAEJECOgoIABCABBAUEIcCOgcIABCABBAKOg4ILhCABBDHARCvARDLAToJCAAQFhAeEPEEOgYIABAWEB46BwghEKABEApKBAhBGAFQjAdYhGtgk3JoAXAAeACAAZgBiAGCGpIBBDAuMjSYAQCgAQHAAQE&sclient=gws-wiz-serp' target="_blank"><img className="brandicon" src={googlelogo} alt="Google" /></a></p>
                                <p className='TestLtd'><FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    I have been coming here for many years, would definitely recommend doing doing your taxes here.
                                </h2>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg={12} md={12} sm={12}>

                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>Claudia Coelho</strong> &nbsp; <a href='https://www.google.com/search?q=vtac+accounting+toronto&newwindow=1&bih=964&biw=2048&hl=en&sxsrf=AJOqlzVO4uETOdZdvgE05MRcsR8djpoKyg%3A1677356611124&ei=Q276Y9D5BvyfseMPwZSjsAc&ved=0ahUKEwiQp96nwLH9AhX8T2wGHUHKCHYQ4dUDCA4&uact=5&oq=vtac+accounting+toronto&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIFCCEQoAEyBQghEKABMgUIIRCgAToECCMQJzoGCAAQBRAeOgUIABCGAzoHCAAQHhDxBDoECAAQHjoFCAAQgAQ6CgguEK8BEMcBECc6CgguEMcBEK8BEEM6BAgAEEM6BQgAEJECOgoIABCABBAUEIcCOgcIABCABBAKOg4ILhCABBDHARCvARDLAToJCAAQFhAeEPEEOgYIABAWEB46BwghEKABEApKBAhBGAFQjAdYhGtgk3JoAXAAeACAAZgBiAGCGpIBBDAuMjSYAQCgAQHAAQE&sclient=gws-wiz-serp' target="_blank"><img className="brandicon" src={googlelogo} alt="Google" /></a></p>
                                <p className='TestLtd'><FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    I have been using your services for the past 10 years. I am extremely happy with your professionalism and understanding of my needs.  I would highly recommend your company to anyone in need .
                                    Thanks again
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>

                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>Monir Khan</strong> &nbsp; <a href='https://www.google.com/search?q=vtac+accounting+toronto&newwindow=1&bih=964&biw=2048&hl=en&sxsrf=AJOqlzVO4uETOdZdvgE05MRcsR8djpoKyg%3A1677356611124&ei=Q276Y9D5BvyfseMPwZSjsAc&ved=0ahUKEwiQp96nwLH9AhX8T2wGHUHKCHYQ4dUDCA4&uact=5&oq=vtac+accounting+toronto&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIFCCEQoAEyBQghEKABMgUIIRCgAToECCMQJzoGCAAQBRAeOgUIABCGAzoHCAAQHhDxBDoECAAQHjoFCAAQgAQ6CgguEK8BEMcBECc6CgguEMcBEK8BEEM6BAgAEEM6BQgAEJECOgoIABCABBAUEIcCOgcIABCABBAKOg4ILhCABBDHARCvARDLAToJCAAQFhAeEPEEOgYIABAWEB46BwghEKABEApKBAhBGAFQjAdYhGtgk3JoAXAAeACAAZgBiAGCGpIBBDAuMjSYAQCgAQHAAQE&sclient=gws-wiz-serp' target="_blank"><img className="brandicon" src={googlelogo} alt="Google" /></a></p>
                                <p className='TestLtd'><FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    I am work with Circle of Care ,  last 10 years V-tac Accounting  & Tax , do my tax return. Great service.
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>

                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>Annie</strong> &nbsp; <a href='https://www.google.com/search?q=vtac+accounting+toronto&newwindow=1&bih=964&biw=2048&hl=en&sxsrf=AJOqlzVO4uETOdZdvgE05MRcsR8djpoKyg%3A1677356611124&ei=Q276Y9D5BvyfseMPwZSjsAc&ved=0ahUKEwiQp96nwLH9AhX8T2wGHUHKCHYQ4dUDCA4&uact=5&oq=vtac+accounting+toronto&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIFCCEQoAEyBQghEKABMgUIIRCgAToECCMQJzoGCAAQBRAeOgUIABCGAzoHCAAQHhDxBDoECAAQHjoFCAAQgAQ6CgguEK8BEMcBECc6CgguEMcBEK8BEEM6BAgAEEM6BQgAEJECOgoIABCABBAUEIcCOgcIABCABBAKOg4ILhCABBDHARCvARDLAToJCAAQFhAeEPEEOgYIABAWEB46BwghEKABEApKBAhBGAFQjAdYhGtgk3JoAXAAeACAAZgBiAGCGpIBBDAuMjSYAQCgAQHAAQE&sclient=gws-wiz-serp' target="_blank"><img className="brandicon" src={googlelogo} alt="Google" /></a></p>
                                <p className='TestLtd'><FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    People are helpful. The accountant we worked with is very knowledgeable and was quick. Overall had a great experience and will recommend them as well as go back there to do future taxes.
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>

                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>Shirley Gallaugher</strong> &nbsp; <a href='https://www.google.com/search?q=vtac+accounting+toronto&newwindow=1&bih=964&biw=2048&hl=en&sxsrf=AJOqlzVO4uETOdZdvgE05MRcsR8djpoKyg%3A1677356611124&ei=Q276Y9D5BvyfseMPwZSjsAc&ved=0ahUKEwiQp96nwLH9AhX8T2wGHUHKCHYQ4dUDCA4&uact=5&oq=vtac+accounting+toronto&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIFCCEQoAEyBQghEKABMgUIIRCgAToECCMQJzoGCAAQBRAeOgUIABCGAzoHCAAQHhDxBDoECAAQHjoFCAAQgAQ6CgguEK8BEMcBECc6CgguEMcBEK8BEEM6BAgAEEM6BQgAEJECOgoIABCABBAUEIcCOgcIABCABBAKOg4ILhCABBDHARCvARDLAToJCAAQFhAeEPEEOgYIABAWEB46BwghEKABEApKBAhBGAFQjAdYhGtgk3JoAXAAeACAAZgBiAGCGpIBBDAuMjSYAQCgAQHAAQE&sclient=gws-wiz-serp' target="_blank"><img className="brandicon" src={googlelogo} alt="Google" /></a></p>
                                <p className='TestLtd'><FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    VTAC Jane Street, Toronto has completed my personal and business taxes for the past 6 years.  Nazim works in the best interests of customers and is always available to offer advice when requested.
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>

                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>Galina Jancisinova</strong> &nbsp; <a href='https://www.google.com/search?q=vtac+accounting+toronto&newwindow=1&bih=964&biw=2048&hl=en&sxsrf=AJOqlzVO4uETOdZdvgE05MRcsR8djpoKyg%3A1677356611124&ei=Q276Y9D5BvyfseMPwZSjsAc&ved=0ahUKEwiQp96nwLH9AhX8T2wGHUHKCHYQ4dUDCA4&uact=5&oq=vtac+accounting+toronto&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIFCCEQoAEyBQghEKABMgUIIRCgAToECCMQJzoGCAAQBRAeOgUIABCGAzoHCAAQHhDxBDoECAAQHjoFCAAQgAQ6CgguEK8BEMcBECc6CgguEMcBEK8BEEM6BAgAEEM6BQgAEJECOgoIABCABBAUEIcCOgcIABCABBAKOg4ILhCABBDHARCvARDLAToJCAAQFhAeEPEEOgYIABAWEB46BwghEKABEApKBAhBGAFQjAdYhGtgk3JoAXAAeACAAZgBiAGCGpIBBDAuMjSYAQCgAQHAAQE&sclient=gws-wiz-serp' target="_blank"><img className="brandicon" src={googlelogo} alt="Google" /></a></p>
                                <p className='TestLtd'><FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    Nazim is amazing and so it’s staff working for him.
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>

                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>K Balasis</strong> &nbsp; <a href='https://www.google.com/search?q=vtac+accounting+toronto&newwindow=1&bih=964&biw=2048&hl=en&sxsrf=AJOqlzVO4uETOdZdvgE05MRcsR8djpoKyg%3A1677356611124&ei=Q276Y9D5BvyfseMPwZSjsAc&ved=0ahUKEwiQp96nwLH9AhX8T2wGHUHKCHYQ4dUDCA4&uact=5&oq=vtac+accounting+toronto&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIFCCEQoAEyBQghEKABMgUIIRCgAToECCMQJzoGCAAQBRAeOgUIABCGAzoHCAAQHhDxBDoECAAQHjoFCAAQgAQ6CgguEK8BEMcBECc6CgguEMcBEK8BEEM6BAgAEEM6BQgAEJECOgoIABCABBAUEIcCOgcIABCABBAKOg4ILhCABBDHARCvARDLAToJCAAQFhAeEPEEOgYIABAWEB46BwghEKABEApKBAhBGAFQjAdYhGtgk3JoAXAAeACAAZgBiAGCGpIBBDAuMjSYAQCgAQHAAQE&sclient=gws-wiz-serp' target="_blank"><img className="brandicon" src={googlelogo} alt="Google" /></a></p>
                                <p className='TestLtd'><FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    Excellent Service Thank You Nazim.
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>

                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>Nav Bassan</strong> &nbsp; <a href='https://www.google.com/search?q=vtac+accounting+toronto&newwindow=1&bih=964&biw=2048&hl=en&sxsrf=AJOqlzVO4uETOdZdvgE05MRcsR8djpoKyg%3A1677356611124&ei=Q276Y9D5BvyfseMPwZSjsAc&ved=0ahUKEwiQp96nwLH9AhX8T2wGHUHKCHYQ4dUDCA4&uact=5&oq=vtac+accounting+toronto&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIFCCEQoAEyBQghEKABMgUIIRCgAToECCMQJzoGCAAQBRAeOgUIABCGAzoHCAAQHhDxBDoECAAQHjoFCAAQgAQ6CgguEK8BEMcBECc6CgguEMcBEK8BEEM6BAgAEEM6BQgAEJECOgoIABCABBAUEIcCOgcIABCABBAKOg4ILhCABBDHARCvARDLAToJCAAQFhAeEPEEOgYIABAWEB46BwghEKABEApKBAhBGAFQjAdYhGtgk3JoAXAAeACAAZgBiAGCGpIBBDAuMjSYAQCgAQHAAQE&sclient=gws-wiz-serp' target="_blank"><img className="brandicon" src={googlelogo} alt="Google" /></a></p>
                                <p className='TestLtd'><FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    I recently switched from ADP payroll to V-Tac payroll services and honestly I regret not switching over sooner. If I knew the service was provided I wouldn’t of even gone anywhere else.
                                    <br></br>
                                    Staff is very helpful, very responsive and very accommodating. Since joining payroll services I have now brought all my business accounting needs to V-Tac as well because they are a one stop shop.
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>

                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>Enver coovadia</strong> &nbsp; <a href='https://www.google.com/search?q=vtac+accounting+toronto&newwindow=1&bih=964&biw=2048&hl=en&sxsrf=AJOqlzVO4uETOdZdvgE05MRcsR8djpoKyg%3A1677356611124&ei=Q276Y9D5BvyfseMPwZSjsAc&ved=0ahUKEwiQp96nwLH9AhX8T2wGHUHKCHYQ4dUDCA4&uact=5&oq=vtac+accounting+toronto&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIFCCEQoAEyBQghEKABMgUIIRCgAToECCMQJzoGCAAQBRAeOgUIABCGAzoHCAAQHhDxBDoECAAQHjoFCAAQgAQ6CgguEK8BEMcBECc6CgguEMcBEK8BEEM6BAgAEEM6BQgAEJECOgoIABCABBAUEIcCOgcIABCABBAKOg4ILhCABBDHARCvARDLAToJCAAQFhAeEPEEOgYIABAWEB46BwghEKABEApKBAhBGAFQjAdYhGtgk3JoAXAAeACAAZgBiAGCGpIBBDAuMjSYAQCgAQHAAQE&sclient=gws-wiz-serp' target="_blank"><img className="brandicon" src={googlelogo} alt="Google" /></a></p>
                                <p className='TestLtd'><FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    Vtac payroll and accounting has been doing my companies payroll for over a decade! am writing to thank you for the quality of service provided by your company. We sincerely appreciate your efficient, gracious customer service, the level of detail and accountability you have demonstrated on each payrol and the way you conduct business as a whole.
                                    Looking forward to another decade
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>

                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>sarah s</strong> &nbsp; <a href='https://www.google.com/search?q=vtac+accounting+toronto&newwindow=1&bih=964&biw=2048&hl=en&sxsrf=AJOqlzVO4uETOdZdvgE05MRcsR8djpoKyg%3A1677356611124&ei=Q276Y9D5BvyfseMPwZSjsAc&ved=0ahUKEwiQp96nwLH9AhX8T2wGHUHKCHYQ4dUDCA4&uact=5&oq=vtac+accounting+toronto&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIFCCEQoAEyBQghEKABMgUIIRCgAToECCMQJzoGCAAQBRAeOgUIABCGAzoHCAAQHhDxBDoECAAQHjoFCAAQgAQ6CgguEK8BEMcBECc6CgguEMcBEK8BEEM6BAgAEEM6BQgAEJECOgoIABCABBAUEIcCOgcIABCABBAKOg4ILhCABBDHARCvARDLAToJCAAQFhAeEPEEOgYIABAWEB46BwghEKABEApKBAhBGAFQjAdYhGtgk3JoAXAAeACAAZgBiAGCGpIBBDAuMjSYAQCgAQHAAQE&sclient=gws-wiz-serp' target="_blank"><img className="brandicon" src={googlelogo} alt="Google" /></a></p>
                                <p className='TestLtd'><FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    I work with Amanda Ross Realty and their customer service here was amazing! Nahim is very professional and great to work with.
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>

                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>v cicchelli</strong> &nbsp; <a href='https://www.google.com/search?q=vtac+accounting+toronto&newwindow=1&bih=964&biw=2048&hl=en&sxsrf=AJOqlzVO4uETOdZdvgE05MRcsR8djpoKyg%3A1677356611124&ei=Q276Y9D5BvyfseMPwZSjsAc&ved=0ahUKEwiQp96nwLH9AhX8T2wGHUHKCHYQ4dUDCA4&uact=5&oq=vtac+accounting+toronto&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIFCCEQoAEyBQghEKABMgUIIRCgAToECCMQJzoGCAAQBRAeOgUIABCGAzoHCAAQHhDxBDoECAAQHjoFCAAQgAQ6CgguEK8BEMcBECc6CgguEMcBEK8BEEM6BAgAEEM6BQgAEJECOgoIABCABBAUEIcCOgcIABCABBAKOg4ILhCABBDHARCvARDLAToJCAAQFhAeEPEEOgYIABAWEB46BwghEKABEApKBAhBGAFQjAdYhGtgk3JoAXAAeACAAZgBiAGCGpIBBDAuMjSYAQCgAQHAAQE&sclient=gws-wiz-serp' target="_blank"><img className="brandicon" src={googlelogo} alt="Google" /></a></p>
                                <p className='TestLtd'><FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    After shopping around, I came to V TAC over 6 years ago  for my business and personal taxes.
                                    Nazim and his accountants are very skilled and I couldn't be happier with their results . If your worried about your taxes this is the place to go.
                                    Vince
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>

                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>Tim Dupuis</strong> &nbsp; <a href='https://www.google.com/search?q=vtac+accounting+toronto&newwindow=1&bih=964&biw=2048&hl=en&sxsrf=AJOqlzVO4uETOdZdvgE05MRcsR8djpoKyg%3A1677356611124&ei=Q276Y9D5BvyfseMPwZSjsAc&ved=0ahUKEwiQp96nwLH9AhX8T2wGHUHKCHYQ4dUDCA4&uact=5&oq=vtac+accounting+toronto&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIFCCEQoAEyBQghEKABMgUIIRCgAToECCMQJzoGCAAQBRAeOgUIABCGAzoHCAAQHhDxBDoECAAQHjoFCAAQgAQ6CgguEK8BEMcBECc6CgguEMcBEK8BEEM6BAgAEEM6BQgAEJECOgoIABCABBAUEIcCOgcIABCABBAKOg4ILhCABBDHARCvARDLAToJCAAQFhAeEPEEOgYIABAWEB46BwghEKABEApKBAhBGAFQjAdYhGtgk3JoAXAAeACAAZgBiAGCGpIBBDAuMjSYAQCgAQHAAQE&sclient=gws-wiz-serp' target="_blank"><img className="brandicon" src={googlelogo} alt="Google" /></a></p>
                                <p className='TestLtd'><FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    My wife & I have been going to V TAC for over 30 years and have had great service.Nazim and his staff are very professional and friendly.Cost to do your taxes is reasonable and we would recommend V TAC to anyone looking to do their taxes.Tim & Sheila D
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>

                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>Robert Ostapchuk</strong> &nbsp; <a href='https://www.google.com/search?q=vtac+accounting+toronto&newwindow=1&bih=964&biw=2048&hl=en&sxsrf=AJOqlzVO4uETOdZdvgE05MRcsR8djpoKyg%3A1677356611124&ei=Q276Y9D5BvyfseMPwZSjsAc&ved=0ahUKEwiQp96nwLH9AhX8T2wGHUHKCHYQ4dUDCA4&uact=5&oq=vtac+accounting+toronto&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIFCCEQoAEyBQghEKABMgUIIRCgAToECCMQJzoGCAAQBRAeOgUIABCGAzoHCAAQHhDxBDoECAAQHjoFCAAQgAQ6CgguEK8BEMcBECc6CgguEMcBEK8BEEM6BAgAEEM6BQgAEJECOgoIABCABBAUEIcCOgcIABCABBAKOg4ILhCABBDHARCvARDLAToJCAAQFhAeEPEEOgYIABAWEB46BwghEKABEApKBAhBGAFQjAdYhGtgk3JoAXAAeACAAZgBiAGCGpIBBDAuMjSYAQCgAQHAAQE&sclient=gws-wiz-serp' target="_blank"><img className="brandicon" src={googlelogo} alt="Google" /></a></p>
                                <p className='TestLtd'><FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    This was the first time I took my taxes here and it was amazing. The price was incredibly reasonable and they saved me money. I'll never take my taxes anywhere else again.
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>

                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>harry rudolfs</strong> &nbsp; <a href='https://www.google.com/search?q=vtac+accounting+toronto&newwindow=1&bih=964&biw=2048&hl=en&sxsrf=AJOqlzVO4uETOdZdvgE05MRcsR8djpoKyg%3A1677356611124&ei=Q276Y9D5BvyfseMPwZSjsAc&ved=0ahUKEwiQp96nwLH9AhX8T2wGHUHKCHYQ4dUDCA4&uact=5&oq=vtac+accounting+toronto&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIFCCEQoAEyBQghEKABMgUIIRCgAToECCMQJzoGCAAQBRAeOgUIABCGAzoHCAAQHhDxBDoECAAQHjoFCAAQgAQ6CgguEK8BEMcBECc6CgguEMcBEK8BEEM6BAgAEEM6BQgAEJECOgoIABCABBAUEIcCOgcIABCABBAKOg4ILhCABBDHARCvARDLAToJCAAQFhAeEPEEOgYIABAWEB46BwghEKABEApKBAhBGAFQjAdYhGtgk3JoAXAAeACAAZgBiAGCGpIBBDAuMjSYAQCgAQHAAQE&sclient=gws-wiz-serp' target="_blank"><img className="brandicon" src={googlelogo} alt="Google" /></a></p>
                                <p className='TestLtd'><FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    I've known Nazim for almost 20 years and I always go to VTac to do my taxes. Even when I moved out of town 8 years ago. I always make the trip to Alliance Plaza every year. Nazim saves me money and always has my back if there are any problems. He's simply the best!
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>

                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>Camelita Potot</strong> &nbsp; <a href='https://www.google.com/search?q=vtac+accounting+toronto&newwindow=1&bih=964&biw=2048&hl=en&sxsrf=AJOqlzVO4uETOdZdvgE05MRcsR8djpoKyg%3A1677356611124&ei=Q276Y9D5BvyfseMPwZSjsAc&ved=0ahUKEwiQp96nwLH9AhX8T2wGHUHKCHYQ4dUDCA4&uact=5&oq=vtac+accounting+toronto&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIFCCEQoAEyBQghEKABMgUIIRCgAToECCMQJzoGCAAQBRAeOgUIABCGAzoHCAAQHhDxBDoECAAQHjoFCAAQgAQ6CgguEK8BEMcBECc6CgguEMcBEK8BEEM6BAgAEEM6BQgAEJECOgoIABCABBAUEIcCOgcIABCABBAKOg4ILhCABBDHARCvARDLAToJCAAQFhAeEPEEOgYIABAWEB46BwghEKABEApKBAhBGAFQjAdYhGtgk3JoAXAAeACAAZgBiAGCGpIBBDAuMjSYAQCgAQHAAQE&sclient=gws-wiz-serp' target="_blank"><img className="brandicon" src={googlelogo} alt="Google" /></a></p>
                                <p className='TestLtd'><FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    Nazim thank for the great job that you do it’s well appreciated.
                                    Best wishes to you & the family stay safe.
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>

                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>Jimmy La</strong> &nbsp; <a href='https://www.google.com/search?q=vtac+accounting+toronto&newwindow=1&bih=964&biw=2048&hl=en&sxsrf=AJOqlzVO4uETOdZdvgE05MRcsR8djpoKyg%3A1677356611124&ei=Q276Y9D5BvyfseMPwZSjsAc&ved=0ahUKEwiQp96nwLH9AhX8T2wGHUHKCHYQ4dUDCA4&uact=5&oq=vtac+accounting+toronto&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIFCCEQoAEyBQghEKABMgUIIRCgAToECCMQJzoGCAAQBRAeOgUIABCGAzoHCAAQHhDxBDoECAAQHjoFCAAQgAQ6CgguEK8BEMcBECc6CgguEMcBEK8BEEM6BAgAEEM6BQgAEJECOgoIABCABBAUEIcCOgcIABCABBAKOg4ILhCABBDHARCvARDLAToJCAAQFhAeEPEEOgYIABAWEB46BwghEKABEApKBAhBGAFQjAdYhGtgk3JoAXAAeACAAZgBiAGCGpIBBDAuMjSYAQCgAQHAAQE&sclient=gws-wiz-serp' target="_blank"><img className="brandicon" src={googlelogo} alt="Google" /></a></p>
                                <p className='TestLtd'><FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    Nazeem, Nur, and the rest of Vtac Accounting are fantastic! They have helped with my personal and business taxes for a very long time. They are prompt with their responses and will personally call you to answer your questions. Very professional, kind, and trustworthy people. Thank you for taking care of us over the years. If you’re looking for people who care about you and your accounting, make sure you go to Vtac Accounting!
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>

                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>Vlad Pavliuc</strong> &nbsp; <a href='https://www.google.com/search?q=vtac+accounting+toronto&newwindow=1&bih=964&biw=2048&hl=en&sxsrf=AJOqlzVO4uETOdZdvgE05MRcsR8djpoKyg%3A1677356611124&ei=Q276Y9D5BvyfseMPwZSjsAc&ved=0ahUKEwiQp96nwLH9AhX8T2wGHUHKCHYQ4dUDCA4&uact=5&oq=vtac+accounting+toronto&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIFCCEQoAEyBQghEKABMgUIIRCgAToECCMQJzoGCAAQBRAeOgUIABCGAzoHCAAQHhDxBDoECAAQHjoFCAAQgAQ6CgguEK8BEMcBECc6CgguEMcBEK8BEEM6BAgAEEM6BQgAEJECOgoIABCABBAUEIcCOgcIABCABBAKOg4ILhCABBDHARCvARDLAToJCAAQFhAeEPEEOgYIABAWEB46BwghEKABEApKBAhBGAFQjAdYhGtgk3JoAXAAeACAAZgBiAGCGpIBBDAuMjSYAQCgAQHAAQE&sclient=gws-wiz-serp' target="_blank"><img className="brandicon" src={googlelogo} alt="Google" /></a></p>
                                <p className='TestLtd'><FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    Professional, efficient, very well informed, always helpful. Excellent service.
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>

                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>Luqman Haley</strong> &nbsp; <a href='https://www.google.com/search?q=vtac+accounting+toronto&newwindow=1&bih=964&biw=2048&hl=en&sxsrf=AJOqlzVO4uETOdZdvgE05MRcsR8djpoKyg%3A1677356611124&ei=Q276Y9D5BvyfseMPwZSjsAc&ved=0ahUKEwiQp96nwLH9AhX8T2wGHUHKCHYQ4dUDCA4&uact=5&oq=vtac+accounting+toronto&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIFCCEQoAEyBQghEKABMgUIIRCgAToECCMQJzoGCAAQBRAeOgUIABCGAzoHCAAQHhDxBDoECAAQHjoFCAAQgAQ6CgguEK8BEMcBECc6CgguEMcBEK8BEEM6BAgAEEM6BQgAEJECOgoIABCABBAUEIcCOgcIABCABBAKOg4ILhCABBDHARCvARDLAToJCAAQFhAeEPEEOgYIABAWEB46BwghEKABEApKBAhBGAFQjAdYhGtgk3JoAXAAeACAAZgBiAGCGpIBBDAuMjSYAQCgAQHAAQE&sclient=gws-wiz-serp' target="_blank"><img className="brandicon" src={googlelogo} alt="Google" /></a></p>
                                <p className='TestLtd'><FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    I have been coming to V-TAC accounting at this location for about 20 years Without any problem whatsoever The best in the West.
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>

                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>Leonardo Sevilla</strong> &nbsp; <a href='https://www.google.com/search?q=vtac+accounting+toronto&newwindow=1&bih=964&biw=2048&hl=en&sxsrf=AJOqlzVO4uETOdZdvgE05MRcsR8djpoKyg%3A1677356611124&ei=Q276Y9D5BvyfseMPwZSjsAc&ved=0ahUKEwiQp96nwLH9AhX8T2wGHUHKCHYQ4dUDCA4&uact=5&oq=vtac+accounting+toronto&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIFCCEQoAEyBQghEKABMgUIIRCgAToECCMQJzoGCAAQBRAeOgUIABCGAzoHCAAQHhDxBDoECAAQHjoFCAAQgAQ6CgguEK8BEMcBECc6CgguEMcBEK8BEEM6BAgAEEM6BQgAEJECOgoIABCABBAUEIcCOgcIABCABBAKOg4ILhCABBDHARCvARDLAToJCAAQFhAeEPEEOgYIABAWEB46BwghEKABEApKBAhBGAFQjAdYhGtgk3JoAXAAeACAAZgBiAGCGpIBBDAuMjSYAQCgAQHAAQE&sclient=gws-wiz-serp' target="_blank"><img className="brandicon" src={googlelogo} alt="Google" /></a></p>
                                <p className='TestLtd'><FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    It's a good place, may not have the best looks but they provide a good service. The accountant there help me with some issues my previous accountant couldn't manage. I actually became their client because someone recommended me this place.
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>

                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>Philippe Ndayizeye</strong> &nbsp; <a href='https://www.google.com/search?q=vtac+accounting+toronto&newwindow=1&bih=964&biw=2048&hl=en&sxsrf=AJOqlzVO4uETOdZdvgE05MRcsR8djpoKyg%3A1677356611124&ei=Q276Y9D5BvyfseMPwZSjsAc&ved=0ahUKEwiQp96nwLH9AhX8T2wGHUHKCHYQ4dUDCA4&uact=5&oq=vtac+accounting+toronto&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIFCCEQoAEyBQghEKABMgUIIRCgAToECCMQJzoGCAAQBRAeOgUIABCGAzoHCAAQHhDxBDoECAAQHjoFCAAQgAQ6CgguEK8BEMcBECc6CgguEMcBEK8BEEM6BAgAEEM6BQgAEJECOgoIABCABBAUEIcCOgcIABCABBAKOg4ILhCABBDHARCvARDLAToJCAAQFhAeEPEEOgYIABAWEB46BwghEKABEApKBAhBGAFQjAdYhGtgk3JoAXAAeACAAZgBiAGCGpIBBDAuMjSYAQCgAQHAAQE&sclient=gws-wiz-serp' target="_blank"><img className="brandicon" src={googlelogo} alt="Google" /></a></p>
                                <p className='TestLtd'><FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    (Translated by Google) I have been working with Nasim for almost 17 years. Nasim is always
                                    courteous, kind and professional. If you want a competent accountant, please contact Nasim of VTAC Toronto.
                                    <br></br>
                                    (Original)
                                    Je travaille avec Nasim depuis bientot 17 ans. Nasim est toujours
                                    courtois, gentil et professionel. Si tu veux un comptable compétent, veuillez contacter Nasim de VTAC Toronto.
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>

                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>KAWA GRANDSON ENTERTAINMENT</strong> &nbsp; <a href='https://www.google.com/search?q=vtac+accounting+toronto&newwindow=1&bih=964&biw=2048&hl=en&sxsrf=AJOqlzVO4uETOdZdvgE05MRcsR8djpoKyg%3A1677356611124&ei=Q276Y9D5BvyfseMPwZSjsAc&ved=0ahUKEwiQp96nwLH9AhX8T2wGHUHKCHYQ4dUDCA4&uact=5&oq=vtac+accounting+toronto&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIFCCEQoAEyBQghEKABMgUIIRCgAToECCMQJzoGCAAQBRAeOgUIABCGAzoHCAAQHhDxBDoECAAQHjoFCAAQgAQ6CgguEK8BEMcBECc6CgguEMcBEK8BEEM6BAgAEEM6BQgAEJECOgoIABCABBAUEIcCOgcIABCABBAKOg4ILhCABBDHARCvARDLAToJCAAQFhAeEPEEOgYIABAWEB46BwghEKABEApKBAhBGAFQjAdYhGtgk3JoAXAAeACAAZgBiAGCGpIBBDAuMjSYAQCgAQHAAQE&sclient=gws-wiz-serp' target="_blank"><img className="brandicon" src={googlelogo} alt="Google" /></a></p>
                                <p className='TestLtd'><FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" style={{ color: "rgb(185, 179, 179)" }} icon={faStar} />
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    Nasim is very good accountant. I went to his office with my family for my income tax. He is very good and friendly. Helps you to get right income tax and tells u other benefits if you are eligible.
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>

                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>E S</strong> &nbsp; <a href='https://www.google.com/search?q=vtac+accounting+toronto&newwindow=1&bih=964&biw=2048&hl=en&sxsrf=AJOqlzVO4uETOdZdvgE05MRcsR8djpoKyg%3A1677356611124&ei=Q276Y9D5BvyfseMPwZSjsAc&ved=0ahUKEwiQp96nwLH9AhX8T2wGHUHKCHYQ4dUDCA4&uact=5&oq=vtac+accounting+toronto&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIFCCEQoAEyBQghEKABMgUIIRCgAToECCMQJzoGCAAQBRAeOgUIABCGAzoHCAAQHhDxBDoECAAQHjoFCAAQgAQ6CgguEK8BEMcBECc6CgguEMcBEK8BEEM6BAgAEEM6BQgAEJECOgoIABCABBAUEIcCOgcIABCABBAKOg4ILhCABBDHARCvARDLAToJCAAQFhAeEPEEOgYIABAWEB46BwghEKABEApKBAhBGAFQjAdYhGtgk3JoAXAAeACAAZgBiAGCGpIBBDAuMjSYAQCgAQHAAQE&sclient=gws-wiz-serp' target="_blank"><img className="brandicon" src={googlelogo} alt="Google" /></a></p>
                                <p className='TestLtd'><FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" icon={faStar} />
                                    <FontAwesomeIcon className="staricon" style={{ color: "rgb(185, 179, 179)" }} icon={faStar} />
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    Quick walk in service for personal income tax with no appointment needed. The staff is friendly and it does cost less than most of the regular places.
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>Asif Khan</strong> &nbsp; <a href='https://www.facebook.com/vtacaccountingandtax/reviews' target="_blank" style={{ textDecoration: "none" }}> <FontAwesomeIcon className="brandicon" icon={faFacebook} /></a></p>
                                <p className='TestLtd'><img className="staricon" src={fbstar} />

                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    Vtac accounting is a one stop shop for all your accounting and payroll needs. Mr. Nazim is a true professional and expert on all aspects of  tax/accounting and payroll. I have been a happy client for the past 15 plus years. Thank you for your exceptional service.
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>

                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>Martin Worrod</strong> &nbsp; <a href='https://www.facebook.com/vtacaccountingandtax/reviews' target="_blank" style={{ textDecoration: "none" }}> <FontAwesomeIcon className="brandicon" icon={faFacebook} /></a></p>
                                <p className='TestLtd'><img className="staricon" src={fbstar} />

                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    we have been getting our taxes done there for about 15 years and have never had a problem the staff there are friendly and knowledgeable
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>Janette Strong</strong> &nbsp; <a href='https://www.facebook.com/vtacaccountingandtax/reviews' target="_blank" style={{ textDecoration: "none" }}> <FontAwesomeIcon className="brandicon" icon={faFacebook} /></a></p>
                                <p className='TestLtd'><img className="staricon" src={fbstar} />

                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    I recommend Vtac to small business owners like myself. Your service is top notch.
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>Shelley Ince</strong> &nbsp; <a href='https://www.facebook.com/vtacaccountingandtax/reviews' target="_blank" style={{ textDecoration: "none" }}> <FontAwesomeIcon className="brandicon" icon={faFacebook} /></a></p>
                                <p className='TestLtd'><img className="staricon" src={fbstar} />

                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    Always reliable and thorough. Have been coming to Vtac for almost 2 decades now and wouldn't think of going anywhere else.
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>Wafa Malik</strong> &nbsp; <a href='https://www.facebook.com/vtacaccountingandtax/reviews' target="_blank" style={{ textDecoration: "none" }}> <FontAwesomeIcon className="brandicon" icon={faFacebook} /></a></p>
                                <p className='TestLtd'><img className="staricon" src={fbstar} />

                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    Excellent firm , polite and helpful staff . Extremely knowledgeable about all taxes and accounting needs . Never had any disappointment .
                                </h2>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>Massa Roger</strong> &nbsp; <a href='https://www.facebook.com/vtacaccountingandtax/reviews' target="_blank" style={{ textDecoration: "none" }}> <FontAwesomeIcon className="brandicon" icon={faFacebook} /></a></p>
                                <p className='TestLtd'><img className="staricon" src={fbstar} />

                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    Amazeing place to go very fast and reasonable prices I recommend everyone to go their for their taxes
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>George Adamidis</strong> &nbsp; <a href='https://www.facebook.com/vtacaccountingandtax/reviews' target="_blank" style={{ textDecoration: "none" }}> <FontAwesomeIcon className="brandicon" icon={faFacebook} /></a></p>
                                <p className='TestLtd'><img className="staricon" src={fbstar} />

                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    Vtac Accounting handles both my family and corporate tax returns.  They are professional, accurate and very fairly priced.  I highly recommend their service.
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>Shafurdeen Rafeek</strong> &nbsp; <a href='https://www.facebook.com/vtacaccountingandtax/reviews' target="_blank" style={{ textDecoration: "none" }}> <FontAwesomeIcon className="brandicon" icon={faFacebook} /></a></p>
                                <p className='TestLtd'><img className="staricon" src={fbstar} />

                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    As a business owner, my tax needs have been flawless for the past ten years working with V-Tac Accounting. I do love the customer service and the friendly staff who always gives a stellar performances. The prices are amazing to fit anyone's budget and they will try their utmost best to get you the most out of your returns. I highly recommend you give them a try this tax season.
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>Bobby Singh</strong> &nbsp; <a href='https://www.facebook.com/vtacaccountingandtax/reviews' target="_blank" style={{ textDecoration: "none" }}> <FontAwesomeIcon className="brandicon" icon={faFacebook} /></a></p>
                                <p className='TestLtd'><img className="staricon" src={fbstar} />

                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    ITS GREAT SERVICE IN AFFORDABLE PRICE. CORPORATION AND PERSONAL TAX GREAT ATTENTION
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>Robert L. Smith</strong> &nbsp; <a href='https://www.facebook.com/vtacaccountingandtax/reviews' target="_blank" style={{ textDecoration: "none" }}> <FontAwesomeIcon className="brandicon" icon={faFacebook} /></a></p>
                                <p className='TestLtd'><img className="staricon" src={fbstar} />

                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    I highly recommend V-Tac Accounting.  They have been stellar in servicing my needs.  Why would you go to anyone else?
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>Manuel Ventura</strong> &nbsp; <a href='https://www.facebook.com/vtacaccountingandtax/reviews' target="_blank" style={{ textDecoration: "none" }}> <FontAwesomeIcon className="brandicon" icon={faFacebook} /></a></p>
                                <p className='TestLtd'><img className="staricon" src={fbstar} />

                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    As a business owner, my book keeping and tax needs have been flawless for over five years working with V-Tac.  It's been great finally finding an accounting firm as well as payroll to deal with all of my needs.
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>Lana J Borg </strong> &nbsp; <a href='https://www.facebook.com/vtacaccountingandtax/reviews' target="_blank" style={{ textDecoration: "none" }}> <FontAwesomeIcon className="brandicon" icon={faFacebook} /></a></p>
                                <p className='TestLtd'><img className="staricon" src={fbstar} />

                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    Doing taxes and having to pay sure isn't my most favorite thing but coming here to have my taxes prepared has been a fabulous experience! The staff are super friendly and take the time and patience to address your needs. This is only our second year  but  wish we had know about them sooner. We recommended a couple others this year as well.. FIVE STAR all the way!  We are extremely satisfied with their service and very reasonable prices!  VTAC Accounting and Tax is the best!
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>Topone Barandlounge</strong> &nbsp; <a href='https://www.facebook.com/vtacaccountingandtax/reviews' target="_blank" style={{ textDecoration: "none" }}> <FontAwesomeIcon className="brandicon" icon={faFacebook} /></a></p>
                                <p className='TestLtd'><img className="staricon" src={fbstar} />

                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    My family has been going to Vtac accounting and tax for many years and we have always been satisfied with there services! One of the best income tax company that I can always refer others to is Vtac accounting and tax. A very friendly environment.
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='testDivimg'>
                                <img className="TestImg" src={demoImg} />
                            </div>
                            <div className='testDiv'>
                                <p className='TestClientName'><strong>Kevin Connor</strong> &nbsp; <a href='https://www.facebook.com/vtacaccountingandtax/reviews' target="_blank" style={{ textDecoration: "none" }}> <FontAwesomeIcon className="brandicon" icon={faFacebook} /></a></p>
                                <p className='TestLtd'><img className="staricon" src={fbstar} />

                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <div className='googleRevHr' style={{ display: "-webkit-inline-flex" }}>
                                <h2 className='reviewtitle'>
                                    My family has been going to Vtac for many years and we have always been satisfied with there services! Last year we applied for the sick tax benefits because of my father's health and Vtac did a good job in getting us even more money, thank you Vtac!
                                </h2>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        );
    }
}

export default GoogleReview;