import React, { Component, Fragment } from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Container, Row, Col, Button, Carousel } from 'react-bootstrap';
import p2 from '../asset/images/slideImg3.jpg';
import p3 from '../asset/images/slideImg2.jpg';
import p4 from '../asset/images/slideImg1.jpg';
import { Link } from 'react-router-dom';

export default class ClientReview extends Component {
  render() {
    var settings = {
      arrows: true,
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 5000,

      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
    return (
      <Fragment>
        <Container fluid={true} className="topSlideDiv mt-5 p-0">
          <Row>
            <Col lg={12} md={12} sm={12}>
              <Carousel autoplay={true} infinite={true}>
                <Carousel.Item interval={3000}>
                  <img
                    className="d-block w-100 topSlideImg" src={p2} />
                  <Carousel.Caption>

                    <div className="captionForSlide">
                      <h2 className='topTitle'><strong>Professional</strong></h2>
                      <p className='topSlidePara'>Our values and professionalism define who we are and what we do. It enables us to establish meaningful relationships with clients in the marketplace while allowing us to protect and enhance our own reputation. We are confident that our staff and our work will always reflect our firm’s values.</p>
                      {/* <div className='hr'></div> */}
                      <Link to="/about-us"><Button className="topBannerButton" >Learn More</Button></Link>
                    </div>
                  </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item interval={3000}>
                  <img
                    className="d-block w-100 topSlideImg"
                    src={p3}
                    alt="Second slide"
                  />

                  <Carousel.Caption>
                    <div className="captionForSlide">
                      <h2 className="topTitle"><strong>Trust</strong></h2>
                      <p className="topSlidePara">Our integrity and reliability make our Accounting services trustworthy. You can be assured that your records will always remain confidential and secure with us. We take the protection of your interests extremely seriously by safeguarding your personal data, allowing you the freedom to do businesses with us comfortably.
                      </p>
                      <Link to="/about-us"><Button className="topBannerButton" >Learn More</Button></Link>
                    </div>
                  </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item interval={3000}>
                  <img className="d-block w-100 topSlideImg"src={p4} />
                  <Carousel.Caption>
                    <div className="captionForSlide">

                      <h2 className="topTitle"><strong>Experience</strong></h2>
                      <p className="topSlidePara">Your accounting needs revolve around complex laws and legal requirements. As qualified experts, we recognize the importance of staying informed and up-to-date with new legislation so that you don’t have to. Take advantage of our expert knowledge to help put your mind at ease.</p>
                      <Link to="/about-us"><Button className="topBannerButton" >Learn More</Button></Link>
                    </div>
                  </Carousel.Caption>
                </Carousel.Item>
              </Carousel>
            </Col>
          </Row>
        </Container>
      </Fragment>
    )
  }
}
