import React, { Component, Fragment } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import TAXTiis from '../asset/images/TAX Tiis.png';
import TAXRATE from '../asset/images/TAX RATE.png';
import house from '../asset/images/house.jpg';
import TAXDates from '../asset/images/TAX Dates.png';
import taximplication1 from '../asset/images/tax-implication1.webp';
import Studenttax from '../asset/images/Student tax.png';
import taxdeduction4 from '../asset/images/tax-deduction-4.png';
import taxdeduction2 from '../asset/images/tax-deduction-2.png';
import tipsfsttimefiling2 from '../asset/images/5tips-fst-time-filing2.webp';
import taxinmultiplestate from '../asset/images/tax-in-multiple-state.png';

class BlogPart10 extends Component {
    render() {
        return (
            <Fragment>
                <Container>
                    <Row>
                        <Col lg={8} md={6} sm={12}>
                            <div className='blogDetailDiv'>
                                <h2 className='blogTopTitle fs-1'>Tax deductions for medical expenses under the CRA</h2>
                                <h2 className='blogDescTitle'>Why should you claim deductions for medical expenses?</h2>
                                <p className='blogDesc'>As you approach retirement, it can be difficult to manage rising healthcare costs. To bridge the gap between your limited budget and increasing medical expenses, consider the benefits of a reverse mortgage and filing CRA medical expense claims. Both options can help protect you from financial strain and provide the coverage you need for your healthcare needs.</p>
                                <p className='blogDesc'>If you're struggling to cover regular, out-of-pocket medical expenses, a reverse mortgage can be a great way to tap into your home's equity and receive tax-free cash. You can choose to receive payments on a regular basis or as a lump sum. Plus, at the end of the year, you can maximize recovery of your medical expenses by claiming a non-refundable Medical Expense Tax Credit (METC) through CRA allowable medical expenses.</p>
                                <img variant="top" className='blog1PageImg2 mt-4 mb-5' src={taxdeduction4} alt="tips for -tax-deduction-for medical- expenses"/> 
                                <p className='blogDesc'>Do you have medical expenses that are not covered by provincial health insurance programs? There are solutions to help you finance these costs in the future. Learn more about how you can pay for your prescription drugs, mobility aids, physiotherapy, and other medical expenses not covered by federal programs.</p>
                                <h2 className='blogDescTitle'>Medical expenses in Canada</h2>
                                <p className='blogDesc'>Canadian healthcare costs continue to climb rapidly each year. Prior to the onset of the COVID-19 pandemic, it was estimated that healthcare costs would go up by 5.4% annually. It is difficult to predict the effect of the pandemic on healthcare spending over the long term. Generally, in Canada, public healthcare accounts for roughly 70% of total medical expenses, with the remainder split between private health insurance and out-of-pocket payments. Those without private insurance may spend up to $5,000 annually on medical costs, with those over 60 facing expenses of up to $30,000 per year.</p>
                                <ul className='blogDesc'>
                                    <li>The average Canadian household spends an estimated $450 annually on prescription drugs and an additional $550 on private health plan premiums. For some, this can add up to a total of $1,500 per year just for prescription drugs.</li>
                                    <li>If you need assistive equipment like a cane, walker, scooter, or wheelchair, you may have to pay for some of these costs out of pocket. Wheelchairs can be as expensive as $4,000, while electric wheelchairs and other mobility aids can range from $2,000 to $10,000 or more. Home modifications to accommodate mobility aids can be even more costly. While there are government programs to help pay for ramps or chair lifts, they may not be available to people with certain incomes.</li>
                                    <li>By age 55, there is a 10% chance that you will require long-term care, and this probability increases to 50% by age 75. Since long-term care is not covered under the Canada Health Act, most of these costs will have to be paid by you. Depending on where you live and your annual income, some or all of your home care costs may be covered by the government in certain provinces.</li>
                                    <li style={{listStyle:"none"}}>
                                        <ul className='blogDesc'>
                                            <li>The fee for a personal care worker is between $28 and $35 per hour, while a registered nurse may charge from $45 up to $80 per hour.</li>
                                            <li>The cost of home healthcare services varies depending on the hours required. For a 3 to 8-hour shift, the rate is usually between $25 and $35 per hour. If you need overnight care or a live-in arrangement, the annual cost is between $58,000 and $75,000. For 24-hour in-home care, the cost is more than $200,000 each year.</li>
                                            <li>Nursing home care is an alternative when in-home care cannot be obtained. According to the Cost of Care Survey of 2017, the average annual expense for a semi-private room in a nursing home (including board, medication, and care) was $85,776, while a private room came with a median cost of $97,452.</li>
                                        </ul>
                                    </li>
                                    <li>Provincial health insurance plans may not cover certain medical expenses, such as dental care, physiotherapy, prescription glasses and ambulance services. These additional costs can quickly accumulate, even if you purchase a private insurance plan to cover them.
                                    </li>
                                </ul>
                                <p className='blogDesc'>Instead of using your retirement savings or depending on help from family, explore other ways to manage your finances that fit within your budget.</p>
                                <h2 className='blogDescTitle'>How to include CRA allowable medical expenses on your tax return</h2>
                                <p className='blogDesc'>To claim medical expenses through the Canada Revenue Agency (CRA), there are two sections in your Schedule 1 tax return where you can claim Medical Expense Tax Credit (METC). If you are unsure about who should be claiming these expenses, follow these steps:</p>
                                <ul className='blogDesc'>
                                    <li>To calculate your total allowable medical expense deduction for yourself, your spouse, your common-law partner and your dependent children under 18, use line 33099 on your tax return. If both you and your spouse have taxable income, it is more beneficial to claim the medical expenses on the return with lower income. Total your CRA allowable medical expenses for the year and subtract 3% of your net income, or $2,421 – whichever is the lesser of the two amounts.</li>
                                    <li>If you are claiming for other dependents, such as close family members or older children in your care, use line 33199. Use the same calculations as above, but remember to calculate the 3% on your dependent's net income, or $2,421, whichever is lower.</li>
                                </ul>
                                <h2 className='blogDescTitle'>How much of your CRA medical expenses can be claimed as a tax deduction?</h2>
                                <img variant="top" className='blog1PageImg2 mt-4 mb-5' src={taxdeduction2} alt="tips for self-employed-taxpayers"></img>
                                <p className='blogDesc'>If you are looking to be eligible for medical expenses, the minimum amount you must spend is either $2,421 or 3% of your net income, whichever is lower. To give you an example, here are two scenarios that can help you understand:</p>
                                <ul className='blogDesc'>
                                    <li>The total credit on your CRA medical expenses claim will be $1,800, calculated by subtracting 3% of your net income (the amount on line 236 of your return) of $40,000 (i.e. $1,200) from $3,000.</li>
                                    <li>From your total net income of $80,000, 3% of that will be $2,400. If you spent a total of $3,000, you can deduct $2,400 and claim $600 as METC (Medical Expense Tax Credit) on your tax returns.</li>
                                </ul>
                                <h2 className='blogDescTitle'>What documents should you submit to support your claims for eligible medical expenses?</h2>
                                <p className='blogDesc'>Make sure you keep relevant documents handy in case the CRA asks for a review later. These documents include:</p>
                                <p className='blogDesc'><strong>Receipts:</strong> Keep a record of all receipts for CRA-deductible medical expenses that you claim under METC. The receipt must show the name of the person or business that you paid for.</p>
                                <p className='blogDesc'><strong>Prescriptions:</strong> To claim the cost of prescription drugs that are listed in the CRA medical expense guide, make sure you have the corresponding prescription copies with you. If you don't have the copy, you can ask an authorized medical practitioner to reissue it.</p>
                                <p className='blogDesc'><strong>Certification in writing:</strong> If you need a certification for certain medical expenses outlined in the CRA medical expense guide, you can request one from your primary healthcare provider or an authorized medical practitioner.</p>
                                <p className='blogDesc'><strong>Form 2201 Disability Tax Credit Certification:</strong> If you require financial assistance for expenses related to nursing home care or personalized therapy, you must apply for the Disability Tax Credit Certificate through the Canada Revenue Agency (CRA) by submitting Form 2201. For more information on this requirement, please visit the CRA website.</p>
                                <h2 className='blogDescTitle'>Eligible medical expenses in Canada</h2>
                                <p className='blogDesc'>The Canada Revenue Agency (CRA) lists a wide range of medical expenses that can be claimed by those 55 and older. Some of the more common ones include:</p>
                                <p className='blogDesc'><strong>Medical treatment:</strong> You can claim eligible medical expenses that are listed by the Canada Revenue Agency (CRA) that are not covered by your provincial insurance plans, as long as they were provided by an authorized medical practitioner in a public or licensed private hospital, regardless of whether they were received in Canada or abroad.</p>
                                <p className='blogDesc'><strong>Assistance with hearing and vision:</strong> Claim the expenses you have incurred for hearing aids and all related accessories (such as batteries and repairs), audible signal devices, and captioning equipment, as these are all allowable medical expenses according to the CRA. You can also claim any expenses related to vision issues, such as eyeglasses, laser eye surgery, contact lenses, and reading services.</p>
                                <p className='blogDesc'><strong>Mobility aids:</strong> You can claim CRA-allowable medical expenses (maximum $5,000) such as scooters, wheelchairs, wheelchair carriers, walking aids, and up to 20% of the cost of a van to transport wheelchair users.</p>
                                <p className='blogDesc'><strong>Care facilities:</strong> If you, your spouse, or your dependent are receiving full-time care in a nursing home or other institution, you can claim the associated fees, including food and accommodation. However, if you are receiving in-home care or staying in a retirement home, you are only eligible to claim the salaries or wages of the attendant care services.</p>
                                <p className='blogDesc'><strong>Drugs:</strong> Prescription medications and vaccinations are covered under eligible claims. However, non-prescription medications and most vitamin supplements are not able to be claimed.</p>
                                <p className='blogDesc'><strong>Medical and non-medical equipment:</strong> You can claim Canadian Revenue Agency (CRA) medical expenses for a variety of qualifying medical equipment, such as heart monitoring devices, kidney machines, catheters, phototherapy equipment, and more. Additionally, you can also claim these expenses for non-medical items, such as assisted breathing devices, electrotherapy devices, air purifiers, air conditioners, lifts, and transportation equipment. This includes any associated repair costs as well.</p>
                                <p className='blogDesc'><strong>Renovations and household mobility aids:</strong> You may be able to claim costs for mobility-related aids, such as bathroom rails or stair chairs, as well as for any structural renovations needed to make a space wheelchair-accessible.</p>
                                <p className='blogDesc'><strong>Medical expenses for travel:</strong> If you travel over 40 kilometres for medical treatment, the Canada Revenue Agency (CRA) permits you to claim expenses for transit such as taxi, bus, or train. If the distance of your travel exceeds 80 kilometres, you are also eligible to claim accommodation, meals, and vehicle expenses (including parking charges) for yourself. </p>
                                <h2 className='blogDescTitle'>How can you finance medical expenses if you don't have insurance or need more funds?</h2>
                                <p className='blogDesc'>If your budget does not allow for an insurance policy or an annuity, check if you qualify for any government-provided income assistance programs and benefits. If you do not qualify or require more funds, you could explore different borrowing options, such as:</p>
                                <p className='blogDesc'><strong>Personal loans:</strong> A lump sum loan can be a great way to finance a one-time expense that is not covered by your provincial insurance program, such as laser eye surgery or dental surgery. The loan amount can be repaid in monthly instalments at competitive interest rates.</p>
                                <p className='blogDesc'><strong>Conversions related to life insurance policies:</strong> You may choose to convert your existing life insurance policy into a long-term care benefit plan or receive a lump sum by cashing in your policy. However, please be aware that in either situation, your beneficiaries will not receive the full value of your policy upon your death.</p>
                                <p className='blogDesc'><strong>Home equity line of credit (HELOC):</strong> If you own at least 20% of your home and have a good credit score, you may be able to qualify for a low-interest line of credit that is secured by the value of your home. Repayments will start as soon as you take out the loan.</p>
                                <p className='blogDesc'><strong>Reverse mortgage:</strong> If you are 55 years or older and own your own home, you can access some of the value of your home in tax-free cash without any monthly payments. The amount must be paid back when you decide to move, sell, or after you pass away.</p>
                        
                               
                                
                            </div>
                        </Col>

                        <Col lg={4} md={6} sm={12}>
                            <div className='relatedArticle'>
                                <h2 className='fs-3 '>Related Articles</h2>
                                <hr style={{ width: "100%", float: "left" }}></hr>
                            </div>
                            <Card className='blogCardRight' >
                                <Link to="/ways-to-stay-ahead-of-tax-season" style={{ textDecoration: "none" }}>
                                    <div>
                                        <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                        <Card.Img variant="top" className='industryImg' src={TAXTiis} />
                                    </div>

                                    <Card.Body>
                                        <Card.Title className='blogCardRightTitle'>8 straight ways to stay ahead of tax season 2023</Card.Title>
                                    </Card.Body>
                                </Link>
                            </Card>
                            <Card className='blogCardRight float-right'>
                                <Link to="/federal-tax-brackets-rates-credits" style={{ textDecoration: "none" }}>
                                    <div>
                                        <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                        <Card.Img variant="top" className='industryImg' src={TAXRATE} />
                                    </div>

                                    <Card.Body>
                                        <Card.Title className='blogCardRightTitle'>Federal Tax Brackets, Rates & Credits 2023</Card.Title>
                                    </Card.Body>
                                </Link>
                            </Card>
                            <Card className='blogCardRight float-right'>
                                <Link to="/housing-benefit-and-the-way-to-apply" style={{ textDecoration: "none" }}>
                                    <div>
                                        <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                        <Card.Img variant="top" className='industryImg' src={house} />
                                    </div>

                                    <Card.Body>
                                        <Card.Title className='blogCardRightTitle'>Are you a renter in Canada? Learn about the Housing Benefit and the way to apply for it. </Card.Title>
                                    </Card.Body>
                                </Link>
                            </Card>

                            <Card className='blogCardRight float-right'>
                                <Link to="/important-tax-deadlines" style={{ textDecoration: "none" }}>
                                    <div>
                                        <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                        <Card.Img variant="top" className='industryImg' src={TAXDates} />
                                    </div>

                                    <Card.Body>
                                        <Card.Title className='blogCardRightTitle'>Important Tax Deadlines 2023</Card.Title>
                                    </Card.Body>
                                </Link>
                            </Card>
                            <Card className='blogCardRight'>
                                <Link to="/tips-for-tax-students" style={{ textDecoration: "none" }}>
                                    <div>
                                        <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                        <Card.Img variant="top" className='industryImg' src={Studenttax} />
                                    </div>

                                    <Card.Body>
                                        <Card.Title className='blogCardRightTitle'>Maximizing Your 2022 Return: Tips for Students</Card.Title>
                                    </Card.Body>
                                </Link>
                            </Card>
                            <Card className='blogCardRight float-right'>
                                <Link to="/tax-implications-for-having-multiple-employers" style={{ textDecoration: "none" }}>
                                    <div>
                                        <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                        <Card.Img variant="top" className='industryImg' src={taximplication1} />
                                    </div>

                                    <Card.Body>
                                        <Card.Title className='blogCardRightTitle'>Tax implications for having multiple employers</Card.Title>
                                    </Card.Body>
                                </Link>
                            </Card>
                            <Card className='blogCardRight float-right'>
                                <Link to="/tips-for-a-great-first-time-filing-experience" style={{ textDecoration: "none" }}>
                                    <div>
                                        <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                        <Card.Img variant="top" className='industryImg' src={tipsfsttimefiling2} />
                                    </div>

                                    <Card.Body>
                                        <Card.Title className='blogCardRightTitle'>5 tips for a great first-time filing experience</Card.Title>
                                    </Card.Body>
                                </Link>
                            </Card>
                            <Card className='blogCardRight float-right'>
                                <Link to="/how-to-filing-taxes-in-multiple-states-when-moved" style={{ textDecoration: "none" }}>
                                    <div>
                                        <Card.Title className='tipsfortaxTitle'>Tips For Tax</Card.Title>
                                        <Card.Img variant="top" className='industryImg' src={taxinmultiplestate} />
                                    </div>

                                    <Card.Body>
                                        <Card.Title className='blogCardRightTitle'>How to Filing Taxes in Multiple States When Moved</Card.Title>
                                    </Card.Body>
                                </Link>
                            </Card>

                        </Col>
                    </Row>
                </Container>
            </Fragment>
        );
    }
}

export default BlogPart10;