import React, { Component, Fragment } from 'react';
import '../asset/css/custom.css';
import '../asset/css/bootstrap.min.css';
import '../asset/css/responsive.css';

import { Navbar, Nav, Container, Button, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import vtaclogo from '../asset/images/Vtac2.png';
import { NavLink, Link } from "react-router-dom";
import NavDropdown from 'react-bootstrap/NavDropdown';
import 'aos/dist/aos.css';
import AOS from 'aos';
class NewTopNavigation extends Component {
  constructor(props) {
    super();
    this.state = {
      pageTitle: props.title,
      activeItem: null,
    }

    // this.handleItemClick = this.handleItemClick.bind(this);

  }
  handleItemClick(eventKey) {
    
      this.setState({ activeItem: eventKey });
   
  }
  // scroll to top on page load

  componentDidMount() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    AOS.init();
  }

  render() {
    return (
      <Fragment>
        <title>{this.state.pageTitle}</title>
        <Container fluid={true} className='topHeader p-0' top='fixed'>
          <Row>
            <Col lg={12} md={12} sm={12} >
              <div className='topSocialLink'>
                <a href="https://www.linkedin.com/company/v-tac-accounting-&-tax/" target='_blank' className="topHeadicon"><FontAwesomeIcon className='headFontawsome' icon={faLinkedin} /></a>
                <a href="https://www.facebook.com/vtacaccountingandtax" target='_blank' className="topHeadicon"><FontAwesomeIcon className='headFontawsome' icon={faFacebook} /></a>
                <a href="https://twitter.com/vtacaccouting" target='_blank' className="topHeadicon"><FontAwesomeIcon className='headFontawsome' icon={faTwitter} /></a>
                <a href="tel:+1-866-882-2829" className='topHeadicon'><FontAwesomeIcon className='headFontawsome' style={{ color: "orange" }} icon={faPhone} /> 1-866-882-2829</a>
                <a target='_blank' className='topHeadicon'>| &nbsp;<FontAwesomeIcon style={{ color: '#f89a1c' }} icon={faEnvelope} /> &nbsp;info@vtac.ca</a>
              </div>
            </Col>
            <Col lg={12} md={12} sm={12}>
              <Navbar collapseOnSelect expand="lg" className='navSection2 ' >
                <Container fluid>
                  <Navbar.Brand ><Link to="/"><img src={vtaclogo} className="vtacLogo2" alt='VTAC logo' title='V-Tac Accounting & Tax' onClick={() => {
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                  }} /></Link></Navbar.Brand>
                  <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                  <Navbar.Collapse id="responsive-navbar-nav">

                    <Nav className='ms-auto nav-list'>
                      <Nav.Link >
                        <NavLink className="navItem2" to="/" onClick={() => {
                          window.scrollTo({ top: 0, behavior: 'smooth' });
                        }}>Home</NavLink>
                      </Nav.Link>

                      <NavDropdown title="Services" id="collasible-nav-dropdown" renderMenuOnMount={true} >
                        <hr className='navHr'></hr>
                        {/* <NavDropdown.Item className='navDropdownItem' as={Link} to="/tips-for-tax" onClick={() => {
                          window.scrollTo({ top: 0, behavior: 'smooth' });
                        }}>Tips For Tax</NavDropdown.Item> */}
                        <NavDropdown.Item className='navDropdownItem' as={Link} to="/accounting"
                          eventKey="1"
                          active={this.state.activeItem === "1"}
                          onClick={() => this.handleItemClick("1")}>Accounting</NavDropdown.Item>
                        <NavDropdown.Item className='navDropdownItem' as={Link} to="/bookkeeping" eventKey="2"
                          active={this.state.activeItem === "2"}
                          onClick={() => this.handleItemClick("2")}>Bookkeeping</NavDropdown.Item>
                        <NavDropdown.Item className='navDropdownItem' as={Link} to="/taxation" onClick={() => {
                          window.scrollTo({ top: 0, behavior: 'smooth' });
                        }}>Taxation</NavDropdown.Item>
                        <NavDropdown.Item className='navDropdownItem' as={Link} to="/payroll-processing" onClick={() => {
                          window.scrollTo({ top: 0, behavior: 'smooth' });
                        }}>Payroll Processing</NavDropdown.Item>
                        <NavDropdown.Item className='navDropdownItem' as={Link} to="/tax-clinic" onClick={() => {
                          window.scrollTo({ top: 0, behavior: 'smooth' });
                        }}>V-Tac Tax Clinic</NavDropdown.Item>
                        <NavDropdown.Item className='navDropdownItem' as={Link} to="/business-incorporation" onClick={() => {
                          window.scrollTo({ top: 0, behavior: 'smooth' });
                        }}>Business Incorporation</NavDropdown.Item>

                        <NavDropdown.Item className='navDropdownItem' as={Link} to="/business-plan" onClick={() => {
                          window.scrollTo({ top: 0, behavior: 'smooth' });
                        }}>Business Plan and Loans</NavDropdown.Item>
                        <NavDropdown.Item className='navDropdownItem' as={Link} to="/accounting-software">Accounting Software</NavDropdown.Item>
                        <NavDropdown.Item className='navDropdownItem' as={Link} to="/case-study" onClick={() => {
                          window.scrollTo({ top: 0, behavior: 'smooth' });
                        }}>Case Study</NavDropdown.Item>
                        <NavDropdown.Item className='navDropdownItem' as={Link} to="/other-services" onClick={() => {
                          window.scrollTo({ top: 0, behavior: 'smooth' });
                        }}>Other Services</NavDropdown.Item>
                      </NavDropdown>

                      <NavDropdown title="About" id="collasible-nav-dropdown" renderMenuOnMount={true}>
                        <hr className='navHr'></hr>
                        <NavDropdown.Item as={Link} to="/about-us" onClick={() => {
                          window.scrollTo({ top: 0, behavior: 'smooth' });
                        }}>About Us</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/our-clients">Our Clients</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/clients-review" onClick={() => {
                          window.scrollTo({ top: 0, behavior: 'smooth' });
                        }}>Clients Review</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/our-testimonials" onClick={() => {
                          window.scrollTo({ top: 0, behavior: 'smooth' });
                        }}>Our Testimonials</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/news-and-updates" onClick={() => {
                          window.scrollTo({ top: 0, behavior: 'smooth' });
                        }}>News and Updates</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/covid-19-update" onClick={() => {
                          window.scrollTo({ top: 0, behavior: 'smooth' });
                        }}>COVID-19 Update!</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/useful-links" onClick={() => {
                          window.scrollTo({ top: 0, behavior: 'smooth' });
                        }}>Useful Links</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/contest" onClick={() => {
                          window.scrollTo({ top: 0, behavior: 'smooth' });
                        }}>Contest</NavDropdown.Item>
                      </NavDropdown>
                      <NavDropdown title="Contact" id="collasible-nav-dropdown" renderMenuOnMount={true}>
                        <hr className='navHr'></hr>
                        <NavDropdown.Item as={Link} to="/contact" onClick={() => {
                          window.scrollTo({ top: 0, behavior: 'smooth' });
                        }}>Contact Us</NavDropdown.Item>

                        <NavDropdown.Item as={Link} to="/request-quote" onClick={() => {
                          window.scrollTo({ top: 0, behavior: 'smooth' });
                        }}>Request Quote</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/careers" onClick={() => {
                          window.scrollTo({ top: 0, behavior: 'smooth' });
                        }}>Careers</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/faqs" onClick={() => {
                          window.scrollTo({ top: 0, behavior: 'smooth' });
                        }}>FAQS</NavDropdown.Item>
                      </NavDropdown>
                      <Nav.Link >
                        <NavLink className="navItem2" to="/tips-for-tax" onClick={() => {
                          window.scrollTo({ top: 0, behavior: 'smooth' });
                        }}>Tips For Tax</NavLink>
                      </Nav.Link>
                    </Nav>
                    <Nav className='ml-auto'>

                      <Nav.Link ><NavLink to="/request-quote"><Button className="navButtonLink2" onClick={() => {
                        window.scrollTo({ top: 0, behavior: 'smooth' });
                      }}><span><strong>Request Quote</strong></span></Button>{' '}</NavLink></Nav.Link>
                      <Nav.Link ><NavLink to="/contest"><Button className="contestbutton" onClick={() => {
                        window.scrollTo({ top: 0, behavior: 'smooth' });
                      }}><span><strong>CONTEST</strong></span></Button>{' '}</NavLink></Nav.Link>
                      <Nav.Link >
                        <NavLink to="/remote-tax-filing"><Button className="navFilingbutton" onClick={() => {
                          window.scrollTo({ top: 0, behavior: 'smooth' });
                        }}><span><strong>Remote Filing</strong></span></Button>{' '}</NavLink>
                      </Nav.Link>
                      <Nav.Link style={{ cursor: "none" }}> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Nav.Link>
                    </Nav>

                  </Navbar.Collapse>
                </Container>
              </Navbar>
            </Col>
          </Row>

        </Container>
      </Fragment>
    );
  }
}

export default NewTopNavigation;