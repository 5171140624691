import React, { Component, Fragment } from 'react';
import { Col, Container, Row} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
 import AOS from 'aos';
 import 'aos/dist/aos.css';
import Slide5 from '../asset/images/Slide5.jpg';
import Slide4 from '../asset/images/Slide4.jpg';

class SendQuotationSms extends Component {
    componentDidMount() {
        AOS.init();
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <Fragment>
                <Container fluid={true} className="accContainer" >

                    <Container className='accSection' >
                        <div className='reqHeader'>
                            <h2>
                                Request Success</h2>
                        </div>
                        <div className='mt-3'></div>
                        <Container className='SectionBetween'>
                            <Row>
                                <Col lg={6} md={6} sm={12}>
                                    <div className=" quoteCard">
                                        <div id="RQForm">
                                            <div data-aos="fade-right"
                                                data-aos-anchor="#example-anchor"
                                                data-aos-offset="500"
                                                data-aos-duration="1000">
                                                <div id='SuccessMSgDiv'>
                                                    <h2 className='SuccessMsg'>Thank you for submitting your request for a quote. We will get back to you shortly.</h2>
                                                    <p className='SuccessMsgDesc'>if you would like to speak with someone right away, feel free to call us at:</p>
                                                    <p className='SuccessMsgDesc mb-5' ><a href="tel:+1-866-882-2829" style={{ color: "#E6001F" }}><FontAwesomeIcon className='headFontawsome' icon={faPhone} />1-866-882-2829</a></p>
                                                </div>
                                            </div>

                                        </div>
                                        {/* <div id='SuccessMSgDiv'>
                                        <h1 className='SuccessMsg'>Thank you for reaching out to us. We will be in touch shortly.</h1>
                                        <p className='SuccessMsgDesc'>For urgent issues, please call us at: </p>
                                        <p className='SuccessMsgDesc mb-5' style={{ color: "#E6001F", cursor: "pointer" }}><strong>1-866-882-2829</strong></p>
                                    </div> */}
                                    </div>
                                </Col>
                                <Col lg={6} md={6} sm={12}>
                                    <div className='reqImgDiv'>
                                        <img className="REQImg" src={Slide5} />
                                        <img className="REQImg" src={Slide4} />
                                    </div>
                                </Col>
                            </Row>
                        </Container>

                    </Container>
                </Container>
            </Fragment>
        );
    }
}

export default SendQuotationSms;