import React, { Component, Fragment } from 'react';
import ContactUs from '../components/ContactUs';
import PageTop from '../components/PageTop';
import VtacQuote from '../components/VtacQuote';
import Footer from '../components/Footer';
import NewTopNavigation from '../components/NewTopNavigation';
class ContactUsPage extends Component {
    render() {
        return (
            <Fragment>
                <NewTopNavigation title="Contact-V-Tac Accounting & Tax | The Professionals' Professional" />
                <PageTop topPageAccSoftTitle="Contact Us" />
                <ContactUs />
                <VtacQuote />
                <Footer />
            </Fragment>
        );
    }
}

export default ContactUsPage;