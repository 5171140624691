import React, { Component, Fragment } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

class PageTop2 extends Component {
    render() {
        return (
            <Fragment>
                <Container fluid={true} className="topFixedPage2 p-0">
                    <div className="topPageOverlay">
                        <Container>
                            <Row>
                                <Col className="text-center">
                             
                                    <h1 className="topPageTitle">{this.props.topPageTitle}</h1>
                                    <p className="topPagesubTitle" >{this.props.topPagesubTitle}</p>
                                    <p className="topPagesubTitle2" >{this.props.topPageaAuthor}</p>
                                    <h2 className="topPageAccSoftTitle">{this.props.topPageAccSoftTitle}</h2>
                                    
                                    
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </Container>
            </Fragment>
        );
    }
}

export default PageTop2;